import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "../../components/Link/Link";
import { Country } from "../../data/models/ContractTypes";

interface Props {
  country: Country;
}

const LinksGDPR: Record<Country, string> = {
  [Country.SWEDEN]: "https://worldline.com/sv-se/compliancy/privacy.html",
  [Country.DENMARK]: "https://worldline.com/da-dk/compliancy/privacy.html",
  [Country.NORWAY]: "https://worldline.com/no-no/compliancy/privacy.html",
  [Country.FINLAND]: "https://worldline.com/fi-fi/compliancy/privacy.html",
  [Country.GB]: "https://worldline.com/en/compliancy/privacy.html",
};

export const PrivacyPolicy: React.FunctionComponent<Props> = ({ country }) => {
  const { t } = useTranslation();

  return (
    <>
      <strong>{t("Privacy policy")}</strong>
      <div className="small mt-1">
        {t(
          "When you become a customer of Worldline, Worldline will start processing your personal data, in order to give you or your company access to our various payment solutions. You can read more about our processing of personal data here"
        )}
        :{" "}
        <Link to={LinksGDPR[country]} external>
          Worldline GDPR
        </Link>
      </div>
    </>
  );
};
