import React from "react";
import cx from "classnames";
import styles from "./Citizenships.module.scss";
import { useTranslation } from "react-i18next";
import { Select } from "../Forms/Select";
import { getCountryOptions } from "../../components/Countries/countryOptions";
import {
  BeneficialOwner,
  CountryCode,
  Language,
  SelectedSignatory,
  SigningPerson,
} from "../../data/models/ContractTypes";
import { CountryChip } from "../../components/Countries/CountryChip";

interface Props {
  person: SelectedSignatory | SigningPerson | BeneficialOwner;
  onChange: (value: string, name: string) => void;
  onRemoveCitizenship: (country: CountryCode) => void;
}

export const Citizenships: React.FunctionComponent<Props> = ({
  person,
  onChange,
  onRemoveCitizenship,
}) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="relative">
      <Select
        className={cx("mt-1", "compact", {
          [styles.hasValue]: !!person.citizenships.length,
        })}
        label={t("Select citizenship")}
        onChange={onChange}
        name="citizenships"
        disabled={person.citizenships.length > 2}
        value=""
        options={getCountryOptions(
          i18n.language as Language,
          person.citizenships
        )}
      />

      <div
        className={cx(
          styles.chips,
          "mt-1",
          styles[`no-${person.citizenships.length}`],
          {
            [styles.hasCitizenships]: person.citizenships.length,
          }
        )}
      >
        {person.citizenships.map((citizenship) => {
          return (
            <button
              onClick={() => {
                onRemoveCitizenship(citizenship);
              }}
              key={citizenship}
            >
              <CountryChip countryCode={citizenship} />
            </button>
          );
        })}
      </div>
    </div>
  );
};
