import React, { ReactNode } from "react";
import { Banner } from "../Banner/Banner";
import { Status } from "../../modules/Forms/FormContext";
import { Button } from "../Buttons/Button";
import { useTranslation } from "react-i18next";

interface Props {
  children?: ReactNode;
  retry?: () => void;
}

export const GenericError: React.FunctionComponent<Props> = ({
  children,
  retry,
}) => {
  const { t } = useTranslation();
  if (children && retry) {
    return (
      <Banner status={Status.ERROR}>
        {children}
        <div className="mt-1">
          <Button block size="small" variant="outlined" onClick={retry}>
            {t("Retry")}
          </Button>
        </div>
      </Banner>
    );
  }

  if (children) {
    return <Banner status={Status.ERROR}>{children}</Banner>;
  }

  if (retry) {
    return (
      <Banner status={Status.ERROR}>
        <b>{t("Something went wrong")}</b>
        <div className="mt-1">
          <Button block size="small" variant="outlined" onClick={retry}>
            {t("Retry")}
          </Button>
        </div>
      </Banner>
    );
  }

  return (
    <Banner status={Status.ERROR}>
      <b>{t("Something went wrong")}</b>
      <div>{t("Refresh the page and try again.")}</div>
    </Banner>
  );
};
