import React, { ReactNode } from "react";
import cx from "classnames";
import styles from "./Wrapper.module.scss";

interface Props {
  children: ReactNode;
  className?: string;
  padded?: boolean;
  highlight?: boolean;
  size?: "mini" | "small" | "large";
}

export const Wrapper: React.FunctionComponent<Props> = ({
  children,
  className,
  padded = true,
  highlight = false,
  size = "",
}) => {
  return (
    <div
      className={cx(styles.wrapper, styles[size], className, {
        [styles.padded]: padded,
        [styles.highlight]: highlight,
      })}
    >
      {children}
    </div>
  );
};
