import React, { ReactNode } from "react";
import cx from "classnames";
import styles from "./InfoBox.module.scss";
import { MdInfo } from "react-icons/md";

interface Props {
  children: ReactNode;
  className?: string;
}

export const InfoBox: React.FunctionComponent<Props> = ({
  children,
  className,
}) => {
  return (
    <div className={cx(styles.infoBox, className)}>
      <MdInfo className={styles.icon} />
      <div className={styles.body}>{children}</div>
    </div>
  );
};
