import React from "react";
import { NavigationStory } from "../../components/Story/Navigation/NavigationStory";
import { SigningPath } from "./routes";
import { Suspense } from "../../pages/Suspense";
import { Sign } from "./Sign/Sign";
import { Complete } from "./Complete/Complete";
import { StoryFrameProps } from "../../components/storyTypes";

export const SIGNING_BASE_ROUTE = "/external/:linkId";

export const SigningName: Record<SigningPath, string> = {
  [SigningPath.SIGNING]: "Signing",
  [SigningPath.COMPLETE]: "Done",
};

export const routes: StoryFrameProps[] = [
  {
    path: SigningPath.SIGNING,
    hideProgress: true,
    element: (
      <Suspense>
        <Sign />
      </Suspense>
    ),
  },
  {
    path: SigningPath.COMPLETE,
    hideProgress: true,
    element: (
      <Suspense>
        <Complete />
      </Suspense>
    ),
  },
];

export const Signing: React.FunctionComponent = () => {
  return <NavigationStory routes={routes} baseRoute={SIGNING_BASE_ROUTE} />;
};
