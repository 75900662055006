import { FaMagnifyingGlass } from "react-icons/fa6";
import { useRiskCase } from "../../hooks/useRiskCase";
import { useTranslation } from "react-i18next";
import { BackofficeCard } from "../../components/card/BackofficeCard";
import { BackofficeCardHeader } from "../../components/card/BackofficeCardHeader";
import { DataList } from "../../components/Datalist/DataList";
import { DataListItem } from "../../components/Datalist/DataListItem";
import { Suspense } from "../../../Suspense";
import Skeleton from "react-loading-skeleton";
import { getIntlDateTime } from "../../../../components/dateUtils";
import { FormattedPrice } from "../../../../components/FormattedPrice/FormattedPrice";

export const RiskCreditsafeCard: React.FC = () => {
  return (
    <BackofficeCard>
      <BackofficeCardHeader title="Creditsafe" icon={FaMagnifyingGlass} />
      <Suspense skeleton={<Skeleton height={100} className="mb-2" />}>
        <Inner />
      </Suspense>
    </BackofficeCard>
  );
};

const Inner = () => {
  const { t } = useTranslation();

  const {
    data: {
      screeningResults: { creditsafe },
    },
  } = useRiskCase();
  const { screened, creditScore, mainActivity } = creditsafe;

  return (
    <div>
      <DataList type="horizontal" align="left">
        <DataListItem label={t("Date")} value={getIntlDateTime(screened)} />
      </DataList>

      <hr />

      <DataList type="vertical" align="left" columns>
        <DataListItem
          label={t("Industry")}
          value={mainActivity.industrySector}
          column
        />
        <DataListItem
          label={t("Activity description")}
          value={mainActivity.description}
          column
        />
        <DataListItem
          label={t("Credit score")}
          value={creditScore.creditScore}
          column
        />
        <DataListItem
          label={t("Credit limit")}
          value={
            creditScore.creditLimit ? (
              typeof creditScore.creditLimit === "number" ? (
                <FormattedPrice
                  value={creditScore.creditLimit}
                  currency={creditScore.creditLimitCurrency}
                />
              ) : (
                creditScore.creditLimit
              )
            ) : (
              t("Not available")
            )
          }
          column
        />
        <DataListItem
          label={t("Description")}
          value={creditScore.providerDescription}
          column
        />
        <DataListItem
          label={t("Assessment")}
          value={creditScore.assessment}
          column
        />
        <DataListItem
          label={t("Probability of default")}
          value={creditScore.pod}
          column
        />
        <DataListItem
          label={t("Common value")}
          value={creditScore.commonValue}
          column
        />
      </DataList>
    </div>
  );
};
