import { Country } from "../../data/models/ContractTypes";

export interface PhoneValidatorResponseInvalid {
  valid: boolean;
}

export interface PhoneValidatorResponseCountry {
  valid: boolean;
  country: Country;
  prefix: string;
}

export interface PhoneValidatorResponseName {
  valid: boolean;
  name: string;
  prefix: string;
}

export const PHONE_TO_DEFINED_COUNTRY_CODE: Record<string, Country> = {
  "+46": Country.SWEDEN,
  "+45": Country.DENMARK,
  "+358": Country.FINLAND,
  "+47": Country.NORWAY,
};

export const EXAMPLE_PHONE_NUMBER: Record<Country, string> = {
  [Country.SWEDEN]: "70 123 45 67",
  [Country.DENMARK]: "20 12 34 56",
  [Country.FINLAND]: "40 123 4567",
  [Country.NORWAY]: "91 23 45 67",
  [Country.GB]: "7712 345 678",
};

export const PrefixMap: Record<Country, string> = {
  [Country.SWEDEN]: "+46",
  [Country.DENMARK]: "+45",
  [Country.NORWAY]: "+47",
  [Country.FINLAND]: "+358",
  [Country.GB]: "+44",
};

export const PHONE_TO_UNDEFINED_COUNTRY_CODE: Record<string, string> = {
  "+48": "PL",
  "+385": "HR",
  "+32": "BE",
  "+359": "BG",
  "+49": "DE",
  "+372": "EE",
  "+353": "IE",
  "+30": "GR",
  "+34": "ES",
  "+33": "FR",
  "+39": "IT",
  "+357": "CY",
  "+371": "LV",
  "+370": "LT",
  "+352": "LU",
  "+36": "HU",
  "+356": "MT",
  "+31": "NL",
  "+43": "AT",
  "+351": "PT",
  "+40": "RO",
  "+386": "SI",
  "+421": "SK",
};

const inList = (
  list: Record<string, string | Country>,
  phoneNumber: string
) => {
  for (let i = 5; i >= 1; i--) {
    const code = phoneNumber.substring(0, i);
    const country = list[code];
    if (country) {
      return { country, prefix: code };
    }
  }
};

export function sanitizePhoneNumber(phoneNumber?: string) {
  if (!phoneNumber) {
    return "";
  }

  const sanitizedNumber = phoneNumber
    .replace(/[--]/g, " ")
    .replace(/[^0-9\s+]/g, "")
    .replace(/ {2,}/g, " ")
    .trim();

  return sanitizedNumber;
}

export function replacePhoneNumberBeginning(phoneNumber?: string) {
  if (!phoneNumber) {
    return "";
  }

  const phoneItem = getCountryAndPrefixFromPhoneNumber(phoneNumber);

  if (!phoneItem.valid) {
    throw new Error("Can not match phone number prefix");
  }

  const prefix = (
    phoneItem as PhoneValidatorResponseCountry | PhoneValidatorResponseName
  ).prefix;

  // Found no prefix for this country
  if (!prefix) {
    return phoneNumber;
  }

  const zeroedPrefix = prefix.replace("+", "00");
  const prefixLength = prefix.length;

  if (phoneNumber.startsWith(prefix)) {
    if (phoneNumber.charAt(prefixLength) === "0") {
      return (
        phoneNumber.slice(0, prefixLength) + phoneNumber.slice(prefixLength + 1)
      );
    }
  } else if (phoneNumber.startsWith(zeroedPrefix)) {
    return phoneNumber.replace("00", "+");
  } else if (phoneNumber.startsWith("00")) {
    return phoneNumber.replace("00", `${prefix} `);
  } else if (phoneNumber.startsWith("0")) {
    return phoneNumber.replace("0", `${prefix} `);
  }

  return phoneNumber;
}

export function getCountryAndPrefixFromPhoneNumber(
  phoneNumber?: string
):
  | PhoneValidatorResponseInvalid
  | PhoneValidatorResponseCountry
  | PhoneValidatorResponseName {
  if (!phoneNumber) {
    return {
      valid: false,
    };
  }

  if (typeof phoneNumber !== "string") {
    return {
      valid: false,
    };
  }

  const definedItem = inList(PHONE_TO_DEFINED_COUNTRY_CODE, phoneNumber);
  const country = definedItem?.country as Country;

  if (country) {
    return {
      valid: true,
      country,
      prefix: definedItem?.prefix,
    };
  }

  const undefinedItem = inList(PHONE_TO_UNDEFINED_COUNTRY_CODE, phoneNumber);
  const name = undefinedItem?.country;

  if (name) {
    return {
      valid: true,
      name,
      prefix: undefinedItem?.prefix,
    };
  }

  return {
    valid: false,
  };
}
