import React from "react";
import styles from "./Timeline.module.scss";

export const Circle: React.FunctionComponent = () => {
  return (
    <svg
      className={styles.circle}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="8" />
    </svg>
  );
};
