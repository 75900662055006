import React, {
  useCallback,
  ReactNode,
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
  useMemo,
} from "react";
import ReactDOM from "react-dom";
import cx from "classnames";
import styles from "./Overlay.module.scss";

interface Props {
  open: boolean;
  children: ReactNode;
  onClose?: () => void;
  className?: string;
  local?: boolean;
  size?: "small" | "medium" | "large";
  padded?: boolean;
  overflow?: boolean;
}

const ESC = "Escape";

const InnerOverlay: React.FunctionComponent<{
  children: ReactNode;
  tryClose: () => void;
}> = ({ children, tryClose }) => {
  if (!children) {
    return null;
  }

  return (
    <>
      <div className={styles.backdrop} onClick={tryClose} />
      <div className={styles.dialogWrapper}>
        <div className={styles.dialogInner}>
          {/* <Action className={styles.close} size="small" onClick={tryClose}>
            <MdClose />
          </Action> */}
          <dialog className={styles.dialog} open>
            <div className={styles.body}>{children}</div>
          </dialog>
        </div>
      </div>
    </>
  );
};

export const Overlay: React.FunctionComponent<Props> = ({
  open,
  children,
  onClose,
  className,
  local = false,
  size = "medium",
  padded = true,
  overflow = false,
}) => {
  const [elems, setElems] = useState<ReactNode>(null);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const dialogRef = useRef<HTMLDivElement>(null);
  const portalElementRef = React.useRef<Element>(
    document.getElementById("overlay-portal")
  );

  const dialogId = useRef(
    "dialog" + Math.random().toString(36).substring(2, 9)
  );

  const onTransitionEnd = useCallback(
    (ev: TransitionEvent) => {
      if ((ev.target as HTMLElement).classList.contains(dialogId.current)) {
        if (ev.propertyName !== "opacity") {
          return;
        }

        if (!open) {
          setElems(null);
        }
      }
    },
    [open]
  );

  useEffect(() => {
    if (!dialogRef.current) {
      return;
    }
    const ref = dialogRef.current;
    ref.addEventListener("transitionend", onTransitionEnd);

    return () => {
      ref.removeEventListener("transitionend", onTransitionEnd);
    };
  }, [onTransitionEnd]);

  const tryClose = useCallback(() => {
    if (!onClose) {
      return;
    }

    onClose();
  }, [onClose]);

  useEffect(() => {
    if (open) {
      setElems(children);
    } else {
      setShowDialog(false);
    }
  }, [open, children]);

  useLayoutEffect(() => {
    if (elems) {
      setShowDialog(true);
    }
  }, [elems]);

  const handler = useCallback(
    (ev: KeyboardEvent) => {
      if (!onClose) {
        return;
      }

      if (ev.code === ESC) {
        (document.activeElement as HTMLElement).blur();
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    window.addEventListener("keydown", handler, false);
    return () => {
      window.removeEventListener("keydown", handler, false);
    };
  }, [handler]);

  const classes = useMemo(
    () =>
      cx(styles.wrapper, dialogId.current, className, styles[size], {
        [styles.show]: showDialog,
        [styles.padded]: padded,
        [styles.allowClose]: !!onClose,
        [styles.hideOverflow]: !overflow,
      }),
    [showDialog, size, padded, onClose, overflow, className]
  );

  if (!open && !elems) {
    return null;
  }

  if (!local && portalElementRef.current) {
    return ReactDOM.createPortal(
      <div className={classes} ref={dialogRef}>
        <InnerOverlay tryClose={tryClose}>{elems}</InnerOverlay>
      </div>,
      portalElementRef.current
    );
  }

  return (
    <div className={classes} ref={dialogRef}>
      <InnerOverlay tryClose={tryClose}>{elems}</InnerOverlay>
    </div>
  );
};
