import { queryClient } from "..";
import { API } from "./API";
import { dataContract } from "./dataContract";
import { ContractDocument, DocumentId } from "./models/ContractTypes";

const QUERY_KEY = "document";

export const dataDocument = {
  QUERY_KEY,
  getDocuments: () => ({
    queryKey: [QUERY_KEY],
    queryFn: () => API.get<ContractDocument[]>(`/merchant/documents`),
  }),

  saveDocument: (documentId: DocumentId) => ({
    mutationFn: async (document: File) => {
      const formData = new FormData();
      formData.append("document", document);

      return await API.postFormData<void>(
        `/merchant/documents/file/${documentId}`,
        formData
      );
    },
    onSuccess: async () => {
      // could run in parallel here...
      await queryClient.invalidateQueries({
        queryKey: dataDocument.getDocuments().queryKey,
      });
      await queryClient.invalidateQueries({
        queryKey: dataContract.getContractKey(),
      });
    },
  }),

  deleteDocument: (documentId: DocumentId) => ({
    mutationFn: () => API.delete(`/merchant/documents/file/${documentId}`),
    onSuccess: async () => {
      // could run in parallel here...
      await queryClient.invalidateQueries({
        queryKey: dataDocument.getDocuments().queryKey,
      });
      await queryClient.invalidateQueries({
        queryKey: dataContract.getContractKey(),
      });
    },
  }),
};

export const getContactDocumentUrl = (document: ContractDocument) =>
  API.getUrl(`/merchant/documents/file/${document.documentId}`);
