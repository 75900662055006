import React, { RefObject, useImperativeHandle, useRef } from "react";
import { useValidation } from "./hooks/useValidation";
import { useForm } from "./hooks/useForm";
import cx from "classnames";
import styles from "./HiddenInput.module.scss";
import { TextInputProps } from "./TextInput";
import { Dynamic } from "../../components/Animations/Dynamic";

interface Props extends Omit<TextInputProps, "onChange" | "label"> {
  value?: any;
  name?: string;
  scrollToRef?: RefObject<HTMLElement>;
  className?: string;
}

export const HiddenInput = React.forwardRef<HTMLDivElement, Props>(
  ({ value = "", name, validators = [], scrollToRef, className }, ref) => {
    const inputId = useRef(
      "hidden_" + Math.random().toString(36).substring(2, 9)
    );
    const innerRef = useRef<HTMLDivElement>(null);
    useImperativeHandle(ref, () => innerRef.current as HTMLDivElement);

    const [validity, errorMessages, showFormErrors] = useValidation(
      value,
      validators
    );
    useForm(
      inputId.current,
      validity,
      value,
      () => {},
      scrollToRef || innerRef
    );

    const showErrors = showFormErrors && errorMessages.length > 0;

    return (
      <div className={cx(styles.hidden, className)} ref={innerRef}>
        <input
          value={value}
          type="hidden"
          id={inputId.current}
          name={name || inputId.current}
        />

        <Dynamic
          name={`${showErrors}${errorMessages[0]?.toString() ?? "empty"}`}
        >
          {showErrors ? errorMessages[0] : null}
        </Dynamic>
      </div>
    );
  }
);
