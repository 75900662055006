import { FaBuilding } from "react-icons/fa6";
import { useRiskCase } from "../../hooks/useRiskCase";
import { useTranslation } from "react-i18next";
import { BackofficeCard } from "../../components/card/BackofficeCard";
import { BackofficeCardHeader } from "../../components/card/BackofficeCardHeader";
import { DataList } from "../../components/Datalist/DataList";
import { DataListItem } from "../../components/Datalist/DataListItem";
import { Suspense } from "../../../Suspense";
import Skeleton from "react-loading-skeleton";
import { MembershipOptions } from "../../../../data/models/ContractTypes";

export const RiskPurposeCard: React.FC = () => {
  const { t } = useTranslation();

  return (
    <BackofficeCard>
      <BackofficeCardHeader
        title={t("Business Purpose and Nature")}
        icon={FaBuilding}
      />
      <Suspense skeleton={<Skeleton height={100} className="mb-2" />}>
        <Inner />
      </Suspense>
    </BackofficeCard>
  );
};

const Inner = () => {
  const { t } = useTranslation();

  const {
    data: { contract },
  } = useRiskCase();
  const { businessRisk, financialKyc, companyDetails } = contract;

  const {
    hasFormerAcquirer,
    formerAcquirer,
    hasPrepaid,
    prepaidOptions,
    hasSubscriptionAndMembership,
    subscriptionAndMembership,
    hasGiftCards,
    giftCard,
  } = businessRisk;

  const { industryCode, industryText } = companyDetails;

  const { businessModel, productDescription } = financialKyc;

  return (
    <div>
      <DataList type="horizontal" align="left">
        <DataListItem label={t("Business model")} value={businessModel} />
        <DataListItem
          label={t("Product description")}
          value={productDescription}
        />
        {hasFormerAcquirer && (
          <DataListItem label={t("Former aquirer")} value={formerAcquirer} />
        )}
      </DataList>

      <hr />

      <DataList type="horizontal" align="left">
        <DataListItem label={t("Industry code")} value={industryCode} />
        <DataListItem label={t("Industry text")} value={industryText} />
      </DataList>

      <hr />

      <DataList type="horizontal" align="left">
        <DataListItem
          label={t("Pre payments (TTD)")}
          value={hasPrepaid ? t("Yes") : t("No")}
        />

        {hasPrepaid && prepaidOptions && (
          <DataListItem
            label={t("Pre payments (TTD) details")}
            value={
              <table style={{ borderSpacing: 0, width: "100%" }}>
                <tbody>
                  {Object.entries(prepaidOptions).map(([key, value]) => (
                    <tr key={key}>
                      <td style={{ paddingRight: "var(--spacing)" }}>
                        {key} days
                      </td>
                      <td align="right">{value}%</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            }
          />
        )}
      </DataList>

      <hr />

      <DataList type="horizontal" align="left">
        <DataListItem
          label={t("Subscription and membership")}
          value={hasSubscriptionAndMembership ? t("Yes") : t("No")}
        />
        {hasSubscriptionAndMembership && (
          <>
            <DataListItem
              label={t("As percentage of sales")}
              value={subscriptionAndMembership?.salesAmount + "%"}
            />
            <DataListItem
              label={t("0 - 1 months")}
              value={
                (subscriptionAndMembership?.[MembershipOptions.ZERO__ONE] ||
                  0) + "%"
              }
              column
            />
            <DataListItem
              label={t("3 months")}
              value={
                (subscriptionAndMembership?.[MembershipOptions.THREE] || 0) +
                "%"
              }
              column
            />
            <DataListItem
              label={t("12 months")}
              value={
                (subscriptionAndMembership?.[MembershipOptions.YEAR] || 0) + "%"
              }
              column
            />
            <DataListItem
              label={t("Other")}
              value={
                (subscriptionAndMembership?.[MembershipOptions.OTHER] || 0) +
                "%"
              }
              column
            />
          </>
        )}
      </DataList>

      <hr />

      <DataList type="horizontal" align="left">
        <DataListItem
          label={t("Gift cards")}
          value={hasGiftCards ? t("Yes") : t("No")}
        />
        {hasGiftCards && (
          <>
            <DataListItem
              label={t("As percentage of sales")}
              value={(giftCard?.salesAmount || 0) + "%"}
              column
            />
            <DataListItem
              label={t("Validity period")}
              value={giftCard?.validityPeriod}
              column
            />
          </>
        )}
      </DataList>
    </div>
  );
};
