import React, { SyntheticEvent, useCallback } from "react";
import {
  NumberFormatValues,
  NumericFormat,
  SourceInfo,
} from "react-number-format";
import { InputWrapper } from "./InputWrapper";
import { InputProps } from "./Input";

export const defaultNumberFormatting = {
  thousandSeparator: " ",
  decimalSeparator: ",",
  inputMode: "decimal",
  suffix: "",
  prefix: "",
  allowedDecimalSeparators: [".", ","],
  allowNegative: false,
  allowLeadingZeros: false,
  decimalScale: 2,
};

export interface NumberProps extends Omit<InputProps, "value" | "onChange"> {
  value?: number;
  formatInputValues?: any;
  onChange: (
    value: number | undefined,
    name: string,
    ev?:
      | SyntheticEvent<HTMLInputElement, Event>
      | React.MouseEvent<HTMLButtonElement>
  ) => void;
}

export const Number = React.forwardRef<HTMLInputElement, NumberProps>(
  (props, ref) => {
    const {
      value,
      name,
      onChange,
      onBlur,
      placeholder,
      disabled,
      constraints,
      autocomplete,
      formatInputValues,
    } = props;

    const change = useCallback(
      (values: NumberFormatValues, ev: SourceInfo) => {
        onChange(values.floatValue, name, ev.event);
      },
      [name, onChange]
    );

    const blur = useCallback(
      (ev: React.ChangeEvent<HTMLInputElement>) =>
        onBlur && onBlur(ev.target.value, name, ev),
      [name, onBlur]
    );

    return (
      <InputWrapper {...props} value={value?.toString()}>
        <NumericFormat
          {...formatInputValues}
          value={value ?? ""}
          onValueChange={change}
          onBlur={blur}
          getInputRef={ref}
          autoComplete={autocomplete}
          placeholder={placeholder}
          required={constraints.isRequired}
          disabled={disabled}
        />
      </InputWrapper>
    );
  }
);
