import React from "react";
import cx from "classnames";
import styles from "./ClearButton.module.scss";
import { Button } from "../../../components/Buttons/Button";
import { FaXmark } from "react-icons/fa6";

interface Props {
  onClear?: (ev: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  value?: string;
}

export const ClearButton: React.FunctionComponent<Props> = ({
  onClear,
  disabled = false,
  value,
}) => {
  if (!onClear) {
    return null;
  }

  if (disabled) {
    return null;
  }

  return (
    <Button
      tabIndex={value?.length ? 0 : -1}
      className={cx(styles.clear, {
        [styles.hasValue]: !!value,
      })}
      action
      variant="text"
      onClick={(event) => onClear(event)}
    >
      <FaXmark />
    </Button>
  );
};
