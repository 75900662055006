import { FaMagnifyingGlass } from "react-icons/fa6";
import { useRiskCase } from "../../hooks/useRiskCase";
import { useTranslation } from "react-i18next";
import { BackofficeCard } from "../../components/card/BackofficeCard";
import { BackofficeCardHeader } from "../../components/card/BackofficeCardHeader";
import { DataList } from "../../components/Datalist/DataList";
import { DataListItem } from "../../components/Datalist/DataListItem";
import { Suspense } from "../../../Suspense";
import Skeleton from "react-loading-skeleton";
import { getIntlDateTime } from "../../../../components/dateUtils";
import { Beacon } from "../../../../components/Beacon/Beacon";
import { Validity } from "../../../../data/models/ContractTypes";

export const RiskMatchCard: React.FC = () => {
  return (
    <BackofficeCard>
      <BackofficeCardHeader title="Match" icon={FaMagnifyingGlass} />
      <Suspense skeleton={<Skeleton height={100} className="mb-2" />}>
        <Inner />
      </Suspense>
    </BackofficeCard>
  );
};

const Inner = () => {
  const { t } = useTranslation();

  const {
    data: {
      screeningResults: { match: matchResult },
    },
  } = useRiskCase();
  const { screened, inquiryMatch, trueMatch, match } = matchResult;

  return (
    <div>
      <DataList type="horizontal" align="left">
        <DataListItem label={t("Date")} value={getIntlDateTime(screened)} />
      </DataList>

      <hr />

      <DataList type="vertical" align="left" columns>
        <DataListItem
          label="Inquiry Match"
          value={
            <span className="flex align-center gap-1">
              <Beacon
                className="flex"
                validity={inquiryMatch ? Validity.MISSING : Validity.VALID}
              />
              {inquiryMatch ? t("Hit") : t("Clear")}
            </span>
          }
          column
        />
        <DataListItem
          label="True Match"
          value={
            <span className="flex align-center gap-1">
              <Beacon
                className="flex"
                validity={trueMatch ? Validity.MISSING : Validity.VALID}
              />
              {trueMatch ? t("Hit") : t("Clear")}
            </span>
          }
          column
        />
      </DataList>

      {match && (
        <>
          <hr />
          <DataList type="vertical" align="left">
            <DataListItem label={t("Reason code")} value={match.reasonCode} />
            <DataListItem label={t("Reason")} value={match.reason} />
          </DataList>
          <div className="mt-2">
            <div dangerouslySetInnerHTML={{ __html: match.renderedHtml }} />
          </div>
        </>
      )}
    </div>
  );
};
