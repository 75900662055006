import React from "react";
import { AvailableSignatory } from "../../../data/models/ContractTypes";

interface Props {
  signatory: AvailableSignatory;
}

export const Signatory: React.FunctionComponent<Props> = ({ signatory }) => {
  return <li>{signatory.name}</li>;
};
