import { FaSignature } from "react-icons/fa6";
import { useRiskCase } from "../../hooks/useRiskCase";
import { useTranslation } from "react-i18next";
import { BackofficeCard } from "../../components/card/BackofficeCard";
import { BackofficeCardHeader } from "../../components/card/BackofficeCardHeader";
import { DataList } from "../../components/Datalist/DataList";
import { DataListItem } from "../../components/Datalist/DataListItem";
import { Suspense } from "../../../Suspense";
import Skeleton from "react-loading-skeleton";
import { Fragment } from "react/jsx-runtime";
import { getIntlDateTime } from "../../../../components/dateUtils";
import { useState } from "react";
import { Button } from "../../../../components/Buttons/Button";
import { Dynamic } from "../../../../components/Animations/Dynamic";
import { Box } from "../../../../components/Boxes/Box";

export const RiskSignatoriesCard: React.FC = () => {
  const { t } = useTranslation();

  return (
    <BackofficeCard>
      <BackofficeCardHeader title={t("Signatories")} icon={FaSignature} />
      <Suspense skeleton={<Skeleton height={100} className="mb-2" />}>
        <Inner />
      </Suspense>
    </BackofficeCard>
  );
};

const Inner = () => {
  const { t } = useTranslation();
  const [showSigningGroups, setShowSigningGroups] = useState(false);

  const {
    data: { contract },
  } = useRiskCase();

  const { selectedSignatories, signingGroups } = contract;

  return (
    <div>
      {selectedSignatories.map((signatory, index, acc) => (
        <Fragment key={signatory.id}>
          <DataList type="horizontal" align="left" className="mb-2">
            <DataListItem label={t("Name")} value={signatory.name} />
            <DataListItem
              label={t("Personal identity number")}
              value={signatory.nationalId}
            />
            <DataListItem
              label={t("Nationality")}
              value={signatory.nationality}
            />
            <DataListItem
              label={t("Date of birth")}
              value={signatory.dateOfBirth}
            />
            <DataListItem
              label={t("Place of birth")}
              value={signatory.placeOfBirth}
            />
            <DataListItem
              label={t("Citizenship(s)")}
              value={signatory.citizenships?.join(", ")}
            />
            <DataListItem
              label={t("Country of residence")}
              value={signatory.countryOfResidence}
            />
            <DataListItem
              label={t("Position in company")}
              value={signatory.position}
            />
          </DataList>
          <DataList type="vertical" align="left" key={signatory.id} columns>
            <DataListItem label={t("Email")} value={signatory.email} column />
            <DataListItem
              label={t("Time of signing")}
              value={getIntlDateTime(signatory.signed)}
              column
            />
          </DataList>
          {index !== acc.length - 1 && <hr />}
        </Fragment>
      ))}

      {signingGroups.length > 0 && (
        <>
          <hr />
          <Button
            variant="outlined"
            size="mini"
            block
            onClick={() => setShowSigningGroups(!showSigningGroups)}
          >
            {showSigningGroups
              ? t("Hide signing groups")
              : t("View signing groups")}
          </Button>
        </>
      )}
      <Dynamic name={showSigningGroups ? "show" : "hide"}>
        {showSigningGroups && (
          <div className="mt-2 flex-column gap-1">
            {signingGroups.map((group) => (
              <Box key={group.id} noIcon>
                {group.signees.map((signatory, index, array) => (
                  <Fragment key={index}>
                    <DataList type="horizontal" align="left" className="mb-2">
                      <DataListItem label={t("Name")} value={signatory.name} />
                      <DataListItem
                        label={t("Positions")}
                        value={signatory.positions}
                      />
                      <DataListItem
                        label={t("Primary contact")}
                        value={signatory.primaryContact ? t("Yes") : t("No")}
                      />
                    </DataList>
                    {index !== array.length - 1 && <hr />}
                  </Fragment>
                ))}
              </Box>
            ))}
          </div>
        )}
      </Dynamic>
    </div>
  );
};
