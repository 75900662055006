import React from "react";
import styles from "./Timeline.module.scss";

export const Check: React.FunctionComponent = () => {
  return (
    <svg
      className={styles.check}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="10" />
      <path d="M8 8 L8 14 L18 14" transform="translate(-7, 9)rotate(-45)" />
    </svg>
  );
};
