import { PropsWithChildren } from "react";
import styles from "./BackofficeCard.module.scss";
import clsx from "classnames";

interface Props extends PropsWithChildren {
  className?: string;
}

export const BackofficeCard: React.FC<Props> = ({ children, className }) => {
  return <div className={clsx(styles.card, className)}>{children}</div>;
};

export const BackofficeCardHighlight: React.FC<Props> = ({
  children,
  className,
}) => {
  return (
    <div className={clsx(styles.cardHighlight, className)}>{children}</div>
  );
};
