import i18next from "i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { Language } from "./data/models/ContractTypes";
import resourcesToBackend from "i18next-resources-to-backend";

export type TI = any;

export enum TRANSLATION_NAMESPACE {
  COMMON = "common",
  MCC = "mcc",
}

export const DEFAULT_TRANSLATION_NAMESPACE = TRANSLATION_NAMESPACE.COMMON;

i18next
  .use(initReactI18next)
  .use(
    resourcesToBackend(
      (language: string, namespace: string) =>
        import(`./translations/${language}/${namespace}.json`)
    )
  )
  .use(
    new I18nextBrowserLanguageDetector(undefined, {
      order: ["localStorage"],
    })
  )
  .init({
    debug: false,
    returnEmptyString: false,
    // Since our translation keys are the translation in english, we should not need a fallback
    fallbackLng: Language.ENGLISH,
    ns: Object.values(TRANSLATION_NAMESPACE),
    defaultNS: DEFAULT_TRANSLATION_NAMESPACE,
    interpolation: {
      escapeValue: false,
    },
    load: "languageOnly",
  });

export default i18next;
