import { RefObject, useEffect, useState } from "react";
import { FormContainer } from "../modules/Forms/Form";

export const useIsFormValid = (
  formContainer:
    | React.MutableRefObject<FormContainer | undefined>
    | RefObject<FormContainer>
    | null
) => {
  const [isFormValid, setIsFormValid] = useState(
    formContainer?.current?.isValid ?? false
  );

  useEffect(() => {
    if (!formContainer) return;

    const onUpdate = () => {
      if (
        formContainer.current &&
        isFormValid !== formContainer.current.isValid
      ) {
        setTimeout(() => {
          setIsFormValid(formContainer.current?.isValid ?? false);
        }, 0);
      }
    };

    const container = formContainer.current;
    container?.addListener(onUpdate);
    return () => {
      container?.removeListener(onUpdate);
    };
  }, [formContainer, isFormValid]);

  return isFormValid;
};

export const useIsFormInvalid = (
  formContainer:
    | React.MutableRefObject<FormContainer | undefined>
    | RefObject<FormContainer>
    | null
) => {
  const [isFormInvalid, setIsFormInvalid] = useState(
    formContainer?.current?.isInvalid ?? false
  );

  useEffect(() => {
    if (!formContainer) return;

    const onUpdate = () => {
      if (
        formContainer.current &&
        isFormInvalid !== formContainer.current.isInvalid
      ) {
        setTimeout(() => {
          setIsFormInvalid(formContainer.current?.isInvalid ?? false);
        }, 0);
      }
    };

    const container = formContainer.current;
    container?.addListener(onUpdate);
    return () => {
      container?.removeListener(onUpdate);
    };
  }, [formContainer, isFormInvalid]);

  return isFormInvalid;
};
