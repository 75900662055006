import React, { useCallback, useEffect, useState } from "react";
import cx from "classnames";
import styles from "./Sign.module.scss";
import { useTranslation } from "react-i18next";
import { useLinkId } from "../../../hooks/useLinkId";
import { useStoryNavigate } from "../../../hooks/useStoryNavigate";
import { Wrapper } from "../../../components/Wrapper";
import { SigningPath } from "../routes";
import { Button } from "../../../components/Buttons/Button";
import { useSearchParams } from "react-router-dom";
import {
  dataSigning,
  QUERY_KEY,
  QUERY_RESULT_ABORTED,
  QUERY_RESULT_SUCCESS,
} from "../../../data/dataSigning";
import { useMutation, useSuspenseQueries } from "@tanstack/react-query";
import { Form } from "../../../modules/Forms/Form";
import { Dynamic } from "../../../components/Animations/Dynamic";
import { GenericError } from "../../../components/Errors/GenericError";
import { Status } from "../../../modules/Forms/FormContext";
import { CountryCode, SigningPerson } from "../../../data/models/ContractTypes";
import { onNextFrame } from "../../../components/utils";
import { SignatoryForm } from "../../../modules/SignatoryForm/SignatoryForm";
import { queryClient } from "../../..";
import { FakeSign } from "../../Onboarding/Summary/Summary";
import { ContractThumbnail } from "../../../components/Icons/ContractThumbnail";
import { PrivacyPolicy } from "../../../modules/TermsAndConditions/PrivacyPolicy";
import { TermsAndConditions } from "../../../modules/TermsAndConditions/TermsAndConditions";
import { Confirm } from "../../../modules/TermsAndConditions/Confirm";
import { getDefaultCountryByLanguage } from "../../../components/langUtils";
import {
  ButtonContent,
  CountryToMethod,
} from "../../Onboarding/SignIn/Identification";

function getForwardSignedUrl() {
  return `${SigningPath.COMPLETE}?${QUERY_KEY}=${QUERY_RESULT_SUCCESS}` as SigningPath;
}

export const Sign: React.FunctionComponent = () => {
  const { t, i18n } = useTranslation();
  const linkId = useLinkId();
  const [searchParams] = useSearchParams();
  const signingStatus = searchParams.get(QUERY_KEY);
  const queryKey = dataSigning.loadSigningPersonKey(linkId);

  const { navigate } = useStoryNavigate();

  const [{ data: signingPerson }] = useSuspenseQueries({
    queries: [dataSigning.fetchSigningStatus(linkId)],
  });

  const [signatory, setSignatory] = useState<SigningPerson>(signingPerson);

  useEffect(() => {
    if (signingStatus === QUERY_RESULT_SUCCESS) {
      navigate(getForwardSignedUrl());
      return;
    }

    if (signatory.signed) {
      navigate(SigningPath.COMPLETE);
      return;
    }
  }, [signatory.signed, navigate, signingStatus]);

  const {
    mutate: getSigningLink,
    isPending,
    isError,
  } = useMutation({
    mutationFn: () => dataSigning.loadSigningLink(linkId),
    onSuccess: (data) => {
      if (data.signed) {
        navigate(getForwardSignedUrl());
        return;
      }

      window.location.href = data.signUrl;
    },
  });

  const {
    // TODO
    isError: isSaveError, // eslint-disable-line
    isPending: isSavePending, // eslint-disable-line
    reset, // eslint-disable-line
    mutate: onSave,
  } = useMutation({
    mutationFn: async () => dataSigning.updateSigningPerson(linkId, signatory),
    onMutate: async () => {
      await queryClient.cancelQueries({
        queryKey,
      });

      const previousSave = queryClient.getQueryData<SigningPerson>(queryKey);
      if (!previousSave) {
        return;
      }

      queryClient.setQueryData<SigningPerson>(queryKey, signatory);

      return { previousSave };
    },
    onError: (err, store, context) => {
      if (!context) {
        return;
      }

      setSignatory(context.previousSave);
      queryClient.setQueryData(queryKey, context.previousSave);
    },
  });

  const onChange = useCallback((value: string, name: string) => {
    setSignatory((prev) => {
      if (Array.isArray(prev[name as keyof SigningPerson])) {
        return {
          ...prev,
          [name]: (prev[name as keyof SigningPerson] as string[]).concat(value),
        };
      }

      return { ...prev, [name]: value };
    });
  }, []);

  const onRemoveCitizenship = useCallback(
    (value: CountryCode) => {
      const copy = {
        ...signatory,
        citizenships: signatory.citizenships.filter((item) => item !== value),
      };

      setSignatory(copy);
      onNextFrame(() => {
        onSave();
      });
    },
    [signatory, onSave]
  );

  const { mutate: fakeSign } = useMutation({
    mutationFn: () => dataSigning.fakeSign(),
    onSuccess: () => {
      navigate(SigningPath.COMPLETE);
    },
  });

  const buttonStatus = isPending ? Status.DISABLED : Status.DEFAULT;

  let ingress = t(
    "{{companyName}} has been registered for Worldline payment services. Since you are a person authorized to sign for the firm we need a signature from you in order to proceed with the application.",
    {
      companyName: signatory.companyName,
    }
  );

  if (signatory.primary) {
    ingress = t(
      "We are happy that you have decided to use Worldline for accepting payments. In order for us to set up the account we would need your signature."
    );
  }

  const signingMethod = CountryToMethod[signatory?.nationality];

  return (
    <Wrapper>
      <div className={styles.wrapper}>
        <h2>
          {t("Hi, {{name}}", {
            name: signatory.name,
          })}
        </h2>
        <p>{ingress}</p>
        <Form
          className="mt-8"
          onSaveTrigger={() => onSave()}
          onSubmit={(_, form) => {
            if (form.isInvalid) {
              return;
            }

            getSigningLink();
          }}
        >
          <ContractThumbnail />

          {signatory.primary ? null : (
            <div className="mt-8">
              <p>
                {t(
                  "Before signing the contract, we need some information from you."
                )}
              </p>
              <div className="mt-4">
                <SignatoryForm
                  onChange={onChange}
                  onRemoveCitizenship={onRemoveCitizenship}
                  person={signatory}
                />
              </div>
            </div>
          )}

          <div className="mt-8">
            <PrivacyPolicy
              country={
                signatory.nationality ||
                getDefaultCountryByLanguage(i18n.language)
              }
            />
          </div>

          <div className="mt-5">
            <TermsAndConditions
              country={
                signatory.nationality ||
                getDefaultCountryByLanguage(i18n.language)
              }
            />
          </div>

          <hr className="mt-5" />

          <div className="mt-5">
            <Confirm />
          </div>

          <Dynamic name={isError.toString()}>
            {isError ? <GenericError /> : null}
          </Dynamic>

          <Dynamic name={signingStatus || "empty"}>
            {signingStatus === QUERY_RESULT_ABORTED ? <GenericError /> : null}
          </Dynamic>

          <div className={cx("mt-8", styles.signButton)}>
            <Button type="submit" block status={buttonStatus}>
              <ButtonContent
                signingMethod={signingMethod}
                buttonText={t("Sign")}
              />
            </Button>
          </div>
        </Form>
        <div className="mt-8">
          <FakeSign fakeSign={fakeSign} />
        </div>
      </div>
    </Wrapper>
  );
};
