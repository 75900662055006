import { Language, UTCDate } from "../data/models/ContractTypes";
import i18n from "../i18n";

const MISSING_DATE = " - ";

export function getIntlDate(
  date?: Date | string | UTCDate,
  language?: string,
  options?: Intl.DateTimeFormatOptions
) {
  if (!date) {
    return MISSING_DATE;
  }

  const dateObject = new Date(date);

  if (dateObject instanceof Date && !isNaN(dateObject as any)) {
    return new Intl.DateTimeFormat(
      language || navigator.language,
      options
    ).format(dateObject);
  } else {
    return MISSING_DATE;
  }
}

export function getIntlDateTime(
  date?: Date | string | UTCDate,
  locale?: string,
  options?: Intl.DateTimeFormatOptions
) {
  if (!date) {
    return MISSING_DATE;
  }

  const dateObject = new Date(date);

  if (dateObject instanceof Date && !isNaN(dateObject as any)) {
    return dateObject.toLocaleString(locale || navigator.language, options);
  } else {
    return MISSING_DATE;
  }
}

const displayLanguageCache: Record<string, Intl.DisplayNames> = {};

export const getLanguageName = (
  language: Language,
  currentLanguage?: Language | string
) => {
  const lang =
    currentLanguage ||
    (i18n.language ? i18n.language : Language.ENGLISH.toString());
  if (!displayLanguageCache[lang]) {
    displayLanguageCache[lang] = new Intl.DisplayNames(lang, {
      type: "language",
    });
  }

  return displayLanguageCache[lang].of(language);
};
