import React, { useMemo } from "react";
import i18n from "../../i18n";

interface Props {
  value: number;
  currency: string;
  locale?: string;
  maximumFractionDigits?: number;
  minimumFractionDigits?: number;
}

export const FormattedPrice: React.FC<Props> = ({
  value,
  currency,
  locale = i18n.language || "default",
  maximumFractionDigits = 2,
  minimumFractionDigits = 2,
}) => {
  const formattedPrice = useMemo(
    () =>
      new Intl.NumberFormat(locale, {
        style: "currency",
        currency,
        maximumFractionDigits,
        minimumFractionDigits,
      }).format(value),
    [currency, locale, maximumFractionDigits, minimumFractionDigits, value]
  );

  return <>{formattedPrice}</>;
};

export const formattedPrice = (
  value: number,
  currency: string,
  locale = i18n.language || "default",
  maximumFractionDigits = 2,
  minimumFractionDigits = 2
) =>
  new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
    maximumFractionDigits,
    minimumFractionDigits,
  }).format(value);
