import { VALIDATION_STATE } from "../FormContext";
import { ValidationResponse } from "../hooks/useValidation";
import { BaseValidator } from "./BaseValidator";

export class KnownCharactersValidator extends BaseValidator {
  name = "KnownCharactersValidator";

  validate(value: string): Promise<ValidationResponse> {
    return new Promise<ValidationResponse>((resolve) => {
      if (/^[a-zA-ZåäöæøÅÄÖÆØ\s\-,.0-9]+$/.test(value)) {
        resolve({ status: VALIDATION_STATE.SUCCESS });
      } else {
        resolve({
          status: VALIDATION_STATE.FAILED,
          message: this.error,
        });
      }
    });
  }
}
