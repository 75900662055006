import { useCallback, useRef, useState } from "react";

export const useStickySidebar = () => {
  const [isSticky, setSticky] = useState(false);
  const resizeObserverRef = useRef<ResizeObserver | null>(null);
  const nodeRef = useRef<HTMLDivElement | null>(null);
  const resizeHandlerRef = useRef<() => void>(() => {});

  const checkSticky = useCallback((node: HTMLDivElement) => {
    const windowHeight = window.innerHeight;
    if (windowHeight) {
      const sidebarHeight = node.getBoundingClientRect().height;
      setSticky(sidebarHeight < windowHeight - 50);
    }
  }, []);

  const sidebarRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (resizeObserverRef.current) {
        resizeObserverRef.current.disconnect();
        resizeObserverRef.current = null;
      }

      if (nodeRef.current) {
        window.removeEventListener("resize", resizeHandlerRef.current);
        nodeRef.current = null;
      }

      nodeRef.current = node;

      if (!node) {
        return;
      }

      const handleResize = () => {
        checkSticky(node);
      };

      resizeHandlerRef.current = handleResize;
      window.addEventListener("resize", handleResize);

      resizeObserverRef.current = new ResizeObserver(() => {
        checkSticky(node);
      });
      resizeObserverRef.current.observe(node);
      resizeObserverRef.current.observe(document.body);

      // Initial check
      checkSticky(node);
    },
    [checkSticky]
  );

  return [sidebarRef, isSticky] as const;
};
