import { ReactNode } from "react";

interface Props {
  label: ReactNode;
  value?: ReactNode | string | number;
  column?: boolean;
}

export const DataListItem: React.FC<Props> = ({ label, value, column }) => {
  if (column) {
    return (
      <div className="column">
        <dt>
          <span>{label}</span>
        </dt>
        <dd>
          <span>{value ?? "-"}</span>
        </dd>
      </div>
    );
  }

  return (
    <>
      <dt>
        <span>{label}</span>
      </dt>
      <dd>
        <span>{value ?? "-"}</span>
      </dd>
    </>
  );
};
