import { AnimatePresence } from "framer-motion";
import React, { createContext, ReactNode, useContext, useState } from "react";
import { Routes, Route, useLocation, matchPath } from "react-router-dom";
import { NavigationStoryHeader } from "./NavigationStoryHeader";
import { motion } from "framer-motion";
import styles from "./NavigationStory.module.scss";
import { getIndex } from "../../utils";
import { Direction, NavigationRoutes, StoryFrameProps } from "../../storyTypes";
import bg from "../../bg.svg";

interface ContextProps {
  direction: Direction;
  setDirection: (direction: Direction) => void;
  baseRoute: string;
  routes: StoryFrameProps[]; // All applicable routes for the onboarding
  filteredRoutes: StoryFrameProps[]; // Purged routes that are
  // applicable for this product type and type of customer
  setFilteredRoutes: (routes: StoryFrameProps[]) => void;
}

export const NavigationStoryContext = createContext<ContextProps>({
  direction: Direction.FORWARDS,
  setDirection: (
    direction: Direction // eslint-disable-line
  ) => {},
  routes: [],
  filteredRoutes: [],
  setFilteredRoutes: (
    routes: StoryFrameProps[] // eslint-disable-line
  ) => {},
  baseRoute: "",
});

export const MOTION_VARIANTS = {
  initial: ({ direction }: { direction: Direction }) => {
    return {
      left: direction === Direction.BACKWARDS ? "-100%" : "100%",
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    };
  },
  in: {
    left: 0,
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
  out: ({ direction }: { direction: Direction }) => ({
    left: direction === Direction.BACKWARDS ? "100%" : "-100%",
    opacity: 0,
    transition: {
      duration: 0.5,
    },
  }),
};

export const StoryPage: React.FunctionComponent<{
  children: ReactNode;
  direction: Direction;
}> = ({ children, direction }) => {
  return (
    <motion.div
      className={styles.storyPage}
      custom={{
        direction,
      }}
      initial="initial"
      animate="in"
      exit="out"
      variants={MOTION_VARIANTS}
    >
      {children}
    </motion.div>
  );
};

export const NavigationStory: React.FunctionComponent<
  NavigationRoutes & { saveLanguage?: boolean }
> = ({ routes, baseRoute, saveLanguage }) => {
  const [direction, setDirection] = useState<Direction>(Direction.FORWARDS);
  const [filteredRoutes, setFilteredRoutes] = useState<StoryFrameProps[]>([
    ...routes,
  ]);

  return (
    <NavigationStoryContext.Provider
      value={{
        direction,
        routes,
        filteredRoutes,
        setFilteredRoutes,
        baseRoute,
        setDirection,
      }}
    >
      <Inner saveLanguage={saveLanguage} />
    </NavigationStoryContext.Provider>
  );
};

const Inner: React.FunctionComponent<{ saveLanguage?: boolean }> = ({
  saveLanguage,
}) => {
  const location = useLocation();
  const { direction, routes, filteredRoutes, baseRoute } = useContext(
    NavigationStoryContext
  );

  const paths = location.pathname.split("/");
  const match = matchPath({ path: baseRoute }, location.pathname);
  const currentRoute = match ? "/" : `/${paths.pop() || ""}`;
  const currentIndex = getIndex(currentRoute, routes);

  return (
    <div className={styles.navigationStory}>
      <div className={styles.bg} style={{ backgroundImage: `url(${bg})` }} />
      <NavigationStoryHeader
        currentRouteIndex={currentIndex}
        numberOfRoutes={filteredRoutes.length}
        name=""
        saveLanguage={saveLanguage}
      />
      <AnimatePresence initial={false}>
        <Routes location={location} key={location.pathname}>
          {routes.map((route) => {
            return (
              <Route
                key={route.path}
                path={route.path}
                element={
                  <StoryPage direction={direction}>{route.element}</StoryPage>
                }
              />
            );
          })}
        </Routes>
      </AnimatePresence>
    </div>
  );
};
