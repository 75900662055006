import React from "react";
import { SigningGroup as SigningGroupType } from "../../../data/models/ContractTypes";
import { Signatory } from "./Signatory";

interface Props {
  signingGroup: SigningGroupType;
}

export const SigningGroup: React.FunctionComponent<Props> = ({
  signingGroup,
}) => {
  return (
    <ul>
      {signingGroup.signees.map((signee) => {
        return <Signatory key={signee.name} signatory={signee} />;
      })}
    </ul>
  );
};
