import { FaBuilding } from "react-icons/fa6";
import { useRiskCase } from "../../hooks/useRiskCase";
import { useTranslation } from "react-i18next";
import { BackofficeCard } from "../../components/card/BackofficeCard";
import { BackofficeCardHeader } from "../../components/card/BackofficeCardHeader";
import { DataList } from "../../components/Datalist/DataList";
import { DataListItem } from "../../components/Datalist/DataListItem";
import { Suspense } from "../../../Suspense";
import Skeleton from "react-loading-skeleton";
import { Flag } from "../../../../components/Countries/Flag";

export const RiskCompanyCard: React.FC = () => {
  const { t } = useTranslation();

  return (
    <BackofficeCard>
      <BackofficeCardHeader
        title={t("Company information")}
        icon={FaBuilding}
        verified="Roaring"
      />
      <Suspense skeleton={<Skeleton height={100} className="mb-2" />}>
        <Inner />
      </Suspense>
    </BackofficeCard>
  );
};

const Inner = () => {
  const { t } = useTranslation();

  const {
    data: { contract },
  } = useRiskCase();
  const { companyDetails } = contract;

  const businessAddress = (
    <>
      {companyDetails.street}
      <br />
      {[companyDetails.postalCode, companyDetails.city].join(", ")}
    </>
  );

  return (
    <div>
      <DataList type="horizontal" align="left">
        <DataListItem label={t("Company name")} value={companyDetails.name} />
        <DataListItem
          label={t("Organization number")}
          value={companyDetails.organizationNumber}
        />
        <DataListItem
          label={t("Country")}
          value={
            <>
              <Flag height={12} country={contract.country} /> {contract.country}
            </>
          }
        />

        <DataListItem
          label={t("Legal entity type")}
          value={companyDetails.legalEntityType}
        />

        <DataListItem
          label={t("Incorporation date")}
          value={companyDetails.registrationDate}
        />

        <DataListItem label={t("Address")} value={businessAddress} />

        <DataListItem
          label={t("Industry code")}
          value={companyDetails.industryCode}
        />
        <DataListItem
          label={t("Industry text")}
          value={companyDetails.industryText}
        />
      </DataList>
    </div>
  );
};
