import { useSuspenseQuery } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { Wrapper } from "../../../components/Wrapper";
import { dataSupplement } from "../../../data/dataSupplements";
import { SupplementPath } from "../routes";
import { SUPPLEMENT_STORY_BASE_ROUTE } from "../SupplementStory";
import { SupplementItem } from "./components/SupplementItem/SupplementItem";

export const AnswerStep: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const { linkId } = useParams<{ linkId: string }>();
  const { t } = useTranslation();

  const { data } = useSuspenseQuery(dataSupplement.getSupplements(linkId!));

  const { supplements, companyName, name } = data;

  // if all supplements are answered, redirect to complete path
  const allAnswered = supplements.every((supplement) => !!supplement.answered);

  useEffect(() => {
    if (allAnswered) {
      navigate(
        generatePath(SUPPLEMENT_STORY_BASE_ROUTE + SupplementPath.COMPLETE, {
          linkId,
        }),
        {
          replace: true,
        }
      );
    }
  }, [allAnswered, linkId, navigate]);

  return (
    <Wrapper size="mini">
      <h2>
        {t("Hello {{name}}!", {
          name,
        })}
      </h2>
      <p className="passive">
        {t(
          "In order to complete your application for payment services with {{ourCompanyName}} for {{companyName}}, we need some additional information from you. Please provide answers to the requests below.",
          {
            companyName,
            ourCompanyName: "Worldline",
          }
        )}
      </p>
      <div className="flex-column gap-3 mt-3">
        {supplements.map((supplement, index) => (
          <SupplementItem
            key={supplement.id}
            supplement={supplement}
            index={index}
          />
        ))}
      </div>
    </Wrapper>
  );
};
