import React, { useState, useMemo, useCallback } from "react";
import styles from "./Bankgiro.module.scss";
import Kontonummer from "kontonummer";
import { Wrapper } from "../../../../components/Wrapper";
import {
  useContract,
  useInvalidateContract,
} from "../../../../hooks/useContract";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { dataBank } from "../../../../data/dataBank";
import { useStoryNavigate } from "../../../../hooks/useStoryNavigate";
import { OnboardingPath } from "../../routes";
import { Form } from "../../../../modules/Forms/Form";
import { TextInput } from "../../../../modules/Forms/TextInput";
import { RequiredValidator } from "../../../../modules/Forms/validators/RequiredValidator";
import { Dynamic } from "../../../../components/Animations/Dynamic";
import { Validity } from "../../../../data/models/ContractTypes";
import { GenericError } from "../../../../components/Errors/GenericError";
import { BankValidatorMinLength } from "../../../../modules/Forms/validators/BankValidatorMinLength";
import { BankValidatorMaxLength } from "../../../../modules/Forms/validators/BankValidatorMaxLength";
import { SavedAccount } from "../SavedAccount";
import { StoryButtons } from "../../../StoryButtons";
import { View } from "../../../../modules/View/View";
import { MdTaskAlt } from "react-icons/md";
import { Box } from "../../../../components/Boxes/Box";
import { Status } from "../../../../modules/Forms/FormContext";

const MIN_LENGTH_ACCOUNT = 11;
const MAX_LENGTH_ACCOUNT = 15;

// Banks from "kontonummer"
const BankName = [
  "Aion Bank",
  "Avanza Bank",
  "BlueStep Finans",
  "BNP Paribas",
  "Citibank",
  "Danske Bank",
  "DNB Bank",
  "Ekobanken",
  "Erik Penser",
  "Handelsbanken",
  "ICA Banken",
  "IKANO Bank",
  "JAK Medlemsbank",
  "Klarna Bank",
  "Lån & Spar Bank Sverige",
  "Landshypotek",
  "Lunar Bank",
  "Länsförsäkringar Bank",
  "Marginalen Bank",
  "Multitude Bank",
  "Nordax Bank",
  "Nordea",
  "Nordea Plusgirot",
  "Nordnet Bank",
  "Northmill Bank",
  "Resurs Bank",
  "Riksgälden",
  "Santander Consumer Bank",
  "SBAB",
  "SEB",
  "Skandiabanken",
  "Sparbanken Syd",
  "Svea Bank",
  "Swedbank",
  "Ålandsbanken",
];

// https://www.bankgirot.se/om-bankgirot/betalsystem/vara-betalsystem/bankgirosystemet/
// "IKANO Bank" and "SBAB" modified in order to match those from "kontonummer"
const allowedBanks = [
  "Aion Bank",
  "BNP Paribas",
  "Citibank",
  "Danske Bank",
  "DNB Bank",
  "Ekobanken",
  "Handelsbanken",
  "ICA Banken",
  "IKANO Bank",
  "JAK Medlemsbank",
  "Klarna Bank",
  "Lunar Bank",
  "Länsförsäkringar Bank",
  "Marginalen Bank",
  "Multitude Bank",
  "Nordea",
  "Nordnet Bank",
  "Northmill Bank",
  "Resurs Bank",
  "Riksgälden",
  "SBAB",
  "Skandiabanken",
  "SEB",
  "Sparbanken Syd",
  "Svea Bank",
  "Swedbank",
  "Ålandsbanken",
];

function cleanNumber(numberAsString?: string) {
  if (!numberAsString) {
    return "";
  }

  return numberAsString.replace(/\D/g, "");
}

export function showNotSupportedBanks() {
  const notFound: string[] = [];
  BankName.forEach((name) => {
    const found = allowedBanks.find((bank) => name === bank);
    if (!found) {
      notFound.push(name);
    }
  });
  console.log("notFound", notFound);
}

export const Bankgiro: React.FunctionComponent = () => {
  const contract = useContract();
  const { t } = useTranslation();
  const [bankAccount, setBankAccount] = useState<string>("");
  const [info, setInfo] = useState<Kontonummer | null>(null);
  const [hasShowedWarning, setHasShowedWarning] = useState<boolean>(false);
  const invalidate = useInvalidateContract();
  const { navigate } = useStoryNavigate();

  const showBankName = useMemo(() => {
    return !!info && cleanNumber(bankAccount).length >= MIN_LENGTH_ACCOUNT;
  }, [info, bankAccount]);

  const showBankUnsupported = useMemo(() => {
    if (!info) {
      return false;
    }

    if (cleanNumber(bankAccount).length < MIN_LENGTH_ACCOUNT) {
      return false;
    }

    if (allowedBanks.includes(info.bankName)) {
      return false;
    }

    return true;
  }, [info, bankAccount]);

  const {
    mutate: onSave,
    isPending,
    isError,
    reset,
  } = useMutation({
    mutationFn: () => {
      return dataBank.queueToSwedishBGC(cleanNumber(bankAccount));
    },
    onSuccess: () => {
      invalidate();
      navigate(OnboardingPath.OWNERS);
    },
  });

  const next = useCallback(() => {
    navigate(OnboardingPath.OWNERS);
  }, [navigate]);

  return (
    <Wrapper>
      <Form
        name="bank-account"
        onSubmit={(_, form) => {
          if (!form.isValid) {
            return;
          }

          if (!info?.valid && !hasShowedWarning) {
            setHasShowedWarning(true);
            return;
          }

          setHasShowedWarning(false);
          onSave();
        }}
      >
        <View
          header={t("Payout account")}
          fixedValidity={
            contract.bankAccount?.iban ? Validity.VALID : undefined
          }
          size="small"
        >
          <p>
            {t(
              "Enter your bank account where you wish to receive your payouts, the bank account must be owned by the company."
            )}
          </p>

          <SavedAccount bankAccount={contract.bankAccount} next={next} />

          <div className="mt-6">
            <p>
              <b>{t("Example")}:</b> 3300 7810251111
            </p>
            <div className="mt-4" />
            <TextInput
              name="bankAccount"
              label={t("Account number (including clearing)")}
              value={bankAccount}
              disabled={isPending}
              validators={[
                new RequiredValidator(t("Required")),
                new BankValidatorMinLength(
                  MIN_LENGTH_ACCOUNT,
                  t("At least {{min}} digits are required", {
                    min: MIN_LENGTH_ACCOUNT,
                  })
                ),
                new BankValidatorMaxLength(
                  MAX_LENGTH_ACCOUNT,
                  t("Not more than {{max}} digits are allowed", {
                    max: MAX_LENGTH_ACCOUNT,
                  })
                ),
              ]}
              onChange={(value) => {
                reset();
                setBankAccount(value);
                setHasShowedWarning(false);
                try {
                  const info = Kontonummer.parse(value, {
                    mode: "strict",
                  });
                  setInfo(info);
                } catch (err) {
                  setInfo(null);
                }
              }}
              inputAttributes={{
                inputMode: "numeric",
              }}
            />
          </div>

          <Dynamic name={showBankName ? "account" : ""}>
            {info && showBankName ? (
              <div className={styles.info}>
                <MdTaskAlt /> &nbsp;&nbsp;
                <b>{info.bankName}</b> - {`${info.format("pretty")} `}
              </div>
            ) : null}
          </Dynamic>

          <Dynamic name={showBankUnsupported ? "unsupported" : ""}>
            {info && showBankUnsupported ? (
              <Box status={Status.ERROR} className="small">
                {t(
                  "This bank is not supported. We are unable to continue with the application."
                )}
              </Box>
            ) : null}
          </Dynamic>

          <Dynamic name={hasShowedWarning ? "warning" : ""}>
            {hasShowedWarning ? (
              <div className={styles.warning}>
                <Box status={Status.WARNING} className="small">
                  {t(
                    "Bank accounts are sometimes hard to verify. In this case, we are unable to verify the entered account number. Please check the account number again. If it looks good, you may proceed."
                  )}
                </Box>
              </div>
            ) : null}
          </Dynamic>

          <Dynamic name={isError ? "error" : ""}>
            {isError ? (
              <div className={styles.error}>
                <GenericError />
              </div>
            ) : null}
          </Dynamic>

          <div className="mt-6">
            <StoryButtons
              disabled={isPending}
              disableNext={showBankUnsupported}
            />
          </div>
        </View>
      </Form>
    </Wrapper>
  );
};
