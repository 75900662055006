import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../../components/Buttons/Button";
import cx from "classnames";
import styles from "./FileUpload.module.scss";
import { FaArrowUpFromBracket } from "react-icons/fa6";

interface FileUploadProps {
  onFileSelect: (file: File) => void;
  acceptedFormats?: string[];
  acceptAllFormats?: boolean;
}

export const FileUpload: React.FC<FileUploadProps> = ({
  onFileSelect,
  acceptedFormats = ["pdf", "jpeg", "jpg", "bmp", "png"],
  acceptAllFormats = false,
}) => {
  const [dragActive, setDragActive] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const file = e.dataTransfer.files[0];
      setSelectedFile(file);
      onFileSelect(file);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setSelectedFile(file);
      onFileSelect(file);
    }
  };

  const onButtonClick = () => {
    inputRef.current?.click();
  };

  return (
    <div className={styles.fileUploadContainer}>
      <div
        className={cx(styles.dropzone, {
          [styles.dragActive]: dragActive,
        })}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
        onClick={onButtonClick}
      >
        <input
          ref={inputRef}
          type="file"
          className={styles.inputFile}
          accept={
            !acceptAllFormats
              ? acceptedFormats.map((format) => `.${format}`).join(",")
              : undefined
          }
          onChange={handleChange}
        />
        <div>
          <FaArrowUpFromBracket size={32} className="passive" />
        </div>
        <div className="flex align-center gap-1">
          {dragActive ? (
            t("Drop it!")
          ) : (
            <>
              {t("Drag and drop a file or")}
              <Button
                variant="inline"
                onClick={(event) => {
                  event.stopPropagation();
                  onButtonClick();
                }}
                size="small"
              >
                {t("Choose file")}
              </Button>
            </>
          )}{" "}
        </div>
        {selectedFile && (
          <div>
            <strong className="text-09">{selectedFile.name}</strong>
          </div>
        )}
        {!acceptAllFormats && (
          <span className="text-08 passive">
            {t("The following formats are supported: {{formats}}", {
              formats: acceptedFormats.join(", "),
            })}
          </span>
        )}
      </div>
    </div>
  );
};
