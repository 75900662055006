import React from "react";
import ReactCountryFlag from "react-country-flag";
import cx from "classnames";
import styles from "./Flag.module.scss";

interface Props {
  country: string;
  height?: number | string;
}

export const Flag: React.FunctionComponent<Props> = ({
  country,
  height = 30,
}) => {
  if (!country) {
    return null;
  }

  return (
    <ReactCountryFlag
      className={cx(styles.flag, styles.shadow, styles.rounded)}
      style={{
        width: "auto",
        height,
      }}
      svg
      countryCode={country.toUpperCase()}
    />
  );
};

export { type Props as FlagProps };
