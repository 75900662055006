import { FaMinus, FaPlus } from "react-icons/fa6";
import { Button } from "../components/Buttons/Button";
import { ConfirmButton } from "../components/Buttons/ConfirmButton";
import { Link } from "../components/Link/Link";
import { Spinner } from "../components/Spinner/Spinner";
import { Status } from "../modules/Forms/FormContext";
import { TextInput } from "../modules/Forms/TextInput";
import { QuantityInput } from "../modules/Forms/QuantityInput";
import { Page } from "./Page";
import { useState } from "react";
import { Form } from "../modules/Forms/Form";
import { RequiredValidator } from "../modules/Forms/validators/RequiredValidator";
import { Select } from "../modules/Forms/Select";
import { Option } from "../components/types";
import { NumberInput } from "../modules/Forms/NumberInput";
import { MinValidator } from "../modules/Forms/validators/MinValidator";
import { Checkboxes } from "../modules/Forms/Checkboxes";
import { Radiobuttons } from "../modules/Forms/Radiobuttons";
import { TextArea } from "../modules/Forms/TextArea";

export const TestPage: React.FunctionComponent = () => {
  return (
    <div style={{ maxWidth: "var(--media-tablet)", margin: "0 auto" }}>
      <Page>
        <div>
          <h4>TextInput</h4>
          <TextInputExample />
          <h4>TextArea</h4>
          <TextAreaExample />
          <h4>Select</h4>
          <SelectExample />
          <h4>NumberInput</h4>
          <NumberExample />
          <h4>Checkboxes</h4>
          <CheckboxesExample />
          <h4>Radiobuttons</h4>
          <RadiobuttonsExample />
          <h4>QuantityInput</h4>
          <div>
            <QuantityInput value={1} onChange={() => {}} name="test" />
          </div>
          <h4>Spinner</h4>
          <div>
            <Spinner />
          </div>
          <h4>Link</h4>
          <div>
            <Link to="/test">internal link</Link>
          </div>
          <div>
            <Link external to="https://google.com">
              External link
            </Link>
          </div>
          <h4>Button</h4>
          <div
            className="gap-2"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <div className="gap-2" style={{ display: "flex" }}>
              <Button onClick={() => {}}>Default</Button>
              <Button onClick={() => {}} variant="outlined">
                Outlined
              </Button>
              <Button onClick={() => {}} variant="text">
                Text
              </Button>
            </div>
            <div className="gap-2" style={{ display: "flex" }}>
              <Button onClick={() => {}} color="success">
                Success color
              </Button>
              <Button onClick={() => {}} color="warning">
                Warning color
              </Button>
              <Button onClick={() => {}} color="error">
                Error color
              </Button>
            </div>
            <div className="gap-2" style={{ display: "flex" }}>
              <Button onClick={() => {}} status={Status.SUCCESS}>
                Success
              </Button>
              <Button onClick={() => {}} status={Status.ERROR}>
                Error
              </Button>
              <Button onClick={() => {}} status={Status.PENDING}>
                Pending
              </Button>
              <Button onClick={() => {}} status={Status.DISABLED}>
                Disabled
              </Button>
            </div>
            <div className="gap-2" style={{ display: "flex" }}>
              <Button onClick={() => {}} action>
                <FaPlus />
              </Button>
              <Button onClick={() => {}} action variant="outlined">
                <FaMinus />
              </Button>
              <Button onClick={() => {}} action variant="text">
                =
              </Button>
              <Button onClick={() => {}} action status={Status.DISABLED}>
                ?
              </Button>
            </div>
            <div className="gap-2" style={{ display: "flex" }}>
              <Button onClick={() => {}} size="small">
                Small
              </Button>
              <Button onClick={() => {}} size="mini">
                Mini
              </Button>
            </div>
          </div>
          <h4>ConfirmButton</h4>
          <ConfirmButton
            onClick={() => {}}
            regretButtonText="Cancel"
            confirmButtonText="Confirm"
          >
            Button
          </ConfirmButton>
        </div>
      </Page>
    </div>
  );
};

const TextInputExample: React.FunctionComponent = () => {
  const [value, setValue] = useState<string>("");

  return (
    <Form name="TextInputExample">
      <TextInput
        label="TextInput"
        onChange={setValue}
        value={value}
        hint="This is a hint"
      />
      <TextInput
        label="TextInput required"
        onChange={setValue}
        value={value}
        validators={[new RequiredValidator("This field is required")]}
      />
    </Form>
  );
};
const TextAreaExample: React.FunctionComponent = () => {
  const [value, setValue] = useState<string>("");

  return (
    <Form name="TextAreaExample">
      <TextArea
        label="TextArea"
        onChange={setValue}
        value={value}
        hint="This is a hint"
        attributes={{ rows: 5 }}
      />
      <TextArea
        label="TextArea required"
        onChange={setValue}
        value={value}
        validators={[new RequiredValidator("This field is required")]}
      />
    </Form>
  );
};

const SelectExample: React.FunctionComponent = () => {
  const [value, setValue] = useState<string>("");

  const options: Option<string>[] = [
    { value: "1", text: "Option 1" },
    { value: "2", text: "Option 2" },
    { value: "3", text: "Option 3" },
  ];

  return (
    <Form name="SelectExample">
      <Select
        label="Select"
        onChange={setValue}
        value={value}
        options={options}
        placeholder="Select an option"
        hint="This is a hint"
      />
      <Select
        label="Select required"
        onChange={setValue}
        value={value}
        options={options}
        placeholder="Select an option"
        validators={[new RequiredValidator("This field is required")]}
      />
    </Form>
  );
};

const NumberExample: React.FunctionComponent = () => {
  const [value, setValue] = useState<number>();

  return (
    <Form name="NumberExample">
      <NumberInput
        label="Number"
        onChange={setValue}
        value={value}
        hint="This is a hint"
      />
      <NumberInput
        label="Number required"
        onChange={setValue}
        value={value}
        validators={[
          new RequiredValidator("This field is required"),
          new MinValidator(1, "Value must be greater than 0"),
        ]}
      />
    </Form>
  );
};

const CheckboxesExample = () => {
  const [values, setValues] = useState<string[]>([]);

  const options: Option<string>[] = [
    { value: "1", text: "Option 1" },
    { value: "2", text: "Option 2" },
    { value: "3", text: "Option 3 (disabled)", disabled: true },
  ];

  return (
    <Form name="CheckboxesExample">
      <Checkboxes
        label="Checkbox group"
        onChange={setValues}
        values={values}
        options={options}
        hint="This is a hint"
        disabled
      />
      <Checkboxes
        label="Checkbox group"
        onChange={setValues}
        values={values}
        options={options}
        hint="This is a hint"
        validators={[new RequiredValidator("This field is required")]}
      />
    </Form>
  );
};

const RadiobuttonsExample = () => {
  const [value, setValue] = useState<string>();

  const options: Option<string>[] = [
    { value: "1", text: "Option 1" },
    { value: "2", text: "Option 2" },
    { value: "3", text: "Option 3 (disabled)", disabled: true },
  ];

  return (
    <Form name="RadiobuttonsExample">
      <Radiobuttons
        label="Radio group"
        onChange={setValue}
        value={value}
        options={options}
        hint="This is a hint"
        disabled
      />
      <Radiobuttons
        label="Radio group"
        onChange={setValue}
        value={value}
        options={options}
        hint="This is a hint"
        validators={[new RequiredValidator("This field is required")]}
      />
    </Form>
  );
};
