import cx from "classnames";
import { Option, Stringifiable } from "../types";
import { Status } from "../../modules/Forms/FormContext";
import { ButtonPane } from "./ButtonPane";
import React from "react";

interface Props<T extends Stringifiable> {
  options: Option<T>[];
  value: T;
  color?: "default" | "error" | "warning" | "success";
  size?: "default" | "mini" | "small";
  status?: Status;
  onClick: (ev: any, value: T) => void;
  data?: any;
  className?: string;
}

export function SelectableButtons<T extends Stringifiable>(props: Props<T>) {
  const { options, value, color, status, size, onClick, data, className } =
    props;

  return (
    <div className={cx(className)}>
      <ButtonPane>
        {options.map((opt) => {
          return (
            <React.Fragment key={opt.value.toString()}>
              <ButtonPane.Button
                onClick={onClick}
                variant={value === opt.value ? "selected" : "outlined"}
                size={size}
                status={status}
                color={color}
                data={{
                  ...opt,
                  ...data,
                }}
              >
                {opt.text}
              </ButtonPane.Button>
            </React.Fragment>
          );
        })}
      </ButtonPane>
    </div>
  );
}
