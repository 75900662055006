import { useParams } from "react-router-dom";

export type LinkIdParams = {
  linkId: string;
};

export function useLinkId() {
  const { linkId } = useParams<LinkIdParams>();

  return linkId as string;
}
