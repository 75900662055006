import { useMutation } from "@tanstack/react-query";
import { t } from "i18next";
import { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Dynamic } from "../../../../../components/Animations/Dynamic";
import { Box } from "../../../../../components/Boxes/Box";
import { Button } from "../../../../../components/Buttons/Button";
import {
  dataSupplement,
  SupplementRequest,
} from "../../../../../data/dataSupplements";
import { useIsFormInvalid } from "../../../../../hooks/useFormValidity";
import { Form, FormContainer } from "../../../../../modules/Forms/Form";
import { Status } from "../../../../../modules/Forms/FormContext";
import { HiddenInput } from "../../../../../modules/Forms/HiddenInput";
import { TextArea } from "../../../../../modules/Forms/TextArea";
import { RequiredValidator } from "../../../../../modules/Forms/validators/RequiredValidator";
import { useDebouncedStatus } from "../../../../../pages/backoffice/hooks/useDebouncedStatus";
import { FileUpload } from "../FileUpload/FileUpload";
import styles from "./SupplementItem.module.scss";

interface Props {
  supplement: SupplementRequest;
  index: number;
}

export const SupplementItem: React.FunctionComponent<Props> = ({
  supplement,
  index,
}) => {
  const { linkId } = useParams<{ linkId: string }>();
  const { request, requestDocument, answered, translatedRequest } = supplement;
  const [answer, setAnswer] = useState<string>("");
  const [document, setDocument] = useState<File>();
  const formRef = useRef<FormContainer>();

  const { mutate: save, status: mutationStatus } = useMutation(
    dataSupplement.saveSupplement(linkId!, supplement.id)
  );

  const status = useDebouncedStatus(mutationStatus);

  const isFormInvalid = useIsFormInvalid(formRef);

  return (
    <div className={styles.wrapper}>
      <h4 className="mb-1 mt-0">
        {t("Request #{{number}}", {
          number: index + 1,
        })}
      </h4>
      <p className="mt-0">{translatedRequest || request}</p>
      <Dynamic name={answered ? "answered" : "not-answered"}>
        <div className="flex-column gap-2">
          {!!answered ? (
            <Box status={Status.SUCCESS} className="text-09" noIcon>
              {t("Thank you, we've received your response.")}
            </Box>
          ) : (
            <Form
              name="supplement"
              formContainer={formRef}
              onSubmit={(_, form) => {
                if (form.isInvalid) {
                  return;
                }

                save({ answer, document });
              }}
            >
              <TextArea
                label={t("Your response")}
                onChange={setAnswer}
                value={answer}
                validators={[
                  new RequiredValidator(t("You need to provide a response")),
                ]}
                attributes={{
                  rows: 4,
                }}
              />
              {requestDocument && (
                <div className="mb-2">
                  <FileUpload onFileSelect={setDocument} />
                  <HiddenInput
                    value={typeof document !== "undefined" ? "file" : undefined}
                    validators={[
                      new RequiredValidator(t("An attachment is required")),
                    ]}
                  />
                </div>
              )}
              <Button
                type="submit"
                block
                status={status}
                variant={isFormInvalid ? "outlined" : "primary"}
              >
                {t("Submit response")}
              </Button>
            </Form>
          )}
        </div>
      </Dynamic>
    </div>
  );
};
