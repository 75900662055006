import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { MccCategory } from "../../data/models/mcc";

interface Props {
  value: MccCategory;
}

export const MccCategoryLabel: FunctionComponent<Props> = ({ value }) => {
  const { t } = useTranslation();
  return <>{getMccCategoryTranslation(t)[value]}</>;
};

export const getMccCategoryTranslation = (
  t: TFunction
): Record<MccCategory, string> => ({
  [MccCategory.CAR_RELATED]: t("Car related business"),
  [MccCategory.ENTERTAINMENT]: t("Entertainment"),
  [MccCategory.HOTELS_AND_ACCOMODATION]: t("Hotels and accomodation"),
  [MccCategory.MISCELLANEOUS_STORES]: t("Miscellaneous stores"),
  [MccCategory.TRANSPORTATION]: t("Transportation"),
  [MccCategory.AGRICULTURE_AND_ANIMALS]: t("Agriculture and animals"),
  [MccCategory.HEALTH_MEDICAL_AND_BEAUTY]: t("Health, medical and beauty"),
  [MccCategory.FOOD_DRINKS_AND_RESTAURANTS]: t("Food, drinks and restaurants"),
  [MccCategory.CLOTHING_STORES]: t("Clothing stores"),
  [MccCategory.CONTRACTORS]: t("Contractors"),
  [MccCategory.OTHERS]: t("Others"),
  [MccCategory.UTILITY_SERVICES]: t("Utility services"),
  [MccCategory.PROFESSIONAL_SERVICES]: t("Professional services"),
  [MccCategory.RETAIL_STORES_AND_SERVICES]: t("Retail stores and services"),
});
