import { Country } from "../../../data/models/ContractTypes";
import { VALIDATION_STATE } from "../FormContext";
import { ValidationResponse } from "../hooks/useValidation";
import { BaseValidator } from "./BaseValidator";

export class PostalCodeValidator extends BaseValidator {
  name = "PostalCodeValidator";
  country: Country;

  constructor(country: Country, error: string) {
    super(error);
    this.country = country;
  }

  validate(value: string): Promise<ValidationResponse> {
    return new Promise<ValidationResponse>((resolve) => {
      let valid: boolean;
      switch (this.country) {
        case Country.DENMARK:
          valid = value.length === 4 && /^\d+$/.test(value);
          break;
        case Country.FINLAND:
          let code = value;
          if (code.startsWith("AX")) {
            code = code.replace("AX-", "");
          }
          valid = code.length === 5 && /^\d+$/.test(value);
          break;
        case Country.NORWAY:
          valid = value.length === 4 && /^\d+$/.test(value);
          break;
        case Country.SWEDEN:
          valid = value.length === 5 && /^\d+$/.test(value);
          break;
        default:
          valid = true;
          break;
      }

      if (valid) {
        resolve({ status: VALIDATION_STATE.SUCCESS });
      } else {
        resolve({
          status: VALIDATION_STATE.FAILED,
          message: this.error,
        });
      }
    });
  }
}
