import { FaIdBadge } from "react-icons/fa6";
import { useRiskCase } from "../../hooks/useRiskCase";
import { useTranslation } from "react-i18next";
import { BackofficeCard } from "../../components/card/BackofficeCard";
import { BackofficeCardHeader } from "../../components/card/BackofficeCardHeader";
import { DataList } from "../../components/Datalist/DataList";
import { DataListItem } from "../../components/Datalist/DataListItem";
import { Suspense } from "../../../Suspense";
import Skeleton from "react-loading-skeleton";
import { getLanguageName } from "../../../../components/dateUtils";

export const RiskPrimaryContactCard: React.FC = () => {
  const { t } = useTranslation();

  return (
    <BackofficeCard>
      <BackofficeCardHeader title={t("Primary contact")} icon={FaIdBadge} />
      <Suspense skeleton={<Skeleton height={100} className="mb-2" />}>
        <Inner />
      </Suspense>
    </BackofficeCard>
  );
};

const Inner = () => {
  const { t, i18n } = useTranslation();

  const {
    data: { contract },
  } = useRiskCase();
  const { primaryContact } = contract;

  return (
    <div>
      <DataList type="horizontal" align="left" columns>
        <DataListItem label={t("Name")} value={primaryContact.name} column />
        <DataListItem
          label={t("Preferred language")}
          value={getLanguageName(primaryContact.language, i18n.language)}
          column
        />
        <DataListItem label={t("Email")} value={primaryContact.email} column />
        <DataListItem label={t("Phone")} value={primaryContact.phone} column />
      </DataList>
    </div>
  );
};
