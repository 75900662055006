import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Checkboxes } from "../Forms/Checkboxes";
import { HiddenInput } from "../Forms/HiddenInput";
import { RequiredValidator } from "../Forms/validators/RequiredValidator";

export const Confirm: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const [hasAgreed, setHasAgreed] = useState<boolean>();

  return (
    <>
      <div className="small mb-4">
        {t(
          "We confirm that the information we have provided in this application is complete and correct. We accept credit information can be obtained and that the application may be rejected without further justification."
        )}
      </div>
      <Checkboxes
        className="compact"
        onChange={() => {
          setHasAgreed((prev) => !prev);
        }}
        values={hasAgreed ? [true] : []}
        options={[
          {
            value: true,
            text: t(
              "I have reviewed and confirm that all information is correct"
            ),
          },
        ]}
      />

      <HiddenInput
        value={hasAgreed ? [true] : undefined}
        validators={[
          new RequiredValidator(
            t("You must confirm the information before signing")
          ),
        ]}
      />
    </>
  );
};
