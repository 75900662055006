import clsx from "classnames";
import styles from "./Tabs.module.scss";
import { LayoutGroup, motion } from "framer-motion";
import { useId } from "react";
import { Option } from "../../../../components/types";
import cx from "classnames";

interface Props<T> {
  tabs: Option<T>[];
  onChange: (value: T) => void;
  value: T | undefined;
  disabled?: boolean;
  className?: string;
  freeWidth?: boolean;
}

export const Tabs = <T extends string | boolean | number>({
  tabs,
  onChange,
  value,
  disabled,
  className,
  freeWidth,
}: Props<T>): JSX.Element | null => {
  const identifier = useId();

  if (!tabs.length) {
    return null;
  }

  return (
    <LayoutGroup id={identifier}>
      <motion.ul
        className={cx(styles.tabs, className, {
          [styles.freeWidth]: freeWidth,
        })}
        layoutRoot
      >
        {tabs.map((tab) => {
          const active = tab.value === value;
          return (
            <li key={tab.value.toString()}>
              <button
                className={clsx(styles.tab, {
                  [styles.active]: active,
                  [styles.disabled]: tab.disabled || disabled,
                })}
                onClick={() => onChange(tab.value)}
                type="button"
              >
                {tab.text}
              </button>
              {active && (
                <motion.div
                  className={styles.indicator}
                  layoutId="tab-active-indicator"
                  layout="position"
                  transition={{
                    duration: 0.5,
                    type: "spring",
                  }}
                />
              )}
            </li>
          );
        })}
      </motion.ul>
    </LayoutGroup>
  );
};
