import React, { useState, useCallback, useRef } from "react";
import styles from "./Products.module.scss";
import { useTranslation } from "react-i18next";
import { Form } from "../../../modules/Forms/Form";
import { Wrapper } from "../../../components/Wrapper";
import { View } from "../../../modules/View/View";
import { StoryButtons } from "../../StoryButtons";
import { useStoryNavigate } from "../../../hooks/useStoryNavigate";
import { OnboardingPath } from "../routes";
import { useContract, useInvalidateContract } from "../../../hooks/useContract";
import { dataContract } from "../../../data/dataContract";
import { Prices } from "../../../data/dataCheckout";
import { useMutation } from "@tanstack/react-query";
import {
  Contract,
  SalesLocation,
  Terminal,
  TerminalPreset,
  TerminalType,
} from "../../../data/models/ContractTypes";
import { ProductOffer } from "../../../components/ProductOffer/ProductOffer";
import { dataSalesLocation } from "../../../data/dataSalesLocation";
import { queryClient } from "../../..";
import { HiddenInput } from "../../../modules/Forms/HiddenInput";
import { RequiredValidator } from "../../../modules/Forms/validators/RequiredValidator";
import { TermsOfService } from "../../../modules/Offering/TermsOfService";
import { Acquiring } from "../../../modules/Offering/Acquiring/Acquiring";
import { AdditionalCosts } from "../../../modules/Offering/AdditionalCosts";
import { useMerchantPricing } from "../../../data/dataPricing";
import { Dynamic } from "../../../components/Animations/Dynamic";
import { Button } from "../../../components/Buttons/Button";
import { FaPlus } from "react-icons/fa6";

interface Props {
  contract: Contract;
}

export const Products: React.FunctionComponent = () => {
  const contract = useContract();
  return <Inner contract={contract} />;
};

const Inner: React.FunctionComponent<Props> = ({ contract }) => {
  const { t } = useTranslation();
  const { currency, prices } = useMerchantPricing();
  const { navigate } = useStoryNavigate();
  const invalidate = useInvalidateContract();

  const hasTerminals = contract.locations.reduce((acc, cur) => {
    if (acc) {
      return acc;
    }

    return cur.terminals.some((terminal) => terminal.count > 0);
  }, false);

  const {
    // TODO
    mutate: commitLocations,
    isError: isCommitError, // eslint-disable-line
  } = useMutation({
    mutationFn: () => dataSalesLocation.commitLocations(),
    onSuccess: () => {
      invalidate();
      navigate(OnboardingPath.FINANCIAL);
    },
  });

  return (
    <Wrapper>
      <Form
        onSubmit={(_, form) => {
          if (form.isInvalid) {
            return;
          }

          commitLocations();
        }}
      >
        <View header={t("Confirm terminal selection")} size="small">
          <hr />
          <div className="mt-3">
            {contract.locations.map((location) => {
              return (
                <Location
                  location={location}
                  key={location.id}
                  contract={contract}
                  prices={prices}
                  currency={currency}
                />
              );
            })}
          </div>

          <div className="mt-8">
            <TermsOfService currency={currency} />
          </div>

          <HiddenInput
            value={hasTerminals ? true : undefined}
            validators={[
              new RequiredValidator(t("You must select at least one terminal")),
            ]}
          />

          <div className="mt-6">
            <StoryButtons />
          </div>
        </View>
      </Form>
    </Wrapper>
  );
};

function getTerminalByModel(
  model: TerminalType,
  terminals: Terminal[]
): Terminal {
  return (
    terminals.find((terminal) => terminal.model === model) || {
      model,
      count: 0,
    }
  );
}

function sortTerminals(terminals: Terminal[]) {
  const copy = [...Object.values(TerminalType)];

  copy.sort((a, b) => {
    const terminalA = getTerminalByModel(a, terminals);
    const terminalB = getTerminalByModel(b, terminals);

    if (terminalA.count > terminalB.count) {
      return -1;
    }

    if (terminalB.count > terminalA.count) {
      return 1;
    }

    return 0;
  });

  return copy;
}

const Location: React.FunctionComponent<{
  location: SalesLocation;
  contract: Contract;
  currency: string;
  prices: Prices;
}> = (props) => {
  const { t } = useTranslation();
  const [store, setStore] = useState<SalesLocation>(props.location);
  const { prices, contract, location } = props;
  const queryKey = dataContract.getContractKey();
  const terminals = useRef<TerminalType[]>(sortTerminals(location.terminals));

  const allTypesHasTerminals = terminals.current.every((model) => {
    const currentTerminal = getTerminalByModel(model, location.terminals);
    return currentTerminal.count > 0;
  });

  const [showAll, setShowAll] = useState<boolean>(allTypesHasTerminals);

  const {
    // TODO
    isError, // eslint-disable-line
    isPending, // eslint-disable-line
    reset, // eslint-disable-line
    mutate: onSave,
  } = useMutation({
    mutationFn: async (copy: SalesLocation) =>
      dataSalesLocation.saveLocation(copy),
    onMutate: async (copy: SalesLocation) => {
      await queryClient.cancelQueries({
        queryKey,
      });

      const previousContract = queryClient.getQueryData<Contract>(queryKey);
      if (!previousContract) {
        return;
      }

      const locations = previousContract.locations.map((location) => {
        if (copy.id === location.id) {
          return copy;
        }

        return location;
      });

      const update: Contract = {
        ...previousContract,
        locations,
      };

      queryClient.setQueryData<Contract>(queryKey, update);

      return { previousContract };
    },
    onError: (err, variables, context) => {
      if (!context) {
        return;
      }

      const revertedStore = context.previousContract.locations.find(
        (location) => location.id === store.id
      );

      if (revertedStore) {
        setStore(revertedStore);
      }

      queryClient.setQueryData(queryKey, context.previousContract);
    },
  });

  const save = useCallback(
    (model: TerminalType, quantity: number) => {
      const copy = { ...store };
      const updatedTerminalIndex = copy.terminals.findIndex(
        (item) => item.model === model
      );

      const updatedTerminal = {
        model,
        count: quantity,
      };

      if (updatedTerminalIndex === -1) {
        copy.terminals.push(updatedTerminal);
      } else {
        copy.terminals[updatedTerminalIndex] = updatedTerminal;
      }

      copy.terminals = copy.terminals.filter(
        (terminalItem) => terminalItem.count !== 0
      );

      copy.terminalPreset = TerminalPreset.STANDARD; // TODO

      setStore(copy);
      onSave(copy);
    },
    [store, onSave]
  );

  return (
    <>
      {terminals.current.map((terminal, idx) => {
        const currentTerminal = getTerminalByModel(
          terminal,
          location.terminals
        );

        // Always show one terminal type even though
        // there are no added one
        let show = idx === 0;

        // Always show if there is a terminal
        // added of this type
        show = show || currentTerminal.count > 0;

        // Always show if user has explicitly
        // want to show all terminals
        show = show || showAll;

        return (
          <Product
            {...props}
            onSave={save}
            key={terminal}
            terminalType={terminal}
            show={show}
          />
        );
      })}

      <div className="center">
        <Dynamic name={showAll ? "show" : "hide"}>
          {showAll ? (
            <div className="pt-2" />
          ) : (
            <Button variant="text" onClick={() => setShowAll(true)}>
              <FaPlus /> {t("Add more terminals")}
            </Button>
          )}
        </Dynamic>
      </div>

      {
        // TODO Additional costs and Acquring should be moved to top container
        // so that they are not rendered for each store
      }

      <AdditionalCosts country={contract.country} prices={prices} />

      <div className="mt-2">
        <Acquiring
          prices={prices}
          country={contract.country}
          initiallyOpen={false}
        />
      </div>
    </>
  );
};

const Product: React.FunctionComponent<{
  location: SalesLocation;
  terminalType: TerminalType;
  contract: Contract;
  currency: string;
  prices: Prices;
  onSave: (model: TerminalType, quantity: number) => void;
  show?: boolean;
}> = ({
  terminalType,
  prices,
  location,
  currency,
  contract,
  onSave,
  show = true,
}) => {
  const terminal = location.terminals.find(
    (terminal) => terminal.model === terminalType
  ) || {
    model: terminalType,
    count: 0,
  };

  return (
    <div className={styles.offer}>
      <ProductOffer
        // showBrands
        show={show}
        country={contract.country}
        key={terminal.model}
        terminalType={terminal.model}
        quantity={terminal.count}
        price={prices[terminal.model]?.value}
        discount={prices[terminal.model]?.discount}
        currency={currency}
        onClick={(quantity) => {
          onSave(terminal.model, quantity);
        }}
      />
    </div>
  );
};
