import { createContext, RefObject } from "react";
import { FormContainer } from "./Form";

export enum Status {
  DEFAULT = "default",
  DISABLED = "is-disabled",
  PENDING = "is-pending",
  ERROR = "has-error",
  SUCCESS = "was-successful",
  WARNING = "is-warning",
}

export enum VALIDATION_STATE {
  UNVALIDATED = "UNVALIDATED",
  PENDING = "PENDING",
  FAILED = "FAILED",
  SUCCESS = "SUCCESS",
}

export interface Input {
  name: string;
  validationState: VALIDATION_STATE;
  value: any;
  resetValidation: () => void;
  scrollToRef: RefObject<HTMLElement> | undefined;
}

export interface FormContextInterface {
  updateInput(input: Input): void;
  removeInput(inputName: string): void;
  forceErrors: boolean;
  formContainer: FormContainer;
}

export const FormContext = createContext<FormContextInterface | null>(null);
