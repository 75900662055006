import React, { useMemo } from "react";
import cx from "classnames";
import styles from "./FormerAcquirer.module.scss";
import { useTranslation } from "react-i18next";
import { BusinessRisk } from "../../../../data/models/ContractTypes";
import { ButtonPane } from "../../../../components/Buttons/ButtonPane";
import { HiddenInput } from "../../../../modules/Forms/HiddenInput";
import { RequiredValidator } from "../../../../modules/Forms/validators/RequiredValidator";
import { Dynamic } from "../../../../components/Animations/Dynamic";
import { Option } from "../../../../components/types";
import { Select } from "../../../../modules/Forms/Select";

interface Props {
  risk: BusinessRisk;
  onChange: (event: React.MouseEvent<HTMLButtonElement>, data: any) => void;
}

enum Acquirer {
  WORLDLINE = "Worldline",
  SWEDBANK_BABS = "Swedbank/Babs",
  ELAVON = "Elavon",
  NETS = "NETS",
  HANDELSBANKEN = "Handelsbanken",
  OTHER = "Other",
}

export const FormerAcquirer: React.FunctionComponent<Props> = ({
  risk,
  onChange,
}) => {
  const { t } = useTranslation();

  const options: Option<string>[] = useMemo(() => {
    const items: Option<string>[] = Object.values(Acquirer).map((item) => ({
      text: item,
      value: item,
    }));

    items.unshift({
      text: "",
      value: "",
      disabled: true,
    });

    return items;
  }, []);

  return (
    <div className={cx(styles.wrapper)}>
      <b>
        {t(
          "Has your company previously had a merchant agreement with any other acquirer?"
        )}
      </b>
      <ButtonPane className="mt-1">
        <ButtonPane.Button
          variant={risk.hasFormerAcquirer === true ? "selected" : "outlined"}
          data={{
            hasFormerAcquirer: true,
          }}
          onClick={onChange}
        >
          {t("Yes")}
        </ButtonPane.Button>

        <ButtonPane.Button
          variant={risk.hasFormerAcquirer === false ? "selected" : "outlined"}
          data={{
            hasFormerAcquirer: false,
          }}
          onClick={onChange}
        >
          {t("No")}
        </ButtonPane.Button>
      </ButtonPane>

      <HiddenInput
        value={risk.hasFormerAcquirer === undefined ? undefined : true}
        validators={[new RequiredValidator(t("Required value"))]}
      />

      <Dynamic name={risk.hasFormerAcquirer ? "yes" : "no"}>
        {risk.hasFormerAcquirer ? (
          <div className="pt-2">
            <Select
              onChange={(value, name, ev) => {
                onChange(ev as any, {
                  formerAcquirer: value,
                });
              }}
              options={options}
              value={risk.formerAcquirer || ""}
              validators={[new RequiredValidator(t("Value is required"))]}
            />
          </div>
        ) : null}
      </Dynamic>
    </div>
  );
};
