import { Contract, Country, LegalEntityType } from "./ContractTypes";

export function isSweden(contract: Contract) {
  return contract.country === Country.SWEDEN;
}

export function isDenmark(contract: Contract) {
  return contract.country === Country.DENMARK;
}

export function isNorway(contract: Contract) {
  return contract.country === Country.NORWAY;
}

export function isFinland(contract: Contract) {
  return contract.country === Country.FINLAND;
}

export function isSoleProprietary(contract: Contract) {
  return (
    contract.companyDetails.legalEntityType === LegalEntityType.SOLE_PROPRIETARY
  );
}
