import React, { useState, useRef } from "react";
import { Form, FormContainer } from "../../../modules/Forms/Form";
import { useTranslation } from "react-i18next";
import { Dynamic } from "../../../components/Animations/Dynamic";
import { useMutation } from "@tanstack/react-query";
import { Wrapper } from "../../../components/Wrapper";
import { useStoryNavigate } from "../../../hooks/useStoryNavigate";
import { ServerError } from "../../../data/API";
import { OnboardingPath } from "../routes";
import { dataContact } from "../../../data/dataContact";
import { RequiredValidator } from "../../../modules/Forms/validators/RequiredValidator";
import { View } from "../../../modules/View/View";
import { TextInput } from "../../../modules/Forms/TextInput";
import { StoryButtons } from "../../StoryButtons";
import { useContract } from "../../../hooks/useContract";
import { dataContract } from "../../../data/dataContract";
import { queryClient } from "../../..";
import { Contract } from "../../../data/models/ContractTypes";
import { MinLengthValidator } from "../../../modules/Forms/validators/MinLengthValidator";
import { EmailValidator } from "../../../modules/Forms/validators/EmailValidator";
import { Button } from "../../../components/Buttons/Button";
import { MaxLengthValidator } from "../../../modules/Forms/validators/MaxLengthValidator";
import {
  replacePhoneNumberBeginning,
  sanitizePhoneNumber,
} from "../../../modules/Phone/PhoneToCountry";
import { StatusError } from "../../../components/Errors/StatusError";
import {
  getPhoneValidators,
  PhoneWithCountry,
} from "../../../modules/Forms/PhoneWithCountry";
import { validate } from "../../../modules/Forms/validators/validationUtils";

const ERROR_BAD_REQUEST = 400;
const LENGTH_OF_CODE = 6;
const VERIFICATION_EXPIRATION_TIME = 10;

export const Contact: React.FunctionComponent = () => {
  const { primaryContact, country } = useContract();
  const { navigate } = useStoryNavigate();
  const { t } = useTranslation();
  const [code, setCode] = useState<string>("");
  const [showCode, setShowCode] = useState<boolean>(false);
  const [email, setEmail] = useState<string>(primaryContact.email || "");
  const [phone, setPhone] = useState<string>(primaryContact.phone || "");
  const [errorStatus, setErrorStatus] = useState<number>();
  const validatedEmail = useRef<string>(primaryContact.email || "");
  const queryKey = dataContract.getContractKey();
  const formRef = useRef<FormContainer>();

  const {
    // TODO
    isPending: isPhonePending, // eslint-disable-line
    isError: isPhoneError,
    reset: resetPhone, // eslint-disable-line
    mutate: savePhone,
  } = useMutation({
    mutationFn: (copy: string) => dataContact.savePhone(copy),
    onMutate: async (copy: string) => {
      await queryClient.cancelQueries({
        queryKey,
      });

      const previousContract = queryClient.getQueryData<Contract>(queryKey);
      if (!previousContract) {
        return;
      }

      const update: Contract = {
        ...previousContract,
        primaryContact: {
          ...previousContract.primaryContact,
          phone: copy,
        },
      };

      queryClient.setQueryData<Contract>(queryKey, update);

      return { previousContract };
    },
    onError: (err, store, context) => {
      if (!context) {
        return;
      }

      setPhone(context.previousContract.primaryContact.phone || "");
      queryClient.setQueryData(queryKey, context.previousContract);
    },
  });

  const {
    isPending: isEmailPending,
    isError: isEmailError,
    reset: resetEmailError,
    mutate: saveEmail,
  } = useMutation({
    mutationFn: () => {
      return dataContact.startEmailVerification(email);
    },
    onSuccess: () => {
      formRef.current?.resetValidation();
      setShowCode(true);
    },
    onError: (error) => setErrorStatus((error as ServerError<any>).status),
  });

  const {
    isPending: isVerifyCodePending,
    isError: isVerifyCodeError,
    reset: resetCodeError,
    mutate: saveCode,
  } = useMutation({
    mutationFn: () => dataContact.verifyEmailCode(code),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: dataContract.getContractKey(),
        refetchType: "all",
      });
      navigate(OnboardingPath.SALES_LOCATIONS);
    },
    onError: (error) => setErrorStatus((error as ServerError<any>).status),
  });

  return (
    <Wrapper>
      <Form
        formContainer={formRef}
        onSaveTrigger={(event) => {
          const name = (event.target as any).getAttribute("name");

          // We will request email code which
          // can not be saved on trigger
          if (!["phone"].includes(name)) {
            return;
          }

          try {
            let sanitizedPhone = sanitizePhoneNumber(phone);
            sanitizedPhone = replacePhoneNumberBeginning(sanitizedPhone);
            validate(getPhoneValidators(country, t))(phone)
              .then(() => {
                savePhone(sanitizedPhone);
                setPhone(sanitizedPhone);
              })
              .catch(() => {
                setPhone(phone);
              });
          } catch (err) {
            setPhone(phone);
          }
        }}
        onSubmit={(event, form) => {
          resetEmailError();

          if (!form.isValid) {
            return;
          }

          if (validatedEmail.current === email) {
            navigate(OnboardingPath.SALES_LOCATIONS);
            return;
          }

          if (showCode) {
            saveCode();
          } else {
            saveEmail();
          }
        }}
      >
        <View header={t("How can we reach you?")} size="small">
          <p>
            {t(
              "The mobile phone number will be used to inform you about the delivery and other important information from Worldline in the future."
            )}
          </p>

          <PhoneWithCountry
            disabled={isEmailPending || isVerifyCodePending}
            onChange={(value, name) => {
              resetPhone();

              if (name === "phoneCountryPrefix") {
                try {
                  let sanitizedPhone = sanitizePhoneNumber(value);
                  sanitizedPhone = replacePhoneNumberBeginning(sanitizedPhone);

                  validate(getPhoneValidators(country, t))(sanitizedPhone)
                    .then(() => {
                      savePhone(sanitizedPhone);
                      setPhone(sanitizedPhone);
                    })
                    .catch(() => {
                      setPhone(value);
                    });
                } catch (err) {
                  setPhone(value);
                }
                return;
              }

              setPhone(value);
            }}
            phone={phone}
            country={country}
          />

          <StatusError status={isPhoneError} className="pt-1">
            {t("We couldn't save the phone number.")}
          </StatusError>
          <div className="mt-5">
            <p>
              {t(
                "We need an email address to send the invoices. Make sure that you have access to this email address since we will verify it by a code."
              )}
            </p>
          </div>
          <div className="mt-3">
            <TextInput
              label={t("Email")}
              value={email}
              onChange={(value) => {
                resetCodeError();
                resetEmailError();
                setShowCode(false);
                setEmail(value);
              }}
              validators={[
                new RequiredValidator(t("Email is required")),
                new EmailValidator(t("Email is incorrectly formated")),
              ]}
              disabled={isEmailPending || isVerifyCodePending}
            />
          </div>
          <div className="mt-3">
            <Dynamic name={showCode ? "code" : ""}>
              {showCode ? (
                <>
                  <p>
                    {t(
                      "Enter the verification code that we just sent to your email."
                    )}
                  </p>
                  <TextInput
                    label={t("Verification code")}
                    value={code}
                    onChange={(value) =>
                      setCode(value ? value.slice(0, LENGTH_OF_CODE) : "")
                    }
                    validators={[
                      new RequiredValidator("Code is required"),
                      new MinLengthValidator(
                        LENGTH_OF_CODE,
                        t(
                          "The verification code has to be {{length}} characters",
                          {
                            length: LENGTH_OF_CODE,
                          }
                        )
                      ),
                      new MaxLengthValidator(
                        LENGTH_OF_CODE,
                        t(
                          "The verification code has to be {{length}} characters",
                          {
                            length: LENGTH_OF_CODE,
                          }
                        )
                      ),
                    ]}
                    hint={t(
                      "The verification code is valid for {{expirationTime}} minutes",
                      {
                        expirationTime: VERIFICATION_EXPIRATION_TIME,
                      }
                    )}
                    disabled={isEmailPending || isVerifyCodePending}
                  />
                </>
              ) : (
                <Button
                  block
                  onClick={() => {
                    saveEmail();
                  }}
                >
                  {t("Request code")}
                </Button>
              )}
            </Dynamic>
          </div>

          <StatusError
            status={isEmailError && errorStatus === ERROR_BAD_REQUEST}
            className="pt-2"
          >
            {t(
              "Seems like the email is badly formatted. Update and try again?"
            )}
          </StatusError>
          <StatusError
            status={isEmailError && errorStatus !== ERROR_BAD_REQUEST}
            className="pt-2"
          >
            {t("We couldn't save the email address. Try again?")}
          </StatusError>
          <StatusError
            status={isVerifyCodeError && errorStatus === ERROR_BAD_REQUEST}
            className="pt-2"
          >
            {t("The provided code seems incorrect. Reenter and try again?")}
          </StatusError>
          <StatusError
            status={isVerifyCodeError && errorStatus !== ERROR_BAD_REQUEST}
            className="pt-2"
          >
            {t("We couldn't save the email. Try again?")}
          </StatusError>

          <div className="mt-8">
            <StoryButtons
              disabled={isEmailPending || isVerifyCodePending || isPhonePending}
            />
          </div>
        </View>
      </Form>
    </Wrapper>
  );
};
