import { FunctionComponent } from "react";
import { DocumentType } from "../../data/models/ContractTypes";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

interface Props {
  value: DocumentType;
}

export const DocumentTypeLabel: FunctionComponent<Props> = ({ value }) => {
  const { t } = useTranslation();
  return <>{getDocumentTypeTranslation(t)[value]}</>;
};

export const getDocumentTypeTranslation = (
  t: TFunction
): Record<DocumentType, string> => ({
  TAXI_LICENSE: t("Taxi License"),
  POWER_OF_ATTORNEY: t("Power of Attorney"),
  PROOF_OF_ACCREDITATION: t("Proof of Accreditation"),
  FINANCIAL_STATEMENT: t("Financial Statement"),
  SUPPLEMENT: t("Supplement"),
  VISA_SCREENING: "Visa " + t("Screening"),
  RISK_DOCUMENT: t("Risk Document"),
  SIGNED_CONTRACT: t("Signed Contract"),
  CREDITSAFE: "Creditsafe " + t("Screening"),
});
