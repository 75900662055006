import React from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import {
  CountryCode,
  Language,
  SelectedSignatory,
  SigningPerson,
} from "../../data/models/ContractTypes";
import { Select } from "../Forms/Select";
import { getCountryOptions } from "../../components/Countries/countryOptions";
import { RequiredValidator } from "../Forms/validators/RequiredValidator";
import { HiddenInput } from "../Forms/HiddenInput";
import { Citizenships } from "./Citizenships";

interface Props {
  person: SelectedSignatory | SigningPerson;
  onRemoveCitizenship: (country: CountryCode) => void;
  onChange: (value: string, name: string) => void;
}

export const SignatoryForm: React.FunctionComponent<Props> = ({
  person,
  onRemoveCitizenship,
  onChange,
}) => {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <Select
        className={cx("mt-1")}
        label={t("Country of birth")}
        onChange={onChange}
        name="placeOfBirth"
        value={person.placeOfBirth || ""}
        options={getCountryOptions(i18n.language as Language)}
        validators={[new RequiredValidator(t("Value is required"))]}
      />

      <Select
        className={cx("mt-1")}
        label={t("Country of residence")}
        onChange={onChange}
        name="countryOfResidence"
        value={person.countryOfResidence || ""}
        options={getCountryOptions(i18n.language as Language)}
        validators={[new RequiredValidator(t("Value is required"))]}
      />

      <Citizenships
        onChange={onChange}
        onRemoveCitizenship={onRemoveCitizenship}
        person={person}
      />

      <HiddenInput
        value={!!person.citizenships.length ? true : undefined}
        validators={[new RequiredValidator(t("Required value"))]}
      />
    </div>
  );
};
