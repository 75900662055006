import { FaThumbsDown } from "react-icons/fa6";
import Skeleton from "react-loading-skeleton";
import { Suspense } from "../../../Suspense";
import { BackofficeCard } from "../../components/card/BackofficeCard";
import { BackofficeCardHeader } from "../../components/card/BackofficeCardHeader";
import { useTranslation } from "react-i18next";
import { RejectCase } from "./RejectCase/RejectCase";

interface Props {
  disabled: boolean;
}

export const RiskRejectCard: React.FC<Props> = ({ disabled }) => {
  const { t } = useTranslation();

  return (
    <BackofficeCard>
      <BackofficeCardHeader
        title={t("Reject application")}
        icon={FaThumbsDown}
        iconClassName="text-error"
        color="error"
      />

      <Suspense skeleton={<Skeleton height={100} />}>
        <Inner disabled={disabled} />
      </Suspense>
    </BackofficeCard>
  );
};

const Inner: React.FC<Props> = ({ disabled }) => {
  const { t } = useTranslation();

  return (
    <>
      <p className="passive">{t("Reject application")}</p>
      <RejectCase disabled={disabled} />
    </>
  );
};
