import React, { ReactNode } from "react";
import cx from "classnames";
import styles from "./Chip.module.scss";
import { Status } from "../../modules/Forms/FormContext";

export enum FocusAlign {
  LEFT = "left",
  RIGHT = "right",
}

interface Props {
  children: ReactNode;
  className?: string;
  status?: Status;
  focusAlign?: FocusAlign;
  padded?: boolean;
}

interface FocusProps {
  children: ReactNode;
}

export const Chip: React.FunctionComponent<Props> & {
  Focus: React.FunctionComponent<FocusProps>;
} = ({
  children,
  className,
  focusAlign = FocusAlign.LEFT,
  status = "",
  padded = true,
}) => {
  return (
    <div
      className={cx(
        styles.chip,
        styles[status],
        styles[focusAlign],
        className,
        {
          [styles.padded]: padded,
        }
      )}
    >
      {children}
    </div>
  );
};

export const Focus: React.FunctionComponent<FocusProps> = ({ children }) => {
  return <div className={cx(styles.focus)}>{children}</div>;
};

Chip.Focus = Focus;
