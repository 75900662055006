import clsx from "classnames";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../components/Buttons/Button";
import { getIntlDateTime } from "../../../../components/dateUtils";
import { ContractSupplement } from "../../../../data/dataRisk";
import { RiskSupplementOverlay } from "./RiskSupplementOverlay";
import styles from "./SupplementRow.module.scss";

interface Props {
  supplement: ContractSupplement;
}

export const SupplementRow: React.FC<Props> = ({ supplement }) => {
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <RiskSupplementOverlay
        isOpen={isPreviewOpen}
        onClose={() => setIsPreviewOpen(false)}
        supplement={supplement}
      />
      <div className={styles.container}>
        <div className="truncated">
          <div className="truncated text-500 text-09">{supplement.request}</div>
          <span
            className={clsx("text-08 passive text-600", {
              [styles.successText]: !!supplement.completed,
              [styles.warningText]: !supplement.completed,
            })}
          >
            {!!supplement.completed
              ? t("Submitted - {{date}}", {
                  date: getIntlDateTime(supplement.completed),
                })
              : t("Waiting for response")}
          </span>
        </div>
        <Button
          onClick={() => setIsPreviewOpen(true)}
          size="mini"
          variant="text"
          className={styles.button}
        >
          {t("View")}
        </Button>
      </div>
    </>
  );
};
