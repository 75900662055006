import React from "react";
import cx from "classnames";
import styles from "./Waiting.module.scss";
import { useTranslation } from "react-i18next";
import { Confirmed, TimelineStatus } from "./Timeline/Confirmed";
import { Signatories } from "./Timeline/Signatories";
import { Verified } from "./Timeline/Verified";
import { Shipped } from "./Timeline/Shipped";

export const Waiting: React.FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <div className={cx(styles.waiting)}>
      <p>
        {t(
          "You have signed the contract and if you have other signatories that needs to sign the contract they will be notified via e-mail."
        )}
      </p>

      <div className="mt-4">
        <Confirmed status={TimelineStatus.DONE} />
        <Signatories status={TimelineStatus.ACTIVE} />
        <Verified status={TimelineStatus.NOT_STARTED} />
        <Shipped status={TimelineStatus.NOT_STARTED} />
      </div>
    </div>
  );
};
