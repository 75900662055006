import { FaMagnifyingGlass } from "react-icons/fa6";
import { useRiskCase } from "../../hooks/useRiskCase";
import { useTranslation } from "react-i18next";
import { BackofficeCard } from "../../components/card/BackofficeCard";
import { BackofficeCardHeader } from "../../components/card/BackofficeCardHeader";
import { DataList } from "../../components/Datalist/DataList";
import { DataListItem } from "../../components/Datalist/DataListItem";
import { Suspense } from "../../../Suspense";
import Skeleton from "react-loading-skeleton";
import { getIntlDateTime } from "../../../../components/dateUtils";
import { Fragment } from "react/jsx-runtime";
import { Beacon } from "../../../../components/Beacon/Beacon";
import { Validity } from "../../../../data/models/ContractTypes";
import { Link } from "../../../../components/Link/Link";

const CM1LoginUrl =
  "https://aml.cm1.se/Login/Login?ReturnUrl=%2fPepBlockedSearch%2fPerson";

export const RiskCm1Card: React.FC = () => {
  const { t } = useTranslation();

  return (
    <BackofficeCard>
      <BackofficeCardHeader
        title={
          <div className="flex align-center gap-2">
            <span>CM1</span>
            <Link external to={CM1LoginUrl} className="text-09">
              {t("Login to CM1")}
            </Link>
          </div>
        }
        icon={FaMagnifyingGlass}
      />
      <Suspense skeleton={<Skeleton height={100} className="mb-2" />}>
        <Inner />
      </Suspense>
    </BackofficeCard>
  );
};

const Inner = () => {
  const { t } = useTranslation();

  const {
    data: {
      screeningResults: { cm1 },
    },
  } = useRiskCase();
  const { companyScreened, persons } = cm1;

  return (
    <div>
      <DataList type="horizontal" align="left">
        <DataListItem
          label={t("Date")}
          value={getIntlDateTime(companyScreened)}
        />
      </DataList>

      <hr />

      {persons.map((person, index, acc) => (
        <Fragment key={person.name}>
          <DataList type="horizontal" align="left" className="mb-2">
            <DataListItem label={t("Name")} value={person.name} />
            <DataListItem
              label={t("Personal identity number")}
              value={person.nationalId}
            />
            {/* <DataListItem label={t("Nationality")} value={person.nationality} /> */}
          </DataList>
          <DataList type="vertical" align="left" columns>
            <DataListItem
              label={t("Pep")}
              value={
                <span className="flex align-center gap-1">
                  <Beacon
                    className="flex"
                    validity={person.pep ? Validity.MISSING : Validity.VALID}
                  />
                  {person.pep ? t("Hit") : t("Clear")}
                </span>
              }
              column
            />
            <DataListItem
              label={t("Sanctioned")}
              value={
                <span className="flex align-center gap-1">
                  <Beacon
                    className="flex"
                    validity={
                      person.sanction ? Validity.MISSING : Validity.VALID
                    }
                  />
                  {person.sanction ? t("Hit") : t("Clear")}
                </span>
              }
              column
            />
          </DataList>
          {index !== acc.length - 1 && <hr />}
        </Fragment>
      ))}
    </div>
  );
};
