import React, { useContext } from "react";
import { Bankgiro } from "./Bankgiro/Bankgiro";
import { isNorway, isSweden } from "../../../data/models/ContractUtils";
import { useSuspenseQueries } from "@tanstack/react-query";
import { dataContract } from "../../../data/dataContract";
import { Kar } from "./Kar/Kar";
import { Roaring } from "./Roaring/Roaring";
import { GlobalContext } from "../../..";
import { QueryKey } from "../../../data/Store";
import { Iban } from "./Iban/Iban";

export const Bank: React.FunctionComponent = () => {
  const { search } = useContext(GlobalContext);

  const [{ data: contract }] = useSuspenseQueries({
    queries: [dataContract.fetchContract()],
  });

  if (search[QueryKey.USE_ROARING_BANKS]) {
    return <Roaring />;
  }

  if (isSweden(contract)) {
    return <Bankgiro />;
  }

  if (isNorway(contract)) {
    return <Kar />;
  }

  return <Iban />;
};
