import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "../../../../components/Boxes/Box";
import { Button } from "../../../../components/Buttons/Button";
import { Overlay } from "../../../../components/Overlay/Overlay";
import { dataRisk } from "../../../../data/dataRisk";
import { SalesLocation } from "../../../../data/models/ContractTypes";
import { Form } from "../../../../modules/Forms/Form";
import { Status } from "../../../../modules/Forms/FormContext";
import { useContractId } from "../../hooks/useContractId";
import { useDebouncedStatus } from "../../hooks/useDebouncedStatus";
import { MccSelect } from "./MccSelect/MccSelect";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  location: SalesLocation;
}

export const RiskMccEditOverlay: React.FC<Props> = ({
  isOpen,
  onClose,
  location,
}) => {
  return (
    <Overlay open={isOpen} onClose={onClose}>
      <Inner {...{ onClose, location }} />
    </Overlay>
  );
};

const Inner: React.FC<Pick<Props, "onClose" | "location">> = ({
  onClose,
  location,
}) => {
  const contractId = useContractId();
  const [newMcc, setNewMcc] = useState<string>();
  const { t } = useTranslation();

  const {
    mutate: handdleChange,
    status: mutationStatus,
    isSuccess,
  } = useMutation(dataRisk.setMcc(contractId, location.id));

  const debouncedStatus = useDebouncedStatus(mutationStatus);

  const status = isSuccess ? Status.SUCCESS : debouncedStatus;

  return (
    <div>
      <h4 className="mb-0 mt-0">{t("Change MCC")}</h4>
      <p>
        {t("Change the MCC of location {{name}}.", {
          name: location.doingBusinessAs,
        })}
      </p>

      <Form
        onSubmit={(_, form) => {
          if (form.isInvalid) {
            return;
          }

          if (typeof newMcc === "undefined") {
            return;
          }

          handdleChange(newMcc, {
            onSuccess: onClose,
          });
        }}
        className="flex-column gap-2"
      >
        <Box
          status={Status.SUCCESS}
          className="text-09 flex justify-between"
          noIcon
        >
          <span>{t("Current MCC")}</span>
          <strong>{location.mcc}</strong>
        </Box>
        <MccSelect
          value={newMcc}
          onChange={setNewMcc}
          name="mcc"
          disabled={false}
        />
        <div className="flex gap-2">
          <Button
            type="button"
            block
            variant="text"
            className="mt-1"
            onClick={onClose}
          >
            {t("Cancel")}
          </Button>
          <Button
            type="submit"
            block
            variant="primary"
            status={status}
            className="mt-1"
          >
            {t("Save change")}
          </Button>
        </div>
      </Form>
    </div>
  );
};
