export enum SortDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export interface Sort {
  sorted: boolean;
  direction?: SortDirection;
  property?: string;
}

export interface ResponsePage<T> {
  content?: T[];
  /**
   * Number of total pages
   */
  totalPages: number;
  /**
   * Number of total elements
   */
  totalElements: number;
  /**
   * Current page size
   */
  size: number;
  /**
   * Current page number
   */
  number: number;
  /**
   * Number of elements on this page
   */
  numberOfElements: number;

  /**
   * Indicates if this is the first page
   */
  first: boolean;
  /**
   * Indicates if this is the last page
   */
  last: boolean;
  /**
   * Is this set empty
   */
  empty: boolean;

  sort: Sort;
}
