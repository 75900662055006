import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "../../../../components/Boxes/Box";
import { Button } from "../../../../components/Buttons/Button";
import { getLanguageName } from "../../../../components/dateUtils";
import { getLanguageDisplayName } from "../../../../components/langUtils";
import { Overlay } from "../../../../components/Overlay/Overlay";
import { dataRisk } from "../../../../data/dataRisk";
import { Language } from "../../../../data/models/ContractTypes";
import { Checkboxes } from "../../../../modules/Forms/Checkboxes";
import { Form } from "../../../../modules/Forms/Form";
import { Status } from "../../../../modules/Forms/FormContext";
import { TextArea } from "../../../../modules/Forms/TextArea";
import { RequiredValidator } from "../../../../modules/Forms/validators/RequiredValidator";
import { useContractId } from "../../hooks/useContractId";
import { useDebouncedStatus } from "../../hooks/useDebouncedStatus";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  contractLanguage: Language | undefined;
}

export const RiskRequestSupplementOverlay: React.FC<Props> = ({
  isOpen,
  onClose,
  contractLanguage,
}) => {
  return (
    <Overlay open={isOpen} onClose={onClose}>
      <Inner {...{ onClose, contractLanguage }} />
    </Overlay>
  );
};

const Inner: React.FC<Pick<Props, "onClose" | "contractLanguage">> = ({
  onClose,
  contractLanguage,
}) => {
  const contractId = useContractId();
  const [description, setDescription] = useState("");
  const [isAttachmentRequired, setIsUploadRequired] = useState(false);
  const [autoTranslate, setAutoTranslate] = useState(false);
  const { t, i18n } = useTranslation();

  const {
    mutate: handleAdd,
    status: mutationStatus,
    isSuccess,
  } = useMutation(dataRisk.createSupplement(contractId));

  const debouncedStatus = useDebouncedStatus(mutationStatus);

  const status = isSuccess ? Status.SUCCESS : debouncedStatus;

  const language = contractLanguage;

  const languageDisplay = language
    ? getLanguageDisplayName(language, i18n.language as Language)
    : t("Unknown");

  return (
    <div>
      <h4 className="mb-0 mt-0">{t("Request a supplement")}</h4>
      <p>
        {t(
          "An email will be sent to the primary contact of the merchant, with a request to provide the described supplement."
        )}
      </p>

      <Form
        onSubmit={(_, form) => {
          if (form.isInvalid) {
            return;
          }

          handleAdd(
            {
              question: description,
              uploadRequired: isAttachmentRequired,
              autoTranslate,
            },
            {
              onSuccess: onClose,
            }
          );
        }}
        className="flex-column gap-2"
      >
        <Box
          status={Status.SUCCESS}
          className="text-09 flex justify-between"
          noIcon
        >
          <span>{t("Primary contact language")}</span>
          <strong>{language ? getLanguageName(language) : t("Unknown")}</strong>
        </Box>
        <TextArea
          attributes={{ rows: 4 }}
          label={t("Description of request")}
          name="description"
          value={description}
          onChange={setDescription}
          validators={[new RequiredValidator(t("Description is required"))]}
          message={t(
            "Please describe the request, this will be the description the merchant will see."
          )}
          hint={t(
            "Please describe the request, this will be the description the merchant will see."
          )}
        />
        <div>
          <Checkboxes
            values={autoTranslate ? ["true"] : []}
            onChange={() => {
              setAutoTranslate(!autoTranslate);
            }}
            options={[
              {
                value: "true",
                text: t(
                  "Translate the request into the language of the primary contact ({{language}})",
                  {
                    language: languageDisplay,
                  }
                ),
              },
            ]}
            hint={t(
              "We will translate the request description to the merchants preferred language, the translated request will be displayed to the merchant"
            )}
          />
          <Checkboxes
            values={isAttachmentRequired ? ["true"] : []}
            onChange={() => {
              setIsUploadRequired(!isAttachmentRequired);
            }}
            options={[
              {
                value: "true",
                text: "Require attachment",
              },
            ]}
            hint={t("Require the merchant to attach a document / file")}
          />
        </div>
        <Button
          type="submit"
          block
          variant="primary"
          status={status}
          className="mt-1"
        >
          {t("Request supplement")}
        </Button>
      </Form>
    </div>
  );
};
