import { AnimatePresence, motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { Box } from "../../../../components/Boxes/Box";
import { RiskDecision } from "../../../../data/models/RiskTypes";
import { Status } from "../../../../modules/Forms/FormContext";

interface Props {
  decision?: RiskDecision;
}

export const RiskCaseStatus: React.FC<Props> = ({ decision }) => {
  const { t } = useTranslation();

  return (
    <AnimatePresence>
      {!!decision && decision === RiskDecision.APPROVED && (
        <motion.div
          initial={{ height: 0, opacity: 0 }}
          animate={{ height: "auto", opacity: 1 }}
        >
          <Box status={Status.SUCCESS} noIcon>
            {t("Risk assessment marked as approved")}
          </Box>
        </motion.div>
      )}
      {!!decision && decision === RiskDecision.REJECTED && (
        <motion.div
          initial={{ height: 0, opacity: 0 }}
          animate={{ height: "auto", opacity: 1 }}
        >
          <Box status={Status.ERROR} noIcon>
            {t("Risk assessment marked as rejected")}
          </Box>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
