import React from "react";
import { useTranslation } from "react-i18next";
import { Wrapper } from "../../../components/Wrapper";
import styles from "./CompleteStep.module.scss";

export const CompleteStep: React.FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <Wrapper className={styles.wrapper}>
      <h2>{t("Thank you!")}</h2>
      <p className="text-center">
        {t(
          "We have received your information and will process it as soon as possible."
        )}
      </p>
    </Wrapper>
  );
};
