import { Link, generatePath, useMatch } from "react-router-dom";
import { getIntlDateTime } from "../../../../components/dateUtils";
import { RiskClosedItem } from "../../../../data/dataRisk";
import { BACKOFFICE_CASE_ROUTE } from "../../BackofficeCasePage/BackofficeCasePage";
import styles from "./ClosedCaseItem.module.scss";
import clsx from "classnames";
import { FaBuilding } from "react-icons/fa6";
import { Flag } from "../../../../components/Countries/Flag";
import { RiskDecision } from "../../../../data/models/RiskTypes";

interface Props {
  item: RiskClosedItem;
}

export const ClosedCaseItem: React.FunctionComponent<Props> = ({ item }) => {
  const match = useMatch(BACKOFFICE_CASE_ROUTE);

  const isActive = match?.params.id === item.id;

  return (
    <li className={styles.root}>
      <Link
        className={clsx(styles.wrapper, {
          [styles.active]: isActive,
        })}
        to={generatePath(BACKOFFICE_CASE_ROUTE, {
          id: item.id,
        })}
      >
        <div className={styles.name}>
          <span>{item.companyName}</span>
          <Flag country={item.country} height={12} />
        </div>
        <div className={styles.orgNumber}>
          <FaBuilding size={12} />
          <span>{item.organizationNumber}</span>
        </div>
        <div className={styles.meta}>
          <div
            className={clsx(styles.decision, {
              [styles.accepted]: item.decision === RiskDecision.APPROVED,
              [styles.rejected]: item.decision === RiskDecision.REJECTED,
            })}
          >
            {item.decision}
          </div>
          <div>{getIntlDateTime(item.closed)}</div>
        </div>
      </Link>
    </li>
  );
};
