import { Wrapper } from "../../../components/Wrapper";
import styles from "./BackofficeDashboardPage.module.scss";

export const BACKOFFICE_DASHBOARD_ROUTE = "/backoffice";

export const BackofficeDashboardPage: React.FunctionComponent = () => {
  return (
    <Wrapper className={styles.wrapper}>
      <h1>No case selected</h1>
      <p>Please select a case to start the action!</p>
    </Wrapper>
  );
};
