import { VALIDATION_STATE } from "../FormContext";
import { ValidationResponse } from "../hooks/useValidation";
import { BaseValidator } from "./BaseValidator";

export const MaxLengthValidatorName = "MaxLengthValidator";

export class MaxLengthValidator extends BaseValidator {
  name = MaxLengthValidatorName;
  max: number;

  constructor(max: number, error: string) {
    super(error);
    this.max = max;
  }

  get maxLength() {
    return this.max;
  }

  validate(value: string): Promise<ValidationResponse> {
    return new Promise<ValidationResponse>((resolve) => {
      if (value.length <= this.max) {
        resolve({ status: VALIDATION_STATE.SUCCESS });
      } else {
        resolve({
          status: VALIDATION_STATE.FAILED,
          message: this.error,
        });
      }
    });
  }
}
