import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

interface Props {
  value: string;
}

export const PricingEntryLabel: FunctionComponent<Props> = ({ value }) => {
  const { t } = useTranslation();
  return <>{getPricingEntryLabelTranslation(t)[value] || value}</>;
};

export const getPricingEntryLabelTranslation = (
  t: TFunction
): Record<string, string> => ({
  DebitDomestic: t("Debit cards domestic"),
  DebitEEA: t("Debit cards {{area}}", { area: "EEA" }),
  DebitOutsideEEA: t("Debit cards outside {{area}}", { area: "EEA" }),
  CreditDomestic: t("Credit cards domestic"),
  CreditEEA: t("Credit cards {{area}}", { area: "EEA" }),
  CreditOutsideEEA: t("Credit cards outside {{area}}", { area: "EEA" }),
  CommercialCardsDomestic: t("Commercial cards domestic"),
  CommercialCardsEEA: t("Commercial cards {{area}}", { area: "EEA" }),
  CommercialCardsOutsideEEA: t("Commercial cards outside {{area}}", {
    area: "EEA",
  }),
  CorporationCard: t("Corporation cards"),
  AllOther: t("All other cards"),
  MonthlyCost: t("Monthly costs"),
  MonthlyFeePerTerminal: t("Monthly fee/terminal"),
  TransactionFee: t("Transactional fee"),
  InitialFee: t("Initial fee"),
  AdditionalMonths: t("Additional months"),
  MonthlyFeeWireless: t("Monthly fee/wireless terminal"),
  MonthlyFeeWirelessIntegrated: t("Monthly fee/wireless integrated terminal"),
  MonthlyFeeWiredIntegrated: t("Monthly fee/wired integrated terminal"),
  "Shipping Fee": t("Shipping cost/terminal"),
  "Invoice fee": t("Invoice fee"),
  "Invoice remittance": t("Invoice fee"),
  "Debit card domestic": t("Debit cards domestic"),
  "Credit card domestic": t("Credit cards domestic"),
  "Company card domestic": t("Commercial cards domestic"),
  "Debit card EEA": t("Debit cards {{area}}", { area: "EEA" }),
  "Credit card EEA": t("Credit cards {{area}}", { area: "EEA" }),
  "Company card EEA": t("Commercial cards {{area}}", { area: "EEA" }),
  "Debit card outside EEA": t("Debit cards outside {{area}}", { area: "EEA" }),
  "Credit card outside EEA": t("Credit cards outside {{area}}", {
    area: "EEA",
  }),
  "Company card outside EEA": t("Commercial cards outside {{area}}", {
    area: "EEA",
  }),
});
