import { FaClockRotateLeft } from "react-icons/fa6";
import { useRiskCase } from "../../hooks/useRiskCase";
import { useTranslation } from "react-i18next";
import { BackofficeCard } from "../../components/card/BackofficeCard";
import { BackofficeCardHeader } from "../../components/card/BackofficeCardHeader";
import { Suspense } from "../../../Suspense";
import Skeleton from "react-loading-skeleton";
import { getIntlDateTime } from "../../../../components/dateUtils";
import styles from "./RiskAuditHistoryCard.module.scss";

export const RiskAuditHistoryCard: React.FC = () => {
  const { t } = useTranslation();

  return (
    <BackofficeCard>
      <BackofficeCardHeader
        title={t("Case audit history")}
        icon={FaClockRotateLeft}
      />
      <Suspense skeleton={<Skeleton height={100} className="mb-2" />}>
        <Inner />
      </Suspense>
    </BackofficeCard>
  );
};

const Inner = () => {
  const { t } = useTranslation();

  const {
    data: {
      caseDetails: { changeLog },
    },
  } = useRiskCase();

  return (
    <div>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>{t("Date")}</th>
            <th>{t("Field")}</th>
            <th>{t("From")}</th>
            <th>{t("To")}</th>
            <th>{t("User")}</th>
          </tr>
        </thead>
        <tbody>
          {changeLog.map((log) => (
            <tr key={log.created}>
              <td>{getIntlDateTime(log.created)}</td>
              <td>{log.field}</td>
              <td>{log.from}</td>
              <td>{log.to}</td>
              <td>{log.user}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
