import React, { ReactNode } from "react";
import cx from "classnames";
import styles from "./CardList.module.scss";

interface Props {
  children: ReactNode;
  className?: string;
}

export const CardList: React.FunctionComponent<Props> & {
  CardListItem: React.FunctionComponent<Props>;
} = ({ children, className }) => {
  return <ul className={cx(className, styles.cardList)}>{children}</ul>;
};

const CardListItem: React.FunctionComponent<Props> = ({
  children,
  className,
}) => {
  return <li className={cx(className, styles.cardListItem)}>{children}</li>;
};

CardList.CardListItem = CardListItem;
