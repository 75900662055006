import React, { useCallback, ReactNode } from "react";
import cx from "classnames";
import { Button } from "../../Buttons/Button";
import { useStoryNavigate } from "../../../hooks/useStoryNavigate";
import { LinkButton } from "../../Buttons/LinkButton";
import { Path } from "../../storyTypes";

interface Props {
  to: Path;
  children: ReactNode;
  className?: string;
  asButton?: boolean;
}

export const NavigationStoryLink: React.FunctionComponent<Props> = (props) => {
  return props.asButton ? <AsButton {...props} /> : <AsLink {...props} />;
};

const AsLink: React.FunctionComponent<Props> = ({
  to,
  className,
  children,
}) => {
  const { navigate } = useStoryNavigate();

  const onClick = useCallback(
    (ev: React.MouseEvent<HTMLElement, MouseEvent>) => {
      ev.preventDefault();
      navigate(to);
    },
    [navigate, to]
  );

  return (
    <div onClick={onClick}>
      <LinkButton block to={to} className={cx(className)}>
        {children}
      </LinkButton>
    </div>
  );
};

const AsButton: React.FunctionComponent<Props> = ({
  className,
  children,
  to,
}) => {
  const { navigate } = useStoryNavigate();

  const onClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      navigate(to);
    },
    [navigate, to]
  );
  return (
    <Button block type="button" className={className} onClick={onClick}>
      {children}
    </Button>
  );
};
