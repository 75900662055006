import { ReactNode } from "react";
import { BankSupplementPath } from "../stories/BankSupplement/routes";
import { OnboardingPath } from "../stories/Onboarding/routes";
import { SigningPath } from "../stories/Signing/routes";
import { SupplementPath } from "../stories/Supplement/routes";

export type Path =
  | SigningPath
  | OnboardingPath
  | SupplementPath
  | BankSupplementPath;

export interface StoryFrameProps {
  path: Path;
  element: ReactNode;
  hideProgress?: boolean;
}

export interface NavigationRoutes {
  routes: StoryFrameProps[];
  baseRoute: string;
}

export enum Direction {
  FORWARDS,
  BACKWARDS,
}
