import React, { useState } from "react";
import cx from "classnames";
import styles from "./SalesLocation.module.scss";
import { Form } from "../../../modules/Forms/Form";
import { useTranslation } from "react-i18next";
import { RequiredValidator } from "../../../modules/Forms/validators/RequiredValidator";
import { Columns } from "../../../components/Columns/Columns";
import { TextInput } from "../../../modules/Forms/TextInput";
import {
  Contract,
  Country,
  SalesLocation as SalesLocationType,
} from "../../../data/models/ContractTypes";
import { dataSalesLocation } from "../../../data/dataSalesLocation";
import { useMutation } from "@tanstack/react-query";
import { queryClient } from "../../..";
import { dataContract } from "../../../data/dataContract";
import { PhoneLabel } from "../../../modules/Phone/PhoneLabel";
import {
  MIN_PHONE_LENGTH,
  PhoneCountryCodeValidator,
} from "../../../modules/Forms/validators/PhoneCountryCodeValidator";
import { MinLengthValidator } from "../../../modules/Forms/validators/MinLengthValidator";
import { RegexValidator } from "../../../modules/Forms/validators/RegexValidator";
import { MaxLengthValidator } from "../../../modules/Forms/validators/MaxLengthValidator";
import {
  EXAMPLE_PHONE_NUMBER,
  PrefixMap,
} from "../../../modules/Phone/PhoneToCountry";
import { ASCIIValidator } from "../../../modules/Forms/validators/ASCIIValidator";
import { hasRealErrors } from "../../../modules/Forms/FormHelpers";
import { KnownCharactersValidator } from "../../../modules/Forms/validators/KnownCharactersValidator";
import { PostalCodeValidator } from "../../../modules/Forms/validators/PostalCodeValidator";

interface Props {
  store: SalesLocationType;
  country: Country;
}

const DOING_BUSINESS_AS_MAX_LENGTH = 22;

export const SalesLocation: React.FunctionComponent<Props> = ({
  store: originalStore,
  country,
}) => {
  const [store, setStore] = useState<SalesLocationType>(originalStore);
  const { t } = useTranslation();
  const queryKey = dataContract.getContractKey();

  const {
    // TODO
    isError, // eslint-disable-line
    isPending, // eslint-disable-line
    reset, // eslint-disable-line
    mutate: onSave,
  } = useMutation({
    mutationFn: async () => dataSalesLocation.saveLocation(store),
    onMutate: async () => {
      await queryClient.cancelQueries({
        queryKey,
      });

      const previousContract = queryClient.getQueryData<Contract>(queryKey);
      if (!previousContract) {
        return;
      }

      const locations = previousContract.locations.map((location) => {
        if (store.id === location.id) {
          return store;
        }

        return location;
      });

      const update: Contract = {
        ...previousContract,
        locations,
      };

      queryClient.setQueryData<Contract>(queryKey, update);

      return { previousContract };
    },
    onError: (err, variables, context) => {
      if (!context) {
        return;
      }

      const updatedStore = context.previousContract.locations.find(
        (location) => location.id === store.id
      );

      if (updatedStore) {
        setStore(updatedStore);
      }

      queryClient.setQueryData(queryKey, context.previousContract);
    },
  });

  return (
    <Form
      name={`location-${store.id.toString()}`}
      onSaveTrigger={(_, form) => {
        const realErrors = hasRealErrors(form);

        if (realErrors) {
          return;
        }
        onSave();
      }}
    >
      <div className={cx(styles.store)}>
        <Columns>
          <div className="mt-3">
            <TextInput
              className={styles.bg}
              label={<div className={styles.label}>{t("Store name")}</div>}
              name="doingBusinessAs"
              value={store.doingBusinessAs}
              onChange={(value, name) => {
                setStore((prev) => ({
                  ...prev,
                  [name]: value
                    ? value.slice(0, DOING_BUSINESS_AS_MAX_LENGTH)
                    : "",
                }));
              }}
              validators={[
                new RequiredValidator(t("Store name is required")),
                new MaxLengthValidator(
                  DOING_BUSINESS_AS_MAX_LENGTH,
                  t("Can't be longer than {{max}} characters", {
                    max: DOING_BUSINESS_AS_MAX_LENGTH,
                  })
                ),
                new ASCIIValidator(
                  "Store name can't include special characters"
                ),
              ]}
            />
          </div>
          <div className="mt-3">
            <TextInput
              className={styles.bg}
              label={
                <PhoneLabel
                  label={t("Contact phone")}
                  phone={store.storePhoneNumber}
                />
              }
              name="storePhoneNumber"
              value={store.storePhoneNumber}
              onChange={(value, name) => {
                setStore((prev) => ({ ...prev, [name]: value }));
              }}
              validators={[
                new RequiredValidator(t("Contact phone is required")),
                new PhoneCountryCodeValidator(
                  t("Contact phone must be prefixed with a '+'")
                ),
                new MinLengthValidator(
                  MIN_PHONE_LENGTH,
                  t("Contact phone must be at least {{min}} characters", {
                    min: MIN_PHONE_LENGTH,
                  })
                ),
                new RegexValidator(
                  /^([^A-Z])*$/i,
                  t("Contact phone can't include letters")
                ),
              ]}
              hint={`${PrefixMap[country]} ${EXAMPLE_PHONE_NUMBER[country]}`}
            />
          </div>
          <div className="mb-1">
            <TextInput
              className={styles.bg}
              label={t("Street")}
              name="street"
              value={store.street}
              onChange={(value, name) => {
                setStore((prev) => ({
                  ...prev,
                  [name]: value,
                }));
              }}
              validators={[
                new RequiredValidator(t("Street is required")),
                new KnownCharactersValidator(
                  t("Street can't include special characters")
                ),
              ]}
            />
          </div>
          <div className="mb-1">
            <TextInput
              className={styles.bg}
              label={t("Postal code")}
              name="postalCode"
              value={store.postalCode}
              onChange={(value, name) => {
                setStore((prev) => ({
                  ...prev,
                  [name]: value,
                }));
              }}
              validators={[
                new RequiredValidator(t("Postal code is required")),
                new PostalCodeValidator(country, t("Postal code is invalid")),
              ]}
            />
          </div>
        </Columns>

        <div className="mb-1">
          <TextInput
            className={styles.bg}
            label={t("City")}
            name="city"
            value={store.city}
            onChange={(value, name) => {
              setStore((prev) => ({
                ...prev,
                [name]: value,
              }));
            }}
            validators={[
              new RequiredValidator(t("City is required")),
              new KnownCharactersValidator(
                t("City can't include special characters")
              ),
            ]}
          />
        </div>

        {/* <h4>{t("Goods/services in this store")}</h4>
        <ErrorBoundary>
          <MCC
            mcc={store.mcc}
            onChange={(mcc) => {
              setStore((prev) => ({
                ...prev,
                mcc: mcc ? mcc : undefined,
              }));
            }}
          />
        </ErrorBoundary> */}
      </div>
    </Form>
  );
};
