import React, { useCallback, useId, useRef } from "react";
import { VALIDATION_STATE } from "./FormContext";
import { ValidatorConstraints } from "./TextInput";
import { InputWrapper } from "./InputWrapper";

export enum FormattedInputType {
  NUMBER = "number",
  PATTERN = "pattern",
}

export interface LayoutProps {
  isFirst?: boolean;
  isLast?: boolean;
}

export interface InputProps {
  state: VALIDATION_STATE;
  className?: string;
  name: string;
  value?: string;
  label?: string | React.ReactNode;
  message?: string | React.ReactNode;
  hint?: string | React.ReactNode;
  type?: string | FormattedInputType;
  onChange: (
    value: string,
    name: string,
    ev:
      | React.ChangeEvent<HTMLInputElement>
      | React.MouseEvent<HTMLButtonElement>
  ) => void;
  onBlur?: (
    value: string,
    name: string,
    ev: React.ChangeEvent<HTMLInputElement>
  ) => void;
  onFocus?: (value: string) => void;
  onClear?: (ev: React.MouseEvent<HTMLButtonElement>) => void;
  placeholder?: string;
  autoFocus?: boolean;
  autocomplete?: string;
  preIcon?: React.ReactNode;
  postIcon?: React.ReactNode;
  constraints: ValidatorConstraints;
  disabled?: boolean;
  formatInputValues?: any;
  layoutProps?: LayoutProps;
  datalist?: string[];
  list?: string;
  inputAttributes?: Record<string, string | boolean | number>;
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => {
    const {
      value,
      name,
      onChange,
      onBlur,
      placeholder,
      disabled,
      constraints,
      autocomplete,
      datalist,
      type,
      list,
      inputAttributes = {},
    } = props;

    const listId = useRef<string>(useId());

    const change = useCallback(
      (ev: React.ChangeEvent<HTMLInputElement>) =>
        onChange(ev.target.value, name, ev),
      [name, onChange]
    );

    const blur = useCallback(
      (ev: React.ChangeEvent<HTMLInputElement>) =>
        onBlur && onBlur(ev.target.value, name, ev),
      [name, onBlur]
    );

    return (
      <InputWrapper {...props}>
        <input
          name={name}
          value={value}
          onChange={change}
          onBlur={blur}
          ref={ref}
          autoComplete={autocomplete}
          placeholder={placeholder}
          required={constraints.isRequired}
          disabled={disabled}
          list={list || listId.current}
          type={type}
          {...inputAttributes}
        />
        {datalist ? (
          <datalist id={listId.current}>
            {datalist.map((value) => (
              <option key={value} value={value}></option>
            ))}
          </datalist>
        ) : null}
      </InputWrapper>
    );
  }
);
