import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
  useContext,
} from "react";
import { FormContext, Input, VALIDATION_STATE } from "./FormContext";
import { scrollToError } from "./scrollToError";
import { MultiFormContext, useMultiForm } from "./MultiFormContext";
import clsx from "classnames";
import { onNextFrame } from "../../components/utils";

export enum TriggerType {
  BLUR,
  CHANGE,
}

interface Props
  extends Omit<
    React.DetailedHTMLProps<
      React.FormHTMLAttributes<HTMLFormElement>,
      HTMLFormElement
    >,
    "onSubmit" | "onBlur"
  > {
  formContainer?: React.MutableRefObject<FormContainer | undefined>;
  onSubmit?(
    event: React.FormEvent<HTMLFormElement>,
    formRef: FormContainer
  ): void;
  onSaveTrigger?(
    event: React.FormEvent<HTMLFormElement>,
    formRef: FormContainer,
    type: TriggerType
  ): void;
  children: React.ReactNode;
}

interface InputWithTimestamp extends Input {
  added: number;
}

export enum FormEventType {
  UPDATE = "UPDATE",
  FORCE_ERRORS = "FORCE_ERRORS",
}
export interface FormEvent {
  type: FormEventType;
  value?: any;
}

export class FormContainer {
  public inputs: InputWithTimestamp[] = [];
  public id: string = window.crypto.randomUUID();
  private listeners: ((
    event: FormEvent,
    formContainer: FormContainer
  ) => void)[] = [];
  private setForceErrorsFn: (value: boolean) => void;

  constructor(
    setForceErrors: (value: boolean) => void,
    public name: string | undefined
  ) {
    this.setForceErrorsFn = setForceErrors;
  }

  updateInput(newInput: Input) {
    this.removeInput(newInput.name);
    this.inputs.push({
      ...newInput,
      added: new Date().getTime(),
    });
    this.listeners.forEach((listener) =>
      listener(
        {
          type: FormEventType.UPDATE,
        },
        this
      )
    );
  }

  removeInput(inputName: string) {
    this.inputs = this.inputs.filter((input) => input.name !== inputName);
  }

  resetValidation() {
    this.inputs.forEach((input) => input.resetValidation());
    this.setForceErrorsFn(false);
    this.listeners.forEach((listener) =>
      listener(
        {
          type: FormEventType.FORCE_ERRORS,
          value: false,
        },
        this
      )
    );
  }

  setForceErrors(value: boolean) {
    this.setForceErrorsFn(value);
    this.listeners.forEach((listener) =>
      listener(
        {
          type: FormEventType.FORCE_ERRORS,
          value: true,
        },
        this
      )
    );
  }

  get isInvalid(): boolean {
    return !this.inputs.every(
      (input) => input.validationState === VALIDATION_STATE.SUCCESS
    );
  }

  get isValid(): boolean {
    return this.inputs.every(
      (input) => input.validationState === VALIDATION_STATE.SUCCESS
    );
  }

  get isPending(): boolean {
    return this.inputs.some(
      (input) => input.validationState === VALIDATION_STATE.PENDING
    );
  }

  addListener(
    listener: (event: FormEvent, formContainer: FormContainer) => void
  ) {
    this.listeners.push(listener);
  }

  removeListener(
    listener: (event: FormEvent, formContainer: FormContainer) => void
  ) {
    const idx = this.listeners.indexOf(listener);
    if (idx > -1) {
      this.listeners.splice(idx, 1);
    }
  }
}

function shouldSaveOnBlur(element: HTMLElement) {
  const type = (element as unknown as HTMLInputElement)?.type;
  return ["text", "number", "date", "textarea"].includes(type);
}

function shouldSaveOnChange(element: HTMLElement) {
  const type = (element as unknown as HTMLInputElement)?.type;
  return ["radio", "checkbox", "select-one", "select-multiple"].includes(type);
}

export const Form = React.forwardRef<HTMLFormElement, Props>(
  (
    { formContainer, onSubmit, onSaveTrigger, children, name, className },
    ref
  ) => {
    const [forceErrors, setForceErrors] = useState<boolean>(false);
    const formRef = useRef<FormContainer>(
      new FormContainer(setForceErrors, name)
    );
    const multiForm = useMultiForm();
    const setFormValidity = useContext(MultiFormContext)?.setValidity;

    useEffect(() => {
      const name = formRef.current.name;
      const savedFormRef = formRef.current;
      multiForm?.addForm(savedFormRef);
      return () => {
        multiForm?.removeForm(savedFormRef);
        if (name) {
          setFormValidity?.({
            action: "DELETE",
            form: name,
          });
        }
      };
    }, [multiForm, setFormValidity]);

    const internalChangeTrigger = useCallback(
      (event: React.FormEvent<HTMLFormElement>) => {
        event.persist();

        if (!shouldSaveOnChange(event.target as HTMLElement)) {
          return;
        }

        if (!onSaveTrigger) {
          return;
        }

        onNextFrame(() => {
          onSaveTrigger(event, formRef.current, TriggerType.CHANGE);
        });
      },
      [onSaveTrigger]
    );

    const internalSubmit = useCallback(
      (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.persist();
        setForceErrors(true);
        onSubmit && onSubmit(event, formRef.current);

        if (formRef.current.isValid) {
          return;
        }

        const elem = formRef.current.inputs.find(
          (input) => input.validationState === VALIDATION_STATE.FAILED
        );

        if (elem && elem.scrollToRef) {
          scrollToError(elem.scrollToRef);
          window.setTimeout(() => {
            (elem.scrollToRef?.current as HTMLInputElement)?.focus();
          }, 500);
        }
      },
      [onSubmit]
    );

    const internalSaveTrigger = useCallback(
      (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.persist();

        if (!shouldSaveOnBlur(event.target as HTMLElement)) {
          return;
        }

        onSaveTrigger &&
          onSaveTrigger(event, formRef.current, TriggerType.BLUR);
      },
      [onSaveTrigger]
    );

    useEffect(() => {
      if (formContainer) {
        formContainer.current = formRef.current;
      }
    }, [formContainer]);

    const removeInput = useCallback((inputName: string) => {
      formRef.current.removeInput(inputName);
    }, []);

    const updateInput = useCallback((newInput: Input) => {
      formRef.current.updateInput(newInput);
    }, []);

    const value = useMemo(
      () => ({
        updateInput,
        removeInput,
        forceErrors,
        formContainer: formRef.current,
      }),
      [removeInput, updateInput, forceErrors]
    );

    return (
      <form
        className={clsx("form", className)}
        onSubmit={internalSubmit}
        onBlur={internalSaveTrigger}
        onChange={internalChangeTrigger}
        noValidate
        autoComplete="off"
        ref={ref}
      >
        <FormContext.Provider value={value}>{children}</FormContext.Provider>
      </form>
    );
  }
);
