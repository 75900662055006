import { ErrorBoundary } from "@sentry/react";
import { useMutation, useSuspenseQueries } from "@tanstack/react-query";
import cx from "classnames";
import { TFunction } from "i18next";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { queryClient } from "../../..";
import { Dynamic } from "../../../components/Animations/Dynamic";
import { Button } from "../../../components/Buttons/Button";
import { MccCategoryLabel } from "../../../components/MccCategoryLabel/MccCategoryLabel";
import { Wrapper } from "../../../components/Wrapper";
import { dataContract } from "../../../data/dataContract";
import { dataSalesLocation } from "../../../data/dataSalesLocation";
import {
  Contract,
  Language,
  MCC as MccInterface,
  SalesLocation,
} from "../../../data/models/ContractTypes";
import { MccCategory } from "../../../data/models/mcc";
import { useStoryNavigate } from "../../../hooks/useStoryNavigate";
import { Form } from "../../../modules/Forms/Form";
import { HiddenInput } from "../../../modules/Forms/HiddenInput";
import { RequiredValidator } from "../../../modules/Forms/validators/RequiredValidator";
import { View } from "../../../modules/View/View";
import { StoryButtons } from "../../StoryButtons";
import { OnboardingPath } from "../routes";
import styles from "./Mcc.module.scss";

export interface MccButtonOption {
  value: string;
  text: string;
}

const mostCommonCategories: MccCategory[] = [
  MccCategory.HOTELS_AND_ACCOMODATION,
  MccCategory.FOOD_DRINKS_AND_RESTAURANTS,
  MccCategory.HEALTH_MEDICAL_AND_BEAUTY,
  MccCategory.CLOTHING_STORES,
  MccCategory.MISCELLANEOUS_STORES,
];

function getLabelFromMCC(mcc: MccInterface): string {
  return mcc.label;
}

function getCategoryOptions(mccs: MccInterface[]) {
  const uniqueCategories = mccs
    .reduce((acc: MccCategory[], mcc) => {
      if (!acc.includes(mcc.salesCategory)) {
        acc.push(mcc.salesCategory);
      }

      return acc;
    }, [])
    .filter((category) => !mostCommonCategories.includes(category))
    .sort();

  return uniqueCategories;
}

function getFilteredMccOptionsByCategory(
  mccs: MccInterface[],
  t: TFunction,
  category?: string
): MccButtonOption[] {
  let opts;

  if (category) {
    opts = mccs
      .filter((opt) => opt.salesCategory === category)
      .map((opt) => ({
        value: opt.code,
        text: getLabelFromMCC(opt),
      })) as MccButtonOption[];
  } else {
    opts = mccs.map((opt) => ({
      value: opt.code,
      text: getLabelFromMCC(opt),
    })) as MccButtonOption[];
  }

  return opts;
}

export const Mcc: React.FunctionComponent = () => {
  const { t, i18n } = useTranslation();
  const { navigate } = useStoryNavigate();
  const queryKey = dataContract.getContractKey();
  const [{ data: contract }, { data: mccs }] = useSuspenseQueries({
    queries: [
      dataContract.fetchContract(),
      dataSalesLocation.fetchMCCs(i18n.language as Language),
    ],
  });

  const [mcc, setMcc] = useState<string | undefined>(contract.locations[0].mcc);
  const [category, setCategory] = useState<MccCategory>();

  const [showAllCategories, setShowAllCategories] = useState<boolean>(() => {
    const currentCategory = mccs.find(
      ({ code }) => code === (mcc || contract.locations[0].mcc)
    )?.salesCategory;
    if (!currentCategory) {
      return false;
    }

    return !mostCommonCategories.includes(currentCategory);
  });

  const otherCategories = useMemo(() => {
    if (!mccs) {
      return [];
    }

    return getCategoryOptions(mccs);
  }, [mccs]);

  // For now, only a single store is allowed
  const selectedMcc = mcc;

  const selectedMccExtended = mccs?.find(
    ({ code }) => code === (mcc || contract.locations[0].mcc)
  );

  const selectedCategory = category || selectedMccExtended?.salesCategory;

  const visibleCategories = showAllCategories
    ? [...mostCommonCategories, ...otherCategories]
    : selectedCategory
    ? [selectedCategory]
    : mostCommonCategories;

  const mccOptions = useMemo(() => {
    return getFilteredMccOptionsByCategory(
      mccs || [],
      t,
      selectedCategory
    ).sort((a, b) => a.text?.localeCompare(b.text));
  }, [mccs, t, selectedCategory]);

  // const {
  //   // TODO
  //   mutate: commit, // eslint-disable-line
  //   isError: isCommitError, // eslint-disable-line
  // } = useMutation({
  //   mutationFn: () => dataSalesLocation.commitLocations(),
  //   onSuccess: () => {
  //     invalidate();
  //     navigate(OnboardingPath.FINANCIAL);
  //   },
  // });

  const {
    // TODO
    isError, // eslint-disable-line
    isPending,
    mutate: onSave,
  } = useMutation({
    mutationFn: async (updatedLocation: SalesLocation) =>
      dataSalesLocation.saveLocation(updatedLocation),
    onMutate: async (updatedLocation: SalesLocation) => {
      await queryClient.cancelQueries({
        queryKey,
      });

      const previousContract = queryClient.getQueryData<Contract>(queryKey);
      if (!previousContract) {
        return;
      }

      const locations = previousContract.locations.map((location) => {
        if (updatedLocation.id === location.id) {
          return updatedLocation;
        }

        return location;
      });

      const update: Contract = {
        ...previousContract,
        locations,
      };

      queryClient.setQueryData<Contract>(queryKey, update);

      return { previousContract };
    },
    onError: (_, __, context) => {
      if (!context) {
        return;
      }

      queryClient.setQueryData(queryKey, context.previousContract);
    },
  });

  return (
    <Wrapper>
      <Form
        onSubmit={async (_, form) => {
          if (form.isInvalid) {
            return;
          }

          const currentContract = await queryClient.fetchQuery(
            dataContract.fetchContract()
          );

          if (currentContract.documents.length > 0) {
            navigate(OnboardingPath.DOCUMENT_UPLOAD);
            return;
          }

          navigate(OnboardingPath.PRODUCTS);
        }}
      >
        <View header={t("Select your Merchant Category Code")} size="small">
          <p>
            {t(
              `Selecting an accurate Merchant Category Code for your business is important, if the "Merchant Category Code" isn't accurate it might lead to an extended review process of your application and we might need to reach out to you for additional information.`
            )}
          </p>
          <ErrorBoundary>
            <b>{t("Select an industry")}</b>
            <div className={cx("mt-1", styles.shortcuts)}>
              {visibleCategories.map((item) => {
                return (
                  <div key={item} className="mb-1">
                    <Button
                      block
                      variant={
                        item === selectedCategory ? "selected" : "outlined"
                      }
                      onClick={() => {
                        const update = {
                          ...contract.locations[0],
                          mcc: undefined,
                        };
                        onSave(update);
                        setMcc(undefined);
                        setCategory(item);
                        setShowAllCategories(false);
                      }}
                    >
                      <MccCategoryLabel value={item} />
                    </Button>
                  </div>
                );
              })}
              <Button
                block
                variant="text"
                onClick={() => {
                  setShowAllCategories(!showAllCategories);
                }}
              >
                {t("Show all industries")}
              </Button>
              <HiddenInput
                value={selectedCategory}
                validators={[
                  new RequiredValidator(t("Please select an industry")),
                ]}
              />
            </div>

            <div className="full-width">
              <hr />
            </div>

            <Dynamic name={selectedCategory || "none"}>
              {selectedCategory && mccOptions?.map((item) => {
                return (
                  <div key={item.value} className="mb-1">
                    <Button
                      block
                      variant={
                        item.value === selectedMcc ? "selected" : "outlined"
                      }
                      onClick={() => {
                        const opt = mccs.find(
                          ({ code }) => code === item.value
                        );
                        if (opt) {
                          setMcc(opt.code);
                          setCategory(opt.salesCategory);
                          const update = {
                            ...contract.locations[0],
                            mcc: opt.code,
                          };
                          onSave(update);
                        }
                      }}
                    >
                      {item.text}
                    </Button>
                  </div>
                );
              })}
            </Dynamic>
          </ErrorBoundary>

          <div className="mt-4">
            <StoryButtons disabled={isPending} />
          </div>
        </View>
      </Form>
    </Wrapper>
  );
};
