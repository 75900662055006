import React, { useMemo, useRef } from "react";
import styles from "./SignatoryNationality.module.scss";
import { useTranslation } from "react-i18next";
import { Country, SelectedSignatory } from "../../data/models/ContractTypes";
import { useContract } from "../../hooks/useContract";
import { isSweden } from "../../data/models/ContractUtils";
import { Dynamic } from "../../components/Animations/Dynamic";
import { TextInput } from "../Forms/TextInput";
import { RequiredValidator } from "../Forms/validators/RequiredValidator";
import { MinLengthValidator } from "../Forms/validators/MinLengthValidator";
import { MaxLengthValidator } from "../Forms/validators/MaxLengthValidator";
import { Validations } from "../../stories/Onboarding/Owners/Owner";
import { FlagRounded } from "../../components/Countries/FlagRounded";
import { HiddenInput } from "../Forms/HiddenInput";
import { isServerError } from "../../data/API";
import { Box } from "../../components/Boxes/Box";
import { Status } from "../Forms/FormContext";

interface Props {
  associate: SelectedSignatory;
  onChange: (value: string, name: string) => void;
  error: Error | null;
}

export const applicableNationalities: Country[] = [
  Country.SWEDEN,
  Country.DENMARK,
  Country.NORWAY,
  Country.FINLAND,
];

export const SignatoryNationality: React.FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();
  const { associate, onChange } = props;
  const contract = useContract();
  const prePolulated = useRef<boolean>(
    associate.nationality === Country.SWEDEN &&
      isSweden(contract) &&
      !!associate.nationalId
    // We get SSN only in Sweden
  );

  const ssnValidationsByCountry = useMemo(
    () => Validations[associate.nationality || contract.country],
    [contract.country, associate.nationality]
  );

  // if the error is 403 - Forbidden, it means that the signatory couldn't be verified as valid
  // Finnish signatories are verified against the DoB we get from the registry
  // Denmark we validate against the address we get from the registry
  const isInvalidSignatoryError = useMemo(() => {
    if (!isServerError(props.error)) {
      return false;
    }

    if (props.error.status === 403) {
      return true;
    }

    return false;
  }, [props.error]);

  if (prePolulated.current) {
    return <SwedishNationality {...props} />;
  }

  return (
    <>
      {/* If other nationality than the country of the company we can't really verify the signatory, so for now we lock */}
      {/* <div className={styles.label}>{t("Nationality")}</div> */}
      {/* <HiddenInput
        value={associate.nationality ? [true] : undefined}
        validators={[
          new RequiredValidator(
            t("You must add a nationality for this person")
          ),
        ]}
      />

      <div className={cx(styles.nationality)}>
        {applicableNationalities.map((nationality) => {
          return (
            <button
              key={nationality}
              onClick={(ev) => {
                ev.preventDefault();
                onChange(nationality, "nationality");
              }}
              className={cx(styles.button, {
                [styles.active]: nationality === associate.nationality,
              })}
            >
              <FlagRounded country={nationality} height="50px" />
            </button>
          );
        })}
      </div> */}

      <Dynamic name={associate.nationality || "empty"}>
        {associate.nationality ? (
          <TextInput
            label={ssnValidationsByCountry.name}
            name="nationalId"
            onChange={onChange}
            value={associate.nationalId}
            hint={ssnValidationsByCountry.hint}
            placeholder={`${t("E.g.")} "${
              ssnValidationsByCountry.placeholder
            }"`}
            validators={[
              new RequiredValidator(
                t("{{field}} is required", {
                  field: ssnValidationsByCountry.name,
                })
              ),
              new MinLengthValidator(
                ssnValidationsByCountry.hint.length,
                t("At least {{length}} characters are required ({{hint}})", {
                  length: ssnValidationsByCountry.hint.length,
                  hint: ssnValidationsByCountry.hint,
                })
              ),
              new MaxLengthValidator(
                ssnValidationsByCountry.hint.length,
                t(
                  "Not more than {{length}} characters are allowed ({{hint}})",
                  {
                    length: ssnValidationsByCountry.hint.length,
                    hint: ssnValidationsByCountry.hint,
                  }
                )
              ),
            ]}
          />
        ) : null}
      </Dynamic>

      <Dynamic name={isInvalidSignatoryError || "empty"}>
        {isInvalidSignatoryError ? (
          <>
            <Box status={Status.ERROR}>
              {t(
                "We couldn't verify that this person is a valid signatory for the company."
              )}
            </Box>
            <HiddenInput
              value={isInvalidSignatoryError ? undefined : "valid"}
              validators={[new RequiredValidator(" ")]}
            />
          </>
        ) : null}
      </Dynamic>
    </>
  );
};

const SwedishNationality: React.FunctionComponent<Props> = ({ associate }) => {
  return (
    <>
      <div className={styles.label}>{Validations[Country.SWEDEN].name}</div>
      <div className={styles.swedish}>
        <FlagRounded country={associate.nationality as Country} height="20px" />
        <i>{associate.nationalId}</i>
      </div>
    </>
  );
};
