import Skeleton from "react-loading-skeleton";
import styles from "./BackofficeSidebar.module.scss";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useMemo, useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FaFilter, FaMagnifyingGlass } from "react-icons/fa6";
import { Dynamic } from "../../components/Animations/Dynamic";
import { Button } from "../../components/Buttons/Button";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverRef,
} from "../../components/Popover/Popover";
import { dataAuth } from "../../data/dataAuth";
import { dataRisk } from "../../data/dataRisk";
import { Checkboxes } from "../../modules/Forms/Checkboxes";
import { TextInput } from "../../modules/Forms/TextInput";
import { Suspense } from "../Suspense";
import { CaseItem } from "./components/CaseItem/CaseItem";
import { ClosedCaseList } from "./components/ClosedCaseList";
import { Tabs } from "./components/Tabs/Tabs";

const STORAGE_KEY = "self-onboarding-backoffice";

type Filters = {
  showAwaitingSupplement: boolean;
  showClaimedByOthers: boolean;
};

const defaultFilters: Filters = {
  showAwaitingSupplement: true,
  showClaimedByOthers: true,
};

export const BackofficeSidebar: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const [searchActive, setSearchActive] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [view, setView] = useState("open");

  const savedFilters = JSON.parse(
    localStorage.getItem(STORAGE_KEY) || "{}"
  ) as Partial<Filters>;
  const [filters, setFilters] = useState<Filters>({
    showAwaitingSupplement:
      savedFilters.showAwaitingSupplement ??
      defaultFilters.showAwaitingSupplement,
    showClaimedByOthers:
      savedFilters.showClaimedByOthers ?? defaultFilters.showClaimedByOthers,
  });

  const popoverRef = useRef<PopoverRef>(null);

  useEffect(() => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(filters));
  }, [filters]);

  const activeFilterCount = Object.values(filters).filter(Boolean).length;

  const updateFilters = (newFilters: Partial<Filters>) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...newFilters,
    }));
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.logo}>
        <img src="/images/worldline-logo.svg" alt="Worldline logo" />
      </div>

      <Tabs
        tabs={[
          {
            value: "open",
            text: t("Open cases"),
          },
          {
            value: "closed",
            text: t("Closed cases"),
          },
        ]}
        value={view}
        onChange={setView}
        className="text-09"
      />

      <hr className="mt-1 mb-1" />

      {view === "open" && (
        <>
          <div>
            <div className="flex gap-1 justify-between">
              <Popover ref={popoverRef} bordered>
                <PopoverTrigger>
                  <div className={styles.filterButtonWrapper}>
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={(e) => {
                        e.stopPropagation();
                        popoverRef.current?.toggle();
                      }}
                    >
                      {t("Filter")} <FaFilter />
                    </Button>
                    {activeFilterCount > 0 && (
                      <span className={styles.activeFilters}>
                        {activeFilterCount}
                      </span>
                    )}
                  </div>
                </PopoverTrigger>
                <PopoverContent>
                  <div className={styles.filterWrapper}>
                    <div className="flex-column gap-1">
                      <Checkboxes
                        className={styles.checkboxes}
                        values={[
                          filters.showAwaitingSupplement
                            ? "showAwaitingSupplement"
                            : "",
                          filters.showClaimedByOthers
                            ? "showClaimedByOthers"
                            : "",
                        ]}
                        options={[
                          {
                            text: "Waiting for supplements",
                            value: "showAwaitingSupplement",
                          },
                          {
                            text: "Claimed by others",
                            value: "showClaimedByOthers",
                          },
                        ]}
                        onChange={(values) => {
                          updateFilters({
                            showAwaitingSupplement: values.includes(
                              "showAwaitingSupplement"
                            ),
                            showClaimedByOthers: values.includes(
                              "showClaimedByOthers"
                            ),
                          });
                        }}
                      />
                    </div>
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={() => {
                        updateFilters(defaultFilters);
                        popoverRef.current?.close();
                      }}
                    >
                      {t("Reset filters")}
                    </Button>
                    <Button
                      size="small"
                      variant="text"
                      onClick={() => popoverRef.current?.close()}
                    >
                      {t("Close")}
                    </Button>
                  </div>
                </PopoverContent>
              </Popover>
              <Button
                size="small"
                variant="text"
                onClick={() => {
                  setSearchQuery("");
                  setSearchActive(!searchActive);
                }}
              >
                <FaMagnifyingGlass />
              </Button>
            </div>

            <Dynamic name={searchActive ? "searchActive" : "searchInactive"}>
              {searchActive && (
                <div
                  style={{
                    marginBottom: "calc(var(--spacing-4) * -1)",
                    marginTop: "var(--spacing-2)",
                  }}
                >
                  <TextInput
                    placeholder={t("Search")}
                    onChange={setSearchQuery}
                    value={searchQuery}
                    onClear={() => {
                      setSearchQuery("");
                      setSearchActive(false);
                    }}
                  />
                </div>
              )}
            </Dynamic>
          </div>

          <div className={styles.content}>
            <Suspense skeleton={<CaseListSkeleton />}>
              <CaseList
                searchQuery={searchQuery}
                showAwaitingSupplement={filters.showAwaitingSupplement}
                showClaimedByOthers={filters.showClaimedByOthers}
              />
            </Suspense>
          </div>
        </>
      )}
      {view === "closed" && (
        <div className={styles.content}>
          <Suspense skeleton={<CaseListSkeleton />}>
            <ClosedCaseList />
          </Suspense>
        </div>
      )}
    </div>
  );
};

const CaseList: React.FunctionComponent<{
  searchQuery: string;
  showAwaitingSupplement: boolean;
  showClaimedByOthers: boolean;
}> = ({ searchQuery, showAwaitingSupplement, showClaimedByOthers }) => {
  const { data } = useSuspenseQuery(dataRisk.fetchQueue());
  const { data: session } = useSuspenseQuery(dataAuth.whoami());

  const filteredData = useMemo(() => {
    return data?.filter((item) => {
      // Apply search filter, overrides filters
      if (searchQuery) {
        const searchLower = searchQuery.toLowerCase();
        return (
          item.organizationNumber?.toLowerCase().includes(searchLower) ||
          item.companyName?.toLowerCase().includes(searchLower)
        );
      }

      if (!showAwaitingSupplement) {
        if (item.answeredSupplements !== item.requestedSupplements) {
          return false;
        }
      }

      if (!showClaimedByOthers) {
        if (!item.claimedBy) {
          return true;
        }
        if (item.claimedBy !== session?.name) {
          return false;
        }
      }

      return true;
    });
  }, [
    data,
    showAwaitingSupplement,
    showClaimedByOthers,
    searchQuery,
    session?.name,
  ]);

  return (
    <ul className={styles.caseList}>
      {filteredData?.map((item) => (
        <CaseItem key={item.id} item={item} />
      ))}
    </ul>
  );
};

const CaseListSkeleton = () => {
  return (
    <div className="flex-column gap-1">
      {Array.from({ length: 10 }).map((_, index) => (
        <Skeleton height={70} key={index} />
      ))}
    </div>
  );
};
