import { ReactNode } from "react";
import { IconType } from "react-icons";
import clsx from "classnames";
import styles from "./BackofficeCardHeader.module.scss";
import { useTranslation } from "react-i18next";

interface Props {
  title: ReactNode;
  icon?: IconType;
  iconClassName?: string;
  color?: "default" | "error" | "warning" | "success";
  verified?: string;
}

export const BackofficeCardHeader: React.FC<Props> = ({
  title,
  icon: Icon,
  iconClassName,
  color = "default",
  verified,
}) => {
  const { t } = useTranslation();

  return (
    <div className={clsx(styles.container, styles[color])}>
      <h4 className={styles.title}>
        <span>{title}</span>
        <div className={styles.right}>
          {verified && (
            <span className={styles.verified}>
              {t("Verified: {{source}}", {
                source: verified,
              })}
            </span>
          )}
          {Icon && <Icon className={clsx(iconClassName)} size={14} />}
        </div>
      </h4>
    </div>
  );
};
