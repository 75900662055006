import { useMutation } from "@tanstack/react-query";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "../../../../components/Buttons/Button";
import { Spinner } from "../../../../components/Spinner/Spinner";
import { dataAuth } from "../../../../data/dataAuth";
import { Status } from "../../../../modules/Forms/FormContext";
import { BACKOFFICE_DASHBOARD_ROUTE } from "../../BackofficeDashboardPage/BackofficeDashboardPage";
import { BACKOFFICE_AUTH_CALLBACK_URL } from "../BackofficeAuthCallbackPage";

export const SalesforceAuthPrompt: React.FC = () => {
  const [params] = useSearchParams();
  const navigate = useNavigate();

  const { mutate: startLogin, isPending: isStartPending } = useMutation({
    ...dataAuth.startLogin(
      window.location.origin + BACKOFFICE_AUTH_CALLBACK_URL
    ),
    onSuccess: (data) => {
      window.location.href = data.url;
    },
  });

  const {
    mutate: finishLogin,
    error: finishError,
    isPending: isFinishPending,
  } = useMutation(
    dataAuth.startSession(window.location.origin + BACKOFFICE_AUTH_CALLBACK_URL)
  );

  useEffect(() => {
    if (params.has("code")) {
      const code = params.get("code")!;

      finishLogin(code, {
        onSuccess: () => {
          // TODO: redirect to original page
          navigate(BACKOFFICE_DASHBOARD_ROUTE, {
            replace: true,
          });
        },
      });
    }
  }, [finishLogin, navigate, params]);

  const hasCode = params.has("code");

  return (
    <div className="text-center flex-column gap-2 align-center">
      <h1>Sign in</h1>
      <p>You're not signed in, please sign in to proceed.</p>
      {!hasCode ? (
        <Button
          onClick={() => startLogin()}
          status={isStartPending ? Status.PENDING : Status.DEFAULT}
        >
          Sign in with Salesforce
        </Button>
      ) : finishError ? (
        <>
          <p>Something went wrong, please try again...</p>
          <Button
            onClick={() => startLogin()}
            status={isFinishPending ? Status.PENDING : Status.DEFAULT}
          >
            Try again
          </Button>
        </>
      ) : (
        <Spinner />
      )}
    </div>
  );
};
