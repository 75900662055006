import { ImageProps } from "../../components/Image/Image";
import { MccCategory } from "./mcc";

export type ContractId = string & { isContractId: true };
export type AssociateId = string & { isAssociateId: true };
export type TerminalId = number & { isTerminalId: true };
export type UTCDate = string & { isUTCDate: true };
export type Iso8601Date = string & { isIso8601Date: true };
export type CountryCode = string & { isCountryCode: true };
export type DocumentId = number & { isDocumentId: true };
export type ContractVersion = number & { isContractVersion: true };
export type LocationId = number & { isStoreId: true };

export const NO_COUNTRY = undefined;

export type SwedishCompanyRegistrationId = string & {
  isSwedishCompanyRegistrationId: true;
};
export type NorweiganCompanyRegistrationId = string & {
  isNorweiganCompanyRegistrationId: true;
};
export type DanishCompanyRegistrationId = string & {
  isDanishCompanyRegistrationId: true;
};
export type FinnishCompanyRegistrationId = string & {
  isFinnishCompanyRegistrationId: true;
};

export interface Contract {
  started: UTCDate;
  locked?: UTCDate; //We are in or post signing. No updates can be made from normal APIs
  contractProgress: ContractProgress;
  country: Country;
  companyDetails: CompanyDetails;
  primaryContact: PrimaryContact;
  financialKyc: FinancialKyc;
  businessRisk: BusinessRisk;
  locations: SalesLocation[];
  bankAccount?: BankAccount; //If this one exists, we have verified the bank account
  beneficialOwners: BeneficialOwner[];
  selectedSignatories: SelectedSignatory[];
  documents: ContractDocument[];
}

export type TerminalOption = {
  name: string;
  value: string;
};

export enum TerminalPreset {
  STANDARD = "STANDARD",
  TIPPING = "TIPPING",
}

export interface Terminal {
  model: TerminalType;
  count: number;
}

export interface SalesLocation {
  id: LocationId;
  mcc?: string;
  doingBusinessAs?: string;
  storePhoneNumber?: string;
  street?: string;
  city?: string;
  postalCode?: string;
  terminalPreset: TerminalPreset;
  terminals: Terminal[];
}

export interface MCC {
  code: string;
  label: string;
  // description: string;
  // tag: string;
  salesCategory: MccCategory;
  // risk: string;
  // cp: MccRiskLevel;
  // cnp: MccRiskLevel;
}

export enum MccRiskLevel {
  NONE = "NONE",
  RISK_APPROVAL = "RISK_APPROVAL",
  NOT_ALLOWED = "NOT_ALLOWED",
}

export type CompanyRegistrationId =
  | SwedishCompanyRegistrationId
  | NorweiganCompanyRegistrationId
  | DanishCompanyRegistrationId
  | FinnishCompanyRegistrationId;

export enum ContractProgress {
  COMPANY_DATA_CONFIRM = "COMPANY_DATA_CONFIRM",
  CONTACT_DETAILS = "CONTACT_DETAILS",
  LOCATION_SETUP = "LOCATION_SETUP",
  FINANCIAL_KYC = "FINANCIAL_KYC",
  BUSINESS_RISK = "BUSINESS_RISK",
  PAYOUT_ACCOUNT = "PAYOUT_ACCOUNT",
  BENEFICIAL_OWNER = "BENEFICIAL_OWNER",
  SIGNATORIES = "SIGNATORIES",
  CONFIRM_CONTRACT = "CONFIRM_CONTRACT",
  SIGNING = "SIGNING",
  REVIEW = "REVIEW",
  PROVISIONING = "PROVISIONING",
  COMPLETE = "COMPLETE",
}

export interface PriceData {
  label: string;
  displayLabel: string;
  unitType: UnitType;
  value: number;
}

export interface PricePlan {
  pricingModel: PricingModel;
  currency: typeof Currency;
  displayName: string;
  prices: PriceData[];
}

export interface CompanyDetails {
  name: string;
  organizationNumber: string;
  legalEntityType: LegalEntityType;
  street: string;
  postalCode: string;
  city: string;
  registrationDate: Iso8601Date;
}

export interface BeneficialOwner {
  id: string;
  //Får vi alltid från registret för alla länder
  name: string;
  primaryContact: boolean;
  //Får vi i sverige, kan inte uppdateras
  nationalId?: string;
  nationality?: CountryCode;
  //Får vi i Sverige och finland, vi behöver inte uppdatera den
  dateOfBirth?: Iso8601Date;
  placeOfBirth?: string;
  citizenships: CountryCode[]; //Max 3
  //Får vi i Sverige, Finland, Danmark
  countryOfResidence?: CountryCode;
  //Får vi från registret
  capitalStake?: number; // 0.00 - 100
  votingRightsStake?: number; // 0.00 - 100
}

export interface SelectedSignatory {
  id: string;
  name: string;
  primary: boolean;
  email?: string;
  nationalId?: string;
  nationality?: Country;
  position?: string;
  dateOfBirth?: Iso8601Date;
  placeOfBirth?: string;
  citizenships: CountryCode[];
  countryOfResidence?: CountryCode;
  signed?: UTCDate;
}

export interface AvailableSignatory {
  signeeId: number;
  name: string;
  positions: string;
}

export interface SigningGroup {
  id: number;
  selected: boolean;
  signees: AvailableSignatory[];
}

export interface PrimaryContact {
  name: string;
  email?: string; //If this one is set, the email has been validated
  phone?: string;
  language: Language; //Defaults to country default
}

export interface ValidationResult {
  valid: boolean;
}

export enum PricingModel {
  BLENDED = "Blended",
  MONTHLY_FEE_STEP = "MonthlyFeeStep",
  IC_PLUS_PLUS = "IC++",
}

export enum UnitType {
  CURRENCY = "Currency",
  PERCENT = "Percent",
}

export interface PriceData {
  label: string;
  displayLabel: string;
  unitType: UnitType;
  value: number;
}

export interface PricePlan {
  pricingModel: PricingModel;
  currency: typeof Currency;
  displayName: string;
  prices: PriceData[];
}

// https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
export enum Country {
  SWEDEN = "SE",
  DENMARK = "DK",
  NORWAY = "NO",
  FINLAND = "FI",
  GB = "GB",
}

export enum Language {
  ENGLISH = "en",
  SWEDISH = "sv",
  DANISH = "da",
  NORWEGIAN = "nb",
  FINNISH = "fi",
  ARABIC = "ar",
}

export const Currency: Record<Country, string> = {
  [Country.SWEDEN]: "SEK",
  [Country.DENMARK]: "DKK",
  [Country.NORWAY]: "NOK",
  [Country.FINLAND]: "EUR",
  [Country.GB]: "GBP",
};

export const OrganizationNumberLabel: Record<Country, string> = {
  [Country.SWEDEN]: "Organisationsnummer",
  [Country.DENMARK]: "CVR nummer",
  [Country.NORWAY]: "Organisasjonsnummer",
  [Country.FINLAND]: "Y-tunnus",
  [Country.GB]: "Company Registration Number",
};

export enum TerminalType {
  DX8000 = "DX8000",
  MOVE5000 = "MOVE5000",
}

export enum LegalEntityType {
  LIMITED = "LIMITED",
  SOLE_PROPRIETARY = "SOLE_PROPRIETARY",
}

export enum DocumentType {
  TAXI_LICENSE = "TAXI_LICENSE",
  POWER_OF_ATTORNEY = "POWER_OF_ATTORNEY",
  PROOF_OF_ACCREDITATION = "PROOF_OF_ACCREDITATION",
  FINANCIAL_STATEMENT = "FINANCIAL_STATEMENT",
  SUPPLEMENT = "SUPPLEMENT",
  VISA_SCREENING = "VISA_SCREENING",
  RISK_DOCUMENT = "RISK_DOCUMENT",
  SIGNED_CONTRACT = "SIGNED_CONTRACT",
  CREDITSAFE = "CREDITSAFE",
}

export interface Contact {
  position: string;
  phoneNumber: string;
  email: string;
  firstName: string;
  lastName: string;
}

export interface ContractDocument {
  documentId: DocumentId;
  confirmed?: UTCDate;
  fileAvailable: boolean;
  uploaded?: UTCDate;
  filename?: string;
  fileName?: string; // Urk, naming inconsistencies
  mimeType?: string;
  documentType: DocumentType;
  documentDescription?: string; //Den här är satt om sales har skrivit in ett custom dokument, annrs bör språkfil används
}

export const languageByCountry: { [key in Country]: Language } = {
  [Country.SWEDEN]: Language.SWEDISH,
  [Country.DENMARK]: Language.DANISH,
  [Country.NORWAY]: Language.NORWEGIAN,
  [Country.FINLAND]: Language.FINNISH,
  [Country.GB]: Language.ENGLISH,
};

export enum Validity {
  MISSING = "is-missing-information",
  PARTIAL = "has-partial-information",
  VALID = "has-required-information",
  DEFAULT = "default-information",
}

export interface Product extends ImageProps {
  name: string;
  bindingTime: number;
  startFee: number;
}

// Should probably be moved somewhere else
export const ProductItem: Record<TerminalType, Product> = {
  [TerminalType.DX8000]: {
    src: "/images/terminals/dx8000_200x448.png",
    alt: "Android terminal DX8000",
    name: "DX8000",
    ratio: 200 / 448,
    bindingTime: 24,
    startFee: 0,
  },
  [TerminalType.MOVE5000]: {
    src: "/images/terminals/move-5000_212x443.png",
    alt: "Wireless terminal Move 5000",
    name: "Move 5000",
    ratio: 212 / 443,
    bindingTime: 24,
    startFee: 0,
  },
};

export interface FinancialKyc {
  estimatedTransactionsPerYear?: number;
  estimatedAnnualTurnover?: number;
  estimatedRangeOfTransactionValueMin?: number;
  estimatedRangeOfTransactionValueMax?: number;
  estimatedTransactionsByCardPerYear?: number;
  productDescription?: string;
  businessModel?: string;
  licensedEntity?: boolean;
  licensingBodyName?: string;
}

export interface BusinessRisk {
  hasFormerAcquirer?: boolean;
  formerAcquirer?: string;
  hasPrepaid?: boolean;
  prepaidOptions?: PrepaidData;
  hasSubscriptionAndMembership?: boolean;
  subscriptionAndMembership?: SubscriptionAndMembershipData;
  hasGiftCards?: boolean;
  giftCards?: GiftCardsData;
}

export enum Acquirer {
  WORLDLINE = "Worldline",
  SWEDBANK_BABS = "Swedbank/Babs",
  ELAVON = "Elavon",
  NETS = "NETS",
  HANDELSBANKEN = "Handelsbanken",
  OTHER = "Other",
}

export enum PrepaidOptions {
  ZERO = "0",
  ONE__THREE = "1-3",
  FOUR__SEVEN = "4-7",
  EIGHT__FOURTEEN = "8-14",
  FIFTEEN_THIRTY = "15-30",
  THIRTY_ONE__FORTY_FIVE = "31-45",
  FORTY_SIX__SIXTY = "46-60",
  SIXTY_ONE__NINETY = "61-90",
  NINETY_ONE__ONE_HUNDRED_TWENTY = "91-120",
  ONE_HUNDRED_TWENTY_ONE__ONE_HUNDRED_EIGHTY = "121-180",
  ONE_HUNDRED_EIGHTY_ONE__TWO_HUNDRED_SEVENTY = "181-270",
  GREATER_THAN_TWO_HUNDRED_SEVENTY = ">270",
}

export enum MembershipOptions {
  ZERO__ONE = "ZERO__ONE",
  THREE = "THREE",
  YEAR = "YEAR",
  OTHER = "OTHER",
}

export interface SubscriptionAndMembershipData {
  // försäljningsandel, hur stor andel av företagets försäljning är medlemsskap
  salesAmount?: number;
  // in months
  [MembershipOptions.ZERO__ONE]?: number;
  [MembershipOptions.THREE]?: number;
  [MembershipOptions.YEAR]?: number;
  [MembershipOptions.OTHER]?: number;
}

export interface PrepaidData {
  // in days
  [PrepaidOptions.ZERO]?: number;
  [PrepaidOptions.ONE__THREE]?: number;
  [PrepaidOptions.FOUR__SEVEN]?: number;
  [PrepaidOptions.EIGHT__FOURTEEN]?: number;
  [PrepaidOptions.FIFTEEN_THIRTY]?: number;
  [PrepaidOptions.THIRTY_ONE__FORTY_FIVE]?: number;
  [PrepaidOptions.FORTY_SIX__SIXTY]?: number;
  [PrepaidOptions.SIXTY_ONE__NINETY]?: number;
  [PrepaidOptions.NINETY_ONE__ONE_HUNDRED_TWENTY]?: number;
  [PrepaidOptions.ONE_HUNDRED_TWENTY_ONE__ONE_HUNDRED_EIGHTY]?: number;
  [PrepaidOptions.ONE_HUNDRED_EIGHTY_ONE__TWO_HUNDRED_SEVENTY]?: number;
  [PrepaidOptions.GREATER_THAN_TWO_HUNDRED_SEVENTY]?: number;
}

export interface GiftCardsData {
  // försäljningsandel, hur stor andel av företagets försäljning är giftcards 0 - 100
  salesAmount?: number;
  // giltighetstid, hur länge är korten giltiga
  validityPeriod?: string;
}

export interface BankAccount {
  accountHolder: string;
  iban: string;
  bank: string;
}

export interface KarResponse {
  success: boolean;
}

export enum OwnedBy {
  PERSON = "PERSON",
  COMPANY = "COMPANY",
}

export enum BankType {
  PERSONAL = "personal",
  BUSINESS = "business",
}

export enum RoaringeAccountType {
  CACC, // Account used to post debits and credits when
  // no specific account has been nominated
  CARD, // Account used for card payments only
  CASH, // Account used for the payment of cash
  OTHR, // Account not otherwise specified
}

export interface BankListResponse {
  name: string;
  logoUrl: string;
}

export interface StartLoginResponse {
  url: string;
}

export interface RoaringAccount {
  iban: string;
  accountName: string;
  accountType: RoaringeAccountType;
  accountOwner: string;
  valid: boolean;
}

export interface AccountListResponse {
  completed: boolean; //False means that the login hasnt been succesful
  bank: string; //Display name of the bank
  accounts: RoaringAccount[];
}

export interface OtherSignee {
  name: string;
  signed: UTCDate;
  you: boolean;
}

export interface SigningPerson {
  signed: boolean;
  companyName: string;
  name: string;
  placeOfBirth?: string;
  citizenships: CountryCode[];
  countryOfResidence?: string;
  signees: OtherSignee[];
  primary: boolean;
  nationality: Country;
}

export interface SigningLink {
  signed: boolean;
  signUrl: string;
}
