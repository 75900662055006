import { useMutation, useQuery } from "@tanstack/react-query";
import { Box } from "../../../../../components/Boxes/Box";
import { Button } from "../../../../../components/Buttons/Button";
import { dataAuth } from "../../../../../data/dataAuth";
import { dataRisk } from "../../../../../data/dataRisk";
import { Status } from "../../../../../modules/Forms/FormContext";
import { useContractId } from "../../../hooks/useContractId";
import { useDebouncedStatus } from "../../../hooks/useDebouncedStatus";

interface Props {
  claimedBy?: string;
  disabled: boolean;
}

export const RiskClaimStatus: React.FC<Props> = ({ claimedBy, disabled }) => {
  const contractId = useContractId();

  const { data: session } = useQuery(dataAuth.whoami());
  const { status, mutate } = useMutation(dataRisk.claimCase(contractId));

  const debouncedStatus = useDebouncedStatus(status, {
    disabled,
  });

  if (claimedBy === session?.name || typeof session === "undefined") {
    return null;
  }

  if (!claimedBy) {
    return (
      <Box
        status={Status.WARNING}
        noIcon
        className="flex justify-between align-center"
      >
        <div>
          <strong>Case not claimed</strong>
          <div className="text-09">Claim it to start working on it</div>
        </div>
        <div>
          <Button onClick={() => mutate()} status={debouncedStatus}>
            Claim case
          </Button>
        </div>
      </Box>
    );
  }

  return (
    <Box
      status={Status.WARNING}
      noIcon
      className="flex justify-between align-center"
    >
      <div>
        <strong>Case claimed by {claimedBy}</strong>
        <div className="text-09">Claim it to start working on it</div>
      </div>
      <div>
        <Button onClick={() => mutate()}>Claim case</Button>
      </div>
    </Box>
  );
};
