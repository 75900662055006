import React, { useState, useEffect, useCallback } from "react";
import cx from "classnames";
import styles from "./Accounts.module.scss";
import { useTranslation } from "react-i18next";
import { Status } from "../../../../modules/Forms/FormContext";
import { dataBank } from "../../../../data/dataBank";
import { useMutation } from "@tanstack/react-query";
import { RoaringAccount } from "../../../../data/models/ContractTypes";
import { Dynamic } from "../../../../components/Animations/Dynamic";
import { Spinner } from "../../../../components/Spinner/Spinner";
import {
  SelectableList,
  SelectableListItem,
} from "../../../../modules/Forms/SelectableList";
import { GenericError } from "../../../../components/Errors/GenericError";
import { Button } from "../../../../components/Buttons/Button";

interface Props {
  setStatus: React.Dispatch<React.SetStateAction<Status>>;
  setIban: (iban: string) => void;
  iban?: string;
}

export const Accounts: React.FunctionComponent<Props> = ({
  setStatus,
  iban,
  setIban,
}) => {
  const { t } = useTranslation();
  const [accounts, setAccounts] = useState<RoaringAccount[]>();
  const [bank, setBank] = useState<string>("");

  const {
    mutate: listAccounts,
    isPending,
    isError,
    reset,
  } = useMutation({
    mutationFn: () => dataBank.loadRoaringAvailableAccounts(),
    onSuccess: (data) => {
      if (!data.completed) {
        setStatus(Status.ERROR);
        return;
      }

      setBank(data.bank);
      data.accounts.sort((a, b) => {
        if (a.valid && b.valid) {
          return a.accountName.localeCompare(b.accountName);
        }

        if (a.valid) {
          return -1;
        }

        if (b.valid) {
          return 1;
        }

        return a.accountName.localeCompare(b.accountName);
      });
      setAccounts(data.accounts);
      if (data.accounts.length === 1 && data.accounts[0].valid) {
        setIban(data.accounts[0].iban);
      }
    },
  });

  useEffect(() => {
    listAccounts();
  }, [listAccounts]);

  const onChange = useCallback((iban: string) => setIban(iban), [setIban]);

  const noSelectableAccounts =
    accounts && accounts.every((account) => !account.valid);

  return (
    <div className={cx(styles.accounts)}>
      {bank ? <h3>{bank}</h3> : null}

      <Dynamic name={accounts ? "accounts" : "empty"}>
        {!accounts && !isError ? <Spinner /> : null}

        {accounts && accounts.length === 0 ? (
          <GenericError>
            {t(
              "We couldn't find any account in this bank. Please sign in into another bank."
            )}
          </GenericError>
        ) : null}

        {accounts && accounts.length > 0 ? (
          <>
            <SelectableList
              className="small"
              compact
              onChange={onChange}
              value={iban}
            >
              {accounts.map((account) => {
                return (
                  <SelectableListItem
                    key={account.iban}
                    value={account.iban}
                    disabled={!account.valid}
                  >
                    <div
                      className={cx({
                        disabled: !account.valid,
                      })}
                    >
                      <div className={styles.name}>{account.accountName}</div>
                      <div className={cx("truncate", "thin", styles.owner)}>
                        <i>{account.accountOwner}</i>
                      </div>
                      {account.valid ? null : (
                        <div className={styles.error}>
                          {t(
                            "This account is not of valid type and therefore can not be selected"
                          )}
                        </div>
                      )}
                    </div>
                  </SelectableListItem>
                );
              })}
            </SelectableList>
            {noSelectableAccounts ? (
              <div className="pt-2">
                <GenericError>
                  {t(
                    "There are no selectable accounts in this bank. Please sign in into another bank."
                  )}
                </GenericError>
              </div>
            ) : null}
          </>
        ) : null}
      </Dynamic>

      <Dynamic name={isError ? "failed" : "loaded"}>
        {isError ? (
          <GenericError
            retry={() => {
              reset();
              listAccounts();
            }}
          >
            {t("We couldn't fetch the accounts. Try again?")}
          </GenericError>
        ) : null}
      </Dynamic>

      <div className={cx("mt-2")}>
        <Button
          block
          status={isPending ? Status.PENDING : Status.DEFAULT}
          variant="text"
          onClick={() => setStatus(Status.DEFAULT)}
        >
          {t("Change bank")}
        </Button>
      </div>
    </div>
  );
};
