import React from "react";

interface Props {}

export const NoOwners: React.FunctionComponent<Props> = () => {
  // TODO this should never happen. For now, we don't allow ABOs
  // and you are not allowed to start the onboarding if there are
  // no owners
  return <div>No owners</div>;
};
