import { API } from "./API";
import { SigningLink, SigningPerson } from "./models/ContractTypes";

export const QUERY_KEY = "status";
export const QUERY_RESULT_SUCCESS = "SUCCESS";
export const QUERY_RESULT_ABORTED = "ABORTED";

//Ingen endpoint här kräver inloggning utan bara sin unika länk
export const dataSigning = {
  //This endpoint can be polled for status on signing
  loadSigningPerson: (id: string) => API.get<SigningPerson>(`/signing/${id}`),

  loadSigningPersonKey: (id: string): string[] => {
    return ["contract", id];
  },

  fetchSigningStatus: (id: string) => ({
    queryKey: dataSigning.loadSigningPersonKey(id),
    queryFn: () => dataSigning.loadSigningPerson(id),
  }),

  updateSigningPerson: (id: string, person: SigningPerson) =>
    API.post(`/signing/${id}`, person),

  //This endpoint only allows to get the signing link after all data has been supplied
  loadSigningLink: (id: string) =>
    API.get<SigningLink>(`/signing/${id}/signlink`),

  //These require login
  loadPrimaryContactSignLink: () =>
    API.get<SigningLink>(`/merchant/signing/signurl`),

  //En poll-url för primary så man inte behöver ladda hela kontraktet?

  fakeSign: () => API.post(`/internal/mock-signing`),
};
