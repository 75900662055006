import React from "react";
import cx from "classnames";
import styles from "./Timeline.module.scss";
import { useTranslation } from "react-i18next";
import { Check } from "./Check";

interface Props {
  status: TimelineStatus;
}

export enum TimelineStatus {
  NOT_STARTED,
  ACTIVE,
  DONE,
}

export const Confirmed: React.FunctionComponent<Props> = ({ status }) => {
  const { t } = useTranslation();

  return (
    <div
      className={cx(styles.confirmed, {
        [styles.active]: status === TimelineStatus.ACTIVE,
        [styles.done]: status === TimelineStatus.DONE,
      })}
    >
      <div className={cx(styles.inner, styles.solid)}>
        <div className={styles.header}>{t("Contract confirmed")}</div>
        <Check />
      </div>
    </div>
  );
};
