import { ReactNode } from "react";
import { ValidationResponse } from "../hooks/useValidation";
import { BaseValidator } from "./BaseValidator";
import { VALIDATION_STATE } from "../FormContext";

export const MinValidatorName = "MinValidator";

export class MinValidator extends BaseValidator {
  name = MinValidatorName;
  _min: number;

  constructor(min: number, error: string | ReactNode) {
    super(error);
    this._min = min;
  }

  get min() {
    return this._min;
  }

  validate(value: number): Promise<ValidationResponse> {
    return new Promise<ValidationResponse>((resolve) => {
      if (
        typeof value === "undefined" ||
        (typeof value === "string" && value === 0) ||
        isNaN(value) ||
        value >= this._min
      ) {
        resolve({ status: VALIDATION_STATE.SUCCESS });
      } else {
        resolve({
          status: VALIDATION_STATE.FAILED,
          message: this.error,
        });
      }
    });
  }
}
