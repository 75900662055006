import {
  useContext,
  useCallback,
  useState,
  useEffect,
  useRef,
  ReactNode,
} from "react";
import { BaseValidator } from "../validators/BaseValidator";
import { FormContext, Status, VALIDATION_STATE } from "../FormContext";
import { useDebounceCallback } from "usehooks-ts";

export function statusToValidationState(status: Status) {
  if (status === Status.SUCCESS) {
    return VALIDATION_STATE.SUCCESS;
  }
  if (status === Status.PENDING) {
    return VALIDATION_STATE.PENDING;
  }
  if (status === Status.ERROR) {
    return VALIDATION_STATE.FAILED;
  }
  return VALIDATION_STATE.UNVALIDATED;
}

export interface ValidationResponse {
  status: VALIDATION_STATE;
  message?: string | ReactNode;
}

export function useValidation<T>(
  value: T | undefined = undefined,
  validators: BaseValidator[] = []
  // forceErrors: boolean
): [VALIDATION_STATE, string[] | ReactNode[], boolean] {
  const context = useContext(FormContext);
  const [validity, setValidity] = useState(VALIDATION_STATE.UNVALIDATED);
  const [errorMessages, setErrorMessages] = useState<string[] | ReactNode[]>(
    []
  );
  const cachedValue = useRef<T | undefined | {}>({});
  const cachedValidators = useRef<BaseValidator[]>([]);

  const validateCb = useCallback(
    (newValue?: T) => {
      Promise.all(validators.map((validator) => validator.validate(newValue)))
        .then((responses) => {
          const isValid = responses.every(
            (response) => response.status === VALIDATION_STATE.SUCCESS
          );

          if (isValid) {
            setValidity(VALIDATION_STATE.SUCCESS);
            setErrorMessages([]);
          } else {
            setValidity(VALIDATION_STATE.FAILED);
            setErrorMessages(
              responses
                .filter(
                  (response) => response.status === VALIDATION_STATE.FAILED
                )
                .map((response) => response.message || "Unknown error")
            );
          }
        })
        .catch((err) => {
          console.log("err", err);
          setValidity(VALIDATION_STATE.FAILED);
          setErrorMessages(["Unknown server error"]);
        });
    },
    [validators, setValidity]
  );

  const debounceValidate = useDebounceCallback(validateCb, 500);

  const validate = useCallback(
    (newValue: T | undefined) => {
      setErrorMessages([]);
      if (validators.length > 0) {
        setValidity(VALIDATION_STATE.PENDING);
        debounceValidate(newValue);
      } else {
        setValidity(VALIDATION_STATE.SUCCESS);
      }
    },
    [debounceValidate, validators]
  );

  useEffect(() => {
    if (
      cachedValue.current !== value ||
      !areValidatorsEqual(validators, cachedValidators.current)
    ) {
      validate(value);
    }

    cachedValue.current = value;
    cachedValidators.current = validators;
  }, [validate, value, validators]);

  return [
    validators.length === 0 ? VALIDATION_STATE.SUCCESS : validity,
    errorMessages,
    !!context?.forceErrors,
  ];
}

function areValidatorsEqual(
  newValidators: BaseValidator[],
  oldValidators: BaseValidator[]
) {
  if (newValidators.length !== oldValidators.length) {
    return false;
  }
  const newIds = newValidators.map((newValidator) => newValidator.getId());
  const oldIds = oldValidators.map((oldValidator) => oldValidator.getId());
  return newIds.every((id) => oldIds.includes(id));
}
