import React, { ReactNode } from "react";
import cx from "classnames";
import styles from "./Block.module.scss";

interface Props {
  className?: string;
  children: ReactNode;
}

export const Block: React.FunctionComponent<Props> = ({
  className,
  children,
}) => {
  return <div className={cx(styles.block, className)}>{children}</div>;
};
