import { useSuspenseQuery } from "@tanstack/react-query";
import { API } from "./API";
import { getPricing, Pricing, SalesforcePriceResponse } from "./dataCheckout";

const QUERY_KEY = "pricing";

export const dataPricing = {
  QUERY_KEY,
  getPricing: () => ({
    queryKey: [QUERY_KEY],
    queryFn: () => {
      return API.get<SalesforcePriceResponse>(`/merchant/pricing`);
    },
  }),
};

export const useMerchantPricing = (): Pricing => {
  const { data } = useSuspenseQuery(dataPricing.getPricing());

  return {
    currency: data?.currency || "",
    displayName: data?.displayName || "",
    prices: getPricing(data),
  };
};
