import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Route, Routes, useMatch } from "react-router-dom";
import { isDev } from "../..";
import { Spinner } from "../../components/Spinner/Spinner";
import { dataAuth } from "../../data/dataAuth";
import { Language } from "../../data/models/ContractTypes";
import { Suspense } from "../Suspense";
import { SalesforceAuthPrompt } from "./BackofficeAuthCallbackPage/components/SalesforceAuthPrompt";
import {
  BackofficeCasePage,
  BACKOFFICE_CASE_ROUTE,
} from "./BackofficeCasePage/BackofficeCasePage";
import {
  BackofficeDashboardPage,
  BACKOFFICE_DASHBOARD_ROUTE,
} from "./BackofficeDashboardPage/BackofficeDashboardPage";
import styles from "./BackofficeRoot.module.scss";
import { BackofficeSidebar } from "./BackofficeSidebar";

export const BACKOFFICE_BASE_ROUTE = "/backoffice";

// get the route without the base route
const getRelativeRoute = (route: string) => {
  return route.replace(BACKOFFICE_BASE_ROUTE, "");
};

export const BackofficeRoot: React.FunctionComponent = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(Language.ENGLISH);
  }, [i18n]);

  const { isSuccess, isPending } = useQuery({
    ...dataAuth.whoami(),
    enabled: !isDev(),
  });

  const match = useMatch(BACKOFFICE_BASE_ROUTE);

  if (isPending && !isDev()) {
    return (
      <div className="p-4">
        <Spinner size="huge" />
      </div>
    );
  }

  if (!isSuccess && !isDev()) {
    return <SalesforceAuthPrompt />;
  }

  const isRoot = !!match;

  return (
    <div className={styles.wrapper}>
      {isRoot && (
        <div className={styles.sidebar}>
          <BackofficeSidebar />
        </div>
      )}
      <div className={styles.content}>
        <Routes>
          <Route
            path={getRelativeRoute(BACKOFFICE_DASHBOARD_ROUTE)}
            element={
              <Suspense>
                <BackofficeDashboardPage />
              </Suspense>
            }
          />
          <Route
            path={getRelativeRoute(BACKOFFICE_CASE_ROUTE)}
            element={
              <Suspense>
                <BackofficeCasePage />
              </Suspense>
            }
          />
        </Routes>
      </div>
    </div>
  );
};
