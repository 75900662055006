import React, { useState, useCallback, useMemo } from "react";
import cx from "classnames";
import styles from "./Kar.module.scss";
import { useTranslation } from "react-i18next";
import {
  useContract,
  useInvalidateContract,
} from "../../../../hooks/useContract";
import { useStoryNavigate } from "../../../../hooks/useStoryNavigate";
import { useMutation } from "@tanstack/react-query";
import {
  LegalEntityType,
  Validity,
} from "../../../../data/models/ContractTypes";
import { OnboardingPath } from "../../routes";
import { Wrapper } from "../../../../components/Wrapper";
import { Form } from "../../../../modules/Forms/Form";
import { View } from "../../../../modules/View/View";
import { SavedAccount } from "../SavedAccount";
import { Columns } from "../../../../components/Columns/Columns";
import { TextInput } from "../../../../modules/Forms/TextInput";
import { RequiredValidator } from "../../../../modules/Forms/validators/RequiredValidator";
import { MinLengthValidator } from "../../../../modules/Forms/validators/MinLengthValidator";
import { Dynamic } from "../../../../components/Animations/Dynamic";
import { GenericError } from "../../../../components/Errors/GenericError";
import { StoryButtons } from "../../../StoryButtons";
import { Select } from "../../../../modules/Forms/Select";
import { dataBank } from "../../../../data/dataBank";

const MIN_LENGTH_ACCOUNT = 11;

export enum OwnedBy {
  PERSON = "PERSON",
  COMPANY = "COMPANY",
}

function getOwnedBy(legalEntityType: LegalEntityType): OwnedBy {
  if (legalEntityType === LegalEntityType.SOLE_PROPRIETARY) {
    return OwnedBy.PERSON;
  }

  return OwnedBy.COMPANY;
}

export const Kar: React.FunctionComponent = () => {
  const contract = useContract();
  const invalidate = useInvalidateContract();
  const { t } = useTranslation();
  const { navigate } = useStoryNavigate();
  const [bankAccount, setBankAccount] = useState<string>("");
  const [ownedBy, setOwnedBy] = useState<OwnedBy>(
    getOwnedBy(contract.companyDetails.legalEntityType)
  );

  const opts = useMemo(() => {
    return [
      {
        text: t("the company"),
        value: OwnedBy.COMPANY,
      },
      {
        text: t("an individual"),
        value: OwnedBy.PERSON,
      },
    ];
  }, [t]);

  const {
    mutate: onSave,
    isPending,
    isError,
    reset,
  } = useMutation({
    mutationFn: () => dataBank.verifyNorwegianKar(ownedBy, bankAccount),
    onSuccess: () => {
      invalidate();
      navigate(OnboardingPath.OWNERS);
    },
  });

  const next = useCallback(() => {
    navigate(OnboardingPath.OWNERS);
  }, [navigate]);

  return (
    <Wrapper>
      <Form
        name="bank-account"
        onSubmit={(_, form) => {
          if (!form.isValid) {
            return;
          }

          onSave();
        }}
      >
        <View
          header={t("Payout account")}
          fixedValidity={
            contract.bankAccount?.iban ? Validity.VALID : undefined
          }
        >
          <p>
            {t(
              "Enter your bank account where you wish to receive your payouts. We will validate this account through Konto- og adresseringsregisteret (KAR)"
            )}
          </p>

          <SavedAccount bankAccount={contract.bankAccount} next={next} />

          <div className="mt-6">
            <Columns className="mt-4">
              <div>
                <Select
                  onChange={(value) => {
                    console.log("value", value);
                    setOwnedBy(value);
                  }}
                  name="ownedBy"
                  label={t("The bank account is owned by")}
                  options={opts}
                  value={ownedBy}
                />
              </div>
              <div>
                <TextInput
                  name="bankAccount"
                  label={t("Account number")}
                  value={bankAccount}
                  disabled={isPending}
                  hint={t("Minimum {{chars}} characters", {
                    chars: MIN_LENGTH_ACCOUNT,
                  })}
                  validators={[
                    new RequiredValidator("Required"),
                    new MinLengthValidator(
                      MIN_LENGTH_ACCOUNT,
                      t("At least {{min}} digits are required", {
                        min: MIN_LENGTH_ACCOUNT,
                      })
                    ),
                  ]}
                  onChange={(value) => {
                    reset();
                    setBankAccount(value);
                  }}
                  inputAttributes={{
                    inputMode: "numeric",
                  }}
                />
              </div>
            </Columns>
          </div>

          <div className="pt-4" />

          <Dynamic name={isError ? "error" : ""}>
            {isError ? (
              <div className={cx("pt-2", styles.error)}>
                <GenericError />
              </div>
            ) : null}
          </Dynamic>
          <div className="mt-2">
            <StoryButtons disabled={isPending} />
          </div>
        </View>
      </Form>
    </Wrapper>
  );
};
