import React, {
  useState,
  useCallback,
  RefObject,
  useRef,
  useImperativeHandle,
  useMemo,
} from "react";
import { useForm } from "./hooks/useForm";
import { useValidation } from "./hooks/useValidation";
import { BaseValidator } from "./validators/BaseValidator";
import { Number, NumberProps } from "./Number";
import { getConstraints } from "./TextInput";

export interface NumberInputProps
  extends Omit<NumberProps, "state" | "name" | "constraints"> {
  scrollToRef?: RefObject<HTMLElement>;
  name?: string;
  validators?: BaseValidator[];
  clearable?: boolean;
}

function isDefined(value?: any) {
  return value !== undefined && value !== "";
}

export const NumberInput = React.forwardRef<HTMLInputElement, NumberInputProps>(
  (
    {
      name,
      value,
      onChange,
      onBlur,
      scrollToRef,
      validators = [],
      hint,
      label,
      clearable = true,
      disabled,
      preIcon,
      postIcon,
      className,
      type = "number",
      formatInputValues,
      placeholder,
    },
    ref
  ) => {
    const identifier = useRef<string>(window.crypto.randomUUID());
    const innerRef = useRef<HTMLInputElement>(null);
    useImperativeHandle(ref, () => innerRef.current as HTMLInputElement);
    const [showErrors, setShowErrors] = useState<boolean>(isDefined(value));

    const [validity, errorMessages, showFormErrors] = useValidation<number>(
      value,
      validators
    );

    useForm(
      identifier.current,
      validity,
      value,
      () => {},
      scrollToRef ?? innerRef
    );

    const innerBlur = useCallback(
      (value: string, name: string, ev: React.ChangeEvent<any>) => {
        if (isDefined(value)) {
          setShowErrors(true);
        }

        onBlur && onBlur(value, name, ev);
      },
      [onBlur]
    );

    const onClear = useCallback(
      (ev: React.MouseEvent<HTMLButtonElement>) => {
        ev.preventDefault();
        onChange(undefined, name || identifier.current, ev);
        innerRef.current?.focus();
        setShowErrors(false);
      },
      [onChange, name]
    );

    const error = useMemo(() => {
      if (errorMessages.length > 0 && (showErrors || showFormErrors)) {
        return errorMessages[0];
      }

      return;
    }, [errorMessages, showErrors, showFormErrors]);

    const constraints = getConstraints(validators);

    return (
      <Number
        constraints={constraints}
        state={validity}
        onBlur={innerBlur}
        onChange={onChange}
        message={error}
        value={value}
        label={label}
        hint={hint}
        name={name || identifier.current}
        ref={innerRef}
        onClear={clearable ? onClear : undefined}
        disabled={disabled}
        preIcon={preIcon}
        postIcon={postIcon}
        className={className}
        type={type}
        formatInputValues={formatInputValues}
        placeholder={placeholder}
      />
    );
  }
);
