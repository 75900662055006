import React, { ReactNode } from "react";
import cx from "classnames";
import styles from "./ButtonPane.module.scss";
import { Button, ButtonProps } from "./Button";

interface Props {
  children: ReactNode;
  className?: string;
}

export const ButtonPane: React.FunctionComponent<Props> & {
  Button: React.FunctionComponent<ButtonProps>;
} = ({ children, className }) => {
  return <div className={cx(styles.pane, className)}>{children}</div>;
};

const PaneButton: React.FunctionComponent<ButtonProps> = (props) => {
  return <Button {...props} className={cx(props.className, styles.button)} />;
};

ButtonPane.Button = PaneButton;
