import React, { useState, useCallback } from "react";
import styles from "./BusinessRisk.module.scss";
import { useTranslation } from "react-i18next";
import { Form } from "../../../modules/Forms/Form";
import { useStoryNavigate } from "../../../hooks/useStoryNavigate";
import { StoryButtons } from "../../StoryButtons";
import { OnboardingPath } from "../routes";
import { View } from "../../../modules/View/View";
import { Wrapper } from "../../../components/Wrapper";
import { useContract, useInvalidateContract } from "../../../hooks/useContract";
import {
  BusinessRisk as BusinessRiskInterface,
  Contract,
} from "../../../data/models/ContractTypes";
import { useMutation } from "@tanstack/react-query";
import { dataKyc } from "../../../data/dataKyc";
import { queryClient } from "../../..";
import { dataContract } from "../../../data/dataContract";
import { FormerAcquirer } from "./FormerAcquirer/FormerAcquirer";
import { PrePayment } from "./PrePayment/PrePayment";
import { GiftCards } from "./GiftCards/GiftCards";
import { SubscriptionAndMembership } from "./SubscriptionAndMembership/SubscriptionAndMembership";

export const BusinessRisk: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { navigate } = useStoryNavigate();
  const queryKey = dataContract.getContractKey();
  const invalidate = useInvalidateContract();
  const contract = useContract();

  const [risk, setRisk] = useState<BusinessRiskInterface>(
    contract.businessRisk
  );

  const {
    // TODO
    mutate: commit,
    isError: isCommitError, // eslint-disable-line
  } = useMutation({
    mutationFn: () => dataKyc.commitBusinessRisk(),
    onSuccess: () => {
      invalidate();
      navigate(OnboardingPath.BANK_ACCOUNT);
    },
  });

  const {
    // TODO
    isError, // eslint-disable-line
    isPending, // eslint-disable-line
    reset, // eslint-disable-line
    mutate: onSave,
  } = useMutation({
    mutationFn: async (copy: BusinessRiskInterface) =>
      dataKyc.saveBusinessRisk(copy),
    onMutate: async (copy: BusinessRiskInterface) => {
      await queryClient.cancelQueries({
        queryKey,
      });

      const previousContract = queryClient.getQueryData<Contract>(queryKey);
      if (!previousContract) {
        return;
      }

      const update: Contract = {
        ...previousContract,
        businessRisk: copy,
      };

      queryClient.setQueryData<Contract>(queryKey, update);

      return { previousContract };
    },
    onError: (err, financialKyc, context) => {
      if (!context) {
        return;
      }

      setRisk(context.previousContract.businessRisk);
      queryClient.setQueryData(queryKey, context.previousContract);
    },
  });

  const onChange = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, data: any) => {
      const update = { ...risk, ...data };
      setRisk(update);
      onSave(update);
    },
    [risk, onSave]
  );

  return (
    <Wrapper className={styles.businessRisk}>
      <Form
        onSubmit={(_, form) => {
          if (form.isInvalid) {
            return;
          }

          commit();
        }}
      >
        <View header={t("Help us understand your business")} size="small">
          <p>
            {t(
              "Due to anti money laundering regulations, we're required to know more about your business model, what you sell and what customers you serve, please answer the questions below."
            )}
          </p>

          <FormerAcquirer onChange={onChange} risk={risk} />

          <div className="mt-4" />

          <PrePayment onChange={onChange} risk={risk} />

          <div className="mt-4" />

          <GiftCards onChange={onChange} risk={risk} />

          <div className="mt-4" />

          <SubscriptionAndMembership onChange={onChange} risk={risk} />

          <div className="mt-8">
            <StoryButtons />
          </div>
        </View>
      </Form>
    </Wrapper>
  );
};
