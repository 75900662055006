import { useMutation, useSuspenseQuery } from "@tanstack/react-query";
import { FaListCheck } from "react-icons/fa6";
import Skeleton from "react-loading-skeleton";
import { dataRisk } from "../../../../data/dataRisk";
import { BackofficeCard } from "../../components/card/BackofficeCard";
import { BackofficeCardHeader } from "../../components/card/BackofficeCardHeader";
import { useContractId } from "../../hooks/useContractId";
import { useDebouncedStatus } from "../../hooks/useDebouncedStatus";
import { useTranslation } from "react-i18next";
import { Status } from "../../../../modules/Forms/FormContext";
import { Validity } from "../../../../data/models/ContractTypes";
import { ChecklistItem } from "./ChecklistItem/ChecklistItem";
import { CaseAnchor } from "../BackofficeCasePage";
import { RiskChecklist } from "../../../../data/models/RiskTypes";
import { ApproveCase } from "./ApproveCase/ApproveCase";
import { Suspense } from "../../../Suspense";
import { useRiskCase } from "../../hooks/useRiskCase";

interface Props {
  disabled: boolean;
}

export const RiskChecklistCard: React.FC<Props> = ({ disabled }) => {
  const { t } = useTranslation();

  return (
    <BackofficeCard>
      <BackofficeCardHeader
        title={t("Checklist")}
        icon={FaListCheck}
        iconClassName="text-success"
        color="success"
      />

      <Suspense
        skeleton={
          <div className="flex-column gap-2">
            {[...Array(7)].map((_, i) => (
              <Skeleton key={i} height={50} />
            ))}
          </div>
        }
      >
        <Inner disabled={disabled} />
      </Suspense>
    </BackofficeCard>
  );
};

const Inner: React.FC<Props> = ({ disabled }) => {
  const contractId = useContractId();
  const { t } = useTranslation();

  const { data: checklistData } = useSuspenseQuery(
    dataRisk.fetchChecklist(contractId)
  );

  const {
    data: {
      screeningResults,
      caseDetails: { finalComment },
    },
  } = useRiskCase();

  const {
    mutate: updateChecklist,
    status: checklistMutationStatus,
    isPending,
  } = useMutation(dataRisk.updateChecklist(contractId));

  const checklistStatus = useDebouncedStatus(checklistMutationStatus, {
    disabled,
  });

  const checklist = createDefaultChecklist(checklistData);

  // all checklist items are checked
  const canApprove =
    Object.values(checklist).every((value) => value) && !isPending && !disabled;

  const isMatchClear =
    (!screeningResults.match.inquiryMatch &&
      !screeningResults.match.inquiryMatch) ||
    checklist.match;

  const isCm1Clear =
    !screeningResults.cm1.persons.some((res) => res.pep || res.sanction) ||
    checklist.cm1;

  return (
    <>
      <div className="flex-column gap-2 mb-2">
        <ChecklistItem
          title="Creditsafe"
          description={t("Check the result of the {{screening}} screening", {
            screening: "Creditsafe",
          })}
          validity={checklist.creditsafe ? Validity.VALID : Validity.PARTIAL}
          checked={checklist.creditsafe}
          onChange={(checked) =>
            updateChecklist({ ...checklist, creditsafe: checked })
          }
          disabled={[Status.DISABLED, Status.PENDING].includes(checklistStatus)}
          tab="screen"
          anchor={CaseAnchor.CREDITSAFE}
        />
        <ChecklistItem
          title="VMSS"
          description={t("Do VMSS & attach the report to the case")}
          validity={checklist.vmss ? Validity.VALID : Validity.PARTIAL}
          checked={checklist.vmss}
          onChange={(checked) =>
            updateChecklist({ ...checklist, vmss: checked })
          }
          disabled={[Status.DISABLED, Status.PENDING].includes(checklistStatus)}
        />
        <ChecklistItem
          title="Match"
          description={t("Check the result of the {{screening}} screening", {
            screening: "Match",
          })}
          validity={isMatchClear ? Validity.VALID : Validity.MISSING}
          checked={checklist.match}
          onChange={(checked) =>
            updateChecklist({ ...checklist, match: checked })
          }
          disabled={[Status.DISABLED, Status.PENDING].includes(checklistStatus)}
          tab="screen"
          anchor={CaseAnchor.MATCH}
        />
        <ChecklistItem
          title="CM1"
          description={t("Check the result of the {{screening}} screening", {
            screening: "CM1",
          })}
          validity={isCm1Clear ? Validity.VALID : Validity.MISSING}
          checked={checklist.cm1}
          onChange={(checked) =>
            updateChecklist({ ...checklist, cm1: checked })
          }
          disabled={[Status.DISABLED, Status.PENDING].includes(checklistStatus)}
          tab="screen"
          anchor={CaseAnchor.CM1}
        />
        <ChecklistItem
          title={t("Business Purpose and Nature")}
          description={t(
            "Validate business purpose and nature and write rationale"
          )}
          validity={checklist.purpose ? Validity.VALID : Validity.PARTIAL}
          checked={checklist.purpose}
          onChange={(checked) =>
            updateChecklist({ ...checklist, purpose: checked })
          }
          disabled={[Status.DISABLED, Status.PENDING].includes(checklistStatus)}
          tab="data"
          anchor={CaseAnchor.PURPOSE}
        />
        <ChecklistItem
          title={t("Sales locations")}
          description={t("Validate sales locations")}
          validity={checklist.locations ? Validity.VALID : Validity.PARTIAL}
          checked={checklist.locations}
          onChange={(checked) =>
            updateChecklist({ ...checklist, locations: checked })
          }
          disabled={[Status.DISABLED, Status.PENDING].includes(checklistStatus)}
          tab="data"
          anchor={CaseAnchor.LOCATIONS}
        />
        <ChecklistItem
          title={t("Risk tool / CRE")}
          description={t("Complete the CRE")}
          validity={checklist.cre ? Validity.VALID : Validity.PARTIAL}
          checked={checklist.cre}
          onChange={(checked) =>
            updateChecklist({ ...checklist, cre: checked })
          }
          disabled={[Status.DISABLED, Status.PENDING].includes(checklistStatus)}
        />
      </div>
      <ApproveCase disabled={!canApprove} finalComment={finalComment} />
    </>
  );
};

// take in any record of string and boolean and return a RiskChecklist
const createDefaultChecklist = (value: Record<string, boolean>) => {
  const checklist: RiskChecklist = { ...defaultChecklist };
  for (const key in checklist) {
    if (key in value) {
      checklist[key as keyof RiskChecklist] = value[key];
    }
  }
  return checklist;
};

const defaultChecklist: RiskChecklist = {
  cre: false,
  vmss: false,
  creditsafe: false,
  match: false,
  cm1: false,
  purpose: false,
  locations: false,
};
