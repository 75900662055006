import React, {
  ChangeEvent,
  useCallback,
  useId,
  useMemo,
  useRef,
  useState,
} from "react";
import clsx from "classnames";
import { Option, Stringifiable } from "../../components/types";
import { useValidation } from "./hooks/useValidation";
import { useForm } from "./hooks/useForm";
import { getConstraints } from "./TextInput";
import styles from "./Checkboxes.module.scss";
import inputStyles from "./Input.module.scss";
import { BaseValidator } from "./validators/BaseValidator";
import { InputTag } from "./components/InputTag";
import { Checkbox } from "./Checkbox";

interface Props<T extends Stringifiable> {
  className?: string;
  values?: T[];
  hint?: string | React.ReactNode;
  label?: string | React.ReactNode;
  name?: string;
  onChange: (values: T[], name: string, value: T) => void;
  onBlur?: (values: T[] | undefined, name: string, value: T) => void;
  options: Option<T>[];
  disabled?: boolean;
  validators?: BaseValidator[];
  alt?: boolean;
}

export function Checkboxes<T extends Stringifiable>({
  className,
  name,
  label = null,
  onChange,
  onBlur,
  hint,
  options = [],
  values,
  disabled,
  validators = [],
  alt,
}: Props<T>) {
  const ref = useRef<HTMLDivElement>(null);
  const innerValidators = disabled ? [] : validators;
  const inputId = useId();
  const [validity, errorMessages, showFormErrors] = useValidation(
    values,
    innerValidators
  );
  const [showErrors, setShowErrors] = useState<boolean>(
    !!(values && values.length > 0)
  );

  useForm(inputId, validity, values, () => {}, ref);

  const constraints = getConstraints(validators);

  const identifier = useId();

  const internalChange = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      const targetValue = ev.target.value as any;
      const index = values?.indexOf(targetValue) ?? -1;
      const newValues = values ? [...values] : [];
      if (index > -1) {
        newValues.splice(index, 1);
      } else {
        newValues.push(targetValue);
      }
      return onChange(newValues, name || identifier, targetValue);
    },
    [values, onChange, name, identifier]
  );

  const error = useMemo(() => {
    if (errorMessages.length > 0 && (showErrors || showFormErrors)) {
      return errorMessages[0];
    }

    return;
  }, [errorMessages, showErrors, showFormErrors]);

  const innerBlur = useCallback(
    (values: T[] | undefined, name: string, value: T) => {
      setShowErrors(true);

      onBlur && onBlur(values, name, value);
    },
    [onBlur]
  );

  return (
    <div
      className={clsx(styles.wrapper, className, styles[validity], {
        [inputStyles.hasLabel]: !!label,
        [styles.alt]: alt,
      })}
      ref={ref}
    >
      <div className={clsx(inputStyles.name, styles.label)}>
        {label && <div className={inputStyles.inputLabel}>{label}</div>}
        <InputTag
          value={values && values.length === 0 ? "" : "defined"}
          {...constraints}
        />
      </div>
      <div className={styles.options}>
        {options.map((option, idx) => {
          const current = option?.value as any;

          const checked = !!values?.find((value) => value === current);

          const labelId = `${name || identifier}-${idx}`;

          return (
            <label
              htmlFor={labelId}
              key={current}
              className={clsx(styles.option, {
                [styles.isDisabled]: disabled || option.disabled,
              })}
            >
              <input
                id={labelId}
                name={labelId}
                type="checkbox"
                onChange={internalChange}
                value={current}
                checked={checked}
                required={constraints.isRequired}
                disabled={disabled || option.disabled}
                onBlur={() =>
                  innerBlur(values, name || identifier, option.value)
                }
              />
              <Checkbox
                checked={checked}
                className={styles.checkbox}
                isDisabled={disabled || option.disabled}
              />
              <div className={styles.optionLabel}>{option.text}</div>
            </label>
          );
        })}
      </div>

      <div className={inputStyles.messages}>
        <div className={inputStyles.hint}>{hint}</div>
        <div className={inputStyles.error}>{error}</div>
      </div>
    </div>
  );
}
