import { Option } from "../types";
import { countries } from "./countries";
import { CountryCode, Language } from "../../data/models/ContractTypes";
import { getCountryDisplayName } from "../langUtils";

let countryOptions: Option<string>[];

function initCountryOptions() {
  return Object.entries(countries).map((entry) => ({
    value: entry[0],
    text: entry[0],
  }));
}

export function getCountryOptions(
  currentLanguage: Language,
  excluded?: CountryCode[]
): Option<string>[] {
  if (!countryOptions) {
    countryOptions = initCountryOptions();
  }

  const options = countryOptions.filter(
    (option) =>
      !(excluded || []).find((excludedItem) => excludedItem === option.value)
  );

  const opts = options.map((opt) => ({
    value: opt.value,
    text: getCountryDisplayName(opt.value, currentLanguage),
    disabled: false,
  }));

  opts.unshift({
    value: "",
    text: "",
    disabled: true,
  });

  return opts;
}
