import React from "react";
import { NavigationStory } from "../../components/Story/Navigation/NavigationStory";
import { StoryFrameProps } from "../../components/storyTypes";
import { Suspense } from "../../pages/Suspense";
import { AnswerStep } from "./Answer/AnswerStep";
import { CompleteStep } from "./Complete/CompleteStep";
import { BankSupplementPath } from "./routes";

export const BANK_SUPPLEMENT_STORY_BASE_ROUTE = "/bank-supplement/:id";

export const routes: StoryFrameProps[] = [
  {
    path: BankSupplementPath.ANSWER,
    element: <AnswerStep />,
  },
  {
    path: BankSupplementPath.COMPLETE,
    element: (
      <Suspense>
        <CompleteStep />
      </Suspense>
    ),
  },
];

export const BankSupplementStory: React.FunctionComponent = () => {
  return (
    <NavigationStory
      routes={routes}
      baseRoute={BANK_SUPPLEMENT_STORY_BASE_ROUTE}
    />
  );
};
