import { useId, useState } from "react";
import { ButtonProps, MotionButton } from "./Button";
import { AnimatePresence, LayoutGroup, motion } from "framer-motion";
import styles from "./ConfirmButton.module.scss";

interface Props extends ButtonProps {
  confirmButtonText: string;
  regretButtonText: string;
  preAction?: () => boolean;
}

export const ConfirmButton: React.FC<Props> = ({
  children,
  confirmButtonText,
  regretButtonText,
  onClick,
  preAction,
  ...props
}) => {
  const identifier = useId();
  const [isActive, setIsActive] = useState(false);

  return (
    <LayoutGroup id={identifier}>
      <motion.div layoutRoot layout className={styles.confirmButton}>
        {isActive ? (
          <>
            <AnimatePresence>
              <MotionButton
                {...props}
                onClick={() => setIsActive(false)}
                color="error"
                key="regret-button"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ delay: 0.15 }}
                noLayout
              >
                {regretButtonText}
              </MotionButton>
            </AnimatePresence>
            <MotionButton
              {...props}
              type="button"
              onClick={(...event) => {
                setIsActive(false);
                onClick(...event);
              }}
              layoutId="confirm-button"
              layout
              color="default"
              key="confirm-button"
              noLayout
            >
              {confirmButtonText}
            </MotionButton>
          </>
        ) : (
          <MotionButton
            {...props}
            onClick={() => {
              // if preAction is defined and returns false, do not proceed
              if (preAction && !preAction()) return;
              setIsActive(true);
            }}
            layoutId="confirm-button"
            layout
            key="confirm-button-1"
            noLayout
          >
            {children}
          </MotionButton>
        )}
      </motion.div>
    </LayoutGroup>
  );
};
