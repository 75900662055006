import React, { ReactNode } from "react";
import styles from "./TablePagination.module.scss";
import cx from "classnames";

interface ButtonProps {
  pageNumber: number;
  activePage: number;
  label: ReactNode;
  onPageChange: (pageNumber: number) => void;
  disabled?: boolean;
}

const PaginationButton: React.FunctionComponent<ButtonProps> = ({
  pageNumber,
  activePage,
  label,
  onPageChange,
  disabled = false,
}) => (
  <li key={`pagination-key-${pageNumber}`}>
    <button
      className={cx(styles.paginationButton, {
        [styles.active]: pageNumber === activePage,
        [styles.disabled]: disabled,
      })}
      onClick={() => onPageChange(pageNumber)}
    >
      {label}
    </button>
  </li>
);

interface Props {
  activePage: number;
  totalPages: number;
  onPageChange: (pageNumber: number) => void;
  className?: string;
  alwaysShow?: boolean;
  isLoading?: boolean;
}

export const TablePagination: React.FunctionComponent<Props> = ({
  activePage,
  totalPages,
  onPageChange,
  className,
  alwaysShow = false,
  isLoading = false,
}) => {
  if (!alwaysShow && totalPages < 2) {
    return null;
  }

  const buttons: ReactNode[] = [];

  const addPaginationButton = (
    pageNumber: number,
    label: ReactNode,
    disabled = false
  ) => {
    buttons.push(
      <PaginationButton
        key={`pagination-key-${pageNumber}-${label}`}
        pageNumber={pageNumber}
        activePage={activePage}
        label={label}
        onPageChange={onPageChange}
        disabled={disabled}
      />
    );
  };

  if (totalPages <= 8) {
    for (let i = 0; i < totalPages; i++) {
      addPaginationButton(i, i + 1);
    }
  } else {
    addPaginationButton(0, "<<", activePage === 0);

    addPaginationButton(Math.max(0, activePage - 1), "<", activePage === 0);

    const startPage = Math.max(0, activePage - 2);
    const endPage = Math.min(startPage + 4, totalPages - 1);

    for (let i = startPage; i <= endPage; i++) {
      addPaginationButton(i, i + 1);
    }

    addPaginationButton(activePage + 1, ">", activePage === totalPages - 1);

    addPaginationButton(totalPages - 1, ">>", activePage === totalPages - 1);
  }

  return (
    <ul
      key={`pagination-list-${activePage}`}
      className={cx(styles.pagination, className, {
        [styles.loading]: isLoading,
      })}
    >
      {buttons}
    </ul>
  );
};
