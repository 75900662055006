import { FunctionComponent } from "react";
import { DocumentType } from "../../data/models/ContractTypes";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

interface Props {
  value: DocumentType;
}

export const DocumentTypeDescription: FunctionComponent<Props> = ({
  value,
}) => {
  const { t } = useTranslation();
  return <>{getDocumentTypeDescriptionTranslation(t)[value]}</>;
};

export const getDocumentTypeDescriptionTranslation = (
  t: TFunction
): Record<DocumentType, string> => ({
  TAXI_LICENSE: t("Taxi License"),
  POWER_OF_ATTORNEY: t("Power of Attorney"),
  PROOF_OF_ACCREDITATION: t("Licence of accreditation by relevant authority"),
  FINANCIAL_STATEMENT: t("Copy of the latest annual report"),
  SUPPLEMENT: t("Supplement"),
  VISA_SCREENING: "Visa " + t("Screening"),
  RISK_DOCUMENT: t("Risk Document"),
  SIGNED_CONTRACT: t("Signed Contract"),
  CREDITSAFE: "Creditsafe " + t("Screening"),
});
