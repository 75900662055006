import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "../../../../components/Boxes/Box";
import { Button } from "../../../../components/Buttons/Button";
import {
  getIntlDateTime,
  getLanguageName,
} from "../../../../components/dateUtils";
import { Overlay } from "../../../../components/Overlay/Overlay";
import { ContractSupplement, dataRisk } from "../../../../data/dataRisk";
import { Language } from "../../../../data/models/ContractTypes";
import { Form } from "../../../../modules/Forms/Form";
import { Status } from "../../../../modules/Forms/FormContext";
import { DataList } from "../../components/Datalist/DataList";
import { DataListItem } from "../../components/Datalist/DataListItem";
import { Tabs } from "../../components/Tabs/Tabs";
import { useContractId } from "../../hooks/useContractId";
import { useDebouncedStatus } from "../../hooks/useDebouncedStatus";
import { DocumentPreview } from "./DocumentPreview";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  supplement: ContractSupplement;
}

export const RiskSupplementOverlay: React.FC<Props> = ({
  isOpen,
  onClose,
  supplement,
}) => {
  return (
    <Overlay open={isOpen} onClose={onClose} local={false}>
      <Inner {...{ onClose, supplement }} />
    </Overlay>
  );
};

const Inner: React.FC<Pick<Props, "onClose" | "supplement">> = ({
  onClose,
  supplement,
}) => {
  const contractId = useContractId();
  const { t } = useTranslation();
  const [showTranslatedRequest, setShowTranslatedRequest] = useState(false);
  const [showTranslatedResponse, setShowTranslatedResponse] = useState(false);
  const { i18n } = useTranslation();

  const {
    completed,
    requestedBy,
    created,
    request,
    response,
    translatedRequest,
    document,
  } = supplement;

  // const {
  //   mutate: handleDelete,
  //   status: mutationStatus,
  //   isSuccess,
  // } = useMutation(dataRisk.deleteSupplement(contractId, supplement.id));

  // const debouncedStatus = useDebouncedStatus(mutationStatus);

  // const status = isSuccess ? Status.SUCCESS : debouncedStatus;

  const {
    mutate: translate,
    status: translationMutationStatus,
    data: translationData,
  } = useMutation(dataRisk.translateSupplement(contractId, supplement.id));

  const translatedResponse = translationData?.text;

  const translationStatus = useDebouncedStatus(translationMutationStatus, {
    disabled: !completed,
  });

  const currentLanguageName = getLanguageName(
    i18n.language as Language,
    i18n.language
  );

  return (
    <div>
      <h3 className="mb-2 mt-0">{t("Supplement")}</h3>

      <Form
        onSubmit={(_, form) => {
          if (form.isInvalid) {
            return;
          }

          // handleDelete(undefined, {
          //   onSuccess: onClose,
          // });
        }}
        className="flex-column gap-2"
      >
        <DataList columns type="vertical" className="mb-0">
          <DataListItem
            label={t("Created")}
            value={`${getIntlDateTime(created)} - ${requestedBy ?? ""}`}
            column
          />
          <DataListItem
            label={t("Responded")}
            value={getIntlDateTime(completed)}
            column
          />
        </DataList>
        <div>
          <div className="flex align-end text-09 justify-between mb-1">
            <h4 className="mb-0 mt-0">{t("Request")}</h4>

            <Tabs
              freeWidth
              tabs={[
                {
                  value: false,
                  text: t("Original"),
                },
                {
                  value: true,
                  text: t("Translated"),
                  disabled: !translatedRequest,
                },
              ]}
              value={showTranslatedRequest}
              onChange={(value) => setShowTranslatedRequest(value)}
            />
          </div>
          <Box status={Status.PENDING} className="text-09" noIcon>
            <p className="mb-0 mt-0">
              {showTranslatedRequest ? translatedRequest : request}
            </p>
          </Box>
        </div>
        <div>
          <div className="flex align-end text-09 justify-between mb-1">
            <h4 className="mb-0 mt-0">{t("Response")}</h4>
            <div className="flex align-center gap-2">
              <Button
                onClick={() =>
                  translate(i18n.language, {
                    onSuccess: () => setShowTranslatedResponse(true),
                  })
                }
                status={translationStatus}
                size="small"
                variant="text"
              >
                {t("Translate to {{language}}", {
                  language: currentLanguageName,
                })}
              </Button>
              <Tabs
                freeWidth
                tabs={[
                  {
                    value: false,
                    text: t("Original"),
                  },
                  {
                    value: true,
                    text: t("Translated"),
                    disabled: !translatedResponse,
                  },
                ]}
                value={showTranslatedResponse}
                onChange={(value) => setShowTranslatedResponse(value)}
              />
            </div>
          </div>
          {completed ? (
            <Box status={Status.SUCCESS} className="text-09" noIcon>
              <p className="mb-0 mt-0">
                {showTranslatedResponse ? translatedResponse : response}
              </p>
            </Box>
          ) : (
            <span className="passive">{t("Waiting for response")}</span>
          )}
        </div>

        {!!document?.created && (
          <div className="mt-2">
            <h4 className="mt-0 mb-1">{t("Attachment")}</h4>
            <div className="flex-column">
              <DocumentPreview document={document} height="700" />
            </div>
          </div>
        )}
        {/* <Button
          type="submit"
          block
          variant="primary"
          status={status}
          className="mt-1"
        >
          {t("Remove supplement")}
        </Button> */}
        <Button onClick={onClose} block variant="outlined" className="mt-1">
          {t("Close")}
        </Button>
      </Form>
    </div>
  );
};
