import { FunctionComponent, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../..";
import { Button } from "../../../components/Buttons/Button";
import { ProductItem, TerminalType } from "../../../data/models/ContractTypes";
import { QuantityInput } from "../../../modules/Forms/QuantityInput";
import styles from "./ProductCard.module.scss";
import cx from "classnames";
import { useSearchParams } from "react-router-dom";
import { TerminalDescription } from "../../../components/TerminalDescription/TerminalDescription";
import { formattedPrice } from "../../../components/FormattedPrice/FormattedPrice";
import i18n from "../../../i18n";
import { Status } from "../../../modules/Forms/FormContext";
import { LinkButton } from "../../../components/Buttons/LinkButton";

interface Props {
  terminalType: TerminalType;
  price: number | undefined;
  currency: string;
}

export const ProductCard: FunctionComponent<Props> = ({
  terminalType,
  price,
  currency,
}) => {
  const { t } = useTranslation();
  const { search, setSearch } = useContext(GlobalContext);
  const [quantity, setQuantity] = useState(1);
  const [searchParams] = useSearchParams();
  const [isPending, setIsPending] = useState(false);

  const isActive = searchParams.get("terminalType") === terminalType;

  const handleAdd = () => {
    setSearch({
      ...search,
      [terminalType]: (search[terminalType] ?? 0) + quantity,
    });
    setIsPending(true);
    setTimeout(() => {
      setIsPending(false);
    }, 500);
  };

  return (
    <div className={styles.wrapper}>
      <div
        className={cx(styles.card, {
          [styles.isActive]: isActive,
        })}
      >
        <div className={styles.imageWrapper}>
          <div className={styles.productImage}>
            <img
              {...ProductItem[terminalType]}
              alt={ProductItem[terminalType].alt}
            />
          </div>
        </div>
        <div className={styles.content}>
          <b className="text-12">{ProductItem[terminalType].name}</b>
          <span className="passive text-09">
            {<TerminalDescription terminalType={terminalType} />}
          </span>
          <div className={styles.bottomContent}>
            <b>
              {t("{{price}}/month", {
                price: price
                  ? formattedPrice(price, currency, i18n.language)
                  : "-",
              })}
            </b>
            <div className="mt-1">
              {/* TODO: This should link to product page of the terminal type for current country */}
              <LinkButton to="/" variant="text" block size="small">
                {t("Learn more")}
              </LinkButton>
            </div>
          </div>
        </div>
      </div>

      <QuantityInput
        min={1}
        max={10}
        value={quantity}
        onChange={(value) => setQuantity(value)}
        name={terminalType}
        fullWidth
        hideMessages
      />
      <Button
        onClick={handleAdd}
        status={isPending ? Status.PENDING : Status.DEFAULT}
      >
        {t("Add to cart")}
      </Button>
    </div>
  );
};
