import React from "react";
import cx from "classnames";
import styles from "./AllSigned.module.scss";
import { useTranslation } from "react-i18next";
import { Confirmed, TimelineStatus } from "./Timeline/Confirmed";
import { Signatories } from "./Timeline/Signatories";
import { Verified } from "./Timeline/Verified";
import { Shipped } from "./Timeline/Shipped";

export const AllSigned: React.FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <div className={cx(styles.allSigned)}>
      <p>
        {t(
          "Great! All signatories have signed. We will check the provided information and then ship the terminals."
        )}
      </p>

      <Confirmed status={TimelineStatus.DONE} />
      <Signatories status={TimelineStatus.DONE} />
      <Verified status={TimelineStatus.ACTIVE} />
      <Shipped status={TimelineStatus.NOT_STARTED} />
    </div>
  );
};
