import React from "react";
import styles from "./Button.module.scss";
import { AnimatePresence, motion } from "framer-motion";
import clsx from "classnames";
import { BaseButtonProps, getButtonIcon } from "./Button";
import { Link, LinkProps } from "react-router-dom";
import { LinkProps as InternalLinkProps } from "../Link/Link";
import { FaArrowUpRightFromSquare } from "react-icons/fa6";
import { Status } from "../../modules/Forms/FormContext";

type LinkButtonProps = Omit<BaseButtonProps, "throttle" | "data"> &
  InternalLinkProps &
  Pick<LinkProps, "to" | "state">;

const MotionLink = motion.create(Link);

export const LinkButton = React.forwardRef<HTMLButtonElement, LinkButtonProps>(
  (
    props,
    ref // eslint-disable-line
  ) => {
    const {
      children,
      className,
      block = false,
      variant = "primary",
      status = Status.DEFAULT,
      color = "default",
      size = "default",
      action = false,
      tabIndex = 0,
      external = false,
      ...restProps
    } = props;

    let icon = null;

    if (!action) {
      icon = getButtonIcon(status);
    }

    return (
      <MotionLink
        className={clsx(
          styles.button,
          className,
          styles[status],
          styles[variant],
          styles["color-" + color],
          styles["size-" + size],
          {
            [styles.block]: block,
            [styles.action]: action,
          }
        )}
        whileTap={{ scale: block ? 0.975 : 0.95 }}
        style={{
          transformOrigin: "center",
        }}
        transition={{ type: "spring", stiffness: 500, damping: 20 }}
        {...{ tabIndex, external: external.toString() }}
        {...{
          ...(props.external && { target: "_blank", rel: "noreferrer" }),
        }}
        {...restProps}
        layoutRoot
      >
        {children}
        <AnimatePresence initial={false} mode="wait">
          {(icon || props.external) && (
            <motion.span
              className={styles.icon}
              layout
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
            >
              {props.external ? <FaArrowUpRightFromSquare /> : icon}
            </motion.span>
          )}
        </AnimatePresence>
      </MotionLink>
    );
  }
);

export const MotionLinkButton = motion.create(LinkButton);
