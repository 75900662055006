import React, { useState, useCallback, useRef } from "react";
import cx from "classnames";
import styles from "./SigningGroups.module.scss";
import {
  MultiForm,
  MultiFormContainer,
} from "../../../modules/Forms/MultiForm";
import { RequiredValidator } from "../../../modules/Forms/validators/RequiredValidator";
import { SigningGroup as SigningGroupType } from "../../../data/models/ContractTypes";
import { dataAssociates } from "../../../data/dataAssociates";
import { useMutation, useSuspenseQueries } from "@tanstack/react-query";
import { useStoryNavigate } from "../../../hooks/useStoryNavigate";
import { OnboardingPath } from "../routes";
import { Wrapper } from "../../../components/Wrapper";
import { useTranslation } from "react-i18next";
import { NoSignatories } from "../Signatories/NoSignatories";
import { SelectableList } from "../../../modules/Forms/SelectableList";
import { StoryButtons } from "../../StoryButtons";
import { View } from "../../../modules/View/View";
import { queryClient } from "../../..";
import { SigningGroup } from "./SigningGroup";
import { dataContract } from "../../../data/dataContract";

export const SigningGroups: React.FunctionComponent = () => {
  const formContainer = useRef<MultiFormContainer>();
  const { navigate } = useStoryNavigate();
  const { t } = useTranslation();
  const queryKey = dataAssociates.listSigningCombinationsKey();
  const [{ data: signingGroups }] = useSuspenseQueries({
    queries: [dataAssociates.fetchSigningCombinations()],
  });

  const [selectedSignatoryGroup, setSelectedSignatoryGroup] = useState<
    number | undefined
  >(signingGroups.find((group) => group.selected)?.id ?? undefined);

  const {
    // TODO
    isPending: isGroupPending, // eslint-disable-line
    isError: isGroupError, // eslint-disable-line
    reset: resetGroupError, // eslint-disable-line
    mutate: saveGroup,
  } = useMutation({
    mutationFn: async (group: number) =>
      dataAssociates.selectSignatoryGroup(group),
    onMutate: async (group: number) => {
      await queryClient.cancelQueries({
        queryKey,
      });

      const previousGroups =
        queryClient.getQueryData<SigningGroupType[]>(queryKey);
      if (!previousGroups) {
        return;
      }

      const update: SigningGroupType[] = previousGroups.map((signingGroup) => {
        return {
          ...signingGroup,
          selected: signingGroup.id === group,
        };
      });

      queryClient.setQueryData<SigningGroupType[]>(queryKey, update);

      return { previousGroups };
    },
    onError: (err, groups, context) => {
      if (!context) {
        return;
      }

      setSelectedSignatoryGroup(
        context.previousGroups.find((group) => group.selected)?.id
      );
      queryClient.setQueryData(queryKey, context.previousGroups);
    },
  });

  const next = async () => {
    if (!formContainer.current || formContainer.current.isInvalid) {
      formContainer.current?.forceValidation();
      return;
    }

    await queryClient.invalidateQueries({
      queryKey: dataContract.getContractKey(),
      refetchType: "all",
    });

    navigate(OnboardingPath.SIGNATORIES);
  };

  const onChange = useCallback(
    (value: number) => {
      setSelectedSignatoryGroup(value);
      saveGroup(value);
      formContainer.current?.resetValidation();
    },
    [saveGroup]
  );

  // TODO
  // If a new group is selected we will remove all previous saved
  // data for "selectedSignatories". In this case,
  // should warn the customer.

  return (
    <Wrapper>
      <MultiForm multiFormContainer={formContainer}>
        <View header={t("Select signatories")} size="small">
          <p>
            {t(
              "Select the group of signatories that should sign the application"
            )}
          </p>

          <div className={cx(styles.groups, "mt-4")}>
            {!!signingGroups.length ? (
              <SelectableList
                verticalAlignment="center"
                onChange={onChange}
                value={selectedSignatoryGroup}
                validators={[
                  new RequiredValidator(
                    t("A group of signatories is required")
                  ),
                ]}
              >
                {signingGroups.map((signingGroup) => {
                  return (
                    <SelectableList.SelectableListItem
                      key={signingGroup.id}
                      value={signingGroup.id}
                    >
                      <SigningGroup signingGroup={signingGroup} />
                    </SelectableList.SelectableListItem>
                  );
                })}
              </SelectableList>
            ) : (
              <NoSignatories />
            )}
          </div>

          <div className="mt-6">
            <StoryButtons action={next} />
          </div>
        </View>
      </MultiForm>
    </Wrapper>
  );
};
