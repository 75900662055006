import { useTranslation } from "react-i18next";
import { PhoneNumberCountry } from "./PhoneNumberCountry";

export const PhoneLabel: React.FunctionComponent<{
  phone?: string;
  label?: string;
}> = ({ phone, label }) => {
  const { t } = useTranslation();
  const elem = <PhoneNumberCountry phoneNumber={phone} />;

  if (!elem) {
    return <>{label || t("Mobile Phone")}</>;
  }

  return (
    <div className="flex">
      {label || t("Mobile Phone")} &nbsp; {elem}
    </div>
  );
};
