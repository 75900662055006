import React, { useEffect } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { useContract } from "../../../hooks/useContract";
import { Wrapper } from "../../../components/Wrapper";
import { useStoryNavigate } from "../../../hooks/useStoryNavigate";
import { UseMutateFunction, useMutation } from "@tanstack/react-query";
import { CommitRequest, dataContract } from "../../../data/dataContract";
import { OnboardingPath } from "../routes";
import { Dynamic } from "../../../components/Animations/Dynamic";
import { GenericError } from "../../../components/Errors/GenericError";
import { BASE_ROUTE as PRIMARY_BASE_ROUTE } from "../Onboarding";
import { generatePath, useSearchParams } from "react-router-dom";
import { SIGNING_BASE_ROUTE } from "../../Signing/Signing";
import { SigningPath } from "../../Signing/routes";
import { cleanPath } from "../../../components/utils";
import { View } from "../../../modules/View/View";
import { ContractThumbnail } from "../../../components/Icons/ContractThumbnail";
import { Link } from "../../../components/Link/Link";
import styles from "./Summary.module.scss";
import { PrivacyPolicy } from "../../../modules/TermsAndConditions/PrivacyPolicy";
import { TermsAndConditions } from "../../../modules/TermsAndConditions/TermsAndConditions";
import {
  dataSigning,
  QUERY_KEY,
  QUERY_RESULT_SUCCESS,
} from "../../../data/dataSigning";
import { Confirm } from "../../../modules/TermsAndConditions/Confirm";
import { Form } from "../../../modules/Forms/Form";
import { Button } from "../../../components/Buttons/Button";
import { ButtonContent, CountryToMethod } from "../SignIn/Identification";
import { Status } from "../../../modules/Forms/FormContext";
import { StoryButtons } from "../../StoryButtons";
import { isProd } from "../../..";
import { API } from "../../../data/API";

function getSigningUrls(): CommitRequest {
  const internal = cleanPath(`${PRIMARY_BASE_ROUTE}${OnboardingPath.SUMMARY}`);
  const external = cleanPath(`${SIGNING_BASE_ROUTE}${SigningPath.SIGNING}`);

  return {
    internalRedirect: `${window.location.origin}${internal}`,
    externalSigning: `${window.location.origin}${generatePath(external, {
      linkId: "{id}",
    })}`,
  };
}

function getPdfLink() {
  return API.getUrl("/merchant/preview");
}

function getForwardSignedUrl() {
  return `${OnboardingPath.COMPLETE}?${QUERY_KEY}=${QUERY_RESULT_SUCCESS}` as OnboardingPath;
}

export const Summary: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { navigate } = useStoryNavigate();
  const contract = useContract();
  const primaryAsSignatory = contract.selectedSignatories.find(
    (signee) => signee.primary
  );

  const [searchParams] = useSearchParams();
  const signingStatus = searchParams.get(QUERY_KEY);

  useEffect(() => {
    if (signingStatus === QUERY_RESULT_SUCCESS) {
      navigate(getForwardSignedUrl());
      return;
    }
  }, [signingStatus, navigate]);

  const {
    mutate: commitContractDetails,
    isPending,
    isError,
    reset,
  } = useMutation({
    mutationFn: () => dataContract.commitContractDetails(getSigningUrls()),
    onSuccess: (response) => {
      if (response.signed) {
        navigate(getForwardSignedUrl());
        return;
      }

      if (response.shouldSign && response.signUrl) {
        window.location.href = response.signUrl;
        return;
      }

      // Todo, update routes?
      navigate(OnboardingPath.COMPLETE);
    },
  });

  const { mutate: fakeSign } = useMutation({
    mutationFn: () => dataSigning.fakeSign(),
    onSuccess: () => {
      navigate(OnboardingPath.COMPLETE);
    },
  });

  const signingMethod =
    CountryToMethod[primaryAsSignatory?.nationality ?? contract.country];

  return (
    <Wrapper>
      <Form
        onSubmit={(_, form) => {
          if (form.isInvalid) {
            return;
          }

          reset();
          commitContractDetails();
        }}
      >
        <View
          header={t(
            primaryAsSignatory ? "Review and sign" : "Confirm information"
          )}
          indicator={false}
        >
          <ContractThumbnail />

          <div className={styles.pdf}>
            <Link to={getPdfLink()} external>
              {t("Preview application here")}
            </Link>
          </div>

          <PrivacyPolicy country={contract.country} />

          <div className="mt-5">
            <TermsAndConditions country={contract.country} />
          </div>

          <hr className="mt-5" />

          <div className="mt-5">
            <Confirm />
          </div>

          <Dynamic name={isError.toString()}>
            {isError ? <GenericError /> : null}
          </Dynamic>

          <div className="mt-6">
            <Button
              block
              type="submit"
              status={isPending ? Status.DISABLED : Status.DEFAULT}
            >
              {primaryAsSignatory ? (
                <ButtonContent
                  signingMethod={signingMethod}
                  buttonText={t("Signing")}
                />
              ) : (
                <>{t("Confirm")} 👍</>
              )}
            </Button>
          </div>

          <div className={cx("mt-8")}>
            <StoryButtons action={() => {}} disableNext={true} />
          </div>

          <div className="mt-8">
            <FakeSign fakeSign={fakeSign} />
          </div>
        </View>
      </Form>
    </Wrapper>
  );
};

export const FakeSign: React.FunctionComponent<{
  fakeSign: UseMutateFunction<unknown, Error, void, unknown>;
}> = ({ fakeSign }) => {
  if (isProd()) {
    return null;
  }

  return (
    <div className={styles.fakeSign}>
      <p>
        <b>Debug</b>
      </p>
      <Button
        onClick={() => {
          fakeSign();
        }}
        block
      >
        Fake signature for all signatories
      </Button>
    </div>
  );
};
