import React, { useContext } from "react";
import { Wrapper } from "../../../components/Wrapper";
import { NavigationStoryLink } from "../../../components/Story/Navigation/NavigationStoryLink";
import { OnboardingPath } from "../routes";
import { useTranslation } from "react-i18next";
import styles from "./ProductSelection.module.scss";
import { Country, TerminalType } from "../../../data/models/ContractTypes";
import { ProductCard } from "./ProductCard";
import { FaArrowRightLong } from "react-icons/fa6";
import { GlobalContext } from "../../..";
import { useCheckoutPricing } from "../../../data/dataCheckout";
import { QueryKey } from "../../../data/Store";

const PRODUCTS = Object.values(TerminalType);

export const ProductSelection: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { search } = useContext(GlobalContext);

  const { currency, prices } = useCheckoutPricing(
    search[QueryKey.COUNTRY] as Country
  );

  const count = PRODUCTS.reduce((acc, cur) => {
    return acc + (search[cur] || 0);
  }, 0);

  return (
    <Wrapper className={styles.wrapper}>
      <h1 className="text-center">{t("Select terminals")}</h1>
      <div className={styles.productsWrapper}>
        {PRODUCTS.map((terminalType) => (
          <ProductCard
            key={terminalType}
            terminalType={terminalType}
            price={prices[terminalType]?.value}
            currency={currency}
          />
        ))}
      </div>
      <div className={styles.cartBarWrapper}>
        <div className={styles.cartBar}>
          <strong>
            {count > 1
              ? t("{{count}} item in cart", {
                  count,
                })
              : t("{{count}} items in cart", {
                  count,
                })}
          </strong>
          <div>
            <NavigationStoryLink asButton to={OnboardingPath.CHECKOUT}>
              {t("Go to cart")} <FaArrowRightLong />
            </NavigationStoryLink>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
