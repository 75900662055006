import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { progressIndex, Speed } from "./Dynamic";

interface Props {
  children: ReactNode;
  active: boolean;
  speed?: Speed;
}

interface State {
  opacity: number;
  height: string;
  trigger: boolean;
}

export interface Animate {
  from: number;
  to: number;
  name: keyof State;
  postfix?: string;
}

export const Accordion: React.FunctionComponent<Props> = ({
  active,
  children,
  speed = Speed.FAST,
}) => {
  const [state, setState] = useState<State>({
    opacity: active ? 1 : 0,
    height: active ? "auto" : "0px",
    trigger: false,
  });
  const isAnimating = React.useRef<boolean>(false);
  const isUnmounting = React.useRef<boolean>(false);
  const wrapperRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    isUnmounting.current = false;

    return () => {
      isUnmounting.current = true;
    };
  }, []);

  const animate = useCallback(
    (animProps: Animate) => {
      return new Promise<void>((resolve, reject) => {
        const { from, to, name, postfix } = animProps;

        let value: string | number = 0;
        let index = 0;

        function draw() {
          if (isUnmounting.current) {
            reject("Unmounting");
            return;
          }

          const progress = progressIndex[speed][index];
          index++;

          if (!progress && progress !== 0) {
            resolve();
            return;
          }

          value = from + (to - from) * progress;

          if (postfix) {
            value = `${value}${postfix}`;
          }

          setState((prev) => ({ ...prev, [name]: value }));
          requestAnimationFrame(draw);
        }

        requestAnimationFrame(draw);
      }).catch((err) => {
        console.log("err", err);
        setState((prev) => ({
          ...prev,
          opacity: 1,
          height: "auto",
        }));
      });
    },
    [speed]
  );

  const fadeOut = useCallback(() => {
    return animate({
      from: 1,
      to: 0,
      name: "opacity",
    });
  }, [animate]);

  const fadeIn = useCallback(() => {
    return animate({
      from: 0,
      to: 1,
      name: "opacity",
    });
  }, [animate]);

  useEffect(() => {
    if (isAnimating.current) {
      return;
    }

    const height = wrapperRef.current?.scrollHeight ?? 0;

    if (active) {
      if (state.height === "auto" && state.opacity === 1) {
        return;
      }

      if (height === 0) {
        return setState((prev) => ({ ...prev, height: "auto", opacity: 1 }));
      }

      isAnimating.current = true;

      animate({
        from: 0,
        to: wrapperRef.current?.scrollHeight ?? 0,
        name: "height",
        postfix: "px",
      })
        .then(() => {
          return fadeIn().then(() => {
            isAnimating.current = false;
            setState((prev) => ({
              ...prev,
              opacity: 1,
              height: "auto",
            }));
          });
        })
        .catch((err) => {
          console.log("err", err);
          isAnimating.current = false;
          setState((prev) => ({ ...prev, height: "auto", opacity: 1 }));
        });
    } else {
      if (state.height === "0px" && state.opacity === 0) {
        return;
      }

      if (height === 0) {
        return setState((prev) => ({ ...prev, height: "0px", opacity: 0 }));
      }

      isAnimating.current = true;

      fadeOut()
        .then(() => {
          return animate({
            from: height,
            to: 0,
            name: "height",
            postfix: "px",
          }).then(() => {
            isAnimating.current = false;
            setState((prev) => ({
              ...prev,
              trigger: !prev.trigger,
            }));
          });
        })
        .catch((err) => {
          console.log("err", err);
          isAnimating.current = false;
          setState((prev) => ({ ...prev, height: "0px", opacity: 0 }));
        });
    }
  }, [active, fadeIn, fadeOut, state, animate]);

  return (
    <div
      ref={wrapperRef}
      style={{
        ...state,
        overflow: !!state.opacity ? "inherit" : "hidden",
      }}
    >
      {children}
    </div>
  );
};
