import { useState, useEffect, useRef } from "react";

const getHeight = () => window.innerHeight || document.body.clientHeight;

export function useHeight() {
  let [height, setHeight] = useState(getHeight());
  const timer = useRef<number | null>(null);

  useEffect(() => {
    const resizeListener = () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
      timer.current = window.setTimeout(() => setHeight(getHeight()), 300);
    };
    window.addEventListener("resize", resizeListener);

    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  return height;
}
