import React from "react";
import { Country } from "../../data/models/ContractTypes";
import { CircleFlag } from "react-circle-flags";

interface Props {
  country: Country | string;
  height?: string;
  width?: string;
}

export const FlagRounded: React.FunctionComponent<Props> = (props) => {
  const { country, ...rest } = props;
  return (
    <CircleFlag
      countryCode={country.toLowerCase()}
      {...rest}
      style={{
        display: "block",
      }}
    />
  );
};
