import React, { ReactNode } from "react";
import cx from "classnames";
import styles from "./Indicator.module.scss";
import { Validity } from "../../data/models/ContractTypes";
import { MdErrorOutline, MdInfoOutline, MdTaskAlt } from "react-icons/md";
import { LiaSkullCrossbonesSolid } from "react-icons/lia";

interface Props {
  validity?: Validity;
  mini?: boolean;
  className?: string;
  icon?: React.ReactNode;
}

const iconMapping: Record<Validity, ReactNode> = {
  [Validity.DEFAULT]: <MdInfoOutline />,
  [Validity.MISSING]: <LiaSkullCrossbonesSolid />,
  [Validity.PARTIAL]: <MdErrorOutline />,
  [Validity.VALID]: <MdTaskAlt />,
};

export const Indicator: React.FunctionComponent<Props> = ({
  validity = Validity.DEFAULT,
  mini = false,
  className,
  icon,
}) => {
  return (
    <div
      className={cx(styles.indicator, className, styles[validity], {
        [styles.mini]: mini,
      })}
    >
      {icon || iconMapping[validity]}
    </div>
  );
};
