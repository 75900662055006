import {
  AssociateId,
  BeneficialOwner,
  Country,
  CountryCode,
  DocumentId,
  DocumentType,
  GiftCardsData,
  Iso8601Date,
  Language,
  LocationId,
  SubscriptionAndMembershipData,
  TerminalPreset,
  TerminalType,
  UTCDate,
} from "./ContractTypes";

export enum RiskDecision {
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}

export interface RiskCase {
  contract: RiskContract;
  caseDetails: RiskCaseDetails;
  screeningResults: ScreeningResult;
}

export interface RiskCaseDetails {
  created: UTCDate;
  claimed?: UTCDate;
  claimedBy?: string;
  closed?: UTCDate;
  closedBy?: string;
  decision?: RiskDecision;

  businessComment?: string;
  finalComment?: string;
  dueDiligence?: DueDiligenceLevel;
  delayedSettlement?: number;
  rollingReserve?: number;

  changeLog: RiskChangelog[];
}

export interface RiskContract {
  started: UTCDate;
  existingCustomer: boolean;
  country: Country;
  primaryContact: RiskPrimaryContact;
  companyDetails: RiskCompanyDetails;
  financialKyc: RiskFinancialKyc;
  businessRisk: RiskBusinessRisk;
  locations: RiskLocationResponse[];
  bank: RiskBank;
  beneficialOwners: RiskBeneficialOwner[];
  selectedSignatories: RiskSelectedSignatory[];
  documents: RiskDocument[];
  signingGroups: RiskSigningGroup[];
}

export interface RiskPrimaryContact {
  name: string;
  email: string;
  phone: string;
  language: Language;
}

export interface RiskCompanyDetails {
  name: string;
  organizationNumber: string;
  legalEntityType: string;
  street: string;
  postalCode: string;
  city: string;
  registrationDate?: UTCDate;
  industryCode?: string;
  industryText?: string;
}

export interface RiskFinancialKyc {
  estimatedTransactionsPerYear?: number;
  estimatedAnnualTurnover?: number;
  estimatedRangeOfTransactionValueMin?: number;
  estimatedRangeOfTransactionValueMax?: number;
  estimatedTransactionsByCardPerYear?: number;
  productDescription?: string;
  businessModel?: string;
  licensedEntity?: boolean;
  licensingBodyName?: string;
}

export interface RiskBusinessRisk {
  hasFormerAcquirer?: boolean;
  formerAcquirer?: string;
  hasPrepaid?: boolean;
  prepaidOptions?: Map<string, number>;
  hasSubscriptionAndMembership?: boolean;
  subscriptionAndMembership?: SubscriptionAndMembershipData;
  hasGiftCards?: boolean;
  giftCard?: GiftCardsData;
}

export interface RiskLocationResponse {
  id: LocationId;
  mcc: string;
  doingBusinessAs: string;
  street: string;
  city: string;
  postalCode: string;
  terminalPreset: TerminalPreset;
  terminals: { count: number; model: TerminalType }[];
}

export interface RiskBank {
  iban: string;
  bank: string;
  accountHolder?: string;
}

export type RiskBeneficialOwner = BeneficialOwner;

export interface RiskSelectedSignatory {
  id: string;
  name: string;
  primary: boolean;
  email?: string;
  nationalId?: string;
  nationality?: CountryCode;
  position?: string;
  dateOfBirth?: Iso8601Date;
  placeOfBirth?: string;
  citizenships?: CountryCode[];
  countryOfResidence?: CountryCode;
  signed?: UTCDate;
}

export interface RiskSigningGroupPerson {
  name: string;
  positions: string;
  primaryContact: boolean;
}

export interface RiskDocument {
  documentId: DocumentId;
  fileAvailable: boolean;
  documentDescription?: string;
  created?: UTCDate;
  documentType: DocumentType;
  fileSize?: number;
  fileName?: string;
  mimeType?: string;
}

export interface RiskSigningGroup {
  id: number;
  selected: boolean;
  signees: RiskSigningGroupPerson[];
}

export interface RiskChangelog {
  created: UTCDate;
  user: string;
  // Could be also other fields in the future
  field: "MCC";
  from: string;
  to: string;
}

export interface CM1PersonResult {
  associateId: AssociateId;
  screened: UTCDate;
  name: string;
  nationalId?: string;
  pep: boolean;
  sanction: boolean;
}

export interface CM1Result {
  companyScreened: UTCDate;
  persons: CM1PersonResult[];
}

export interface MastercardMatchResult {
  screened: UTCDate;
  inquiryMatch: boolean;
  trueMatch: boolean;
  match?: {
    reasonCode: string;
    reason: string;
    renderedHtml: string;
  };
}

export interface CSMainActivity {
  // Main activity code used in that country e.g. SIC / NACE.
  code?: string;
  // (Optionally populated) A high-level description, describing the industry sector/division in which the company operates.
  industrySector?: string;
  // Description of main activity.
  description?: string;
}

export interface CreditScore {
  commonValue: string; // A, B, C, D or E.
  creditLimit: string | number;
  creditLimitCurrency: string;
  creditScore: string; // Local credit score, as used by the supplier in that particular country
  providerDescription: string; // Local credit score description.
  pod: number; //Probability of Default (POD) is the anticipated likelihood of company default within the next 12 months in a percentage format.
  assessment: string; // An additional score assessment commentary.
}

export interface Creditsafe {
  screened: UTCDate;
  mainActivity: CSMainActivity;
  creditScore: CreditScore;
}

export interface ScreeningResult {
  cm1: CM1Result;
  match: MastercardMatchResult;
  creditsafe: Creditsafe;
}

export interface RiskChecklist {
  cre: boolean;
  vmss: boolean;
  creditsafe: boolean;
  match: boolean;
  cm1: boolean;
  purpose: boolean;
  locations: boolean;
}

export enum DueDiligenceLevel {
  NDD = "NDD",
  SDD = "SDD",
  EDD = "EDD",
}
