import React from "react";
import cx from "classnames";
import styles from "./Or.module.scss";
import { useTranslation } from "react-i18next";

interface Props {
  vertical?: boolean;
  className?: string;
}

export const Or: React.FunctionComponent<Props> = ({ className }) => {
  const { t } = useTranslation();

  return (
    <div className={cx(className, styles.or)}>
      <u />
      <i>{t("Or")}</i>
      <u />
    </div>
  );
};
