import React, { ReactNode } from "react";
import cx from "classnames";
import { Status } from "../../modules/Forms/FormContext";
import { Dynamic } from "../Animations/Dynamic";
import { GenericError } from "./GenericError";

interface Props {
  status: Status | boolean;
  children?: ReactNode;
  className?: string;
}

export const StatusError: React.FunctionComponent<Props> = ({
  status,
  children,
  className,
}) => {
  const showError = status === Status.ERROR || status === true;

  return (
    <Dynamic name={showError ? "yes" : "no"}>
      {showError ? (
        <div className={cx(className)}>
          <GenericError children={children} />
        </div>
      ) : null}
    </Dynamic>
  );
};
