import { FaPencil, FaShop } from "react-icons/fa6";
import { useRiskCase } from "../../hooks/useRiskCase";
import { useTranslation } from "react-i18next";
import { BackofficeCard } from "../../components/card/BackofficeCard";
import { BackofficeCardHeader } from "../../components/card/BackofficeCardHeader";
import { DataList } from "../../components/Datalist/DataList";
import { DataListItem } from "../../components/Datalist/DataListItem";
import { Suspense } from "../../../Suspense";
import Skeleton from "react-loading-skeleton";
import { Fragment } from "react/jsx-runtime";
import { Button } from "../../../../components/Buttons/Button";
import {
  Country,
  Language,
  SalesLocation,
} from "../../../../data/models/ContractTypes";
import { useMemo, useState } from "react";
import { RiskMccEditOverlay } from "./RiskMccEditOverlay";
import { Status } from "../../../../modules/Forms/FormContext";
import { useQuery } from "@tanstack/react-query";
import { dataSalesLocation } from "../../../../data/dataSalesLocation";

interface Props {
  disabled: boolean;
}

export const RiskLocationsCard: React.FC<Props> = ({ disabled }) => {
  const { t } = useTranslation();

  return (
    <BackofficeCard>
      <BackofficeCardHeader title={t("Locations")} icon={FaShop} />
      <Suspense skeleton={<Skeleton height={100} className="mb-2" />}>
        <Inner disabled={disabled} />
      </Suspense>
    </BackofficeCard>
  );
};

const Inner: React.FC<Props> = ({ disabled }) => {
  const {
    data: { contract },
  } = useRiskCase();

  const { locations } = contract;

  return (
    <div>
      {locations.map((location, index, acc) => (
        <Fragment key={location.id}>
          <Location
            location={location}
            country={contract.country}
            disabled={disabled}
          />
          {index !== acc.length - 1 && <hr />}
        </Fragment>
      ))}
    </div>
  );
};

interface LocationProps {
  location: SalesLocation;
  country: Country;
  disabled: boolean;
}

const Location: React.FC<LocationProps> = ({ location, country, disabled }) => {
  const { t, i18n } = useTranslation();
  const [viewEditMcc, setViewEditMcc] = useState(false);

  const { data: mccs } = useQuery(
    dataSalesLocation.fetchMCCs(i18n.language as Language)
  );

  const MccDescription = useMemo(() => {
    return mccs?.find((mcc) => mcc.code === location.mcc)?.label;
  }, [mccs, location.mcc]);

  const googleMapsLink = [
    [location.street, location.city, location.postalCode].join(" "),
    country,
  ]
    .filter(Boolean)
    .join(", ");

  return (
    <>
      <RiskMccEditOverlay
        isOpen={viewEditMcc}
        onClose={() => setViewEditMcc(false)}
        location={location}
      />
      <DataList type="horizontal" align="left" className="mb-2">
        <DataListItem
          label={t("Store name")}
          value={location.doingBusinessAs}
        />
        <DataListItem
          label={t("Address")}
          value={
            <>
              {location.street}
              <br />
              {[location.postalCode, location.city].join(", ")}
            </>
          }
        />
        <DataListItem
          label={t("MCC")}
          value={
            <div className="flex align-center">
              <span>{location.mcc}</span>
              <Button
                variant="text"
                size="mini"
                action
                onClick={() => setViewEditMcc(true)}
                status={disabled ? Status.DISABLED : Status.DEFAULT}
              >
                <FaPencil />
              </Button>
            </div>
          }
        />

        <DataListItem label={t("MCC description")} value={MccDescription} />

        <DataListItem
          label={t("Terminals")}
          value={location.terminals
            .map(({ model, count }) => ` ${count}x ${model}`)
            .join(", ")}
        />
        <DataListItem
          label={t("Terminal preset")}
          value={location.terminalPreset}
        />
      </DataList>

      <iframe
        title="Google Maps"
        width="100%"
        style={{ minHeight: "250px" }}
        src={`https://maps.google.com/maps?width=520&height=400&hl=en&q=${encodeURIComponent(
          googleMapsLink
        )}&t=&z=15&ie=UTF8&iwloc=B&output=embed`}
      />
    </>
  );
};
