import { queryClient } from "..";
import { API } from "./API";
import { SupplementId } from "./dataRisk";
import { ContractId, UTCDate } from "./models/ContractTypes";

const QUERY_KEY = "supplement";

export const dataSupplement = {
  QUERY_KEY,
  getSupplements: (id: string) => ({
    queryKey: [QUERY_KEY, { id }],
    queryFn: () => API.get<SupplementResponse>(`/supplement/${id}`),
  }),

  getBankSupplement: (id: ContractId) => ({
    queryKey: [QUERY_KEY, "bank", { id }],
    queryFn: () => API.get<BankSupplementResponse>(`/supplement/${id}/bank`),
  }),

  saveSupplement: (linkId: string, id: SupplementId) => ({
    mutationFn: async ({ answer, document }: SupplementSubmitRequest) => {
      const formData = new FormData();
      formData.append("answer", answer);
      if (document) {
        formData.append("document", document);
      }

      return await API.postFormData<void>(`/supplement/${id}`, formData);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: dataSupplement.getSupplements(linkId).queryKey,
      });
    },
  }),

  saveBankSupplement: (id: ContractId) => ({
    mutationFn: ({ bankAccount }: BankSupplementSubmitRequest) =>
      API.post<void>(`/supplement/${id}/bank`, { bankAccount }),
  }),
};

export interface SupplementRequest {
  id: SupplementId;
  answered?: UTCDate;
  request: string;
  //If present, will be in the language selected by the primary contact
  translatedRequest?: string;
  requestDocument: boolean;
}

export interface SupplementResponse {
  name: string;
  companyName: string;
  supplements: SupplementRequest[];
}

export interface BankSupplementResponse {
  name: string;
  companyName: string;
}

interface SupplementSubmitRequest {
  answer: string;
  document?: File;
}

interface BankSupplementSubmitRequest {
  bankAccount: string;
}
