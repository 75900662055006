import React, { useState, useCallback } from "react";
import cx from "classnames";
import styles from "./Iban.module.scss";
import { useTranslation } from "react-i18next";
import { Wrapper } from "../../../../components/Wrapper";
import { Form } from "../../../../modules/Forms/Form";
import { View } from "../../../../modules/View/View";
import {
  useContract,
  useInvalidateContract,
} from "../../../../hooks/useContract";
import { useStoryNavigate } from "../../../../hooks/useStoryNavigate";
import { Country, Validity } from "../../../../data/models/ContractTypes";
import { SavedAccount } from "../SavedAccount";
import { useMutation } from "@tanstack/react-query";
import { dataBank } from "../../../../data/dataBank";
import { OnboardingPath } from "../../routes";
import { TextInput } from "../../../../modules/Forms/TextInput";
import { RequiredValidator } from "../../../../modules/Forms/validators/RequiredValidator";
import { MinLengthValidator } from "../../../../modules/Forms/validators/MinLengthValidator";
import { MaxLengthValidator } from "../../../../modules/Forms/validators/MaxLengthValidator";
import { Dynamic } from "../../../../components/Animations/Dynamic";
import { GenericError } from "../../../../components/Errors/GenericError";
import { StoryButtons } from "../../../StoryButtons";
import { Box } from "../../../../components/Boxes/Box";
import { Status } from "../../../../modules/Forms/FormContext";
import {
  IbanValidator,
  validateIBAN,
} from "../../../../modules/Forms/validators/IbanValidator";
import { ExternalValueValidator } from "../../../../modules/Forms/validators/ExternalValueValidator";

const MIN_LENGTH = 16; // TODO
const MAX_LENGTH = 30; // TODO

const IbanExamples: Record<Country, string> = {
  [Country.SWEDEN]: "SE45 5000 0000 0583 9825 7466",
  [Country.DENMARK]: "DK50 0040 0440 1162 43",
  [Country.NORWAY]: "NO93 8601 1117 947",
  [Country.FINLAND]: "FI21 1234 5600 0007 85",
  [Country.GB]: "GB29 NWBK 6016 1331 9268 19",
};

export const Iban: React.FunctionComponent = () => {
  const contract = useContract();
  const invalidate = useInvalidateContract();
  const { t } = useTranslation();
  const { navigate } = useStoryNavigate();
  const [iban, setIban] = useState<string>("");
  const [hasShowedWarning, setHasShowedWarning] = useState<boolean>(false);
  const [status, setStatus] = useState<Status>(Status.DEFAULT);

  const {
    mutate: onSave,
    isPending,
    isError,
    reset,
  } = useMutation({
    mutationFn: () => {
      return dataBank.saveIban(iban.replaceAll(" ", ""));
    },
    onMutate: () => {
      setHasShowedWarning(false);
    },
    onSuccess: (data) => {
      if (!data.valid) {
        setStatus(Status.ERROR);
        return;
      }

      invalidate();
      navigate(OnboardingPath.OWNERS);
    },
  });

  const next = useCallback(() => {
    navigate(OnboardingPath.OWNERS);
  }, [navigate]);

  return (
    <Wrapper>
      <Form
        name="bank-account"
        onSubmit={(_, form) => {
          setStatus(Status.DEFAULT);

          if (!form.isValid) {
            return;
          }

          if (!hasShowedWarning) {
            setHasShowedWarning(true);
            return;
          }

          setHasShowedWarning(false);
          onSave();
        }}
      >
        <View
          header={t("Payout account")}
          size="small"
          fixedValidity={
            contract.bankAccount?.iban ? Validity.VALID : undefined
          }
        >
          <p>
            {t(
              "Enter the IBAN of your bank account where you wish to receive your payouts, the bank account must be owned by the company."
            )}
          </p>

          <SavedAccount bankAccount={contract.bankAccount} next={next} />

          <div className="mt-6">
            <b>{t("Example")}:</b> {IbanExamples[contract.country]}{" "}
            <TextInput
              className={cx("mt-4")}
              name="iban"
              label="IBAN"
              value={iban}
              disabled={isPending}
              validators={[
                new RequiredValidator(t("Required")),
                new MinLengthValidator(
                  MIN_LENGTH,
                  t("At least {{min}} digits are required", {
                    min: MIN_LENGTH,
                  })
                ),
                new MaxLengthValidator(
                  MAX_LENGTH,
                  t("Not more than {{max}} digits are allowed", {
                    max: MAX_LENGTH,
                  })
                ),
                new IbanValidator(t("IBAN seems incorrectly formatted")),
                new ExternalValueValidator(
                  t("IBAN seems incorrectly formatted"),
                  IbanExamples[contract.country] === iban,
                  false
                ),
              ]}
              onChange={(value) => {
                setStatus(Status.DEFAULT);
                reset();
                setIban(value);
                setHasShowedWarning(false);
              }}
            />
            <Dynamic name={hasShowedWarning ? "warning" : ""}>
              {hasShowedWarning ? (
                <div className={styles.warning}>
                  {validateIBAN(iban) ? (
                    <Box status={Status.SUCCESS} className="small">
                      {t(
                        "IBANs are sometimes hard to verify. This, however, looks like a correct one. Just to be safe, you might want to check the IBAN again, otherwise, just proceed."
                      )}
                    </Box>
                  ) : (
                    <Box status={Status.WARNING} className="small">
                      {t(
                        "IBANs are sometimes hard to verify. In this case, we are unable to verify the entered IBAN. Please check the IBAN again. If it looks good, you may proceed."
                      )}
                    </Box>
                  )}
                </div>
              ) : null}
            </Dynamic>
            <Dynamic name={status}>
              {status === Status.ERROR ? (
                <div className={cx(styles.error, "pt-2")}>
                  <GenericError>
                    {t(
                      "We did run into an error. It seems like IBAN is indeed incorrect. Please update and try again."
                    )}
                  </GenericError>
                </div>
              ) : null}
            </Dynamic>
            <Dynamic name={isError ? "error" : ""}>
              {isError ? (
                <div className={cx(styles.error, "pt-2")}>
                  <GenericError />
                </div>
              ) : null}
            </Dynamic>
            <div className="mt-6">
              <StoryButtons disabled={isPending} />
            </div>
          </div>
        </View>
      </Form>
    </Wrapper>
  );
};
