import React, {
  useState,
  useEffect,
  ReactNode,
  useContext,
  useRef,
} from "react";
import cx from "classnames";
import styles from "./AssociateAccordion.module.scss";
import { MdFace } from "react-icons/md";
import {
  BeneficialOwner,
  SelectedSignatory,
  Validity,
} from "../../data/models/ContractTypes";
import { Accordion } from "../../components/Animations/Accordion";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/Buttons/Button";
import { FormContext } from "../Forms/FormContext";
import { onNextFrame } from "../../components/utils";
import { FormContainer, FormEvent } from "../Forms/Form";
import { Indicator } from "../../components/Beacon/Indicator";
import { FaChevronUp } from "react-icons/fa6";

interface Props {
  children: ReactNode;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  person: BeneficialOwner | SelectedSignatory;
}

const TIMER = 300;

export const AssociateAccordion: React.FunctionComponent<Props> = ({
  person,
  open,
  children,
  setOpen,
}) => {
  const { t } = useTranslation();
  const form = useContext(FormContext);
  const timer = useRef<number>();
  const [validity, setValidity] = useState<Validity>(
    form?.formContainer.isValid ? Validity.VALID : Validity.PARTIAL
  );

  useEffect(() => {
    if (!form?.formContainer) {
      return;
    }

    onNextFrame(() => {
      setValidity(
        form.formContainer.isValid ? Validity.VALID : Validity.PARTIAL
      );
    }, 100);
  }, [form]);

  useEffect(() => {
    const onUpdate = (event: FormEvent, formContainer: FormContainer) => {
      window.clearTimeout(timer.current);
      timer.current = window.setTimeout(() => {
        setValidity(formContainer.isValid ? Validity.VALID : Validity.PARTIAL);
      }, TIMER);
    };

    form?.formContainer.addListener(onUpdate);
    return () => {
      form?.formContainer.removeListener(onUpdate);
    };
  }, [form]);

  return (
    <div className={styles.associate}>
      <div className={cx(styles.header)}>
        <div className={styles.icon}>
          <div className={cx(styles.indicator)}>
            <Indicator validity={validity} />
          </div>
          <MdFace />
        </div>
        <div className={cx(styles.name, "truncate")}>{person.name}</div>
        <Button
          className={cx(styles.open)}
          variant="outlined"
          onClick={() => {
            setOpen((prev) => !prev);
          }}
        >
          {open ? <FaChevronUp /> : t("Edit")}
        </Button>
      </div>

      <Accordion active={open}>
        {children}{" "}
        <Button
          className="mt-3"
          variant="outlined"
          block
          onClick={() => {
            setOpen((prev) => !prev);
          }}
        >
          {t("Close")}
        </Button>
      </Accordion>
    </div>
  );
};
