import { useSearchParams } from "react-router-dom";
import { CaseAnchor } from "../BackofficeCasePage/BackofficeCasePage";

export type CaseTab = "data" | "screen" | "rationale" | "log";

export const useRiskCaseTab = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tab: CaseTab = (searchParams.get("type") as CaseTab) || "data";

  const setTab = (value: CaseTab, anchor?: CaseAnchor) => {
    setSearchParams({ type: value });

    if (typeof anchor === "undefined") {
      return;
    }
    setTimeout(() => {
      const element = document.getElementById(anchor);
      if (element) {
        window.scrollTo({
          top: element.offsetTop,
          behavior: "smooth",
        });
      }
    }, 100);
  };

  return [tab, setTab] as const;
};
