import { useSuspenseQuery } from "@tanstack/react-query";
import { FaBullhorn } from "react-icons/fa6";
import Skeleton from "react-loading-skeleton";
import { dataRisk } from "../../../../data/dataRisk";
import { Suspense } from "../../../Suspense";
import { BackofficeCard } from "../../components/card/BackofficeCard";
import { BackofficeCardHeader } from "../../components/card/BackofficeCardHeader";
import { useContractId } from "../../hooks/useContractId";
import { Status } from "../../../../modules/Forms/FormContext";
import { Button } from "../../../../components/Buttons/Button";
import { useState } from "react";
import { Language } from "../../../../data/models/ContractTypes";
import { useTranslation } from "react-i18next";
import { SupplementRow } from "./SupplementRow";
import { RiskRequestSupplementOverlay } from "./RiskRequestSupplementOverlay";

interface Props {
  contractLanguage: Language | undefined;
  disabled?: boolean;
}

export const RiskSupplementsCard: React.FC<Props> = ({
  contractLanguage,
  disabled,
}) => {
  const [isRequesting, setIsRequesting] = useState(false);
  const { t } = useTranslation();

  return (
    <BackofficeCard>
      <RiskRequestSupplementOverlay
        isOpen={isRequesting}
        onClose={() => setIsRequesting(false)}
        contractLanguage={contractLanguage}
      />

      <BackofficeCardHeader
        title={t("Supplements")}
        icon={FaBullhorn}
        iconClassName="text-warning"
        color="warning"
      />

      <Suspense skeleton={<Skeleton height={100} />}>
        <Inner />
      </Suspense>

      <Button
        size="small"
        variant="outlined"
        block
        color="warning"
        onClick={() => setIsRequesting(true)}
        status={disabled ? Status.DISABLED : undefined}
      >
        {t("Request a supplement")}
      </Button>
    </BackofficeCard>
  );
};

const Inner = () => {
  const contractId = useContractId();
  const { t } = useTranslation();

  const { data: supplements } = useSuspenseQuery(
    dataRisk.fetchAllSupplements(contractId)
  );

  return (
    <>
      {supplements.length === 0 ? (
        <p className="passive">
          {t(
            "Reach out to the merchant's primary contact to collect additional information, such as documentation."
          )}
        </p>
      ) : (
        <div className="flex-column gap-1 mb-2">
          {supplements.map((supplement) => (
            <SupplementRow supplement={supplement} key={supplement.id} />
          ))}
        </div>
      )}
    </>
  );
};
