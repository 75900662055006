import React, { useId, useMemo } from "react";
import styles from "./MccSelect.module.scss";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { dataRisk } from "../../../../../data/dataRisk";
import { TextInput } from "../../../../../modules/Forms/TextInput";
import { MCC } from "../../../../../data/models/ContractTypes";
import { Dynamic } from "../../../../../components/Animations/Dynamic";
import { Button } from "../../../../../components/Buttons/Button";
import { Status } from "../../../../../modules/Forms/FormContext";
import { RequiredValidator } from "../../../../../modules/Forms/validators/RequiredValidator";
import { ExternalValueValidator } from "../../../../../modules/Forms/validators/ExternalValueValidator";
import { Box } from "../../../../../components/Boxes/Box";

interface Props {
  onChange: (value: string, name: string) => void;
  value?: string;
  name: string;
  disabled: boolean;
}

export const MccSelect: React.FunctionComponent<Props> = ({
  onChange,
  value,
  name,
  disabled,
}) => {
  const { t } = useTranslation();

  const identifier = useId();

  const { data, isError, refetch } = useQuery(dataRisk.fetchAllMccs());

  const selected = useMemo(
    () => (value ? data?.find((mcc) => mcc.code === value) : undefined),
    [data, value]
  );

  return (
    <>
      <div className={styles.mccInput}>
        <TextInput
          onChange={(newValue, fieldName) => {
            const mccValue = newValue.split(" ")[0];
            onChange(mccValue, fieldName);
          }}
          label={t("Merchant category code")}
          value={value}
          name={name}
          list={identifier}
          disabled={disabled}
          validators={[
            new RequiredValidator("MCC is required"),
            new ExternalValueValidator(
              "MCC code is not valid",
              value,
              selected?.code
            ),
          ]}
          message={
            <div className={styles.description}>
              <MccInfo info={selected} />
            </div>
          }
        />
        <datalist id={identifier}>
          {data?.map((mcc) => (
            <option value={mcc.code + " - " + mcc.label} key={mcc.code}>
              {mcc.label}
            </option>
          ))}
        </datalist>
      </div>
      <Dynamic name={isError ? "error" : "none"}>
        {isError && (
          <Box status={Status.ERROR} className="mt-2" noIcon>
            <div className="flex align-center">
              {t("Failed loading MCCs")}
              <Button
                size="mini"
                color="error"
                onClick={() => refetch()}
                variant="outlined"
              >
                {t("Try again")}
              </Button>
            </div>
          </Box>
        )}
      </Dynamic>
    </>
  );
};

const MccInfo = ({ info }: { info?: MCC }) => {
  if (!info) {
    return null;
  }

  const { label } = info;

  return <div>{label}</div>;
};
