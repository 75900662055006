import React from "react";
import styles from "./FlagAndCountry.module.scss";
import { useTranslation } from "react-i18next";
import { getCountryDisplayName } from "../../components/langUtils";
import { Language } from "../../data/models/ContractTypes";
import { Flag } from "../../components/Countries/Flag";

interface Props {
  countryCode?: string;
  height?: number | string;
}

export const FlagAndCountry: React.FunctionComponent<Props> = ({
  countryCode,
  height = 20,
}) => {
  const { i18n } = useTranslation();

  if (!countryCode) {
    return <>&#8211;</>;
  }

  const name = getCountryDisplayName(countryCode, i18n.language as Language);

  return (
    <span className={styles.flagAndCountry}>
      <Flag country={countryCode} height={height} />
      <span className={styles.name}>{name}</span>
    </span>
  );
};
