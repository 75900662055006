import { Country, Language } from "../data/models/ContractTypes";

const displayNamesCache: Record<string, Intl.DisplayNames> = {};
const languageNamesCache: Record<string, Intl.DisplayNames> = {};

export const DefaultCountryByLanguage: Record<string, Country> = {
  [Language.SWEDISH]: Country.SWEDEN,
  [Language.DANISH]: Country.DENMARK,
  [Language.ENGLISH]: Country.GB,
  [Language.FINNISH]: Country.FINLAND,
  [Language.NORWEGIAN]: Country.NORWAY,
};

export const getDefaultCountryByLanguage = (
  language: Language | string
): Country => {
  return DefaultCountryByLanguage[language as Language] || Country.SWEDEN;
};

export const getCountryDisplayName = (
  country: string | Country,
  currentLanguage: Language
) => {
  if (!displayNamesCache[currentLanguage]) {
    displayNamesCache[currentLanguage] = new Intl.DisplayNames(
      currentLanguage,
      {
        type: "region",
      }
    );
  }

  return displayNamesCache[currentLanguage].of(country) || country;
};

export const getLanguageDisplayName = (
  language: Language,
  currentLanguage: Language
) => {
  if (!languageNamesCache[currentLanguage]) {
    languageNamesCache[currentLanguage] = new Intl.DisplayNames(
      currentLanguage,
      {
        type: "language",
      }
    );
  }

  return languageNamesCache[currentLanguage].of(language) || language;
};
