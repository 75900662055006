import React, { ReactNode } from "react";
import cx from "classnames";
import styles from "./InfoList.module.scss";

interface Props {
  children: ReactNode;
  className?: string;
  split?: boolean;
}

export const InfoList: React.FunctionComponent<Props> & {
  Dt: React.FunctionComponent<Props>;
  Dd: React.FunctionComponent<Props>;
} = ({ children, className, split }) => {
  return (
    <dl
      className={cx(styles.list, className, {
        [styles.split]: split,
      })}
    >
      {children}
    </dl>
  );
};

const Dt: React.FunctionComponent<Props> = ({ children, className }) => {
  return <dt className={cx(className, styles.dt)}>{children}</dt>;
};

const Dd: React.FunctionComponent<Props> = ({ children, className }) => {
  return <dd className={cx(className, styles.dd)}>{children}</dd>;
};

InfoList.Dt = Dt;
InfoList.Dd = Dd;
