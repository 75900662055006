import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../../components/Buttons/Button";
import { Overlay } from "../../../../../components/Overlay/Overlay";
import { dataRisk } from "../../../../../data/dataRisk";
import { DueDiligenceLevel } from "../../../../../data/models/RiskTypes";
import { Form } from "../../../../../modules/Forms/Form";
import { TextArea } from "../../../../../modules/Forms/TextArea";
import { RequiredValidator } from "../../../../../modules/Forms/validators/RequiredValidator";
import { useContractId } from "../../../hooks/useContractId";
import { useDebouncedStatus } from "../../../hooks/useDebouncedStatus";
import { Option } from "../../../../../components/types";
import { Select } from "../../../../../modules/Forms/Select";
import { Status } from "../../../../../modules/Forms/FormContext";
import { NumberInput } from "../../../../../modules/Forms/NumberInput";
import styles from "./ApproveCase.module.scss";
import { MaxValidator } from "../../../../../modules/Forms/validators/MaxValidator";
import { MinValidator } from "../../../../../modules/Forms/validators/MinValidator";

interface Props {
  disabled: boolean;
  finalComment: string | undefined;
}

export const ApproveCase: React.FC<Props> = ({ disabled, finalComment }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <div>
      <ApproveOverlay
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        finalComment={finalComment}
      />
      <Button
        onClick={() => setIsOpen(true)}
        block
        size="small"
        status={disabled ? Status.DISABLED : Status.DEFAULT}
      >
        {t("Approve application")}
      </Button>
    </div>
  );
};

interface OverlayProps {
  isOpen: boolean;
  onClose: () => void;
  finalComment: string | undefined;
}

const ApproveOverlay: React.FC<OverlayProps> = ({
  isOpen,
  onClose,
  finalComment,
}) => {
  return (
    <Overlay open={isOpen} onClose={onClose} local={false}>
      <Inner {...{ onClose, finalComment }} />
    </Overlay>
  );
};

const Inner: React.FC<Pick<OverlayProps, "onClose" | "finalComment">> = ({
  onClose,
  finalComment,
}) => {
  const { t } = useTranslation();
  const contractId = useContractId();
  const [comment, setComment] = useState<string>(finalComment || "");
  const [dueDiligenceLevel, setDueDiligenceLevel] = useState<
    DueDiligenceLevel | undefined
  >();
  const [delayedSettlement, setDelayedSettlement] = useState<
    number | undefined
  >(0);
  const [rollingReserve, setRollingReserve] = useState<number | undefined>(0);

  const { mutate: handleApprove, status: mutationStatus } = useMutation(
    dataRisk.approveCase(contractId)
  );

  const ddOptions: Option<DueDiligenceLevel>[] = [
    { value: DueDiligenceLevel.NDD, text: t("Normal Due Diligence") },
    { value: DueDiligenceLevel.SDD, text: t("Simplified Due Diligence") },
    { value: DueDiligenceLevel.EDD, text: t("Extended Due Diligence") },
  ];

  const debouncedStatus = useDebouncedStatus(mutationStatus);

  return (
    <div>
      <h3 className="mb-2 mt-0">{t("Approve application")}</h3>

      <Form
        onSubmit={(_, form) => {
          if (form.isInvalid) {
            return;
          }

          if (!dueDiligenceLevel) {
            return;
          }

          if (
            typeof delayedSettlement === "undefined" ||
            typeof rollingReserve === "undefined"
          ) {
            return;
          }

          handleApprove(
            {
              finalComment: comment,
              dueDiligence: dueDiligenceLevel,
              delayedSettlement,
              rollingReserve,
            },
            {
              onSuccess: onClose,
            }
          );
        }}
        className="flex-column gap-2"
      >
        <TextArea
          name="comment"
          label={t("Final comment")}
          value={comment}
          onChange={setComment}
          validators={[new RequiredValidator(t("This field is required"))]}
          attributes={{ rows: 4 }}
        />

        <Select
          label="Due diligence level"
          onChange={setDueDiligenceLevel}
          value={dueDiligenceLevel}
          options={ddOptions}
          placeholder="Select an option"
          validators={[new RequiredValidator(t("This field is required"))]}
        />

        <div className={styles.columns}>
          <NumberInput
            label="Delayed settlement"
            name="delayedSettlement"
            onChange={setDelayedSettlement}
            value={delayedSettlement}
            hint="Number of days to delay the settlement"
            validators={[
              new RequiredValidator(t("This field is required")),
              new MaxValidator(41, t("Maximum {{days}} days", { days: 41 })),
              new MinValidator(0, t("Must be 0 or more days")),
            ]}
            clearable={false}
            postIcon={<div className={styles.postIcon}>days</div>}
          />

          <NumberInput
            label="Rolling reserve"
            name="rollingReserve"
            onChange={setRollingReserve}
            value={rollingReserve}
            hint="Percentage of the transaction amount to hold in reserve"
            validators={[
              new RequiredValidator(t("This field is required")),
              new MaxValidator(
                100,
                t("Maximum {{percentage}}%", { percentage: 100 })
              ),
              new MinValidator(0, t("Must be a positive number")),
            ]}
            postIcon={<div className={styles.postIcon}>%</div>}
            clearable={false}
          />
        </div>

        <div className="flex gap-2">
          <Button onClick={onClose} block variant="outlined" className="mt-1">
            {t("Cancel")}
          </Button>
          <Button type="submit" block className="mt-1" status={debouncedStatus}>
            {t("Save & approve")}
          </Button>
        </div>
      </Form>
    </div>
  );
};
