import React, {
  FunctionComponent,
  Suspense as SuspenseWrapper,
  useEffect,
} from "react";
import Skeleton from "react-loading-skeleton";
import { ErrorBoundary, FallbackRender } from "@sentry/react";
import { useQueryErrorResetBoundary } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import { Wrapper } from "../components/Wrapper";
import { isDev } from "..";
import { useTranslation } from "react-i18next";
import { Button } from "../components/Buttons/Button";

interface Props {
  skeleton?: React.ReactNode;
  children: React.ReactNode;
  fallback?: (errorData: ErrorData) => React.ReactElement;
}

export type ErrorData = Parameters<FallbackRender>[0] & {
  reset: () => void;
};

export const Suspense: React.FunctionComponent<Props> = ({
  skeleton,
  children,
  fallback,
}) => {
  const location = useLocation();
  const { reset } = useQueryErrorResetBoundary();

  return (
    <div>
      <ResetBoundary reset={reset} />
      <ErrorBoundary
        key={location.pathname}
        onReset={reset}
        fallback={({ error, resetError, ...rest }) => {
          if (fallback) {
            return fallback({ error, resetError, reset, ...rest });
          }
          return (
            <SuspenseRetry
              error={error}
              reset={reset}
              resetError={resetError}
            />
          );
        }}
      >
        <SuspenseWrapper
          fallback={
            skeleton || (
              <Wrapper>
                <Skeleton height={40} className="mt-2" />
                <div>
                  <Skeleton count={5} height={50} className="mt-2" />
                </div>
              </Wrapper>
            )
          }
        >
          {children}
        </SuspenseWrapper>
      </ErrorBoundary>
    </div>
  );
};

export const SuspenseRetry: FunctionComponent<{
  resetError: () => void;
  reset: () => void;
  error: any;
}> = ({ resetError, reset, error }) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <h3>{t("Oops, something went wrong")}</h3>
      <p>{t("Please try again...")}</p>
      {isDev() && (
        <pre
          style={{
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
          }}
        >
          {(error as any)?.stack}
        </pre>
      )}

      <Button
        onClick={() => {
          reset();
          resetError();
        }}
      >
        {t("Try again")}
      </Button>
    </Wrapper>
  );
};

const ResetBoundary: FunctionComponent<{ reset: () => void }> = ({ reset }) => {
  const location = useLocation();

  // Reset the error queries on page change
  // e.g. going back in story instead of pressing retry
  useEffect(() => {
    reset();
  }, [location.pathname, reset]);

  return null;
};
