import styles from "./Checkbox.module.scss";
import cx from "classnames";
import { FaCheck } from "react-icons/fa6";

interface Props {
  checked: boolean;
  isDisabled?: boolean;
  className?: string;
  size?: "small" | "medium" | "large";
}

export const Checkbox: React.FunctionComponent<Props> = ({
  checked,
  isDisabled,
  className,
  size = "medium",
}) => {
  return (
    <div
      className={cx(styles.wrapper, className, [styles[size]], {
        [styles.isChecked]: checked,
        [styles.isDisabled]: isDisabled,
      })}
    >
      {checked && <FaCheck />}
    </div>
  );
};
