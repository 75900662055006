import React, { useMemo, useRef } from "react";
import cx from "classnames";
import styles from "./PhoneWithCountry.module.scss";
import { useTranslation } from "react-i18next";
import {
  EXAMPLE_PHONE_NUMBER,
  getCountryAndPrefixFromPhoneNumber,
  PHONE_TO_DEFINED_COUNTRY_CODE,
  PHONE_TO_UNDEFINED_COUNTRY_CODE,
  PrefixMap,
} from "../Phone/PhoneToCountry";
import { TextInput } from "./TextInput";
import { PhoneLabel } from "../Phone/PhoneLabel";
import { RequiredValidator } from "./validators/RequiredValidator";
import {
  MIN_PHONE_LENGTH,
  MIN_PREFIXED_PHONE_LENGTH,
  PhoneCountryCodeValidator,
} from "./validators/PhoneCountryCodeValidator";
import { Country } from "../../data/models/ContractTypes";
import { MinLengthValidator } from "./validators/MinLengthValidator";
import { RegexValidator } from "./validators/RegexValidator";
import { Select } from "./Select";
import { TFunction } from "i18next";

interface Props {
  country: Country;
  phone?: string;
  onChange: (value: string, name: string) => void;
  disabled?: boolean;
}

function getFlagEmoji(countryCode: string) {
  const codePoints = countryCode
    .toUpperCase()
    .split("")
    .map((char) => 127397 + char.charCodeAt(0));
  return String.fromCodePoint(...codePoints);
}

export function getPhoneValidators(country: Country, t: TFunction) {
  return [
    new RequiredValidator(t("Mobile phone is required")),
    new PhoneCountryCodeValidator(
      t("Number must be prefixed with a country code, e.g. {{code}}", {
        code: PrefixMap[country],
      })
    ),
    new MinLengthValidator(
      MIN_PHONE_LENGTH,
      t("Number must be at least {{min}} characters", {
        min: MIN_PHONE_LENGTH,
      })
    ),
    new RegexValidator(/^([^A-Z])*$/i, t("Number can't include letters")),
  ];
}

export const PhoneWithCountry: React.FunctionComponent<Props> = ({
  country,
  phone,
  onChange,
  disabled,
}) => {
  const { t } = useTranslation();
  const usePrefix = useRef<boolean>(
    !phone || getCountryAndPrefixFromPhoneNumber(phone).valid
  );

  const validators = useMemo(
    () => getPhoneValidators(country, t),
    [country, t]
  );

  if (usePrefix.current) {
    return (
      <PrefixedPhone
        disabled={disabled}
        onChange={onChange}
        phone={phone}
        country={country}
      />
    );
  }

  return (
    <div className={cx(styles.phone)}>
      <TextInput
        label={<PhoneLabel phone={phone} />}
        name="phone"
        value={phone}
        onChange={(value, name) => {
          onChange(value, name);
        }}
        validators={validators}
        hint={`${PrefixMap[country]} ${EXAMPLE_PHONE_NUMBER[country]}`}
        disabled={disabled}
      />
    </div>
  );
};

function getPrefixFromPhone(country: Country, phone?: string) {
  if (!phone) {
    return PrefixMap[country];
  }

  const item = getCountryAndPrefixFromPhoneNumber(phone);
  return (item as any)?.prefix || PrefixMap[country];
}

const PrefixedPhone: React.FunctionComponent<Props> = ({
  country,
  phone,
  onChange,
  disabled,
}) => {
  const { t } = useTranslation();

  const prefixValue = getPrefixFromPhone(country, phone);

  const prefixes = useMemo(() => {
    return Object.entries({
      ...PHONE_TO_DEFINED_COUNTRY_CODE,
      ...PHONE_TO_UNDEFINED_COUNTRY_CODE,
    }).map(([phone, country]) => {
      return {
        value: phone,
        text: (
          <>
            {getFlagEmoji(country)} &nbsp;{phone}
          </>
        ),
      };
    });
  }, []);

  return (
    <div className={cx(styles.phoneWithCountry)}>
      <div className={styles.prefix}>
        <Select
          label={t("Country")}
          className={styles.prefixed}
          options={prefixes}
          name="phoneCountryPrefix"
          onChange={(value, name) => {
            onChange(`${value}${phone?.replace(prefixValue, "")}`, name);
          }}
          value={prefixValue}
          hint=" "
          disabled={disabled}
        />
      </div>
      <div className={styles.postfix}>
        <TextInput
          label={<PhoneLabel phone={phone} />}
          name="phone"
          value={phone?.replace(prefixValue, "")}
          onChange={(value, name) => {
            onChange(`${prefixValue}${value}`, name);
          }}
          validators={[
            new RequiredValidator(t("Mobile phone is required")),
            new MinLengthValidator(
              MIN_PREFIXED_PHONE_LENGTH,
              t("Number must be at least {{min}} characters", {
                min: MIN_PREFIXED_PHONE_LENGTH,
              })
            ),
            new RegexValidator(/^[0-9\s]*$/, t("Number can't include letters")),
          ]}
          hint={EXAMPLE_PHONE_NUMBER[country]}
          disabled={disabled}
        />
      </div>
    </div>
  );
};
