import React, { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Wrapper } from "../../../components/Wrapper";
import { Form } from "../../../modules/Forms/Form";
import { View } from "../../../modules/View/View";
import { useMutation, useSuspenseQueries } from "@tanstack/react-query";
import { dataContract } from "../../../data/dataContract";
import { StoryButtons } from "../../StoryButtons";
import { OnboardingPath } from "../routes";
import { useStoryNavigate } from "../../../hooks/useStoryNavigate";
import { DocumentTypeLabel } from "../../../components/DocumentTypeLabel/DocumentTypeLabel";
import { FileUpload } from "../../Supplement/Answer/components/FileUpload/FileUpload";
import { ContractDocument } from "../../../data/models/ContractTypes";
import { useDebouncedStatus } from "../../../pages/backoffice/hooks/useDebouncedStatus";
import { Dynamic } from "../../../components/Animations/Dynamic";
import { Box } from "../../../components/Boxes/Box";
import { Status } from "../../../modules/Forms/FormContext";
import { HiddenInput } from "../../../modules/Forms/HiddenInput";
import { RequiredValidator } from "../../../modules/Forms/validators/RequiredValidator";
import { dataDocument } from "../../../data/dataDocument";
import {
  MultiForm,
  MultiFormContainer,
} from "../../../modules/Forms/MultiForm";
import { DocumentTypeDescription } from "../../../components/DocumentTypeDescription/DocumentTypeDescription";

export const DocumentUpload: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { navigate } = useStoryNavigate();

  const [{ data: contract }] = useSuspenseQueries({
    queries: [dataContract.fetchContract()],
  });

  const multiFormContainer = useRef<MultiFormContainer>();

  const onSave = useCallback(() => {
    if (!multiFormContainer.current || multiFormContainer.current.isInvalid) {
      multiFormContainer.current?.forceValidation();
      return;
    }

    navigate(OnboardingPath.PRODUCTS);
  }, [navigate]);

  return (
    <Wrapper>
      <MultiForm multiFormContainer={multiFormContainer}>
        <View header={t("Additional documents")} size="small">
          <p>
            {t(
              "We need to collect some additional documentation from you, please upload them below."
            )}
          </p>

          {contract.documents.length > 0 ? (
            <div className="flex-column gap-2">
              {contract.documents.map((document) => (
                <DocumentRequirement
                  key={document.documentId}
                  document={document}
                />
              ))}
            </div>
          ) : (
            <Box status={Status.SUCCESS} className="text-09" noIcon>
              {t("No additional documents required.")}
            </Box>
          )}

          <div className="mt-4">
            <StoryButtons action={onSave} />
          </div>
        </View>
      </MultiForm>
    </Wrapper>
  );
};

interface Props {
  document: ContractDocument;
}

const DocumentRequirement: React.FunctionComponent<Props> = ({ document }) => {
  const { t } = useTranslation();

  const { mutate, status: mutationStatus } = useMutation(
    dataDocument.saveDocument(document.documentId)
  );

  // TODO: add loading indicator for upload
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const status = useDebouncedStatus(mutationStatus);

  return (
    <div>
      <h3>
        <DocumentTypeLabel value={document.documentType} />
      </h3>
      <p>
        <DocumentTypeDescription value={document.documentType} />
      </p>
      <Dynamic name={document.uploaded ? "answered" : "not-answered"}>
        <div className="flex-column gap-2">
          {!!document.uploaded ? (
            <Box status={Status.SUCCESS} className="text-09" noIcon>
              {t("Document uploaded!")}
            </Box>
          ) : (
            <div>
              <Form name={`document-${document.documentId}`}>
                <FileUpload
                  onFileSelect={(file) => {
                    mutate(file);
                  }}
                />
                <HiddenInput
                  value={document.uploaded ? "file" : undefined}
                  validators={[
                    new RequiredValidator(t("Please submit a document")),
                  ]}
                />
              </Form>
            </div>
          )}
        </div>
      </Dynamic>
    </div>
  );
};
