import React from "react";
import styles from "./ProgressBar.module.scss";

interface Props {
  progress?: number;
  timer?: number;
}

export const ProgressBar: React.FunctionComponent<Props> = ({
  progress = 0,
  timer = 300,
}) => {
  return (
    <div className={styles.progressBar}>
      <div
        className={styles.bar}
        style={{
          transform: `translateX(-${100 - progress}%)`,
          transition: `transform ${timer}ms ease`,
        }}
      />
    </div>
  );
};
