import React, { ReactNode } from "react";
import cx from "classnames";
import styles from "./Tag.module.scss";
import { Validity } from "../../data/models/ContractTypes";

interface Props {
  children: ReactNode;
  validity: Validity;
  className?: string;
}

export const Tag: React.FunctionComponent<Props> = ({
  children,
  validity,
  className,
}) => {
  return (
    <div className={cx(styles.tag, className, styles[validity])}>
      {children}
    </div>
  );
};
