import React from "react";
import cx from "classnames";
import styles from "./Timeline.module.scss";
import { useTranslation } from "react-i18next";
import { Circle } from "./Circle";
import { TimelineStatus } from "./Confirmed";
import { Check } from "./Check";

interface Props {
  status: TimelineStatus;
}

export const Shipped: React.FunctionComponent<Props> = ({ status }) => {
  const { t } = useTranslation();

  return (
    <div
      className={cx(styles.shipped, {
        [styles.active]: status === TimelineStatus.ACTIVE,
        [styles.done]: status === TimelineStatus.DONE,
      })}
    >
      <div
        className={cx(styles.inner, {
          [styles.solid]: status === TimelineStatus.DONE,
        })}
      >
        <div className={styles.header}>
          {t("The terminals are on their way")}
        </div>
        {status === TimelineStatus.DONE ? <Check /> : <Circle />}
      </div>
    </div>
  );
};
