import { queryClient } from "..";
import { API } from "./API";

const QUERY_KEY = "auth";

export const dataAuth = {
  QUERY_KEY,

  // 401 means you're not authenticated
  whoami: () => ({
    queryKey: [QUERY_KEY, "whoami"],
    queryFn: () => API.get<SalesforceSession>(`/risk/whoami`),
    staleTime: 5 * 60 * 1000,
  }),

  // send redirecturl to get a login url
  startLogin: (redirectUrl: string) => ({
    mutationFn: () =>
      API.post<LoginUrl>(`/salesforce/login`, {
        redirectUrl,
      }),
  }),

  // Oauth flödet  kommer skicka tillbaks användaren till ovan givna url
  // Man får med `?code=<...>` i callback, posta in den här för att få en session
  startSession: (redirectUrl: string) => ({
    mutationFn: (code: string) =>
      API.post<SalesforceSession>(`/salesforce/login/callback`, {
        code,
        redirectUrl,
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [dataAuth.QUERY_KEY],
      });
    },
  }),
};

export interface LoginUrl {
  url: string;
}

export interface SalesforceSession {
  name: string;
}
