interface MccListEntry {
  code: string;
  salesCategory: MccCategory;
}

export enum MccCategory {
  CAR_RELATED = "Car related business",
  ENTERTAINMENT = "Entertainment",
  HOTELS_AND_ACCOMODATION = "Hotels and accomodation",
  MISCELLANEOUS_STORES = "Miscellaneous stores",
  TRANSPORTATION = "Transportation",
  AGRICULTURE_AND_ANIMALS = "Agriculture and animals",
  HEALTH_MEDICAL_AND_BEAUTY = "Health, medical and beauty",
  FOOD_DRINKS_AND_RESTAURANTS = "Food, drinks and restaurants",
  CLOTHING_STORES = "Clothing stores",
  CONTRACTORS = "Contractors",
  OTHERS = "Others",
  UTILITY_SERVICES = "Utility services",
  PROFESSIONAL_SERVICES = "Professional services",
  RETAIL_STORES_AND_SERVICES = "Retail stores and services",
}

export const MCC_LIST: MccListEntry[] = [
  {
    code: "7512",
    salesCategory: MccCategory.CAR_RELATED,
  },
  {
    code: "7513",
    salesCategory: MccCategory.CAR_RELATED,
  },
  {
    code: "7519",
    salesCategory: MccCategory.CAR_RELATED,
  },
  {
    code: "7929",
    salesCategory: MccCategory.ENTERTAINMENT,
  },
  {
    code: "7941",
    salesCategory: MccCategory.ENTERTAINMENT,
  },
  {
    code: "7991",
    salesCategory: MccCategory.ENTERTAINMENT,
  },
  {
    code: "7011",
    salesCategory: MccCategory.HOTELS_AND_ACCOMODATION,
  },
  {
    code: "7033",
    salesCategory: MccCategory.HOTELS_AND_ACCOMODATION,
  },
  {
    code: "5712",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "7641",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "4011",
    salesCategory: MccCategory.TRANSPORTATION,
  },
  {
    code: "4111",
    salesCategory: MccCategory.TRANSPORTATION,
  },
  {
    code: "4112",
    salesCategory: MccCategory.TRANSPORTATION,
  },
  {
    code: "4131",
    salesCategory: MccCategory.TRANSPORTATION,
  },
  {
    code: "4411",
    salesCategory: MccCategory.TRANSPORTATION,
  },
  {
    code: "4457",
    salesCategory: MccCategory.TRANSPORTATION,
  },
  {
    code: "4511",
    salesCategory: MccCategory.TRANSPORTATION,
  },
  {
    code: "4722",
    salesCategory: MccCategory.TRANSPORTATION,
  },
  {
    code: "4789",
    salesCategory: MccCategory.TRANSPORTATION,
  },
  {
    code: "5995",
    salesCategory: MccCategory.AGRICULTURE_AND_ANIMALS,
  },
  {
    code: "7994",
    salesCategory: MccCategory.ENTERTAINMENT,
  },
  {
    code: "7996",
    salesCategory: MccCategory.ENTERTAINMENT,
  },
  {
    code: "5813",
    salesCategory: MccCategory.FOOD_DRINKS_AND_RESTAURANTS,
  },
  {
    code: "7230",
    salesCategory: MccCategory.HEALTH_MEDICAL_AND_BEAUTY,
  },
  {
    code: "8011",
    salesCategory: MccCategory.HEALTH_MEDICAL_AND_BEAUTY,
  },
  {
    code: "8021",
    salesCategory: MccCategory.HEALTH_MEDICAL_AND_BEAUTY,
  },
  {
    code: "8031",
    salesCategory: MccCategory.HEALTH_MEDICAL_AND_BEAUTY,
  },
  {
    code: "8041",
    salesCategory: MccCategory.HEALTH_MEDICAL_AND_BEAUTY,
  },
  {
    code: "8042",
    salesCategory: MccCategory.HEALTH_MEDICAL_AND_BEAUTY,
  },
  {
    code: "8043",
    salesCategory: MccCategory.HEALTH_MEDICAL_AND_BEAUTY,
  },
  {
    code: "8049",
    salesCategory: MccCategory.HEALTH_MEDICAL_AND_BEAUTY,
  },
  {
    code: "8062",
    salesCategory: MccCategory.HEALTH_MEDICAL_AND_BEAUTY,
  },
  {
    code: "8071",
    salesCategory: MccCategory.HEALTH_MEDICAL_AND_BEAUTY,
  },
  {
    code: "8099",
    salesCategory: MccCategory.HEALTH_MEDICAL_AND_BEAUTY,
  },
  {
    code: "5047",
    salesCategory: MccCategory.HEALTH_MEDICAL_AND_BEAUTY,
  },
  {
    code: "5122",
    salesCategory: MccCategory.HEALTH_MEDICAL_AND_BEAUTY,
  },
  {
    code: "5734",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "5815",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "5912",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "5921",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "5932",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "5933",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "5941",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "5944",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "5971",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "5973",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "5977",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "5993",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "5999",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "7299",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "7333",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "7372",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "7379",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "7841",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "4829",
    salesCategory: MccCategory.OTHERS,
  },
  {
    code: "6211",
    salesCategory: MccCategory.OTHERS,
  },
  {
    code: "6300",
    salesCategory: MccCategory.OTHERS,
  },
  {
    code: "6513",
    salesCategory: MccCategory.OTHERS,
  },
  {
    code: "7399",
    salesCategory: MccCategory.OTHERS,
  },
  {
    code: "8220",
    salesCategory: MccCategory.PROFESSIONAL_SERVICES,
  },
  {
    code: "8241",
    salesCategory: MccCategory.PROFESSIONAL_SERVICES,
  },
  {
    code: "8244",
    salesCategory: MccCategory.PROFESSIONAL_SERVICES,
  },
  {
    code: "8299",
    salesCategory: MccCategory.PROFESSIONAL_SERVICES,
  },
  {
    code: "8661",
    salesCategory: MccCategory.PROFESSIONAL_SERVICES,
  },
  {
    code: "8734",
    salesCategory: MccCategory.PROFESSIONAL_SERVICES,
  },
  {
    code: "5045",
    salesCategory: MccCategory.RETAIL_STORES_AND_SERVICES,
  },
  {
    code: "5099",
    salesCategory: MccCategory.RETAIL_STORES_AND_SERVICES,
  },
  {
    code: "5499",
    salesCategory: MccCategory.RETAIL_STORES_AND_SERVICES,
  },
  {
    code: "5511",
    salesCategory: MccCategory.RETAIL_STORES_AND_SERVICES,
  },
  {
    code: "5521",
    salesCategory: MccCategory.RETAIL_STORES_AND_SERVICES,
  },
  {
    code: "5551",
    salesCategory: MccCategory.RETAIL_STORES_AND_SERVICES,
  },
  {
    code: "5561",
    salesCategory: MccCategory.RETAIL_STORES_AND_SERVICES,
  },
  {
    code: "5571",
    salesCategory: MccCategory.RETAIL_STORES_AND_SERVICES,
  },
  {
    code: "5592",
    salesCategory: MccCategory.RETAIL_STORES_AND_SERVICES,
  },
  {
    code: "4121",
    salesCategory: MccCategory.TRANSPORTATION,
  },
  {
    code: "4816",
    salesCategory: MccCategory.UTILITY_SERVICES,
  },
  {
    code: "4899",
    salesCategory: MccCategory.UTILITY_SERVICES,
  },
  {
    code: "0742",
    salesCategory: MccCategory.AGRICULTURE_AND_ANIMALS,
  },
  {
    code: "0763",
    salesCategory: MccCategory.AGRICULTURE_AND_ANIMALS,
  },
  {
    code: "0780",
    salesCategory: MccCategory.AGRICULTURE_AND_ANIMALS,
  },
  {
    code: "7523",
    salesCategory: MccCategory.CAR_RELATED,
  },
  {
    code: "7531",
    salesCategory: MccCategory.CAR_RELATED,
  },
  {
    code: "7534",
    salesCategory: MccCategory.CAR_RELATED,
  },
  {
    code: "7535",
    salesCategory: MccCategory.CAR_RELATED,
  },
  {
    code: "7538",
    salesCategory: MccCategory.CAR_RELATED,
  },
  {
    code: "7542",
    salesCategory: MccCategory.CAR_RELATED,
  },
  {
    code: "7549",
    salesCategory: MccCategory.CAR_RELATED,
  },
  {
    code: "5013",
    salesCategory: MccCategory.CAR_RELATED,
  },
  {
    code: "5611",
    salesCategory: MccCategory.CLOTHING_STORES,
  },
  {
    code: "5621",
    salesCategory: MccCategory.CLOTHING_STORES,
  },
  {
    code: "5631",
    salesCategory: MccCategory.CLOTHING_STORES,
  },
  {
    code: "5641",
    salesCategory: MccCategory.CLOTHING_STORES,
  },
  {
    code: "5651",
    salesCategory: MccCategory.CLOTHING_STORES,
  },
  {
    code: "5655",
    salesCategory: MccCategory.CLOTHING_STORES,
  },
  {
    code: "5661",
    salesCategory: MccCategory.CLOTHING_STORES,
  },
  {
    code: "5681",
    salesCategory: MccCategory.CLOTHING_STORES,
  },
  {
    code: "5691",
    salesCategory: MccCategory.CLOTHING_STORES,
  },
  {
    code: "5697",
    salesCategory: MccCategory.CLOTHING_STORES,
  },
  {
    code: "5698",
    salesCategory: MccCategory.CLOTHING_STORES,
  },
  {
    code: "5699",
    salesCategory: MccCategory.CLOTHING_STORES,
  },
  {
    code: "7296",
    salesCategory: MccCategory.CLOTHING_STORES,
  },
  {
    code: "1520",
    salesCategory: MccCategory.CONTRACTORS,
  },
  {
    code: "1711",
    salesCategory: MccCategory.CONTRACTORS,
  },
  {
    code: "1731",
    salesCategory: MccCategory.CONTRACTORS,
  },
  {
    code: "1740",
    salesCategory: MccCategory.CONTRACTORS,
  },
  {
    code: "1750",
    salesCategory: MccCategory.CONTRACTORS,
  },
  {
    code: "1761",
    salesCategory: MccCategory.CONTRACTORS,
  },
  {
    code: "1771",
    salesCategory: MccCategory.CONTRACTORS,
  },
  {
    code: "1799",
    salesCategory: MccCategory.CONTRACTORS,
  },
  {
    code: "2741",
    salesCategory: MccCategory.CONTRACTORS,
  },
  {
    code: "2791",
    salesCategory: MccCategory.CONTRACTORS,
  },
  {
    code: "2842",
    salesCategory: MccCategory.CONTRACTORS,
  },
  {
    code: "7911",
    salesCategory: MccCategory.ENTERTAINMENT,
  },
  {
    code: "7922",
    salesCategory: MccCategory.ENTERTAINMENT,
  },
  {
    code: "7932",
    salesCategory: MccCategory.ENTERTAINMENT,
  },
  {
    code: "7933",
    salesCategory: MccCategory.ENTERTAINMENT,
  },
  {
    code: "7992",
    salesCategory: MccCategory.ENTERTAINMENT,
  },
  {
    code: "7993",
    salesCategory: MccCategory.ENTERTAINMENT,
  },
  {
    code: "7997",
    salesCategory: MccCategory.ENTERTAINMENT,
  },
  {
    code: "7998",
    salesCategory: MccCategory.ENTERTAINMENT,
  },
  {
    code: "7999",
    salesCategory: MccCategory.ENTERTAINMENT,
  },
  {
    code: "5811",
    salesCategory: MccCategory.FOOD_DRINKS_AND_RESTAURANTS,
  },
  {
    code: "5812",
    salesCategory: MccCategory.FOOD_DRINKS_AND_RESTAURANTS,
  },
  {
    code: "5814",
    salesCategory: MccCategory.FOOD_DRINKS_AND_RESTAURANTS,
  },
  {
    code: "7297",
    salesCategory: MccCategory.HEALTH_MEDICAL_AND_BEAUTY,
  },
  {
    code: "7298",
    salesCategory: MccCategory.HEALTH_MEDICAL_AND_BEAUTY,
  },
  {
    code: "8050",
    salesCategory: MccCategory.HEALTH_MEDICAL_AND_BEAUTY,
  },
  {
    code: "5975",
    salesCategory: MccCategory.HEALTH_MEDICAL_AND_BEAUTY,
  },
  {
    code: "5976",
    salesCategory: MccCategory.HEALTH_MEDICAL_AND_BEAUTY,
  },
  {
    code: "7032",
    salesCategory: MccCategory.HOTELS_AND_ACCOMODATION,
  },
  {
    code: "5713",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "5714",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "5718",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "5719",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "5722",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "5732",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "5733",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "5735",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "5931",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "5935",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "5937",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "5940",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "5942",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "5943",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "5945",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "5946",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "5947",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "5948",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "5949",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "5950",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "5963",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "5970",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "5972",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "5978",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "5983",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "5992",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "5994",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "5996",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "5997",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "5998",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "7251",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "7261",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "7276",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "7277",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "7278",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "7338",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "7339",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "7342",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "7349",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "7361",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "7375",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "7392",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "7393",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "7394",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "7395",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "7622",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "7623",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "7629",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "7631",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "7692",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "7699",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "7829",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "7832",
    salesCategory: MccCategory.MISCELLANEOUS_STORES,
  },
  {
    code: "4784",
    salesCategory: MccCategory.OTHERS,
  },
  {
    code: "5169",
    salesCategory: MccCategory.OTHERS,
  },
  {
    code: "5172",
    salesCategory: MccCategory.OTHERS,
  },
  {
    code: "6011",
    salesCategory: MccCategory.OTHERS,
  },
  {
    code: "7210",
    salesCategory: MccCategory.OTHERS,
  },
  {
    code: "7211",
    salesCategory: MccCategory.OTHERS,
  },
  {
    code: "7216",
    salesCategory: MccCategory.OTHERS,
  },
  {
    code: "7217",
    salesCategory: MccCategory.OTHERS,
  },
  {
    code: "7221",
    salesCategory: MccCategory.OTHERS,
  },
  {
    code: "8111",
    salesCategory: MccCategory.PROFESSIONAL_SERVICES,
  },
  {
    code: "8211",
    salesCategory: MccCategory.PROFESSIONAL_SERVICES,
  },
  {
    code: "8249",
    salesCategory: MccCategory.PROFESSIONAL_SERVICES,
  },
  {
    code: "8351",
    salesCategory: MccCategory.PROFESSIONAL_SERVICES,
  },
  {
    code: "8641",
    salesCategory: MccCategory.PROFESSIONAL_SERVICES,
  },
  {
    code: "8675",
    salesCategory: MccCategory.PROFESSIONAL_SERVICES,
  },
  {
    code: "8699",
    salesCategory: MccCategory.PROFESSIONAL_SERVICES,
  },
  {
    code: "8911",
    salesCategory: MccCategory.PROFESSIONAL_SERVICES,
  },
  {
    code: "8931",
    salesCategory: MccCategory.PROFESSIONAL_SERVICES,
  },
  {
    code: "5021",
    salesCategory: MccCategory.RETAIL_STORES_AND_SERVICES,
  },
  {
    code: "5039",
    salesCategory: MccCategory.RETAIL_STORES_AND_SERVICES,
  },
  {
    code: "5044",
    salesCategory: MccCategory.RETAIL_STORES_AND_SERVICES,
  },
  {
    code: "5046",
    salesCategory: MccCategory.RETAIL_STORES_AND_SERVICES,
  },
  {
    code: "5051",
    salesCategory: MccCategory.RETAIL_STORES_AND_SERVICES,
  },
  {
    code: "5065",
    salesCategory: MccCategory.RETAIL_STORES_AND_SERVICES,
  },
  {
    code: "5072",
    salesCategory: MccCategory.RETAIL_STORES_AND_SERVICES,
  },
  {
    code: "5074",
    salesCategory: MccCategory.RETAIL_STORES_AND_SERVICES,
  },
  {
    code: "5085",
    salesCategory: MccCategory.RETAIL_STORES_AND_SERVICES,
  },
  {
    code: "5111",
    salesCategory: MccCategory.RETAIL_STORES_AND_SERVICES,
  },
  {
    code: "5131",
    salesCategory: MccCategory.RETAIL_STORES_AND_SERVICES,
  },
  {
    code: "5137",
    salesCategory: MccCategory.RETAIL_STORES_AND_SERVICES,
  },
  {
    code: "5139",
    salesCategory: MccCategory.RETAIL_STORES_AND_SERVICES,
  },
  {
    code: "5192",
    salesCategory: MccCategory.RETAIL_STORES_AND_SERVICES,
  },
  {
    code: "5193",
    salesCategory: MccCategory.RETAIL_STORES_AND_SERVICES,
  },
  {
    code: "5198",
    salesCategory: MccCategory.RETAIL_STORES_AND_SERVICES,
  },
  {
    code: "5199",
    salesCategory: MccCategory.RETAIL_STORES_AND_SERVICES,
  },
  {
    code: "5200",
    salesCategory: MccCategory.RETAIL_STORES_AND_SERVICES,
  },
  {
    code: "5211",
    salesCategory: MccCategory.RETAIL_STORES_AND_SERVICES,
  },
  {
    code: "5231",
    salesCategory: MccCategory.RETAIL_STORES_AND_SERVICES,
  },
  {
    code: "5251",
    salesCategory: MccCategory.RETAIL_STORES_AND_SERVICES,
  },
  {
    code: "5261",
    salesCategory: MccCategory.RETAIL_STORES_AND_SERVICES,
  },
  {
    code: "5271",
    salesCategory: MccCategory.RETAIL_STORES_AND_SERVICES,
  },
  {
    code: "5300",
    salesCategory: MccCategory.RETAIL_STORES_AND_SERVICES,
  },
  {
    code: "5309",
    salesCategory: MccCategory.RETAIL_STORES_AND_SERVICES,
  },
  {
    code: "5310",
    salesCategory: MccCategory.RETAIL_STORES_AND_SERVICES,
  },
  {
    code: "5311",
    salesCategory: MccCategory.RETAIL_STORES_AND_SERVICES,
  },
  {
    code: "5331",
    salesCategory: MccCategory.RETAIL_STORES_AND_SERVICES,
  },
  {
    code: "5399",
    salesCategory: MccCategory.RETAIL_STORES_AND_SERVICES,
  },
  {
    code: "5411",
    salesCategory: MccCategory.RETAIL_STORES_AND_SERVICES,
  },
  {
    code: "5422",
    salesCategory: MccCategory.RETAIL_STORES_AND_SERVICES,
  },
  {
    code: "5441",
    salesCategory: MccCategory.RETAIL_STORES_AND_SERVICES,
  },
  {
    code: "5451",
    salesCategory: MccCategory.RETAIL_STORES_AND_SERVICES,
  },
  {
    code: "5462",
    salesCategory: MccCategory.RETAIL_STORES_AND_SERVICES,
  },
  {
    code: "5532",
    salesCategory: MccCategory.RETAIL_STORES_AND_SERVICES,
  },
  {
    code: "5533",
    salesCategory: MccCategory.RETAIL_STORES_AND_SERVICES,
  },
  {
    code: "5541",
    salesCategory: MccCategory.RETAIL_STORES_AND_SERVICES,
  },
  {
    code: "5542",
    salesCategory: MccCategory.RETAIL_STORES_AND_SERVICES,
  },
  {
    code: "5552",
    salesCategory: MccCategory.RETAIL_STORES_AND_SERVICES,
  },
  {
    code: "5599",
    salesCategory: MccCategory.RETAIL_STORES_AND_SERVICES,
  },
  {
    code: "4119",
    salesCategory: MccCategory.TRANSPORTATION,
  },
  {
    code: "4214",
    salesCategory: MccCategory.TRANSPORTATION,
  },
  {
    code: "4215",
    salesCategory: MccCategory.TRANSPORTATION,
  },
  {
    code: "4225",
    salesCategory: MccCategory.TRANSPORTATION,
  },
  {
    code: "4468",
    salesCategory: MccCategory.TRANSPORTATION,
  },
  {
    code: "4582",
    salesCategory: MccCategory.TRANSPORTATION,
  },
  {
    code: "4812",
    salesCategory: MccCategory.UTILITY_SERVICES,
  },
  {
    code: "4814",
    salesCategory: MccCategory.UTILITY_SERVICES,
  },
  {
    code: "4900",
    salesCategory: MccCategory.UTILITY_SERVICES,
  },
];
