import { ReactNode } from "react";
import { ValidationResponse } from "../hooks/useValidation";
import { BaseValidator } from "./BaseValidator";
import { VALIDATION_STATE } from "../FormContext";

export const MaxValidatorName = "MaxValidator";

export class MaxValidator extends BaseValidator {
  name = MaxValidatorName;
  _max: number;

  constructor(max: number, error: string | ReactNode) {
    super(error);
    this._max = max;
  }

  get max() {
    return this._max;
  }

  validate(value: number): Promise<ValidationResponse> {
    return new Promise<ValidationResponse>((resolve) => {
      if (isNaN(value) || value <= this._max) {
        resolve({ status: VALIDATION_STATE.SUCCESS });
      } else {
        resolve({
          status: VALIDATION_STATE.FAILED,
          message: this.error,
        });
      }
    });
  }
}
