import React from "react";
import { RequiredValidator } from "../../../modules/Forms/validators/RequiredValidator";
import { Wrapper } from "../../../components/Wrapper";
import { useContract } from "../../../hooks/useContract";
import { Form } from "../../../modules/Forms/Form";
import { HiddenInput } from "../../../modules/Forms/HiddenInput";
import { useTranslation } from "react-i18next";
import { View } from "../../../modules/View/View";
import { AllSigned } from "./AllSigned";
import { Waiting } from "./Waiting";
import { useSearchParams } from "react-router-dom";
import { QUERY_KEY, QUERY_RESULT_SUCCESS } from "../../../data/dataSigning";

export const Complete: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const contract = useContract();
  const [searchParams] = useSearchParams();
  const signingStatus = searchParams.get(QUERY_KEY);
  const justSigned = signingStatus === QUERY_RESULT_SUCCESS;

  const allSigned = contract.selectedSignatories.every((signee) => {
    if (signee.primary) {
      return justSigned || signee.signed;
    }

    return signee.signed;
  });

  return (
    <Wrapper>
      <Form>
        <HiddenInput
          value={allSigned ? "All good" : undefined}
          validators={[new RequiredValidator("")]}
        />

        <h1>{t("What happens now?")}</h1>

        <View header={t("Next steps")} size="small">
          {allSigned ? <AllSigned /> : <Waiting />}
        </View>
      </Form>
    </Wrapper>
  );
};
