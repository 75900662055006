import React from "react";
import cx from "classnames";
import styles from "./SavedAccount.module.scss";
import { BankAccount } from "../../../data/models/ContractTypes";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components/Buttons/Button";
import { MdArrowForward } from "react-icons/md";
import { Or } from "../../../components/Or/Or";
import { Block } from "../../../components/Boxes/Block";
import { InfoList } from "../../../components/List/InfoList";

interface Props {
  bankAccount?: BankAccount;
  next: () => void;
}

export const SavedAccount: React.FunctionComponent<Props> = ({
  bankAccount,
  next,
}) => {
  const { t } = useTranslation();

  if (!bankAccount) {
    return null;
  }

  return (
    <div className={styles.account}>
      <Block>
        <b className="large">{t("Saved account")}</b>

        <p>
          {t(
            "There is already a saved bank account associated with this company"
          )}
        </p>

        <InfoList className={cx(styles.list)}>
          <InfoList.Dt>{t("IBAN")}:</InfoList.Dt>
          <InfoList.Dd>{bankAccount.iban}</InfoList.Dd>
          <InfoList.Dt>{t("Bank")}:</InfoList.Dt>
          <InfoList.Dd>{bankAccount.bank}</InfoList.Dd>
          <InfoList.Dt>{t("Account holder")}:</InfoList.Dt>
          <InfoList.Dd>{bankAccount.accountHolder}</InfoList.Dd>
        </InfoList>

        <Button className="mt-5" block onClick={() => next()}>
          {t("Next")} <MdArrowForward />
        </Button>
      </Block>

      <Or className="mt-7" />
    </div>
  );
};
