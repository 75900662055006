import React, { ReactNode } from "react";
import cx from "classnames";
import styles from "./Input.module.scss";
import { VALIDATION_STATE } from "./FormContext";
import { InputTag } from "./components/InputTag";
import { ClearButton } from "./components/ClearButton";
import { InputProps } from "./Input";

export enum FormattedInputType {
  NUMBER = "number",
  PATTERN = "pattern",
}

export const defaultInputValues: Record<FormattedInputType, any> = {
  [FormattedInputType.NUMBER]: {
    thousandSeparator: " ",
    decimalSeparator: ",",
    inputMode: "decimal",
    suffix: "",
    prefix: "",
    allowedDecimalSeparators: [".", ","],
    allowNegative: false,
    allowLeadingZeros: false,
  },
  [FormattedInputType.PATTERN]: {
    valueIsNumericString: true,
    pattern: "",
  },
};

interface Props extends Omit<InputProps, "onChange" | "onBlur"> {
  children: ReactNode;
}

export const InputWrapper: React.FunctionComponent<Props> = ({
  state = VALIDATION_STATE.UNVALIDATED,
  className,
  name,
  label = null,
  value = "",
  onClear,
  message = null,
  hint = null,
  preIcon = null,
  postIcon = null,
  constraints,
  disabled,
  children,
  layoutProps,
}) => {
  return (
    <label
      className={cx(styles.wrapper, className, styles[state], {
        [styles.preIcon]: !!preIcon,
        [styles.postIcon]: !!postIcon,
        [styles.required]: !!constraints.isRequired,
        [styles.hasLabel]: !!label,
        [styles.hasError]: !!message && state === VALIDATION_STATE.FAILED,
        [styles.hasMessage]: !!message && state !== VALIDATION_STATE.FAILED,
        [styles.hasValue]: !!value,
        [styles.isDisabled]: disabled,
        [styles.row]: !!layoutProps,
        [styles.isFirst]: layoutProps?.isFirst,
      })}
      htmlFor={name}
    >
      <div className={cx(styles.name)}>
        <div className={cx(styles.inputLabel, "line-mini")}>{label}</div>
        <InputTag value={value} {...constraints} />
      </div>

      <div className={cx(styles.input, "input")}>
        {preIcon ? preIcon : null}
        {children}
        {typeof onClear !== "undefined" && (
          <ClearButton disabled={disabled} onClear={onClear} value={value} />
        )}
        {postIcon ? postIcon : null}
      </div>

      <div className={cx(styles.messages)}>
        <div className={styles.hint}>{hint}</div>
        <div className={styles.error}>{message}</div>
      </div>
    </label>
  );
};
