import { StoryFrameProps } from "./storyTypes";

export const filterDefined = (obj: any) => {
  if (!obj) {
    return;
  }

  return Object.fromEntries(
    Object.entries(obj).filter(([, v]) => v !== undefined)
  );
};

export function getIndex(currentRoute: string, routes: StoryFrameProps[]) {
  return routes.findIndex((route) => route.path === currentRoute);
}

export function cleanPath(path: string): string {
  return path.replace("//", "/");
}

export function onNextFrame(callback: () => void, timer = 0) {
  setTimeout(() => {
    window.requestAnimationFrame(() => {
      callback();
    });
  }, timer);
}
