import { API } from "./API";
import { Country, Language } from "./models/ContractTypes";

export interface InitLoginRequest {
  country: Country;
  language: Language;
  //return URL will have the following requestr params appended:
  // status=COMPLETE | ABORTED | ERROR
  // sessionId=<id>
  returnUrl: string;
  MOVE5000?: number;
  DX8000?: number;
  email?: string;
  campaignId?: string;
  voucherId?: string;
  trackingId?: string;
}

export interface InitLogin {
  url: string;
}

export enum LoginStatus {
  PENDING = "PENDING", //Den här kommer aldrig i status i URLen
  COMPLETE = "COMPLETE",
  ABORTED = "ABORTED",
  ERROR = "ERROR",
}

export interface LoginResponse {
  status: LoginStatus;
  name?: string;
}

export const dataSession = {
  initLogin: (initLogin: InitLoginRequest) =>
    API.post<InitLogin>(`/login`, initLogin),

  /**
   * Vi pollar alltid, även om vi får en status i FE. Det ger oss möjligheten att logga ev felet vi får från vår provider
   */
  pollLogin: (sessionId: string) =>
    API.get<LoginResponse>(`/login/${sessionId}`),

  fakeLogin: (initLogin: InitLoginRequest) =>
    API.post(`/login/fake`, initLogin),
};
