import { API } from "./API";
import {
  BeneficialOwner,
  SelectedSignatory,
  SigningGroup,
} from "./models/ContractTypes";
import { BASE_PATH } from "./proxy";

export const dataAssociates = {
  saveBeneficialOwner: (owner: BeneficialOwner) =>
    API.post(`${BASE_PATH}/owner`, owner),

  commitOwners: () => API.post(`${BASE_PATH}/owner/commit`),

  listSigningCombinations: () =>
    API.get<SigningGroup[]>(`${BASE_PATH}/signatories/combinations`),

  listSigningCombinationsKey: () => ["listings"],

  fetchSigningCombinations: () => ({
    queryKey: dataAssociates.listSigningCombinationsKey(),
    queryFn: () => dataAssociates.listSigningCombinations(),
  }),

  selectSignatoryGroup: (id: number) =>
    API.post(`${BASE_PATH}/signatories/combinations`, {
      id,
    }),

  updateSignee: (signatory: SelectedSignatory) =>
    API.post(`${BASE_PATH}/signatories/update`, signatory),

  //Flyttar fram status till att vara i CONFIRM_CONTRACT
  commitSignees: () => API.post(`${BASE_PATH}/signatories/commit`),
};
