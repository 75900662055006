import React, { useState, useEffect, useCallback, useRef } from "react";
import styles from "./ListBanks.module.scss";
import { useTranslation } from "react-i18next";
import {
  BankListResponse,
  BankType,
  Contract,
  Country,
  LegalEntityType,
} from "../../../../data/models/ContractTypes";
import { useContract } from "../../../../hooks/useContract";
import { Radiobuttons } from "../../../../modules/Forms/Radiobuttons";
import { useMutation } from "@tanstack/react-query";
import { dataBank } from "../../../../data/dataBank";
import { TextInput } from "../../../../modules/Forms/TextInput";
import { CardList } from "../../../../components/List/CardList";
import { Button } from "../../../../components/Buttons/Button";
import { Dynamic } from "../../../../components/Animations/Dynamic";
import { GenericError } from "../../../../components/Errors/GenericError";

const prioBanks: Record<Country, string[]> = {
  [Country.SWEDEN]: [
    "Danske Bank",
    "Handelsbanken",
    "Länsförsäkringar Bank",
    "Nordea",
    "Nordea Corporate",
    "SEB",
    "Swedbank",
  ],
  [Country.DENMARK]: [
    "Arbejdernes Landsbank",
    "Danske Bank",
    "Jyske Bank",
    "Nykredit Bank",
    "Nordea",
    "Ringkjøbing Landbobank",
    "Saxo Bank",
    "Spar Nord Bank",
    "Sydbank",
  ],
  [Country.NORWAY]: [],
  [Country.FINLAND]: [
    "Nordea",
    "Nordea Corporate",
    "Danske Bank",
    "Handelsbanken",
    "Aktia",
    "S-Pankki",
    "POP Pankki",
  ],
  [Country.GB]: [],
};

interface Props {}

function sortByName(banks: BankListResponse[]): BankListResponse[] {
  banks.sort((a, b) => a.name.localeCompare(b.name));
  return banks;
}

function sortByPrio(
  banks: BankListResponse[],
  country: Country
): BankListResponse[] {
  const prioritizedBanks = prioBanks[country];

  banks.sort((a, b) => {
    const firstIsPrio = prioritizedBanks.includes(a.name);
    const secondIsPrio = prioritizedBanks.includes(b.name);

    if (firstIsPrio && secondIsPrio) {
      return 0;
    }

    if (firstIsPrio) {
      return -1;
    }

    if (secondIsPrio) {
      return 1;
    }

    return 0;
  });
  return banks;
}

export const ListBanks: React.FunctionComponent<Props> = () => {
  const contract = useContract();
  const [ownerType, setOwnerType] = useState<BankType>(
    contract.companyDetails.legalEntityType === LegalEntityType.SOLE_PROPRIETARY
      ? BankType.PERSONAL
      : BankType.BUSINESS
  );

  return (
    <div>
      <OwnerTypeInput
        ownerType={ownerType}
        setOwnerType={setOwnerType}
        contract={contract}
      />

      <Listing ownerType={ownerType} />
    </div>
  );
};

const OwnerTypeInput: React.FunctionComponent<{
  ownerType: BankType;
  setOwnerType: React.Dispatch<React.SetStateAction<BankType>>;
  contract: Contract;
}> = ({ ownerType, contract }) => {
  const { t } = useTranslation();

  if (
    contract.companyDetails.legalEntityType !== LegalEntityType.SOLE_PROPRIETARY
  ) {
    return null;
  }

  return (
    <div>
      <Radiobuttons
        label={t("Who is the owner of the bankaccount")}
        onChange={(value) => {
          console.log("value", value);
        }}
        value={ownerType}
        options={[
          {
            value: BankType.PERSONAL,
            text: t(BankType.PERSONAL),
          },
          {
            value: BankType.BUSINESS,
            text: t(BankType.BUSINESS),
          },
        ]}
      />
    </div>
  );
};

const Listing: React.FunctionComponent<{ ownerType: BankType }> = ({
  ownerType,
}) => {
  const { t } = useTranslation();
  const [banks, setBanks] = useState<BankListResponse[]>([]);
  const [filteredBanks, setFilteredBanks] = useState<BankListResponse[]>([]);
  const [search, setSearch] = useState<string>("");
  const contract = useContract();
  const timer = useRef<number>();
  const [selectedBank, setSelectedBank] = useState<string>();

  const filterFunction = (text: string, items: BankListResponse[]) => {
    if (!text) {
      setFilteredBanks(items);
      return;
    }

    const searchValue = text.toLocaleLowerCase();

    setFilteredBanks(
      items.filter(
        (bankItem) =>
          bankItem.name.toLocaleLowerCase().indexOf(searchValue) > -1
      )
    );
  };

  const {
    mutate: listBanks,
    isPending: listBanksPending,
    isError,
    reset,
  } = useMutation({
    mutationFn: () => dataBank.listRoaringBanks(contract.country, ownerType),
    onSuccess: (data) => {
      let sortedBanks = sortByName(data);
      sortedBanks = sortByPrio(sortedBanks, contract.country);
      setBanks(sortedBanks);
      filterFunction(search, sortedBanks);
    },
  });

  const {
    mutate: initLogin,
    isError: isInitError,
    reset: resetInit,
  } = useMutation({
    mutationFn: (bankName: string) =>
      dataBank.startRoaringLogin(bankName, contract.country, ownerType),
    onSuccess: (data) => {
      window.location.href = data.url;
    },
  });

  useEffect(() => {
    if (!ownerType) {
      return;
    }

    listBanks();
  }, [listBanks, ownerType]);

  const onSelect = useCallback(
    (bankItem: BankListResponse) => {
      resetInit();
      setSelectedBank(bankItem.name);
      initLogin(bankItem.name);
    },
    [initLogin, resetInit]
  );

  return (
    <div className={styles.bank}>
      <div className={styles.filter}>
        <TextInput
          label={t("Search bank")}
          disabled={isError || listBanksPending}
          onChange={(value) => {
            setSearch(value);
            window.clearTimeout(timer.current);

            timer.current = window.setTimeout(() => {
              filterFunction(value, banks);
            }, 300);
          }}
          value={search}
        />
      </div>

      <CardList className="small">
        {filteredBanks.map((bankItem) => {
          return (
            <CardList.CardListItem key={bankItem.name}>
              <Bank
                onSelect={onSelect}
                bank={bankItem}
                isInitError={isInitError && selectedBank === bankItem.name}
              />
            </CardList.CardListItem>
          );
        })}

        {banks.length && filteredBanks.length === 0 ? (
          <CardList.CardListItem>
            {t('No match for search value "{{search}}"', {
              search,
            })}
          </CardList.CardListItem>
        ) : null}
      </CardList>

      <Dynamic name={isError ? "isError" : ""}>
        {isError ? (
          <GenericError
            retry={() => {
              reset();
              listBanks();
            }}
          >
            {t("We couldn't list the applicable banks. Try again?")}
          </GenericError>
        ) : null}
      </Dynamic>
    </div>
  );
};

interface BankProps {
  onSelect: (bank: BankListResponse) => void;
  bank: BankListResponse;
  isInitError?: boolean;
}

export const Bank: React.FunctionComponent<BankProps> = ({
  onSelect,
  bank,
  isInitError = false,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.bankItem}>
        <div className={styles.logo}>
          <img src={bank.logoUrl} alt={bank.name} />
        </div>
        <div className={styles.nameAndButton}>
          <div className={styles.bankName}>{bank.name}</div>
          <div className={styles.button}>
            <Button
              size="small"
              variant="outlined"
              onClick={() => onSelect(bank)}
            >
              {t("Login")}
            </Button>
          </div>
        </div>
      </div>
      <Dynamic name={isInitError ? "isInitError" : ""}>
        {isInitError ? (
          <div className="pt-1">
            <GenericError>
              {t("We couldn't initiate the sign in. Try again?")}
            </GenericError>
          </div>
        ) : null}
      </Dynamic>
    </>
  );
};
