import React from "react";
import cx from "classnames";
import styles from "./ContractThumbnail.module.scss";

export const ContractThumbnail: React.FunctionComponent = () => {
  return (
    <div className={cx(styles.wrapper)}>
      <div className={styles.back} />
      <div className={styles.middle} />
      <div className={styles.front}>
        <div className={styles.logo}>
          <img src="/images/worldline-logo.svg" alt="Worldline logo" />
        </div>

        <div className="mt-2">
          <div className={styles.line} />
          <div className={styles.line} />
          <div className={styles.line} />
          <div className={styles.line} />
        </div>
        <div className={styles.signature} />
      </div>
    </div>
  );
};
