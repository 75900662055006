interface Props {
  value: number;
  decimals?: number;
  locale: string;
}

export const FormattedPercent: React.FC<Props> = ({
  value,
  locale = "default",
  decimals = 2,
}) => {
  return <>{getIntlPercent(locale, value, decimals)}</>;
};

export function getIntlPercent(
  lang: string,
  number: number,
  decimals: number = 0
) {
  try {
    return new Intl.NumberFormat(lang, {
      style: "percent",
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    }).format(number / 100);
  } catch (err) {
    return "- %";
  }
}
