import { API } from "./API";
import { BusinessRisk, FinancialKyc } from "./models/ContractTypes";
import { BASE_PATH } from "./proxy";

export const dataKyc = {
  saveFinancialKyc: (kyc: FinancialKyc) =>
    API.post(`${BASE_PATH}/financial`, kyc),

  commitFinancialKyc: () => API.post(`${BASE_PATH}/financial/commit`),

  saveBusinessRisk: (risk: BusinessRisk) => API.post(`${BASE_PATH}/risk`, risk),

  commitBusinessRisk: () => API.post(`${BASE_PATH}/risk/commit`),
};
