import React from "react";
import { useTranslation } from "react-i18next";
import { Box } from "../../../../components/Boxes/Box";
import { LinkButton } from "../../../../components/Buttons/LinkButton";
import { Link } from "../../../../components/Link/Link";
import { getRiskDocumentUrl } from "../../../../data/dataRisk";
import { RiskDocument } from "../../../../data/models/RiskTypes";

const APPLICATION_PDF = "application/pdf";

interface Props {
  document: RiskDocument;
  height?: string;
  width?: string;
}

export const DocumentPreview: React.FunctionComponent<Props> = ({
  document,
  width = "100%",
  height,
}) => {
  const { t } = useTranslation();
  const url = getRiskDocumentUrl(document);

  if (!url) {
    return (
      <div>
        <i>{t("This document is yet to be uploaded")}</i>
      </div>
    );
  }

  if (document.mimeType === APPLICATION_PDF) {
    return (
      <div>
        <object data={url} type={APPLICATION_PDF} width={width} height={height}>
          <Link external to={url} download="">
            {t("PDF-viewer not supported. Download the document instead.")}
          </Link>
        </object>
      </div>
    );
  }

  if (document.mimeType?.startsWith("image/")) {
    return (
      <div>
        <img
          src={url}
          alt="document"
          style={{
            width: "auto",
            height: "auto",
            maxHeight: height,
            maxWidth: width,
          }}
        />
        <div className="text-09 text-center">
          <Link external to={url}>
            {t("Open in new tab")}
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Box noIcon className="mb-2">
        <div className="mb-2">File format not supported in preview</div>
        <LinkButton to={url} download={document.fileName} block>
          {t("Download attachment")}
        </LinkButton>
      </Box>
    </div>
  );
};
