import React, { ReactNode } from "react";
import cx from "classnames";
import { Link as RouterLink } from "react-router-dom";
import styles from "./Link.module.scss";
import { FaArrowUpRightFromSquare } from "react-icons/fa6";

export interface LinkProps {
  to: string;
  download?: string;
  external?: boolean;
  children: ReactNode;
  className?: string;
}

export const Link: React.FunctionComponent<
  LinkProps & React.HTMLAttributes<HTMLElement>
> = ({ to, external, children, className, download, ...props }) => {
  if (external) {
    return (
      <a
        className={cx(styles.link, className)}
        href={to}
        target="_blank"
        rel="noreferrer"
        download={download}
        {...props}
      >
        {children}
        <FaArrowUpRightFromSquare className={styles.icon} />
      </a>
    );
  }
  return (
    <RouterLink className={cx(styles.link, className)} to={to} {...props}>
      {children}
    </RouterLink>
  );
};
