import { FaMoneyBillTransfer } from "react-icons/fa6";
import { useRiskCase } from "../../hooks/useRiskCase";
import { useTranslation } from "react-i18next";
import { BackofficeCard } from "../../components/card/BackofficeCard";
import { BackofficeCardHeader } from "../../components/card/BackofficeCardHeader";
import { DataList } from "../../components/Datalist/DataList";
import { DataListItem } from "../../components/Datalist/DataListItem";
import { Suspense } from "../../../Suspense";
import Skeleton from "react-loading-skeleton";
import { Currency } from "../../../../data/models/ContractTypes";
import { FormattedPrice } from "../../../../components/FormattedPrice/FormattedPrice";

export const RiskFinancialsCard: React.FC = () => {
  const { t } = useTranslation();

  return (
    <BackofficeCard>
      <BackofficeCardHeader
        title={t("Financials")}
        icon={FaMoneyBillTransfer}
      />
      <Suspense skeleton={<Skeleton height={100} className="mb-2" />}>
        <Inner />
      </Suspense>
    </BackofficeCard>
  );
};

const Inner = () => {
  const { t, i18n } = useTranslation();

  const {
    data: { contract },
  } = useRiskCase();
  const { financialKyc } = contract;

  const {
    estimatedAnnualTurnover,
    estimatedRangeOfTransactionValueMax,
    estimatedRangeOfTransactionValueMin,
    estimatedTransactionsPerYear,
  } = financialKyc;

  return (
    <div>
      <DataList type="horizontal" align="left">
        <DataListItem
          label={t("Estimated annual turnover")}
          value={
            estimatedAnnualTurnover ? (
              <FormattedPrice
                currency={Currency[contract.country]}
                value={estimatedAnnualTurnover}
                locale={i18n.language}
              />
            ) : undefined
          }
        />
        <DataListItem
          label={t("Estimated transactions per year")}
          value={
            estimatedTransactionsPerYear
              ? formatNumber(estimatedTransactionsPerYear, i18n.language)
              : undefined
          }
        />
        {/* <DataListItem
          label={t("Estimated card transactions per year")}
          value={
            estimatedTransactionsByCardPerYear
              ? formatNumber(estimatedTransactionsByCardPerYear, i18n.language)
              : undefined
          }
        /> */}
        <DataListItem
          label={t("Estimated transaction value range")}
          value={
            estimatedRangeOfTransactionValueMin &&
            estimatedRangeOfTransactionValueMax ? (
              <>
                <FormattedPrice
                  currency={Currency[contract.country]}
                  value={estimatedRangeOfTransactionValueMin}
                  locale={i18n.language}
                />
                {" - "}
                <FormattedPrice
                  currency={Currency[contract.country]}
                  value={estimatedRangeOfTransactionValueMax}
                  locale={i18n.language}
                />
              </>
            ) : undefined
          }
        />
      </DataList>
    </div>
  );
};

const formatNumber = (value: number | undefined, locale: string) =>
  value ? new Intl.NumberFormat(locale).format(value) : undefined;
