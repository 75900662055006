import React, {
  useState,
  useCallback,
  RefObject,
  useRef,
  useImperativeHandle,
  useMemo,
} from "react";
import { useForm } from "./hooks/useForm";
import { useValidation } from "./hooks/useValidation";
import { BaseValidator } from "./validators/BaseValidator";
import { Input, InputProps } from "./Input";
import { RequiredValidatorName } from "./validators/RequiredValidator";
import {
  MaxLengthValidator,
  MaxLengthValidatorName,
} from "./validators/MaxLengthValidator";
import {
  MinLengthValidator,
  MinLengthValidatorName,
} from "./validators/MinLengthValidator";

export interface TextInputProps
  extends Omit<InputProps, "state" | "name" | "constraints"> {
  scrollToRef?: RefObject<HTMLElement>;
  name?: string;
  validators?: BaseValidator[];
  clearable?: boolean;
  list?: string;
}

export function isDefined(value?: any) {
  return value !== undefined && value !== "";
}

export interface ValidatorConstraints {
  isRequired?: boolean;
  maxLength?: number;
  minLength?: number;
}

export function getConstraints(validators: BaseValidator[]) {
  return validators.reduce((acc, validator) => {
    if (validator.name === RequiredValidatorName) {
      acc.isRequired = true;
    }

    if (validator.name === MaxLengthValidatorName) {
      acc.maxLength = (validator as MaxLengthValidator).maxLength;
    }

    if (validator.name === MinLengthValidatorName) {
      acc.minLength = (validator as MinLengthValidator).minLength;
    }

    return acc;
  }, {} as ValidatorConstraints);
}

export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      name,
      value = "",
      onChange,
      onBlur,
      scrollToRef,
      validators = [],
      hint,
      message,
      label,
      clearable = true,
      disabled,
      preIcon,
      postIcon,
      className,
      type,
      formatInputValues,
      layoutProps,
      placeholder,
      datalist,
      autocomplete,
      list,
      inputAttributes,
    },
    ref
  ) => {
    const identifier = useRef<string>(window.crypto.randomUUID());
    const innerRef = useRef<HTMLInputElement>(null);
    useImperativeHandle(ref, () => innerRef.current as HTMLInputElement);
    const [showErrors, setShowErrors] = useState<boolean>(isDefined(value));

    const [validity, errorMessages, showFormErrors] = useValidation<string>(
      value,
      validators
    );

    useForm(
      identifier.current,
      validity,
      value,
      () => {},
      scrollToRef ?? innerRef
    );

    const innerBlur = useCallback(
      (value: string, name: string, ev: React.ChangeEvent<any>) => {
        if (isDefined(value)) {
          setShowErrors(true);
        }

        onBlur && onBlur(value, name, ev);
      },
      [onBlur]
    );

    const onClear = useCallback(
      (ev: React.MouseEvent<HTMLButtonElement>) => {
        ev.preventDefault();
        onChange("", name || identifier.current, ev);
        innerRef.current?.focus();
        setShowErrors(false);
      },
      [onChange, name]
    );

    const error = useMemo(() => {
      if (errorMessages.length > 0 && (showErrors || showFormErrors)) {
        return errorMessages[0];
      }

      return null;
    }, [errorMessages, showErrors, showFormErrors]);

    const constraints = getConstraints(validators);

    return (
      <Input
        constraints={constraints}
        state={validity}
        onBlur={innerBlur}
        onChange={onChange}
        message={error || message}
        value={value}
        label={label}
        hint={hint}
        name={name || identifier.current}
        ref={innerRef}
        onClear={clearable ? onClear : undefined}
        disabled={disabled}
        preIcon={preIcon}
        postIcon={postIcon}
        className={className}
        type={type}
        formatInputValues={formatInputValues}
        layoutProps={layoutProps}
        placeholder={placeholder}
        datalist={datalist}
        autocomplete={autocomplete}
        list={list}
        inputAttributes={inputAttributes}
      />
    );
  }
);
