import { ReactNode } from "react";

export interface Stringifiable {
  toString(): string;
}

export interface Option<T> {
  value: T;
  text: string | ReactNode;
  disabled?: boolean;
  data?: any;
}

export interface OptionWithId<T> extends Option<T> {
  id: string;
}

export enum Theme {
  LIGHT = "light",
  DARK = "dark",
}

export enum Align {
  ON_LEFT = "onLeft",
  ON_RIGHT = "onRight",
  ON_TOP = "onTop",
  ON_BOTTOM = "onBottom",
}

export enum BreakPoint {
  MOBILE = "mobile",
  LANDSCAPE = "landscape",
  TABLET = "tablet",
  DESKTOP = "desktop",
  DESKTOP_MID = "desktop-mid",
  DESKTOP_PLUS = "desktop-plus",
}
