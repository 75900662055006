import { VALIDATION_STATE } from "../FormContext";
import { BaseValidator } from "./BaseValidator";

export function validate(
  validators: BaseValidator[]
): (value: any) => Promise<void[]> {
  return function fn(value: any) {
    return Promise.all(
      validators.map((validator) =>
        validator.validate(value).then((result) => {
          if (result.status === VALIDATION_STATE.FAILED) {
            return Promise.reject();
          } else {
            return Promise.resolve();
          }
        })
      )
    );
  };
}
