import { API } from "./API";
import { Contract, Country } from "./models/ContractTypes";
import { BASE_PATH } from "./proxy";

export const AllowedCountries = [
  Country.SWEDEN,
  Country.DENMARK,
  Country.FINLAND,
  Country.NORWAY,
];

export interface CommitResponse {
  shouldSign: boolean;
  signed: boolean;
  signUrl?: string;
}

// Förväntar mig fullständig URL inkl domän, domänen inkl schema kommer valideras
//Där du vill ha länk-värdet skriver du in {id} och så kommer BE ersätta det
export interface CommitRequest {
  //Den interna sidan som kunden ska skickas tillbaka till, det kommer läggas på status=.. när den kommer tillbaks
  internalRedirect: string;
  externalSigning: string;
}

export const dataContract = {
  //The user approves of the company details
  commitCompanyDetails: () => API.post(`${BASE_PATH}/company/commit`),

  /**
   * The user approves of the contract details and the signing starts.
   * When you call this one the contract will lock
   */
  commitContractDetails: (request: CommitRequest) =>
    API.post<CommitResponse>(`${BASE_PATH}/confirm`, request),

  getContract: (): Promise<Contract> =>
    API.get<Contract>(`${BASE_PATH}/contract`),

  getContractKey: (): string[] => {
    return ["contract"];
  },

  cancelContract: () => API.post(`${BASE_PATH}/contract/cancel`),

  sendToSales: (
    companyName: string,
    organizationNumber: string,
    //Email or phone is needed
    email?: string,
    phone?: string
  ) =>
    API.post(`${BASE_PATH}/sent-to-sales`, {
      companyName,
      organizationNumber,
      email,
      phone,
    }),

  fetchContract: () => ({
    queryKey: dataContract.getContractKey(),
    queryFn: () => dataContract.getContract(),
  }),

  // [WhitelistedEndpoint.FETCH_COMPANIES]: () => ({
  //   queryKey: ContractClass.getCompanyKey(),
  //   // queryFn: () => ContractClass.getCompanies(),
  // }),
};
