import React, { useRef, useCallback, useMemo } from "react";
import { Wrapper } from "../../../components/Wrapper";
import { useTranslation } from "react-i18next";
import { Owner } from "./Owner";
import { NoOwners } from "./NoOwners";
import {
  MultiForm,
  MultiFormContainer,
} from "../../../modules/Forms/MultiForm";
import { useStoryNavigate } from "../../../hooks/useStoryNavigate";
import { OnboardingPath } from "../routes";
import { dataContract } from "../../../data/dataContract";
import { dataAssociates } from "../../../data/dataAssociates";
import { useMutation, useSuspenseQueries } from "@tanstack/react-query";
import { StoryButtons } from "../../StoryButtons";
import { View } from "../../../modules/View/View";
import { useAsyncInvalidateContract } from "../../../hooks/useContract";
import styles from "./Owners.module.scss";
import { LegalEntityType } from "../../../data/models/ContractTypes";

export const Owners: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { navigate } = useStoryNavigate();
  const formContainer = useRef<MultiFormContainer>();
  const invalidate = useAsyncInvalidateContract();

  const [{ data: contract }, { data: signingGroups }] = useSuspenseQueries({
    queries: [
      dataContract.fetchContract(),
      dataAssociates.fetchSigningCombinations(),
    ],
  });

  const {
    // TODO
    mutate: commit,
    isError, // eslint-disable-line
  } = useMutation({
    mutationFn: () => dataAssociates.commitOwners(),
    onSuccess: async () => {
      await invalidate();

      if (
        contract.companyDetails.legalEntityType ===
        LegalEntityType.SOLE_PROPRIETARY
      ) {
        navigate(OnboardingPath.SUMMARY);
        return;
      }

      if (1 === signingGroups.length) {
        navigate(OnboardingPath.SIGNATORIES);
        return;
      }

      navigate(OnboardingPath.SIGNING_GROUPS);
    },
  });

  const onSave = useCallback(async () => {
    if (!formContainer.current || formContainer.current.isInvalid) {
      formContainer.current?.forceValidation();
      return;
    }

    commit();
  }, [commit]);

  const restOfOwners = useMemo(
    () => contract.beneficialOwners.filter((owner) => !owner.primaryContact),
    [contract]
  );

  const you = useMemo(
    () => contract.beneficialOwners.filter((owner) => owner.primaryContact)[0],
    [contract]
  );

  return (
    <Wrapper>
      <MultiForm multiFormContainer={formContainer}>
        <View header={t("Beneficial owners")} size="small">
          <p>
            {t(
              "Due to anti money laundering regulations, we're required to know more about the ownership structure, please answer the questions below."
            )}
          </p>

          {you ? (
            <>
              <div className="mb-1">
                <span className={styles.hello}>👋</span>
                <b>{t("You")}</b>
              </div>
              <Owner owner={you} />
            </>
          ) : null}

          {/* <hr className="divider" /> */}
          <div className="mt-4" />

          {you && restOfOwners.length ? (
            <div className="mb-1">
              <b>{t("Other beneficial owners")}</b>
            </div>
          ) : null}

          {restOfOwners.map((owner) => (
            <Owner owner={owner} key={owner.id} />
          ))}

          {contract.beneficialOwners.length === 0 ? <NoOwners /> : null}

          <div className="mt-8">
            <StoryButtons action={onSave} />
          </div>
        </View>
      </MultiForm>
    </Wrapper>
  );
};
