import { VALIDATION_STATE } from "../FormContext";
import { ValidationResponse } from "../hooks/useValidation";
import { BaseValidator } from "./BaseValidator";

export class ASCIIValidator extends BaseValidator {
  name = "ASCIIValidator";

  validate(value: string): Promise<ValidationResponse> {
    return new Promise<ValidationResponse>((resolve) => {
      if (/^[A-Za-z0-9\s.,:;'"&@#*\-()[\]+$/€£¥!%~?]*$/.test(value)) {
        resolve({ status: VALIDATION_STATE.SUCCESS });
      } else {
        resolve({
          status: VALIDATION_STATE.FAILED,
          message: this.error,
        });
      }
    });
  }
}
