import { VALIDATION_STATE } from "../FormContext";
import { ValidationResponse } from "../hooks/useValidation";
import { BaseValidator } from "./BaseValidator";

export const MinLengthValidatorName = "MinLengthValidator";

export class MinLengthValidator extends BaseValidator {
  name = MinLengthValidatorName;
  min: number;

  constructor(min: number, error: string) {
    super(error);
    this.min = min;
  }

  get minLength() {
    return this.min;
  }

  validate(value: string): Promise<ValidationResponse> {
    return new Promise<ValidationResponse>((resolve) => {
      if (value.length === 0 || value.length >= this.min) {
        resolve({ status: VALIDATION_STATE.SUCCESS });
      } else {
        resolve({
          status: VALIDATION_STATE.FAILED,
          message: this.error,
        });
      }
    });
  }
}
