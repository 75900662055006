import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../../components/Buttons/Button";
import { Overlay } from "../../../../../components/Overlay/Overlay";
import { dataRisk } from "../../../../../data/dataRisk";
import { Form } from "../../../../../modules/Forms/Form";
import { TextArea } from "../../../../../modules/Forms/TextArea";
import { RequiredValidator } from "../../../../../modules/Forms/validators/RequiredValidator";
import { useContractId } from "../../../hooks/useContractId";
import { useDebouncedStatus } from "../../../hooks/useDebouncedStatus";
import { Status } from "../../../../../modules/Forms/FormContext";

interface Props {
  disabled: boolean;
}

export const RejectCase: React.FC<Props> = ({ disabled }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <div>
      <RejectOverlay isOpen={isOpen} onClose={() => setIsOpen(false)} />
      <Button
        onClick={() => setIsOpen(true)}
        block
        size="small"
        color="error"
        status={disabled ? Status.DISABLED : Status.DEFAULT}
      >
        {t("Reject application")}
      </Button>
    </div>
  );
};

interface OverlayProps {
  isOpen: boolean;
  onClose: () => void;
}

const RejectOverlay: React.FC<OverlayProps> = ({ isOpen, onClose }) => {
  return (
    <Overlay open={isOpen} onClose={onClose} local={false}>
      <Inner {...{ onClose }} />
    </Overlay>
  );
};

const Inner: React.FC<Pick<OverlayProps, "onClose">> = ({ onClose }) => {
  const { t } = useTranslation();
  const contractId = useContractId();
  const [comment, setComment] = useState<string>("");

  const { mutate: handleReject, status: mutationStatus } = useMutation(
    dataRisk.rejectCase(contractId)
  );

  const debouncedStatus = useDebouncedStatus(mutationStatus);

  return (
    <div>
      <h3 className="mb-2 mt-0">{t("Reject application")}</h3>

      <Form
        onSubmit={(_, form) => {
          if (form.isInvalid) {
            return;
          }

          handleReject(comment, {
            onSuccess: onClose,
          });
        }}
        className="flex-column gap-2"
      >
        <TextArea
          name="comment"
          label={t("Final comment")}
          value={comment}
          onChange={setComment}
          validators={[new RequiredValidator(t("This field is required"))]}
          attributes={{ rows: 4 }}
        />

        <div className="flex gap-2">
          <Button onClick={onClose} block variant="outlined" className="mt-1">
            {t("Cancel")}
          </Button>
          <Button
            type="submit"
            block
            color="error"
            className="mt-1"
            status={debouncedStatus}
          >
            {t("Save & reject")}
          </Button>
        </div>
      </Form>
    </div>
  );
};
