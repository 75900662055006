import React, { useCallback, useRef, useMemo } from "react";
import styles from "./Signatories.module.scss";
import { Wrapper } from "../../../components/Wrapper";
import { useTranslation } from "react-i18next";
import {
  MultiForm,
  MultiFormContainer,
} from "../../../modules/Forms/MultiForm";
import { useStoryNavigate } from "../../../hooks/useStoryNavigate";
import { useContract, useInvalidateContract } from "../../../hooks/useContract";
import { Signatory } from "./Signatory";
import { OnboardingPath } from "../routes";
import { View } from "../../../modules/View/View";
import { StoryButtons } from "../../StoryButtons";
import { useMutation } from "@tanstack/react-query";
import { dataAssociates } from "../../../data/dataAssociates";

export const Signatories: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const contract = useContract();
  const multiFormContainer = useRef<MultiFormContainer>();
  const { navigate } = useStoryNavigate();
  const invalidate = useInvalidateContract();

  const {
    // TODO
    mutate: commit,
    isError, // eslint-disable-line
  } = useMutation({
    mutationFn: () => dataAssociates.commitSignees(),
    onSuccess: () => {
      invalidate();
      navigate(OnboardingPath.SUMMARY);
    },
  });

  const onSave = useCallback(async () => {
    if (!multiFormContainer.current || multiFormContainer.current.isInvalid) {
      multiFormContainer.current?.forceValidation();
      return;
    }

    commit();
  }, [commit]);

  const restOfSignatories = useMemo(
    () => contract.selectedSignatories.filter((signee) => !signee.primary),
    [contract]
  );

  const you = useMemo(
    () => contract.selectedSignatories.filter((signee) => signee.primary)[0],
    [contract]
  );

  return (
    <Wrapper>
      <MultiForm multiFormContainer={multiFormContainer}>
        <View
          header={
            contract.selectedSignatories.length > 1
              ? t("Selected signatories")
              : t("Selected signatory")
          }
          size="small"
        >
          <p>
            {t(
              "Review and complete any missing information about the signatories."
            )}
          </p>

          {you ? (
            <>
              <div className="mb-1">
                <span className={styles.hello}>👋</span>
                <b>{t("You")}</b>
              </div>
              <Signatory signatory={you} />
            </>
          ) : null}

          {/* <hr className="divider" /> */}
          <div className="mt-4" />

          {you && restOfSignatories.length ? (
            <div className="mb-1">
              <b>{t("Other signatories")}</b>
            </div>
          ) : null}

          {restOfSignatories.map((signee) => {
            return <Signatory key={signee.id} signatory={signee} />;
          })}

          <div className="mt-8">
            <StoryButtons action={onSave} />
          </div>
        </View>
      </MultiForm>
    </Wrapper>
  );
};
