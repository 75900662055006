import React, { useCallback, useRef, useEffect } from "react";
import styles from "./Button.module.scss";
import { AnimatePresence, motion } from "framer-motion";
import { FaCheck, FaTriangleExclamation, FaBan } from "react-icons/fa6";
import clsx from "classnames";
import { Status } from "../../modules/Forms/FormContext";
import { Spinner } from "../Spinner/Spinner";

export interface BaseButtonProps {
  children: React.ReactNode;
  className?: string;
  data?: any;
  block?: boolean;
  variant?: "primary" | "outlined" | "text" | "inline" | "selected";
  status?: Status;
  color?: "default" | "error" | "warning" | "success";
  action?: boolean;
  tabIndex?: number;
  throttle?: boolean;
  size?: "default" | "mini" | "small";
  noLayout?: boolean;
  noStatusIcon?: boolean;
}

export interface ButtonProps extends BaseButtonProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement>, data: any) => void;
  type?: "button" | "reset";
  value?: never;
}

interface SubmitProps extends BaseButtonProps {
  onClick?: never;
  type: "submit";
  value?: string | number;
}

function isSubmit(tested: ButtonProps | SubmitProps): tested is SubmitProps {
  return (tested as unknown as SubmitProps).type === "submit";
}

export const Button = React.forwardRef<
  HTMLButtonElement,
  ButtonProps | SubmitProps
>((props, ref) => {
  const {
    children,
    className,
    data,
    block = false,
    variant = "primary",
    status = Status.DEFAULT,
    color = "default",
    size = "default",
    action = false,
    tabIndex = 0,
    value,
    throttle = true,
    noLayout = false,
    noStatusIcon = false,
  } = props;

  let onClick: (data: any, event: React.MouseEvent<HTMLButtonElement>) => void;
  let type: "button" | "reset" | "submit";

  if (isSubmit(props)) {
    onClick = () => {};
    ({ type } = props);
  } else {
    ({ onClick, type = "button" } = props);
  }

  const wasClicked = useRef<boolean>(false);
  const hasUnmounted = useRef<boolean>(false);

  useEffect(() => {
    hasUnmounted.current = false;

    return () => {
      hasUnmounted.current = true;
    };
  }, []);

  const onButtonClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (wasClicked.current) {
        return;
      }

      onClick?.(event, data);
      if (throttle) {
        wasClicked.current = true;

        setTimeout(() => {
          if (!hasUnmounted.current) {
            wasClicked.current = false;
          }
        }, 300);
      }
    },
    [onClick, data, throttle]
  );

  let icon = null;
  if (!action && !noStatusIcon) {
    icon = getButtonIcon(status);
  }

  return (
    <motion.button
      className={clsx(
        styles.button,
        className,
        styles[status],
        styles[variant],
        styles["color-" + color],
        styles["size-" + size],
        {
          [styles.block]: block,
          [styles.action]: action,
        }
      )}
      disabled={status !== Status.DEFAULT}
      onClick={onButtonClick}
      whileTap={{ scale: block ? 0.975 : 0.95 }}
      animate={{
        scale: status === Status.PENDING ? (block ? 0.975 : 0.95) : undefined,
      }}
      style={{
        transformOrigin: "center",
      }}
      transition={{ type: "spring", stiffness: 500, damping: 20 }}
      {...{ tabIndex, type, ref, value }}
      layoutRoot
      layout={!noLayout}
    >
      <div className={styles.content}>{children}</div>
      <AnimatePresence initial={false} mode="wait">
        {icon && (
          <motion.div
            className={styles.icon}
            key={icon.type}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            layout
          >
            {icon}
          </motion.div>
        )}
      </AnimatePresence>
    </motion.button>
  );
});

export function getButtonIcon(status: Status) {
  if (status === Status.ERROR) {
    return <FaTriangleExclamation />;
  }

  if (status === Status.SUCCESS) {
    return <FaCheck />;
  }

  if (status === Status.PENDING) {
    return <Spinner size={28} />;
  }

  if (status === Status.DISABLED) {
    return <FaBan />;
  }

  return null;
}

export const MotionButton = motion.create(Button);
