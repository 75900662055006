import { useMutation, useSuspenseQuery } from "@tanstack/react-query";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { Wrapper } from "../../../components/Wrapper";
import { dataSupplement } from "../../../data/dataSupplements";
import { BankSupplementPath } from "../routes";
import { BANK_SUPPLEMENT_STORY_BASE_ROUTE } from "../BankSupplementStory";
import { ContractId, Validity } from "../../../data/models/ContractTypes";
import { useDebouncedStatus } from "../../../pages/backoffice/hooks/useDebouncedStatus";
import { Button } from "../../../components/Buttons/Button";
import { Form } from "../../../modules/Forms/Form";
import Kontonummer from "kontonummer";
import { Columns } from "../../../components/Columns/Columns";
import { TextInput } from "../../../modules/Forms/TextInput";
import { Status } from "../../../modules/Forms/FormContext";
import { RequiredValidator } from "../../../modules/Forms/validators/RequiredValidator";
import { BankValidatorMinLength } from "../../../modules/Forms/validators/BankValidatorMinLength";
import { BankValidatorMaxLength } from "../../../modules/Forms/validators/BankValidatorMaxLength";
import { Dynamic } from "../../../components/Animations/Dynamic";
import { Beacon } from "../../../components/Beacon/Beacon";
import styles from "./AnswerStep.module.scss";
import { Suspense, SuspenseRetry } from "../../../pages/Suspense";
import { ServerError } from "../../../data/API";

export const AnswerStep: React.FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Wrapper size="mini">
        <Suspense
          fallback={(errorData) => {
            if (
              errorData.error instanceof ServerError &&
              errorData.error.status === 404
            ) {
              return (
                <div>
                  <h2>{t("Nothing to see here...")}</h2>
                  <div>
                    {t(
                      "If you have already submitted your bank account, you can ignore this, otherwise please check the link in the email we sent you."
                    )}
                  </div>
                </div>
              );
            }
            return <SuspenseRetry {...errorData} />;
          }}
        >
          <Inner />
        </Suspense>
      </Wrapper>
    </div>
  );
};

const Inner: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: ContractId }>();
  const { t } = useTranslation();
  const [bankAccount, setBankAccount] = useState<string>("");
  const [clearing, setClearing] = useState<string>("");
  const [info, setInfo] = useState<Kontonummer | null>(null);

  const { data } = useSuspenseQuery(dataSupplement.getBankSupplement(id!));

  const showBankName = useMemo(() => {
    return (
      !!info &&
      cleanNumber(clearing).length + cleanNumber(bankAccount).length >=
        MIN_LENGTH_CLEARING + MIN_LENGTH_ACCOUNT
    );
  }, [info, clearing, bankAccount]);

  const { mutate, status: mutationStatus } = useMutation({
    ...dataSupplement.saveBankSupplement(id!),
    onSuccess: () => {
      navigate(
        generatePath(
          BANK_SUPPLEMENT_STORY_BASE_ROUTE + BankSupplementPath.COMPLETE,
          { id }
        ),
        { replace: true }
      );
    },
  });

  const status = useDebouncedStatus(mutationStatus);

  const { companyName, name } = data;

  return (
    <>
      <h2>
        {t("Hello {{name}}!", {
          name,
        })}
      </h2>
      <p className="passive">
        {t(
          "We could not verify the bank account you provided us with for {{companyName}}. The account must be registered under the company's name. Please provide the bank account where you wish to receive your payouts.",
          {
            companyName,
          }
        )}
      </p>
      <Form
        onSubmit={(_, form) => {
          if (form.isInvalid) {
            return;
          }

          mutate({
            bankAccount: cleanNumber(clearing) + cleanNumber(bankAccount),
          });
        }}
      >
        <Columns className="mt-4">
          <div>
            <TextInput
              name="clearing"
              label={t("Clearing number")}
              value={clearing}
              disabled={status === Status.PENDING}
              validators={[
                new RequiredValidator("Required"),
                new BankValidatorMinLength(
                  MIN_LENGTH_CLEARING,
                  t("At least {{min}} digits are required", {
                    min: MIN_LENGTH_CLEARING,
                  })
                ),
                new BankValidatorMaxLength(
                  MAX_LENGTH_CLEARING,
                  t("Not more than {{max}} digits are allowed", {
                    max: MAX_LENGTH_CLEARING,
                  })
                ),
              ]}
              onChange={(value) => {
                // reset();
                setClearing(value);
                // setHasShowedWarning(false);
                try {
                  const info = Kontonummer.parse(value, bankAccount || "", {
                    mode: "strict",
                  });
                  setInfo(info);
                } catch (err) {
                  setInfo(null);
                }
              }}
              inputAttributes={{
                inputMode: "numeric",
              }}
            />
          </div>
          <div>
            <TextInput
              name="bankAccount"
              label={t("Account number")}
              value={bankAccount}
              disabled={status === Status.PENDING}
              validators={[
                new RequiredValidator(t("Required")),
                new BankValidatorMinLength(
                  MIN_LENGTH_ACCOUNT,
                  t("At least {{min}} digits are required", {
                    min: MIN_LENGTH_ACCOUNT,
                  })
                ),
                new BankValidatorMaxLength(
                  MAX_LENGTH_ACCOUNT,
                  t("Not more than {{max}} digits are allowed", {
                    max: MAX_LENGTH_ACCOUNT,
                  })
                ),
              ]}
              onChange={(value) => {
                // reset();
                setBankAccount(value);
                // setHasShowedWarning(false);
                try {
                  const info = Kontonummer.parse(clearing, value, {
                    mode: "strict",
                  });
                  setInfo(info);
                } catch (err) {
                  setInfo(null);
                }
              }}
              inputAttributes={{
                inputMode: "numeric",
              }}
            />
          </div>
        </Columns>
        <Dynamic name={showBankName ? "account" : ""}>
          {info && showBankName ? (
            <div className={styles.info}>
              <Beacon className="mini" validity={Validity.VALID} /> &nbsp;&nbsp;
              <b>{info.bankName}</b> - {`${info.format("pretty")} `}
            </div>
          ) : null}
        </Dynamic>
        <Button type="submit" status={status} block className="mt-3">
          {t("Submit information")}
        </Button>
      </Form>
    </>
  );
};

const MIN_LENGTH_ACCOUNT = 7;
const MAX_LENGTH_ACCOUNT = 10;
const MIN_LENGTH_CLEARING = 4;
const MAX_LENGTH_CLEARING = 5;

function cleanNumber(numberAsString?: string) {
  if (!numberAsString) {
    return "";
  }

  return numberAsString.replace(/\D/g, "");
}
