import { Beacon } from "../../../../../components/Beacon/Beacon";
import { Validity } from "../../../../../data/models/ContractTypes";
import { Checkbox } from "../../../../../modules/Forms/Checkbox";
import { CaseTab, useRiskCaseTab } from "../../../hooks/useRiskCaseTab";
import { CaseAnchor } from "../../BackofficeCasePage";
import styles from "./ChecklistItem.module.scss";
import cx from "classnames";

interface ChecklistItemProps {
  validity: Validity;
  title: string;
  description?: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  disabled: boolean;
  tab?: CaseTab;
  anchor?: CaseAnchor;
}

export const ChecklistItem: React.FC<ChecklistItemProps> = ({
  validity,
  title,
  checked,
  onChange,
  disabled,
  tab,
  anchor,
}) => {
  const [_, setCurrentTab] = useRiskCaseTab();

  return (
    <div className="flex-column gap-1">
      <div className={styles.header}>
        <Beacon validity={validity} />
        <span
          className={cx(styles.title, {
            [styles.isLink]: tab,
          })}
          onClick={() => {
            if (!tab) return;
            setCurrentTab(tab, anchor);
          }}
        >
          {title}
        </span>
        <label htmlFor={title}>
          <input
            id={title}
            name={title}
            type="checkbox"
            onChange={() => onChange(!checked)}
            checked={checked}
            disabled={disabled}
          />
          <Checkbox
            checked={checked}
            className={styles.checkbox}
            isDisabled={disabled}
            size="small"
          />
        </label>
      </div>
      {/* {description && (
        <span className={cx(styles.description, "passive", "text-09")}>
          {description}
        </span>
      )} */}
    </div>
  );
};
