import React, { useCallback, useRef } from "react";
import { Wrapper } from "../../../components/Wrapper";
import { useTranslation } from "react-i18next";
import { OnboardingPath } from "../routes";
import { SalesLocation } from "./SalesLocation";
import { useStoryNavigate } from "../../../hooks/useStoryNavigate";
import {
  MultiForm,
  MultiFormContainer,
} from "../../../modules/Forms/MultiForm";
import { StoryButtons } from "../../StoryButtons";
import { View } from "../../../modules/View/View";
import { useContract } from "../../../hooks/useContract";

export const SalesLocations: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { navigate } = useStoryNavigate();
  const multiFormContainer = useRef<MultiFormContainer>();

  const contract = useContract();

  const onSave = useCallback(async () => {
    if (!multiFormContainer.current || multiFormContainer.current.isInvalid) {
      multiFormContainer.current?.forceValidation();
      return;
    }

    navigate(OnboardingPath.MCC);
  }, [navigate]);

  return (
    <Wrapper>
      <MultiForm multiFormContainer={multiFormContainer}>
        <View header={t("Sales location")}>
          <p>
            {t(
              "Enter information about your sales location, the information will be displayed on receipts and is also where the terminal(s) will be shipped."
            )}
          </p>
          <div>
            {contract.locations.map((store) => {
              return (
                <SalesLocation
                  store={store}
                  key={store.id}
                  country={contract.country}
                />
              );
            })}
          </div>

          <div className="mt-4">
            <StoryButtons action={onSave} />
          </div>
        </View>
      </MultiForm>
    </Wrapper>
  );
};
