import React from "react";

interface Props {
  fill?: string;
}

export const NBankIdLogo: React.FunctionComponent<Props> = ({
  fill = "#39134C",
}) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 140 21"
    >
      <g>
        <g>
          <path
            style={{ fill }}
            d="M7.5,3h-6C0.7,3,0,2.3,0,1.5v0C0,0.7,0.7,0,1.5,0l6,0C8.3,0,9,0.7,9,1.5v0C9,2.3,8.3,3,7.5,3z"
          />
          <path
            style={{ fill }}
            d="M7.5,15h-6C0.7,15,0,14.3,0,13.5v0C0,12.7,0.7,12,1.5,12h6C8.3,12,9,12.7,9,13.5v0C9,14.3,8.3,15,7.5,15z"
          />
          <path
            style={{ fill }}
            d="M7.5,21h-6C0.7,21,0,20.3,0,19.5v0C0,18.7,0.7,18,1.5,18h6C8.3,18,9,18.7,9,19.5v0C9,20.3,8.3,21,7.5,21z"
          />
          <path
            style={{ fill }}
            d="M19.5,9h-6C12.7,9,12,8.3,12,7.5v0C12,6.7,12.7,6,13.5,6h6C20.3,6,21,6.7,21,7.5v0C21,8.3,20.3,9,19.5,9z"
          />
          <path
            style={{ fill }}
            d="M19.5,15h-6c-0.8,0-1.5-0.7-1.5-1.5v0c0-0.8,0.7-1.5,1.5-1.5h6c0.8,0,1.5,0.7,1.5,1.5v0
          C21,14.3,20.3,15,19.5,15z"
          />
          <path
            style={{ fill }}
            d="M31.5,3h-6C24.7,3,24,2.3,24,1.5v0C24,0.7,24.7,0,25.5,0l6,0C32.3,0,33,0.7,33,1.5v0C33,2.3,32.3,3,31.5,3z"
          />
          <path
            style={{ fill }}
            d="M31.5,9h-6C24.7,9,24,8.3,24,7.5v0C24,6.7,24.7,6,25.5,6h6C32.3,6,33,6.7,33,7.5v0C33,8.3,32.3,9,31.5,9z"
          />
          <path
            style={{ fill }}
            d="M31.5,21h-6c-0.8,0-1.5-0.7-1.5-1.5v0c0-0.8,0.7-1.5,1.5-1.5h6c0.8,0,1.5,0.7,1.5,1.5v0
          C33,20.3,32.3,21,31.5,21z"
          />
        </g>
        <g>
          <path
            style={{ fill }}
            d="M45.8,13.1c0,3.2,1.8,4.9,3.9,4.9c1.9,0,4-1.6,4-4.9c0-3.3-2-4.8-3.9-4.8C47.7,8.3,45.8,9.8,45.8,13.1
           M45.8,0v7.8C46.7,6.4,48.3,5,51,5c3,0,6.6,2.5,6.6,8c0,5.7-3.4,8.5-6.9,8.5c-2,0-3.7-0.9-4.8-2.7V21H42V0H45.8z"
          />
          <path
            style={{ fill }}
            d="M63.3,13.2c0,3.3,2,4.8,3.9,4.8c2.1,0,3.9-1.4,3.9-4.8c0-3.3-1.8-4.9-3.9-4.9C65.4,8.3,63.3,9.8,63.3,13.2
           M59.5,13c0-5.4,3.5-8.1,7-8.1c1.9,0,3.5,0.7,4.7,2.4v-2H75V21h-3.8v-2.2c-1.1,1.5-2.7,2.6-5.2,2.6C63,21.4,59.5,18.8,59.5,13"
          />
          <path
            style={{ fill }}
            d="M82.5,12.1V21h-3.8V5.4h3.8v2.3c1.1-2,3.1-2.7,4.8-2.7c2.7,0,5.3,1.4,5.3,5.6V21h-3.8v-9c0-2.6-1.3-3.6-3-3.6
          C83.9,8.5,82.5,9.6,82.5,12.1"
          />
          <polygon
            style={{ fill }}
            points="96.2,0 96.2,21 100,21 100,17.2 102.1,14.6 106.4,21 110.6,21 104.4,11.9 109.8,5.4 105.5,5.4 
          100,12.6 100,0 		"
          />
          <rect x="114.3" y="0.7" style={{ fill }} width="3.9" height="20.3" />
          <path
            style={{ fill }}
            d="M129.5,0.7h-7.4V21h7.4c5.5,0,10.1-3.3,10.1-10.2C139.6,4,135,0.7,129.5,0.7z M129.5,17.4H126V4.3h3.5
          c3.2,0,5.9,2.1,5.9,6.6C135.4,15.3,132.7,17.4,129.5,17.4z"
          />
        </g>
      </g>
    </svg>
  );
};
