import React, { useCallback } from "react";
import { InputProps } from "./Input";
import { InputWrapper } from "./InputWrapper";
import styles from "./Area.module.scss";

export interface AreaProps
  extends Omit<
    InputProps,
    "list" | "type" | "onChange" | "onBlur" | "datalist"
  > {
  onChange: (
    value: string,
    name: string,
    ev:
      | React.ChangeEvent<HTMLTextAreaElement>
      | React.MouseEvent<HTMLButtonElement>
  ) => void;
  onBlur?: (
    value: string,
    name: string,
    ev: React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
  attributes?: React.TextareaHTMLAttributes<HTMLTextAreaElement>;
}

export const Area = React.forwardRef<HTMLTextAreaElement, AreaProps>(
  (props, ref) => {
    const {
      value,
      name,
      onChange,
      onBlur,
      placeholder,
      disabled,
      constraints,
      autocomplete,
      attributes,
    } = props;

    const change = useCallback(
      (ev: React.ChangeEvent<HTMLTextAreaElement>) =>
        onChange(ev.target.value, name, ev),
      [name, onChange]
    );

    const blur = useCallback(
      (ev: React.ChangeEvent<HTMLTextAreaElement>) =>
        onBlur && onBlur(ev.target.value, name, ev),
      [name, onBlur]
    );

    return (
      <InputWrapper {...props}>
        <textarea
          className={styles.input}
          name={name}
          value={value}
          onChange={change}
          onBlur={blur}
          ref={ref}
          autoComplete={autocomplete}
          placeholder={placeholder}
          required={constraints.isRequired}
          disabled={disabled}
          {...attributes}
        />
      </InputWrapper>
    );
  }
);
