import React, { ReactNode } from "react";
import { Wrapper } from "../components/Wrapper";

interface Props {
  children: ReactNode;
}

// export const Page: React.FunctionComponent<Props> = () => {
//   return (
//     <div>
//       <div id={OVERLAY_PORTAL_ID} />
//       <header></header>
//       <main>
//         <Outlet />
//       </main>
//       <footer></footer>
//     </div>
//   );
// };

export const Page: React.FunctionComponent<Props> = ({ children }) => {
  return (
    <div>
      <header></header>
      <main>
        <Wrapper>{children}</Wrapper>
      </main>
      <footer></footer>
    </div>
  );
};
