import { useSuspenseQuery } from "@tanstack/react-query";
import { useState, useTransition } from "react";
import Skeleton from "react-loading-skeleton";
import { dataRisk, RiskClosedItem } from "../../../data/dataRisk";
import { SortDirection } from "../types/ResponsePage";
import { ClosedCaseItem } from "./ClosedCaseItem/ClosedCaseItem";
import styles from "./ClosedCaseList.module.scss";
import { TablePagination } from "./TablePagination";

export const ClosedCaseList: React.FunctionComponent = () => {
  const [page, setPage] = useState(0);
  const [size] = useState(10);
  const [direction] = useState<SortDirection>(SortDirection.ASC);
  const [field] = useState<keyof RiskClosedItem>("created");
  const [isPendingTransition, startTransition] = useTransition();

  const { data } = useSuspenseQuery(
    dataRisk.fetchClosedCases({
      page: page,
      size: size,
      direction,
      field,
    })
  );

  const handlePageChange = (value: number) => {
    startTransition(() => {
      setPage(value);
    });
  };

  if (data.totalElements === 0) {
    return <div className="text-center">No closed cases found</div>;
  }

  return (
    <>
      <ul className={styles.caseList}>
        {data.content?.map((item) => (
          <ClosedCaseItem key={item.id} item={item} />
        ))}
      </ul>

      <div className={styles.pagination}>
        <TablePagination
          onPageChange={(page) => handlePageChange(page)}
          activePage={data.number}
          totalPages={data.totalPages}
          alwaysShow
          isLoading={isPendingTransition}
        />
      </div>
    </>
  );
};

export const ClosedCaseListSkeleton = () => {
  return (
    <div className="flex-column gap-1">
      {Array.from({ length: 10 }).map((_, index) => (
        <Skeleton height={70} key={index} />
      ))}
    </div>
  );
};
