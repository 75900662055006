import React, { useContext, useRef } from "react";
import cx from "classnames";
import styles from "./NavigationStoryHeader.module.scss";
import { Wrapper } from "../../Wrapper";
import { useTranslation } from "react-i18next";
import { ProgressBar } from "../../Progress/ProgressBar";
import { FaGlobe } from "react-icons/fa6";
import { Button } from "../../Buttons/Button";
import { NavigationStoryContext } from "./NavigationStory";
import {
  Popover,
  PopoverContent,
  PopoverRef,
  PopoverTrigger,
} from "../../Popover/Popover";
import { getLanguageDisplayName } from "../../langUtils";
import { Language } from "../../../data/models/ContractTypes";
import { useMutation } from "@tanstack/react-query";
import { dataContact } from "../../../data/dataContact";

interface Props {
  numberOfRoutes: number;
  currentRouteIndex: number;
  name: string;
  saveLanguage?: boolean;
}

const EnabledLanguages = [
  Language.ENGLISH,
  Language.SWEDISH,
  Language.DANISH,
  Language.FINNISH,
  Language.NORWEGIAN,
  Language.ARABIC,
];

export const NavigationStoryHeader: React.FunctionComponent<Props> = ({
  numberOfRoutes,
  currentRouteIndex,
  saveLanguage: shouldSaveLanguage = false,
}) => {
  const { i18n, t } = useTranslation();
  const { filteredRoutes } = useContext(NavigationStoryContext);
  const hideHeader = filteredRoutes[currentRouteIndex]?.hideProgress;
  const languagePopoverRef = useRef<PopoverRef>(null);

  const { mutate: saveLanguage } = useMutation(dataContact.saveLanguage());

  return (
    <header
      className={cx(styles.header, {
        [styles.hideHeader]: hideHeader,
      })}
    >
      <div dir="ltr">
        <Wrapper className={styles.wrapper}>
          <div className={styles.inner}>
            <div className={styles.logo}>
              <img src="/images/worldline-logo.svg" alt="Worldline logo" />
            </div>
            <div className={styles.rightItems}>
              <div>
                <Popover ref={languagePopoverRef}>
                  <PopoverTrigger>
                    <Button
                      variant="text"
                      onClick={(e) => {
                        e.stopPropagation();
                        languagePopoverRef.current?.toggle();
                      }}
                      size="mini"
                    >
                      <div>
                        <div className="flex gap-1 align-center">
                          <span>{t("Language")}</span> <FaGlobe size={18} />
                        </div>
                      </div>
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent>
                    <div className="flex-column gap-1 p-2">
                      {EnabledLanguages.map((lang) => (
                        <Button
                          onClick={() => {
                            i18n.changeLanguage(lang);
                            languagePopoverRef.current?.close();
                            shouldSaveLanguage && saveLanguage(lang);
                          }}
                          variant={i18n.language === lang ? "primary" : "text"}
                          size="small"
                          block
                          className={styles.languageButton}
                          key={lang}
                        >
                          {getLanguageDisplayName(lang, lang)}
                        </Button>
                      ))}
                    </div>
                  </PopoverContent>
                </Popover>
              </div>
            </div>
          </div>
        </Wrapper>
      </div>
      <section>
        <Wrapper className={styles.progress}>
          <ProgressBar
            timer={500}
            progress={100 * ((currentRouteIndex + 1) / numberOfRoutes)}
          />
        </Wrapper>
      </section>
    </header>
  );
};
