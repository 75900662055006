import React, { FunctionComponent, Suspense, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { ErrorBoundary } from "@sentry/react";
import { useQueryErrorResetBoundary } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import { Wrapper } from "../components/Wrapper";
import { isDev, UNAUTHORIZED_STATUS } from "..";
import { useTranslation } from "react-i18next";
import { ServerError } from "../data/API";
import { Identification } from "../stories/Onboarding/SignIn/Identification";
import { Button } from "../components/Buttons/Button";

interface Props {
  skeleton?: React.ReactNode;
  children: React.ReactNode;
}

export const AuthenticatedSuspense: React.FunctionComponent<Props> = ({
  skeleton,
  children,
}) => {
  const location = useLocation();
  const { reset } = useQueryErrorResetBoundary();

  return (
    <div>
      <ResetBoundary reset={reset} />
      <ErrorBoundary
        key={location.pathname}
        onReset={reset}
        fallback={({ error, resetError }) => {
          if ((error as ServerError<any>).status === UNAUTHORIZED_STATUS) {
            return (
              <Identification resetQuery={resetError} resetBoundary={reset} />
            );
          }

          return <Retry error={error} reset={reset} retry={resetError} />;
        }}
      >
        <Suspense
          fallback={
            skeleton || (
              <Wrapper>
                <div
                  style={{
                    maxWidth: "480px",
                    margin: "0 auto",
                  }}
                >
                  <h1>
                    <Skeleton />
                  </h1>
                  <div>
                    <Skeleton count={5} height={20} className="mt-2" />
                  </div>
                </div>
              </Wrapper>
            )
          }
        >
          {children}
        </Suspense>
      </ErrorBoundary>
    </div>
  );
};

const Retry: FunctionComponent<{
  retry: () => void;
  reset: () => void;
  error: any;
}> = ({ retry, error, reset }) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <h3>{t("Oops, something went wrong")}</h3>
      <p>{t("Please try again...")}</p>
      {isDev() && (
        <pre
          style={{
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
          }}
        >
          {(error as any)?.stack}
        </pre>
      )}

      <Button
        onClick={() => {
          reset();
          retry();
        }}
      >
        {t("Try again")}
      </Button>
    </Wrapper>
  );
};

const ResetBoundary: FunctionComponent<{ reset: () => void }> = ({ reset }) => {
  const location = useLocation();

  // Reset the error queries on page change
  // e.g. going back in story instead of pressing retry
  useEffect(() => {
    reset();
  }, [location.pathname, reset]);

  return null;
};
