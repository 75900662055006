import { useId, useMemo, useRef, useState } from "react";
import { Button } from "../../components/Buttons/Button";
import { Status } from "./FormContext";
import { useForm } from "./hooks/useForm";
import { InputProps } from "./Input";
import { BaseValidator } from "./validators/BaseValidator";
import { isDefined } from "./TextInput";
import { useValidation } from "./hooks/useValidation";
import styles from "./QuantityInput.module.scss";
import inputStyles from "./Input.module.scss";
import cx from "classnames";
import { FaMinus, FaPlus } from "react-icons/fa6";

interface Props extends Pick<InputProps, "name" | "hint" | "disabled"> {
  value: number;
  onChange: (value: number, name: string) => void;
  validators?: BaseValidator[];
  min?: number;
  max?: number;
  fullWidth?: boolean;
  hideMessages?: boolean;
}

export const QuantityInput: React.FunctionComponent<Props> = ({
  name,
  value,
  onChange,
  validators = [],
  min,
  max,
  hint,
  disabled,
  fullWidth,
  hideMessages,
}) => {
  const innerRef = useRef<HTMLDivElement>(null);
  const identifier = useId();
  const [showErrors] = useState<boolean>(isDefined(value));

  const [validity, errorMessages, showFormErrors] = useValidation(
    value,
    validators
  );

  useForm(identifier, validity, value, () => {}, innerRef);

  const error = useMemo(() => {
    if (errorMessages.length > 0 && (showErrors || showFormErrors)) {
      return errorMessages[0];
    }

    return;
  }, [errorMessages, showErrors, showFormErrors]);

  return (
    <div
      ref={innerRef}
      className={cx(styles.wrapper, {
        [styles.fullWidth]: fullWidth,
      })}
    >
      <div className={styles.quantityWrapper}>
        <Button
          variant="text"
          onClick={() => onChange(Math.max(min || 0, value - 1), name)}
          status={
            (typeof min !== "undefined" && value <= min) || disabled
              ? Status.DISABLED
              : Status.DEFAULT
          }
          throttle={false}
          noStatusIcon
          className={styles.button}
        >
          <FaMinus />
        </Button>
        <span className={styles.value}>{value}</span>
        <Button
          variant="text"
          onClick={() => onChange(Math.min(max || Infinity, value + 1), name)}
          status={
            (max && value >= max) || disabled ? Status.DISABLED : Status.DEFAULT
          }
          throttle={false}
          noStatusIcon
          className={styles.button}
        >
          <FaPlus />
        </Button>
      </div>
      <div
        className={cx(inputStyles.messages, {
          [styles.hideMessages]: hideMessages,
        })}
      >
        <div className={inputStyles.hint}>{hint}</div>
        <div className={inputStyles.error}>{error}</div>
      </div>
    </div>
  );
};
