import React, {
  ReactNode,
  FC,
  useRef,
  useState,
  ReactElement,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from "react";
import { PopoverBody } from "./PopoverBody";
import { createPortal } from "react-dom";
import styles from "./Popover.module.scss";

export interface PopoverRef {
  close: () => void;
  open: () => void;
  toggle: () => void;
}

interface Props {
  children: [
    ReactElement<typeof PopoverTrigger>,
    ReactElement<typeof PopoverContent>
  ];
  bordered?: boolean;
  disableShadows?: boolean;
}

const popoverPortalEl = document.getElementById("popover-portal");

export const Popover = forwardRef<PopoverRef, Props>(
  ({ children, bordered, disableShadows }, ref) => {
    const [contentOpen, setContentOpen] = useState(false);
    const triggerRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(
      ref,
      () => ({
        close: () => setContentOpen(false),
        open: () => setContentOpen(true),
        toggle: () => setContentOpen((prev) => !prev),
      }),
      []
    );

    let triggerComponent: ReactNode;
    let contentComponent: ReactNode;

    React.Children.forEach(children, (child) => {
      if (React.isValidElement(child)) {
        if (child.type === PopoverTrigger) {
          triggerComponent = child;
        } else if (child.type === PopoverContent) {
          contentComponent = child;
        } else {
          throw new Error(
            "Popover component can only have PopoverTrigger and PopoverContent components as children"
          );
        }
      }
    });

    const handleTriggerClick = useCallback(() => {
      if (!triggerRef.current) return;
      setContentOpen(true);
    }, []);

    return (
      <>
        <div onClick={handleTriggerClick} ref={triggerRef}>
          {triggerComponent}
        </div>
        {popoverPortalEl &&
          createPortal(
            <>
              {contentOpen && (
                <div
                  className={styles.backdrop}
                  onClick={() => setContentOpen(false)}
                />
              )}
              <PopoverBody
                bordered={bordered}
                disableShadows={disableShadows}
                open={contentOpen}
                triggerRef={triggerRef}
              >
                {contentComponent}
              </PopoverBody>
            </>,
            popoverPortalEl
          )}
      </>
    );
  }
);

export const PopoverTrigger: FC<{ children: ReactNode }> = ({ children }) => {
  return <div>{children}</div>;
};

export const PopoverContent: FC<{ children: ReactNode }> = ({ children }) => {
  return <div>{children}</div>;
};
