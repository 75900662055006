import React, { ReactNode } from "react";
import cx from "classnames";
import styles from "./Columns.module.scss";
import { BreakPoint } from "../types";

interface Props {
  breakPoint?: BreakPoint;
  children?: ReactNode;
  padding?: boolean;
  className?: string;
  columns?: number;
  verticalCentered?: boolean;
}

export const Columns: React.FunctionComponent<Props> = ({
  breakPoint = BreakPoint.TABLET,
  children = null,
  padding = true,
  columns = 2,
  verticalCentered = false,
  className,
}) => {
  return (
    <div
      className={cx(
        styles.columns,
        className,
        styles[breakPoint],
        styles[`columns-${columns}`],
        {
          [styles.padding]: padding,
          [styles.verticalCentered]: verticalCentered,
        }
      )}
    >
      {children}
    </div>
  );
};
