import { ReactNode, FC, useEffect, useRef, useState, RefObject } from "react";
import { useWidth } from "../../hooks/useWidth";
import { useHeight } from "../../hooks/useHeight";
import { AnimatePresence, motion } from "framer-motion";
import styles from "./Popover.module.scss";
import cx from "classnames";

interface Props {
  children?: ReactNode;
  open: boolean;
  triggerRef: RefObject<HTMLDivElement>;
  bordered?: boolean;
  disableShadows?: boolean;
}

const SCALE_CONSTANT = 0.8;

export const PopoverBody: FC<Props> = ({
  children,
  open,
  triggerRef,
  bordered,
  disableShadows,
}) => {
  const [popoverDimensions, setPopoverDimensions] = useState(POPOVER_INIT_POS);
  const [triggerPosition, setTriggerPosition] = useState(TRIGGER_INIT_POS);
  const screenWidth = useWidth();
  const screenHeight = useHeight();
  const popoverRef = useRef<HTMLDivElement>(null);

  const contentClass = cx(styles.content, {
    [styles.bordered]: bordered,
    [styles.disableShadows]: disableShadows,
  });

  useEffect(() => {
    if (!triggerRef.current) return;

    const DOMRect = triggerRef.current.getBoundingClientRect();
    setTriggerPosition(DOMRect);
  }, [open, triggerRef]);

  useEffect(() => {
    if (!popoverRef.current || !open) return;
    const { height, width } = popoverRef.current.getBoundingClientRect();
    // needed because of the scale animation on the popover
    const originalHeight = height / SCALE_CONSTANT;
    const originalWidth = width / SCALE_CONSTANT;

    setPopoverDimensions({ height: originalHeight, width: originalWidth });
  }, [open]);

  return (
    <AnimatePresence>
      {open && (
        <motion.div
          ref={popoverRef}
          className={contentClass}
          initial={{
            scale: SCALE_CONSTANT,
            opacity: 0,
            left: triggerPosition.left - triggerPosition.width / 2,
            top: triggerPosition.top - triggerPosition.height / 2,
          }}
          exit={{ scale: SCALE_CONSTANT, opacity: 0 }}
          animate={{
            scale: 1,
            opacity: 1,
            left: getLeftPosition({
              popoverWidth: popoverDimensions.width,
              trigger: triggerPosition,
              screenWidth,
            }),
            top: getTopPosition({
              popoverHeight: popoverDimensions.height,
              trigger: triggerPosition,
              screenHeight,
            }),
          }}
          transition={{
            duration: 0.1,
            left: { duration: 0 },
            top: { duration: 0 },
          }}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

interface TriggerPosition {
  left: number;
  right: number;
  top: number;
  bottom: number;
  width: number;
  height: number;
}

const TRIGGER_INIT_POS: TriggerPosition = {
  height: 0,
  left: 0,
  right: 0,
  top: 0,
  width: 0,
  bottom: 0,
};

interface PopoverDimensions {
  height: number;
  width: number;
}

const POPOVER_INIT_POS: PopoverDimensions = {
  height: 400,
  width: 300,
};

// get left position for popover relative to screenWidth
const getLeftPosition = (data: {
  popoverWidth: number;
  trigger: TriggerPosition;
  screenWidth: number;
}) => {
  const { popoverWidth, trigger, screenWidth } = data;
  const MARGIN_X = 10;

  const chipXCoordinateMiddle = trigger.left + trigger.width / 2;
  const popoverHalfWidth = popoverWidth / 2;

  const centeredPopoverLeft = chipXCoordinateMiddle - popoverHalfWidth;
  const centeredPopoverRight = centeredPopoverLeft + popoverWidth;

  const overflowingScreenLeft = centeredPopoverLeft < 0;
  const overflowingScreenRight = centeredPopoverRight > screenWidth;

  if (overflowingScreenLeft && overflowingScreenRight) {
    return screenWidth / 2 - popoverHalfWidth;
  }

  if (overflowingScreenLeft) {
    return 0 + MARGIN_X;
  }

  if (overflowingScreenRight) {
    return screenWidth - popoverWidth - MARGIN_X;
  }

  return centeredPopoverLeft;
};

// get top position for popover relative to screenHeight
const getTopPosition = (data: {
  popoverHeight: number;
  trigger: TriggerPosition;
  screenHeight: number;
}) => {
  const { popoverHeight, trigger, screenHeight } = data;
  const MARGIN_Y = 10;

  const popoverBottom = trigger.bottom + popoverHeight;
  const overflowingScreenBottom = popoverBottom > screenHeight;

  if (overflowingScreenBottom) {
    const popoverAboveTrigger = trigger.top - popoverHeight - MARGIN_Y;

    const isStillOverflowing = popoverAboveTrigger < 0;
    const popoverMiddleOfScreen = screenHeight / 2 - popoverHeight / 2;

    return isStillOverflowing ? popoverMiddleOfScreen : popoverAboveTrigger;
  }

  return trigger.bottom + MARGIN_Y;
};
