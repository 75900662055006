import React, {
  ChangeEvent,
  useCallback,
  useId,
  useMemo,
  useRef,
  useState,
} from "react";
import clsx from "classnames";
import { Option, Stringifiable } from "../../components/types";
import { useValidation } from "./hooks/useValidation";
import { useForm } from "./hooks/useForm";
import { getConstraints } from "./TextInput";
import styles from "./Radiobuttons.module.scss";
import inputStyles from "./Input.module.scss";
import { BaseValidator } from "./validators/BaseValidator";
import { InputTag } from "./components/InputTag";
import { Radio } from "./Radio";

interface Props<T extends Stringifiable> {
  className?: string;
  value?: T;
  hint?: string | React.ReactNode;
  label?: string | React.ReactNode;
  name?: string;
  onChange: (
    value: T,
    name: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  onBlur?: (value: T, name: string) => void;
  options: Option<T>[];
  disabled?: boolean;
  validators?: BaseValidator[];
}

export function Radiobuttons<T extends Stringifiable>({
  className,
  name,
  label = null,
  onChange,
  onBlur,
  hint,
  options = [],
  value,
  disabled,
  validators = [],
}: Props<T>) {
  const ref = useRef<HTMLDivElement>(null);
  const innerValidators = disabled ? [] : validators;
  const inputId = useId();
  const [validity, errorMessages, showFormErrors] = useValidation(
    value,
    innerValidators
  );
  const [showErrors, setShowErrors] = useState<boolean>(!!value);

  useForm(inputId, validity, value, () => {}, ref);

  const constraints = getConstraints(validators);

  const identifier = useId();

  const internalChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (typeof options[0].value === "boolean") {
        onChange(
          ("true" === event.target.value) as T,
          name || identifier,
          event
        );
        return;
      }

      if (typeof options[0].value === "number") {
        onChange(
          Number(event.target.value) as unknown as T,
          name || identifier,
          event
        );
        return;
      }

      onChange(event.target.value as any, name || identifier, event);
    },
    [onChange, name, options, identifier]
  );

  const error = useMemo(() => {
    if (errorMessages.length > 0 && (showErrors || showFormErrors)) {
      return errorMessages[0];
    }

    return;
  }, [errorMessages, showErrors, showFormErrors]);

  const innerBlur = useCallback(
    (value: T, name: string) => {
      setShowErrors(true);

      onBlur && onBlur(value, name);
    },
    [onBlur]
  );

  return (
    <div
      className={clsx(styles.wrapper, className, styles[validity], {
        [inputStyles.hasLabel]: !!label,
      })}
      ref={ref}
    >
      <div className={clsx(inputStyles.name, styles.label)}>
        {label && <div className={inputStyles.inputLabel}>{label}</div>}
        <InputTag value={!value ? "" : "defined"} {...constraints} />
      </div>
      <div className={styles.options}>
        {options.map((option, idx) => {
          const current = option?.value as any;
          const checked = value === current;

          const labelId = `${identifier}-${idx}`;

          return (
            <label
              htmlFor={labelId}
              key={current.toString()}
              className={clsx(styles.option, {
                [styles.isDisabled]: disabled || option.disabled,
              })}
            >
              <input
                id={labelId}
                name={labelId}
                type="radio"
                onChange={internalChange}
                value={current}
                checked={checked}
                required={constraints.isRequired}
                disabled={disabled || option.disabled}
                onBlur={() => innerBlur(option.value, name || identifier)}
              />
              <Radio
                checked={checked}
                className={styles.radio}
                isDisabled={disabled || option.disabled}
              />
              <div className={styles.optionLabel}>{option.text}</div>
            </label>
          );
        })}
      </div>

      <div className={inputStyles.messages}>
        <div className={inputStyles.hint}>{hint}</div>
        <div className={inputStyles.error}>{error}</div>
      </div>
    </div>
  );
}
