import React, { useContext, useRef, useState } from "react";
import styles from "./CheckoutSummary.module.scss";
import {
  Country,
  Currency,
  Language,
  TerminalType,
  UnitType,
} from "../../../data/models/ContractTypes";
import {
  SalesForcePriceEntry,
  useCheckoutPricing,
} from "../../../data/dataCheckout";
import { RequiredValidator } from "../../Forms/validators/RequiredValidator";
import { FormattedPrice } from "../../../components/FormattedPrice/FormattedPrice";
import { FormattedPercent } from "../../../components/FormattedPrice/FormattedPercent";
import { ProductOffer } from "../../../components/ProductOffer/ProductOffer";
import { GlobalContext } from "../../..";
import { QueryKey, SearchParams, Store, STORE_KEY } from "../../../data/Store";
import { useTranslation } from "react-i18next";
import { Checkboxes } from "../../Forms/Checkboxes";
import { Link } from "../../../components/Link/Link";
import { HiddenInput } from "../../Forms/HiddenInput";
import { Acquiring } from "../Acquiring/Acquiring";
import { AdditionalCosts } from "../AdditionalCosts";
import { TermsOfService } from "../TermsOfService";
import { Button } from "../../../components/Buttons/Button";
import { FaPlus } from "react-icons/fa6";
import { Dynamic } from "../../../components/Animations/Dynamic";

const AVAILABLE_TERMINALS = Object.values(TerminalType);

export const TERMS_AND_CONDITIONS_URL: Record<Country, string> = {
  [Country.SWEDEN]:
    "https://support.worldline.com/sv-se/home/merchants/agreements/terms-and-conditions",
  [Country.DENMARK]:
    "https://support.worldline.com/da-dk/home/merchants/agreements/terms-and-conditions",
  [Country.FINLAND]:
    "https://support.worldline.com/fi-fi/home/merchants/agreements/terms-and-conditions",
  [Country.NORWAY]:
    "https://support.worldline.com/no-no/home/merchants/agreements/terms-and-conditions",
  // TODO: add GB
  [Country.GB]: "",
};

export function getFormattedValue(
  priceItem: SalesForcePriceEntry,
  country: Country,
  lang: Language
) {
  if (priceItem.unitType === UnitType.CURRENCY) {
    return (
      <FormattedPrice
        value={priceItem.value}
        currency={Currency[country]}
        locale={lang}
      />
    );
  }

  return <FormattedPercent value={priceItem.value} locale={lang} />;
}

function sortTerminals(search: SearchParams) {
  const copy = [...AVAILABLE_TERMINALS];
  copy.sort((a, b) => {
    if ((search[a] || 0) > (search[b] || 0)) {
      return -1;
    }

    if ((search[b] || 0) > (search[a] || 0)) {
      return 1;
    }

    return 0;
  });

  return copy;
}

export const CheckoutSummary: React.FunctionComponent = () => {
  const { search, setSearch } = useContext(GlobalContext);
  const [terms, setTerms] = useState<true>();
  const country = search[QueryKey.COUNTRY] as Country;
  const campaignId = search[QueryKey.CAMPAIGN_ID] as Country;
  const { t } = useTranslation();
  const { currency, prices } = useCheckoutPricing(country, campaignId);
  const terminals = useRef<TerminalType[]>(sortTerminals(search));

  const totalNumberOfTerminals = AVAILABLE_TERMINALS.reduce((acc, cur) => {
    return acc + (search[cur] ?? 0);
  }, 0);

  const allTypesHasTerminals = AVAILABLE_TERMINALS.every(
    (terminal) => (search[terminal] || 0) > 0
  );
  const [showAll, setShowAll] = useState<boolean>(allTypesHasTerminals);

  return (
    <div>
      <div className={styles.productsWrapper}>
        <div className="flex-column">
          {terminals.current.map((value, idx) => {
            // Always show one terminal type even
            // The terminals are sorted so we make sure
            // that the first terminal has terminals or
            // that there are no terminals for any other
            // type (so we will default to the first one)
            let show = idx === 0;

            // Always show if there is a terminal
            // added of this type
            show = show || (search[value] ?? 0) > 0;

            // Always show if user has explicitly
            // want to show all terminals
            show = show || showAll;

            return (
              <ProductOffer
                key={value}
                terminalType={value}
                quantity={search[value] ?? 0}
                price={prices[value]?.value}
                discount={prices[value]?.discount}
                currency={currency}
                country={country}
                show={show}
                onClick={
                  search[QueryKey.SESSION]
                    ? undefined
                    : (quantity) => {
                        setSearch({
                          ...search,
                          [value]: quantity,
                        });
                        Store.setValue(STORE_KEY.STORE_SAVED_QUERY, {
                          ...search,
                          [value]: quantity,
                        });
                      }
                }
                // voucher={
                //   <Chip status={Status.SUCCESS} focusAlign={FocusAlign.LEFT}>
                //     <Chip.Focus>20%</Chip.Focus>
                //     <span className={styles.terminalName}>
                //       {t("Early bird discount")}
                //     </span>
                //   </Chip>
                // }
              />
            );
          })}
        </div>
        <div className="center">
          <Dynamic name={showAll ? "show" : "hide"}>
            {showAll ? null : (
              <Button variant="text" onClick={() => setShowAll(true)}>
                <FaPlus /> {t("Add more terminals")}
              </Button>
            )}
          </Dynamic>
        </div>
      </div>

      <AdditionalCosts country={country} prices={prices} />

      <div className="mt-3">
        <Acquiring prices={prices} country={country} />
      </div>

      <hr className="mt-4" />

      <div className="mt-4">
        <TermsOfService currency={currency} />
      </div>

      <div className="mt-2">
        <HiddenInput
          value={totalNumberOfTerminals === 0 ? undefined : true}
          validators={[
            new RequiredValidator(t("You must select at least one terminal")),
          ]}
        />

        <HiddenInput
          value={terms ? true : undefined}
          validators={[new RequiredValidator(t("You must agree to the terms"))]}
        />
        <Checkboxes
          alt
          options={[
            {
              value: "terms",
              text: (
                <>
                  {t("I agree to the")}{" "}
                  <Link to={TERMS_AND_CONDITIONS_URL[country]} external>
                    {t("terms and conditions")}
                  </Link>
                </>
              ),
            },
          ]}
          values={terms ? ["terms"] : []}
          onChange={(values) => {
            setTerms(values.includes("terms") ? true : undefined);
          }}
        />
      </div>
    </div>
  );
};
