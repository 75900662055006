import { FunctionComponent } from "react";
import { TerminalType } from "../../data/models/ContractTypes";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

interface Props {
  terminalType: TerminalType;
}

export const TerminalDescription: FunctionComponent<Props> = ({
  terminalType,
}) => {
  const { t } = useTranslation();
  return <>{getTerminalDescriptionTranslation(t)[terminalType]}</>;
};

export const getTerminalDescriptionTranslation = (
  t: TFunction
): Record<TerminalType, string> => ({
  [TerminalType.DX8000]: t("Versatile Android terminal"),
  [TerminalType.MOVE5000]: t("Reliable wireless terminal"),
});
