import React, { ReactNode } from "react";

export enum Speed {
  FAST,
  NORMAL,
  SLOW,
  VERY_SLOW,
}

export const progressIndex = {
  [Speed.FAST]: [
    0, 0.08341500000000451, 0.16683000000000903, 0.25024500000001354,
    0.33365999999999985, 0.41707500000000436, 0.5004900000000089,
    0.5839050000000134, 0.6673199999999997, 0.7507350000000043,
    0.8341500000000087, 0.9175650000000133, 1,
  ],
  [Speed.NORMAL]: [
    0, 0.01391112000000001, 0.031300019999999956, 0.05564448000000004,
    0.08694449999999995, 0.1252000800000001, 0.17041122, 0.2225779199999998,
    0.28170018, 0.3477779999999998, 0.42081138000000007, 0.5007996799999999,
    0.58065518, 0.65355512, 0.7194995000000003, 0.77848832, 0.8305215799999999,
    0.8755992800000001, 0.9137214199999999, 0.9448880000000001, 0.96909902,
    0.98635448, 0.9966543800000001, 1,
  ],
  [Speed.SLOW]: [
    0, 0.006182719999999984, 0.01391112000000001, 0.02473087999999998,
    0.03864200000000004, 0.05564447999999998, 0.07573832000000007,
    0.09892351999999999, 0.12520007999999994, 0.15456800000000004,
    0.18702727999999996, 0.2225779200000001, 0.26121991999999994,
    0.30295328000000005, 0.347778, 0.39569407999999984, 0.44670152,
    0.5007996799999999, 0.5548095200000001, 0.6057279999999999,
    0.6535551200000002, 0.6982908799999998, 0.7399352800000001, 0.77848832,
    0.81395, 0.84632032, 0.8755992799999999, 0.90178688, 0.92488312,
    0.9448880000000001, 0.96180152, 0.97562368, 0.9863544799999999, 0.99399392,
    0.998542, 1,
  ],
  [Speed.VERY_SLOW]: [
    0, 0.0008694450000000006, 0.003477779999999991, 0.013911119999999987,
    0.021736125000000016, 0.03130002, 0.04260280500000003, 0.05564448,
    0.07042504499999995, 0.08694450000000002, 0.10520284499999995,
    0.12520008000000005, 0.14693620499999996, 0.17041122000000006,
    0.19562512499999996, 0.2225779200000001, 0.251269605, 0.28170017999999997,
    0.31386964500000003, 0.347778, 0.383425245, 0.42081138, 0.45993640500000005,
    0.5007996799999999, 0.5415968749999998, 0.58065518, 0.617974595, 0.65355512,
    0.6873967549999999, 0.7194995000000001, 0.7498633549999998, 0.77848832,
    0.8053743950000001, 0.8305215799999999, 0.853929875, 0.8755992800000001,
    0.8955297949999999, 0.9137214199999999, 0.930174155, 0.9448880000000001,
    0.957862955, 0.96909902, 0.978596195, 0.9863544799999999, 0.992373875,
    0.9966543800000001, 0.999195995, 1,
  ],
};

interface Props {
  children?: ReactNode;
  name: string | number | boolean;
  speed?: Speed;
}

interface State {
  opacity: number;
  height: string;
  children: React.ReactNode;
  trigger: boolean;
}

interface Animate {
  from: number;
  to: number;
  name: keyof State;
  postfix?: string;
  speed: Speed;
}

export class Dynamic extends React.PureComponent<Props, State> {
  wrapperRef = React.createRef<HTMLDivElement>();
  childRef = React.createRef<HTMLDivElement>();
  isAnimating: boolean = false;
  isInit: boolean = true;
  isUnmounting: boolean = false;
  name: string | number | boolean;
  speed: Speed;

  constructor(props: Props) {
    super(props);
    this.state = {
      opacity: props.children ? 1 : 0,
      height: "auto",
      children: props.children,
      trigger: false,
    };
    this.name = props.name.toString();
    this.speed = props.speed || Speed.FAST;
  }

  componentWillUnmount() {
    this.isUnmounting = true;
  }

  fadeOut = () => {
    return this.animate({
      from: 1,
      to: 0,
      name: "opacity",
      speed: this.speed,
    });
  };

  fadeIn = () => {
    return this.animate({
      from: 0,
      to: 1,
      name: "opacity",
      speed: this.speed,
    });
  };

  updateState =
    (key: keyof State, value: number | string) =>
    (prevState: State): State => ({
      ...prevState,
      [key]: value,
    });

  animate = (props: Animate) => {
    const self = this;

    return new Promise<void>((resolve, reject) => {
      const { from, to, name, postfix } = props;

      if (from === to) {
        resolve();
        return;
      }

      let value: string | number = 0;
      let index = 0;

      function draw() {
        if (self.isUnmounting) {
          reject();
          return;
        }

        const progress = progressIndex[props.speed][index];
        index++;

        if (!progress && progress !== 0) {
          resolve();
          return;
        }

        value = from + (to - from) * progress;

        if (postfix) {
          value = `${value}${postfix}`;
        }

        self.setState(self.updateState(name, value));

        requestAnimationFrame(draw);
      }

      requestAnimationFrame(draw);
    }).catch((err) => {
      this.setState((prev) => ({
        ...prev,
        opacity: 1,
        height: "auto",
      }));
      this.isAnimating = false;
      console.log(
        "Could not animate height " + err + " name " + this.props.name
      );
    });
  };

  componentDidMount() {
    this.isUnmounting = false;
  }

  componentDidUpdate() {
    if (this.isAnimating) {
      return;
    }

    if (this.name === this.props.name) {
      this.setState({ children: this.props.children });
      return;
    }

    this.isAnimating = true;
    this.name = this.props.name;

    let promise;
    if (this.wrapperRef.current?.scrollHeight) {
      promise = this.fadeOut();
    } else {
      promise = Promise.resolve();
    }

    promise
      .then(() => {
        this.setState(
          {
            height: `${this.wrapperRef.current?.scrollHeight}px`,
          },
          () => {
            this.setState(
              {
                children: this.props.children,
              },
              () => {
                const endHeight = this.childRef.current?.scrollHeight ?? 0;

                if (this.state.height === `${endHeight}px`) {
                  this.setState(
                    {
                      height: "auto",
                    },
                    () => {
                      let promise;
                      if (this.wrapperRef.current?.scrollHeight) {
                        promise = this.fadeIn();
                      } else {
                        promise = Promise.resolve();
                      }

                      promise.then(() => {
                        this.isAnimating = false;
                        this.setState({ trigger: !this.state.trigger });
                      });
                    }
                  );

                  return;
                }

                this.animate({
                  from: parseInt(this.state.height, 10),
                  to: endHeight,
                  name: "height",
                  postfix: "px",
                  speed: this.speed,
                })
                  .then(() => {
                    this.setState(
                      {
                        height: "auto",
                      },
                      () => {
                        if (this.wrapperRef.current?.scrollHeight) {
                          promise = this.fadeIn();
                        } else {
                          promise = Promise.resolve();
                        }

                        promise.then(() => {
                          this.isAnimating = false;
                          this.setState({ trigger: !this.state.trigger });
                        });
                      }
                    );
                  })
                  .catch((err) => {
                    console.error(err);
                    this.setState((prev) => ({
                      ...prev,
                      opacity: 1,
                      height: "auto",
                    }));
                    this.isAnimating = false;
                  });
              }
            );
          }
        );
      })
      .catch((err) => {
        console.error(err);
        this.setState((prev) => ({
          ...prev,
          opacity: 1,
          height: "auto",
        }));
        this.isAnimating = false;
      });
  }

  render() {
    const { opacity, height, children } = this.state;

    return (
      <div
        className="height-anim"
        ref={this.wrapperRef}
        style={{
          height,
          opacity,
        }}
      >
        <div ref={this.childRef}>{children}</div>
      </div>
    );
  }
}
