import React from "react";
import { FlagRounded } from "../Countries/FlagRounded";
import { Country } from "../../data/models/ContractTypes";

interface Props {}

export const FtnLogo: React.FunctionComponent<Props> = () => {
  return (
    <>
      <FlagRounded country={Country.FINLAND} width="16px" /> &nbsp;<b>FTN</b>
    </>
  );

  // return (
  //   <svg viewBox="0 0 152 40" xmlns="http://www.w3.org/2000/svg">
  //     <path
  //       fill="#003479"
  //       style="fill: var(--color1, #003479)"
  //       d="M38.545 0h-37.091c-0.8 0-1.455 0.655-1.455 1.455v37.091c0 0.8 0.655 1.455 1.455 1.455h37.091c0.8 0 1.455-0.655 1.455-1.455v-37.091c0-0.8-0.655-1.455-1.455-1.455z"
  //     ></path>
  //     <path
  //       fill="#fff"
  //       style="fill: var(--color2, #fff)"
  //       d="M10.182 14.545v-3.636c0-0.8 0.655-1.455 1.455-1.455h3.636v5.091z"
  //     ></path>
  //     <path
  //       fill="#fff"
  //       style="fill: var(--color2, #fff)"
  //       d="M10.182 19.636h5.091v10.182c0 0.4-0.327 0.727-0.727 0.727h-3.636c-0.4 0-0.727-0.327-0.727-0.727z"
  //     ></path>
  //     <path
  //       fill="#fff"
  //       style="fill: var(--color2, #fff)"
  //       d="M20.364 9.455h9.455c0.4 0 0.727 0.327 0.727 0.727v4.364h-10.182z"
  //     ></path>
  //     <path
  //       fill="#fff"
  //       style="fill: var(--color2, #fff)"
  //       d="M29.818 24.727h-9.455v-5.091h10.182v4.364c0 0.401-0.326 0.727-0.727 0.727z"
  //     ></path>
  //     <path
  //       fill="#003479"
  //       style="fill: var(--color1, #003479)"
  //       d="M56 29.416l0.427-2.933c1.436 0.947 3.423 1.803 5.53 1.803 2.199 0 3.758-1.161 3.758-3.208 0-1.925-0.856-2.719-4.033-4.155-3.972-1.803-5.468-3.116-5.468-6.141 0-3.391 2.596-5.56 6.415-5.56 2.047 0 3.575 0.489 4.796 1.191l-0.458 2.872c-1.161-0.887-2.75-1.558-4.43-1.558-2.414 0-3.483 1.283-3.483 2.841 0 1.619 0.795 2.383 4.002 3.849 4.094 1.864 5.499 3.269 5.499 6.447 0 3.636-2.719 5.927-6.66 5.927-2.292 0-4.339-0.55-5.896-1.375z"
  //     ></path>
  //     <path
  //       fill="#003479"
  //       style="fill: var(--color1, #003479)"
  //       d="M74.205 15.974v9.929c0 1.497 0.641 2.505 2.23 2.505 1.436 0 2.903-0.947 4.216-2.353v-10.081h2.719v14.573h-2.169l-0.336-2.108c-1.375 1.283-3.116 2.414-5.163 2.414-2.78 0-4.216-1.65-4.216-4.277v-10.601z"
  //     ></path>
  //     <path
  //       fill="#003479"
  //       style="fill: var(--color1, #003479)"
  //       d="M92.899 17.929c-2.627 0-3.941 2.291-3.941 5.347 0 2.993 1.375 5.316 3.941 5.316 2.689 0 4.033-2.261 4.033-5.316 0-2.994-1.405-5.347-4.033-5.347zM92.899 30.852c-3.88 0-6.69-2.78-6.69-7.576 0-4.797 2.81-7.607 6.69-7.607 3.911 0 6.783 2.81 6.783 7.607 0 4.796-2.872 7.576-6.783 7.576z"
  //     ></path>
  //     <path
  //       fill="#003479"
  //       style="fill: var(--color1, #003479)"
  //       d="M120.484 30.547v-9.929c0-1.497-0.55-2.505-2.138-2.505-1.436 0-2.811 0.947-4.124 2.353v10.081h-2.72v-9.929c0-1.497-0.549-2.505-2.138-2.505-1.436 0-2.81 0.947-4.124 2.353v10.081h-2.719v-14.573h2.169l0.336 2.108c1.375-1.252 3.025-2.414 5.072-2.414 2.138 0 3.299 0.917 3.849 2.414 1.313-1.252 3.085-2.414 5.132-2.414 2.781 0 4.124 1.65 4.124 4.277v10.601z"
  //     ></path>
  //     <path
  //       fill="#003479"
  //       style="fill: var(--color1, #003479)"
  //       d="M127.017 15.974h2.719v14.573h-2.719zM128.392 12.907c0.947 0 1.711-0.764 1.711-1.711s-0.764-1.741-1.711-1.741c-0.948 0-1.741 0.794-1.741 1.741s0.793 1.711 1.741 1.711z"
  //     ></path>
  //     <path
  //       fill="#003479"
  //       style="fill: var(--color1, #003479)"
  //       d="M145.437 11.867c-1.253 0-2.016 0.519-2.016 1.864v2.242h2.932l0.336 2.292h-3.268v12.281h-2.659v-12.281h-2.199v-2.292h2.199v-2.517c0-2.903 1.925-4.002 4.522-4.002 0.428 0 0.794 0.031 1.161 0.061l0.397 2.474c-0.305-0.061-0.855-0.122-1.405-0.122z"
  //     ></path>
  //     <path
  //       fill="#003479"
  //       style="fill: var(--color1, #003479)"
  //       d="M149.039 15.974h2.719v14.573h-2.719zM150.414 12.907c0.948 0 1.711-0.764 1.711-1.711s-0.764-1.741-1.711-1.741c-0.946 0-1.741 0.794-1.741 1.741s0.795 1.711 1.741 1.711z"
  //     ></path>
  //     <path
  //       fill="#003479"
  //       style="fill: var(--color1, #003479)"
  //       d="M134.959 30.545c-0.948 0-1.742-0.764-1.742-1.711s0.794-1.741 1.742-1.741c0.947 0 1.711 0.794 1.711 1.741s-0.764 1.711-1.711 1.711z"
  //     ></path>
  //   </svg>
  // );
};
