import React from "react";
import styles from "./AdditionalCosts.module.scss";
import { useTranslation } from "react-i18next";
import { Prices } from "../../data/dataCheckout";
import { InfoList } from "../../components/List/InfoList";
import { getFormattedValue } from "./Summary/CheckoutSummary";
import { Country, Language } from "../../data/models/ContractTypes";
import { PricingEntryLabel } from "../../components/PricingEntryLabel/PricingEntryLabel";

interface Props {
  prices: Prices;
  country: Country;
}

export const AdditionalCosts: React.FunctionComponent<Props> = ({
  prices,
  country,
}) => {
  const { i18n } = useTranslation();

  if (!prices.extras.length) {
    return null;
  }

  return (
    <>
      <hr />
      <div className={styles.extras}>
        <InfoList className={styles.list} split>
          {prices.extras.map((item) => {
            return (
              <React.Fragment key={item.label}>
                <InfoList.Dt>
                  <PricingEntryLabel value={item.label || item.displayLabel} />
                </InfoList.Dt>
                <InfoList.Dd className="text-right">
                  {getFormattedValue(item, country, i18n.language as Language)}
                </InfoList.Dd>
              </React.Fragment>
            );
          })}
        </InfoList>
      </div>
    </>
  );
};
