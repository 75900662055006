import { useSuspenseQuery } from "@tanstack/react-query";
import { API } from "./API";
import { Country, PricingModel, TerminalType } from "./models/ContractTypes";

const QUERY_KEY = "checkout";

export const dataCheckout = {
  QUERY_KEY,
  getPricing: (country: Country, promotionCode?: string) => ({
    queryKey: [QUERY_KEY, { country, promotionCode }],
    queryFn: () => {
      if (promotionCode) {
        return API.get<SalesforcePriceResponse>(
          `/checkout/pricing?country=${country}&campaignId=${promotionCode}`
        );
      }

      return API.get<SalesforcePriceResponse>(
        `/checkout/pricing?country=${country}`
      );
    },
  }),
};

enum UnitType {
  CURRENCY = "Currency",
  PERCENT = "Percent",
}

export interface SalesforcePriceResponse {
  pricingModel: PricingModel;
  currency: string;
  displayName: string;
  prices: SalesForcePriceEntry[];
  discounts?: SalesForceDiscountEntry[];
}

export interface PriceItem extends SalesForcePriceEntry {
  discount?: SalesForceDiscountEntry;
}

export interface SalesForcePriceEntry {
  label: string;
  displayLabel: string;
  unitType: UnitType;
  value: number;
  cardScheme: string[];
}

export interface SalesForceDiscountEntry {
  model: string;
  label: string;
  discountPercent: number;
  validMonths: number;
}

const CardPrefixes = ["Debit", "Credit", "Commercial", "Company card"];

const TerminalLabels: Record<string, TerminalType> = {
  "Monthly Fee/Wireless Android": TerminalType.DX8000,
  "Monthly Fee Wireless": TerminalType.MOVE5000,
};

export interface Prices {
  [TerminalType.DX8000]: PriceItem;
  [TerminalType.MOVE5000]: PriceItem;
  acquiring: PriceItem[];
  extras: PriceItem[];
}

export interface Pricing
  extends Pick<SalesforcePriceResponse, "currency" | "displayName"> {
  prices: Prices;
  discounts?: SalesForcePriceEntry[];
}

export const TerminalTypeToSalesforcePriceEntry: Record<TerminalType, string> =
  {
    [TerminalType.DX8000]: "Monthly Fee/Wireless Android",
    [TerminalType.MOVE5000]: "Monthly Fee Wireless",
  };

export function getPricesFromPricingResponse(
  response: SalesforcePriceResponse
) {
  return Object.fromEntries(
    Object.entries(TerminalType).map(([_, value]) => {
      return [
        value,
        response?.prices.find(
          (price) => price.label === TerminalTypeToSalesforcePriceEntry[value]
        ),
      ];
    })
  );
}

export function getPricing(response: SalesforcePriceResponse): Prices {
  let extras: SalesForcePriceEntry[] = [];
  let acquiring: SalesForcePriceEntry[] = [];
  let terminals: Record<TerminalType, PriceItem | undefined> = {} as any;

  response.prices
    .filter((priceItem) => !!priceItem.value)
    .forEach((priceItem) => {
      // Check for matching discount
      const discount = response.discounts?.find(
        (d) => d.label === priceItem.label
      );

      if (TerminalLabels[priceItem.label]) {
        terminals[TerminalLabels[priceItem.label]] = { ...priceItem, discount };
        return;
      }

      if (CardPrefixes.some((prefix) => priceItem.label.startsWith(prefix))) {
        acquiring.push(priceItem);
        return;
      }

      extras.push(priceItem);
    });

  return {
    extras,
    acquiring,
    ...terminals,
  } as Prices;
}

export const useCheckoutPricing = (
  country: Country,
  campaignId?: string
): Pricing => {
  const { data } = useSuspenseQuery(
    dataCheckout.getPricing(country, campaignId)
  );

  return {
    currency: data?.currency || "",
    displayName: data?.displayName || "",
    prices: getPricing(data),
  };
};
