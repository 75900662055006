import React from "react";
import cx from "classnames";
import styles from "./CountryChip.module.scss";
import { Chip } from "../Chip/Chip";
import { FlagAndCountry } from "../../modules/Countries/FlagAndCountry";
import { Country } from "../../data/models/ContractTypes";
import { MdClose } from "react-icons/md";

interface Props {
  countryCode: string | Country;
}

export const CountryChip: React.FunctionComponent<Props> = ({
  countryCode,
}) => {
  return (
    <Chip className={cx(styles.chip)} padded={false}>
      <FlagAndCountry countryCode={countryCode} height={16} />
      <span className={styles.remove}>
        <MdClose />
      </span>
    </Chip>
  );
};
