import styles from "./Radio.module.scss";
import cx from "classnames";
import { FaCircle } from "react-icons/fa6";

interface Props {
  checked: boolean;
  isDisabled?: boolean;
  className?: string;
}

export const Radio: React.FunctionComponent<Props> = ({
  checked,
  isDisabled,
  className,
}) => {
  return (
    <div
      className={cx(styles.wrapper, className, {
        [styles.isChecked]: checked,
        [styles.isDisabled]: isDisabled,
      })}
    >
      {checked && <FaCircle />}
    </div>
  );
};
