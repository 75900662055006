import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import cx from "classnames";
import styles from "./Signatory.module.scss";
import {
  Contract,
  CountryCode,
  SelectedSignatory,
} from "../../../data/models/ContractTypes";
import {
  Form,
  FormContainer,
  FormEvent,
  FormEventType,
} from "../../../modules/Forms/Form";
import { AssociateAccordion } from "../../../modules/AssociateAccordion/AssociateAccordion";
import { TextInput } from "../../../modules/Forms/TextInput";
import { useTranslation } from "react-i18next";
import { RequiredValidator } from "../../../modules/Forms/validators/RequiredValidator";
import { EmailValidator } from "../../../modules/Forms/validators/EmailValidator";
import { SignatoryNationality } from "../../../modules/SignatoryNationality/SignatoryNationality";
import { useMutation } from "@tanstack/react-query";
import { dataAssociates } from "../../../data/dataAssociates";
import { queryClient } from "../../..";
import { dataContract } from "../../../data/dataContract";
import { SignatoryForm } from "../../../modules/SignatoryForm/SignatoryForm";
import { onNextFrame } from "../../../components/utils";
import { useContract } from "../../../hooks/useContract";
import { isServerError } from "../../../data/API";

interface Props {
  signatory: SelectedSignatory;
}

export const Signatory: React.FunctionComponent<Props> = ({
  signatory: original,
}) => {
  const contract = useContract();
  const { t } = useTranslation();
  const [signatory, setSignatory] = useState<SelectedSignatory>(original);
  const [open, setOpen] = useState<boolean>(false);
  const formRef = useRef<FormContainer>();
  const queryKey = dataContract.getContractKey();

  const informationHasAlreadyBeenCollectedAsOwner = useMemo(() => {
    const primaryIsOwner = contract.beneficialOwners.find(
      (owner) => owner.primaryContact
    );

    if (!primaryIsOwner) {
      return false;
    }

    return (
      primaryIsOwner.citizenships.length &&
      primaryIsOwner.countryOfResidence &&
      primaryIsOwner.placeOfBirth
    );
  }, [contract]);

  useEffect(() => {
    const onUpdate = (event: FormEvent, container: FormContainer) => {
      if (!event) {
        return;
      }

      if (event.type === FormEventType.UPDATE) {
        return;
      }

      if (event.type !== FormEventType.FORCE_ERRORS) {
        return;
      }

      if (!event.value) {
        return;
      }

      if (container.isValid) {
        return;
      }

      setOpen(true);
    };

    const container = formRef.current;
    container?.addListener(onUpdate);

    return () => {
      container?.removeListener(onUpdate);
    };
  }, []);

  const {
    // TODO
    isError, // eslint-disable-line
    isPending, // eslint-disable-line
    reset, // eslint-disable-line
    mutate: onSave,
    error,
  } = useMutation({
    mutationFn: async () => dataAssociates.updateSignee(signatory),
    onMutate: async () => {
      await queryClient.cancelQueries({
        queryKey,
      });

      const previousContract = queryClient.getQueryData<Contract>(queryKey);
      if (!previousContract) {
        return;
      }

      const selectedSignatories: SelectedSignatory[] =
        previousContract.selectedSignatories.map((signeeItem) => {
          if (signeeItem.id === signatory.id) {
            return signatory;
          }

          return signeeItem;
        });

      const update: Contract = {
        ...previousContract,
        selectedSignatories,
      };

      queryClient.setQueryData<Contract>(queryKey, update);

      return { previousContract };
    },
    onError: (err, _, context) => {
      if (!context) {
        return;
      }

      if (isServerError(err) && err.status === 403) {
        return;
      }

      const prevSavedSignee = context.previousContract.selectedSignatories.find(
        (signeeItem) => signeeItem.id === signatory.id
      );

      if (prevSavedSignee) {
        setSignatory(prevSavedSignee);
      }

      queryClient.setQueryData(queryKey, context.previousContract);
    },
  });

  const onChange = useCallback((value: string, name: string) => {
    setSignatory((prev) => {
      if (Array.isArray(prev[name as keyof SelectedSignatory])) {
        return {
          ...prev,
          [name]: (prev[name as keyof SelectedSignatory] as string[]).concat(
            value
          ),
        };
      }

      return { ...prev, [name]: value };
    });
  }, []);

  const onRemoveCitizenship = useCallback(
    (value: CountryCode) => {
      const copy = {
        ...signatory,
        citizenships: signatory.citizenships.filter((item) => item !== value),
      };

      setSignatory(copy);
      onNextFrame(() => {
        onSave();
      });
    },
    [signatory, onSave]
  );

  return (
    <div className={cx(styles.signee, "full-width")}>
      <Form
        formContainer={formRef}
        name={`signatory-form-${signatory.id}`}
        onSaveTrigger={() => {
          onSave();
        }}
      >
        <AssociateAccordion person={signatory} open={open} setOpen={setOpen}>
          <div className={cx(styles.inputs)}>
            <SignatoryNationality
              associate={signatory}
              onChange={(...props) => {
                reset();
                onChange(...props);
              }}
              error={error}
            />

            {informationHasAlreadyBeenCollectedAsOwner ||
            !signatory.primary ? null : (
              <div className="mt-4">
                <SignatoryForm
                  onChange={onChange}
                  onRemoveCitizenship={onRemoveCitizenship}
                  person={signatory}
                />
              </div>
            )}

            {signatory.primary ? null : (
              <div className="mt-3">
                <TextInput
                  label={t("Email")}
                  name="email"
                  value={signatory.email}
                  onChange={onChange}
                  validators={[
                    new RequiredValidator(t("Email is required")),
                    new EmailValidator(t("Email is incorrectly formatted")),
                  ]}
                />
              </div>
            )}
          </div>
        </AssociateAccordion>
      </Form>
    </div>
  );
};
