import { queryClient } from "..";
import { API } from "./API";
import {
  ContractDocument,
  ContractId,
  CountryCode,
  DocumentId,
  Language,
  LocationId,
  MCC,
  UTCDate,
} from "./models/ContractTypes";
import {
  DueDiligenceLevel,
  RiskCase,
  RiskChecklist,
  RiskDecision,
  RiskDocument,
} from "./models/RiskTypes";
import { DocumentType } from "../data/models/ContractTypes";
import { MCC_LIST } from "./models/mcc";
import {
  ResponsePage,
  SortDirection,
} from "../pages/backoffice/types/ResponsePage";

export type SupplementId = number & { isSupplementId: true };
export type UserId = string & { isUserId: true };

const QUERY_KEY = "risk";

export const dataRisk = {
  QUERY_KEY,

  // Load contract
  // Same as customer structure
  // + metadata & intern data
  fetchContract: (contractId: ContractId) => ({
    queryKey: [QUERY_KEY, "contract", { contractId }],
    queryFn: () => API.get<RiskCase>(`/risk/contracts/${contractId}`),
  }),

  fetchContractByOrgNr: (organizationNumber: string, country: CountryCode) => ({
    queryKey: [QUERY_KEY, "contract", { organizationNumber, country }], // TODO correct me?
    queryFn: () =>
      API.get<RiskCase[]>(
        `/risk/contracts?organizationNumber=${organizationNumber}&country=${country}`
      ),
  }),

  fetchContractByEmail: (email: string) => ({
    queryKey: [QUERY_KEY, "contract", { email }],
    queryFn: () => API.get<RiskCase[]>(`/risk/contract?email=${email}`), //Better response type?
  }),

  fetchQueue: () => ({
    queryKey: [QUERY_KEY, "queue"],
    queryFn: () => API.get<RiskQueueItem[]>(`/risk/queue`),
  }),

  fetchClosedCases: ({
    page = 0,
    size = 10,
    field,
    direction,
  }: {
    page: number;
    size: number;
    field: keyof RiskClosedItem;
    direction: SortDirection;
  }) => ({
    queryKey: [QUERY_KEY, "queue", "closed", { page, size, field, direction }],
    queryFn: () => {
      let params = `?page=${page}&size=${size}`;
      if (field) {
        params += `&field=${field}`;
      }
      if (direction) {
        params += `&direction=${direction}`;
      }

      return API.get<ResponsePage<RiskClosedItem>>(
        `/risk/queue/closed${params}`
      );
    },
  }),

  fetchAllMccs: () => ({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [QUERY_KEY, "mcc-list"],
    queryFn: async (): Promise<MCC[]> => {
      const { origin } = new URL(window.location.href);
      const mccs = await API.get<Record<string, string>>(
        `${origin}/mcc/mcc-${Language.ENGLISH}.json`
      );

      return MCC_LIST.map((mcc) => ({
        ...mcc,
        label: mccs[mcc.code] || "",
      }));
    },
  }),

  // List supplements
  fetchAllSupplements: (contractId: ContractId) => ({
    queryKey: [QUERY_KEY, "supplements", { contractId }],
    queryFn: () =>
      API.get<ContractSupplement[]>(`/risk/supplements/${contractId}`),
  }),

  // List attachments
  fetchAllDocuments: (contractId: ContractId) => ({
    queryKey: [QUERY_KEY, "documents", { contractId }],
    queryFn: () => API.get<ContractDocument[]>(`/risk/documents/${contractId}`),
  }),

  fetchChecklist: (contractId: ContractId) => ({
    queryKey: [QUERY_KEY, "checklist", { contractId }],
    queryFn: async (): Promise<Partial<RiskChecklist>> => {
      const res = await API.get<{ checklist?: string }>(
        `/risk/contracts/${contractId}/checklist`
      );

      return res.checklist
        ? (JSON.parse(res.checklist) as Partial<RiskChecklist>)
        : {};
    },
  }),

  // Request supplement
  createSupplement: (contractId: ContractId) => ({
    mutationFn: ({
      question: description,
      uploadRequired,
      autoTranslate,
    }: RiskSupplementRequest) =>
      API.post(`/risk/supplements/${contractId}`, {
        uploadRequired,
        question: description,
        autoTranslate,
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: dataRisk.fetchAllSupplements(contractId).queryKey,
      });
    },
  }),

  deleteSupplement: (contractId: ContractId, supplementId: SupplementId) => ({
    mutationFn: () =>
      API.delete(`/risk/supplements/${contractId}/${supplementId}`),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: dataRisk.fetchAllSupplements(contractId).queryKey,
      });
    },
  }),

  translateSupplement: (
    contractId: ContractId,
    supplementId: SupplementId
  ) => ({
    mutationFn: (language: string) =>
      API.post<{ text: string }>(
        `/risk/supplements/${contractId}/${supplementId}/translate?language=${language}`
      ),
  }),

  createDocument: (contractId: ContractId) => ({
    mutationFn: (request: RiskDocumentRequest) => {
      const formData = new FormData();
      formData.append("document", request.document);
      formData.append("comment", request.comment);
      formData.append(
        "documentType",
        request.documentType || DocumentType.RISK_DOCUMENT
      );
      return API.postFormData<ContractDocument>(
        `/risk/documents/${contractId}`,
        formData
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: dataRisk.fetchAllDocuments(contractId).queryKey,
      });
    },
  }),

  deleteDocument: (contractId: ContractId, documentId: DocumentId) => ({
    mutationFn: () => API.delete(`/risk/documents/${contractId}/${documentId}`),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: dataRisk.fetchAllDocuments(contractId).queryKey,
      });
    },
  }),

  // Uppdatera MCC
  // Smäll på fingrarna om man inte claimat
  setMcc: (contractId: ContractId, locationId: LocationId) => ({
    mutationFn: (mcc: string) =>
      API.post(`/risk/contracts/${contractId}/mcc`, {
        mcc,
        locationId,
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: dataRisk.fetchContract(contractId).queryKey,
        exact: true,
      });
    },
  }),

  // Accept case
  approveCase: (contractId: ContractId) => ({
    mutationFn: ({
      finalComment,
      dueDiligence,
      delayedSettlement,
      rollingReserve,
    }: {
      finalComment: string;
      dueDiligence: DueDiligenceLevel;
      delayedSettlement: number;
      rollingReserve: number;
    }) =>
      API.post(`/risk/contracts/${contractId}/accept`, {
        finalComment,
        dueDiligence,
        delayedSettlement,
        rollingReserve,
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: dataRisk.fetchContract(contractId).queryKey,
        exact: true,
      });
    },
  }),

  // Reject case
  // Smäll på fingrarna om man inte claimat
  rejectCase: (contractId: ContractId) => ({
    mutationFn: (finalComment: string) =>
      API.post(`/risk/contracts/${contractId}/reject`, {
        finalComment,
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: dataRisk.fetchContract(contractId).queryKey,
        exact: true,
      });
    },
  }),

  claimCase: (contractId: ContractId) => ({
    mutationFn: () => API.post(`/risk/contracts/${contractId}/claim`),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: dataRisk.fetchContract(contractId).queryKey,
        exact: true,
      });
    },
  }),

  updateChecklist: (contractId: ContractId) => ({
    mutationFn: (checklist: Partial<RiskChecklist>) =>
      API.post(`/risk/contracts/${contractId}/checklist`, {
        checklist: JSON.stringify(checklist),
      }),
    onMutate: async (newChecklist: Partial<RiskChecklist>) => {
      // Cancel outgoing fetches
      await queryClient.cancelQueries({
        queryKey: dataRisk.fetchChecklist(contractId).queryKey,
      });

      // Save previous value
      const previousChecklist = queryClient.getQueryData<
        Partial<RiskChecklist>
      >(dataRisk.fetchChecklist(contractId).queryKey);

      // Optimistically update
      queryClient.setQueryData<Partial<RiskChecklist>>(
        dataRisk.fetchChecklist(contractId).queryKey,
        newChecklist
      );

      // Return context
      return { previousChecklist };
    },
    onError: (_: any, __: any, context: any) => {
      // Rollback on error
      queryClient.setQueryData(
        dataRisk.fetchChecklist(contractId).queryKey,
        context?.previousChecklist
      );
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: dataRisk.fetchChecklist(contractId).queryKey,
        exact: true,
      });
    },
  }),

  updatePurposeRationale: (contractId: ContractId) => ({
    mutationFn: (comment: string) =>
      API.post<void>(`/risk/contracts/${contractId}/business`, {
        businessComment: comment,
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: dataRisk.fetchContract(contractId).queryKey,
        exact: true,
      });
    },
  }),

  updateFinalComment: (contractId: ContractId) => ({
    mutationFn: (comment: string) =>
      API.post<void>(`/risk/contracts/${contractId}/final-comment`, {
        finalComment: comment,
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: dataRisk.fetchContract(contractId).queryKey,
        exact: true,
      });
    },
  }),

  //TODO request translation av supplement
};

export const getRiskDocumentUrl = (document: RiskDocument) =>
  API.getUrl("/risk/documents/file/" + document.documentId);

export interface RiskSupplementRequest {
  question: string;
  uploadRequired: boolean;
  autoTranslate: boolean;
}

export interface RiskDocumentRequest {
  document: File;
  comment: string;
  documentType?: DocumentType.RISK_DOCUMENT | DocumentType.VISA_SCREENING;
}

export interface ContractSupplement {
  id: SupplementId;
  created: UTCDate;
  completed?: UTCDate;
  requestedBy?: string; //Namn på användaren

  request: string;
  translatedRequest?: string;

  response?: string;
  translatedResponse?: string;

  document?: RiskDocument;
}

export interface RiskQueueItem {
  id: ContractId;
  created: UTCDate;
  claimed?: UTCDate;
  claimedBy?: string;
  country: CountryCode;
  companyName: string;
  organizationNumber: string;
  answeredSupplements: number;
  requestedSupplements: number;
}

export interface RiskClosedItem {
  id: ContractId;
  created: UTCDate;
  closed: UTCDate;
  country: CountryCode;
  decision: RiskDecision;
  companyName: string;
  organizationNumber: string;
}
