import React, { ReactNode } from "react";
import cx from "classnames";
import styles from "./Box.module.scss";
import { Status } from "../../modules/Forms/FormContext";
import { MdInfo } from "react-icons/md";

interface Props {
  children: ReactNode;
  className?: string;
  status?: Status;
  noIcon?: boolean;
  fontSize?: "small" | "regular";
}

const iconMap: Record<Status, ReactNode> = {
  [Status.DEFAULT]: <MdInfo />,
  [Status.DISABLED]: <MdInfo />,
  [Status.ERROR]: <MdInfo />,
  [Status.PENDING]: <MdInfo />,
  [Status.SUCCESS]: <MdInfo />,
  [Status.WARNING]: <MdInfo />,
};

export const Box: React.FunctionComponent<Props> = ({
  children,
  className,
  status = Status.DEFAULT,
  noIcon = false,
  fontSize = "regular",
}) => {
  if (noIcon) {
    return (
      <div
        className={cx(styles.box, className, styles[status], {
          [styles.small]: fontSize === "small",
        })}
      >
        {children}
      </div>
    );
  }

  return (
    <div
      className={cx(styles.box, styles.hasIcon, className, styles[status], {
        [styles.small]: fontSize === "small",
      })}
    >
      <div className={styles.icon}>{iconMap[status]}</div>
      <div className={styles.body}>{children}</div>
    </div>
  );
};
