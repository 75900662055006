import { Country, TerminalType } from "./models/ContractTypes";

export enum STORE_KEY {
  STORE_HAS_PREVIOUS_LOGIN = "store.session.previous",
  STORE_SAVED_QUERY = "store.session.query",
}

export enum QueryKey {
  VOUCHER = "voucher",
  CAMPAIGN_ID = "campaignId",
  PROMOTION_CODE = "promotionCode",
  COUNTRY = "country",
  TRACKING_ID = "trackingId",
  SESSION = "sessionId",
  LANGUAGE = "language",
  USE_ROARING_BANKS = "roaring",
}

export interface SearchParams {
  [TerminalType.DX8000]?: number;
  [TerminalType.MOVE5000]?: number;
  [QueryKey.VOUCHER]?: string;
  [QueryKey.CAMPAIGN_ID]?: string;
  [QueryKey.COUNTRY]?: Country;
  [QueryKey.TRACKING_ID]?: string;
  [QueryKey.SESSION]?: string;
  [QueryKey.LANGUAGE]?: string;
  [QueryKey.USE_ROARING_BANKS]?: boolean;
}

export interface LegalEntityStoreObject {
  [key: string]: boolean | undefined;
}

interface StoredData {
  [STORE_KEY.STORE_HAS_PREVIOUS_LOGIN]: string | undefined;
  [STORE_KEY.STORE_SAVED_QUERY]: SearchParams;
}

const NAME = "wlx-nordic-self-onboarding";

const defaultValues = {
  [STORE_KEY.STORE_HAS_PREVIOUS_LOGIN]: undefined,
  [STORE_KEY.STORE_SAVED_QUERY]: {},
};

let storedData: StoredData = { ...defaultValues };
const storedStringData = window.localStorage.getItem(NAME);
if (storedStringData) {
  try {
    storedData = { ...defaultValues, ...JSON.parse(storedStringData) };
  } catch (err) {}
}

export const Store = {
  getValue<T extends keyof StoredData>(key: T): StoredData[T] {
    return storedData[key];
  },

  setValue<Key extends keyof StoredData>(key: Key, value: StoredData[Key]) {
    storedData[key] = value;
    try {
      window.localStorage.setItem(NAME, JSON.stringify(storedData));
    } catch (err) {
      //Do not log
    }
  },

  getStorage() {
    return storedData;
  },
};
