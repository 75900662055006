import React from "react";
import cx from "classnames";
import styles from "./TermsOfService.module.scss";
import { useTranslation } from "react-i18next";
import { Block } from "../../components/Boxes/Block";

interface Props {
  currency: string;
}

export const TermsOfService: React.FunctionComponent<Props> = ({
  currency,
}) => {
  const { t } = useTranslation();

  return (
    <Block>
      <div className={cx(styles.terms)}>
        <b className="small">{t("Terms of Service")}</b>

        <ul className="text-08">
          <li>{t("The monthly cost is invoiced monthly in advance.")}</li>
          <li>
            {t(
              "The cost of Visa, MasterCard, Maestro, DinersClub, UnionPay and JCB is deducted from the payout you receive."
            )}
          </li>
          <li>
            {t(
              "Payment of redeemed account transactions takes place in {{currency}} on the banking day after the account card transaction has been completed.",
              {
                currency,
              }
            )}
          </li>
        </ul>
      </div>
    </Block>
  );
};
