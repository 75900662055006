import React, { useCallback, useMemo } from "react";
import cx from "classnames";
import styles from "./PrePayment.module.scss";
import { useTranslation } from "react-i18next";
import {
  BusinessRisk,
  PrepaidOptions,
} from "../../../../data/models/ContractTypes";
import { ButtonPane } from "../../../../components/Buttons/ButtonPane";
import { HiddenInput } from "../../../../modules/Forms/HiddenInput";
import { RequiredValidator } from "../../../../modules/Forms/validators/RequiredValidator";
import { Dynamic } from "../../../../components/Animations/Dynamic";
import { SelectableButtons } from "../../../../components/Buttons/SelectableButtons";
import { MinValidator } from "../../../../modules/Forms/validators/MinValidator";
import { MaxValidator } from "../../../../modules/Forms/validators/MaxValidator";
import { TFunction } from "i18next";

const Labels = (t: TFunction): Partial<Record<PrepaidOptions, string>> => ({
  [PrepaidOptions.FIFTEEN_THIRTY]: t("0 - 30 days"),
  [PrepaidOptions.SIXTY_ONE__NINETY]: t("31 - 90 days"),
  [PrepaidOptions.ONE_HUNDRED_EIGHTY_ONE__TWO_HUNDRED_SEVENTY]:
    t("91 - 270 days"),
  [PrepaidOptions.GREATER_THAN_TWO_HUNDRED_SEVENTY]: t("More than 270 days"),
});

interface Props {
  risk: BusinessRisk;
  onChange: (event: React.MouseEvent<HTMLButtonElement>, data: any) => void;
}

export const PrePayment: React.FunctionComponent<Props> = ({
  risk,
  onChange,
}) => {
  // TODO Move to modules
  const { t } = useTranslation();

  const onClick = useCallback(
    (ev: any, value: any) => {
      onChange(ev, {
        prepaidOptions: {
          ...risk.prepaidOptions,
          [value.key]: value.value,
        },
      });
    },
    [onChange, risk]
  );

  const options = useMemo(() => {
    return [
      {
        value: 0,
        text: t("~ 0%"),
      },
      {
        value: 50,
        text: t("~ 50%"),
      },
      {
        value: 100,
        text: t("~ 100%"),
      },
    ];
  }, [t]);

  const totalPercentage = useMemo(() => {
    return Object.entries(Labels(t)).reduce((acc, cur) => {
      const key = cur[0] as keyof PrepaidOptions;

      if (!risk.prepaidOptions) {
        return acc;
      }

      return acc + ((risk.prepaidOptions as any)[key] || 0);
    }, 0);
  }, [risk.prepaidOptions, t]);

  return (
    <div className={cx(styles.wrapper)}>
      <b>{t("Does your company accept pre-payment by credit card?")}</b>
      <ButtonPane className="mt-1">
        <ButtonPane.Button
          variant={risk.hasPrepaid === true ? "selected" : "outlined"}
          data={{
            hasPrepaid: true,
          }}
          onClick={onChange}
        >
          {t("Yes")}
        </ButtonPane.Button>

        <ButtonPane.Button
          variant={risk.hasPrepaid === false ? "selected" : "outlined"}
          data={{
            hasPrepaid: false,
          }}
          onClick={onChange}
        >
          {t("No")}
        </ButtonPane.Button>
      </ButtonPane>

      <HiddenInput
        value={risk.hasPrepaid === undefined ? undefined : true}
        validators={[new RequiredValidator(t("Required value"))]}
      />

      <Dynamic name={risk.hasPrepaid ? "yes" : "no"}>
        {risk.hasPrepaid ? (
          <div className="pt-2">
            <div className="pb-4">
              <i>{t("Average number of days from payment to delivery")}</i>

              {Object.entries(Labels(t)).map(([key, label]) => {
                const value =
                  risk.prepaidOptions &&
                  Number(risk.prepaidOptions[key as PrepaidOptions]);
                return (
                  <div key={key} className={cx("mt-2", styles.inputWrapper)}>
                    <strong className="small">{t(label)}</strong>
                    <SelectableButtons
                      className="mt-1"
                      size="small"
                      color="warning"
                      onClick={onClick}
                      value={value ?? ""}
                      options={options}
                      data={{
                        key,
                      }}
                    />
                  </div>
                );
              })}

              <HiddenInput
                value={totalPercentage}
                validators={[
                  new MinValidator(100, t("Total value must add up to 100%")),
                  new MaxValidator(100, t("Total value can not exceed 100%")),
                ]}
              />
            </div>
          </div>
        ) : null}
      </Dynamic>
    </div>
  );
};
