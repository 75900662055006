import { QueryStatus } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Status } from "../../../modules/Forms/FormContext";

type InternalQueryStatus = QueryStatus | "idle";

interface DebouncedStatusOptions {
  delay?: number;
  disabled?: boolean;
}

export function useDebouncedStatus(
  value: InternalQueryStatus,
  options?: DebouncedStatusOptions
): Status {
  const internalOptions: DebouncedStatusOptions = {
    delay: 2500,
    disabled: false,
    ...options,
  };

  const [debouncedValue, setDebouncedValue] = useState<Status>(
    QueryStatusToStatus[value]
  );

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(Status.DEFAULT);
    }, internalOptions.delay);

    setDebouncedValue(QueryStatusToStatus[value]);

    return () => {
      clearTimeout(handler);
    };
  }, [value, internalOptions.delay]);

  return internalOptions.disabled ? Status.DISABLED : debouncedValue;
}

export const statusFromQuery = (status: InternalQueryStatus) =>
  QueryStatusToStatus[status];

const QueryStatusToStatus: Record<InternalQueryStatus, Status> = {
  idle: Status.DEFAULT,
  pending: Status.PENDING,
  error: Status.ERROR,
  success: Status.SUCCESS,
};
