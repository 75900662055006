import { BaseValidator } from "./BaseValidator";
import { ReactNode } from "react";
import { VALIDATION_STATE } from "../FormContext";
import { ValidationResponse } from "../hooks/useValidation";

export class ExternalValueValidator<T> extends BaseValidator {
  name = "ExternalValueValidator";

  constructor(
    error: ReactNode,
    private externalValue: T,
    private targetValue: T
  ) {
    super(error);
    this.name = `ExternalValueValidator-${externalValue}-${targetValue}`;
  }

  validate(): Promise<ValidationResponse> {
    return new Promise<ValidationResponse>((resolve) => {
      if (this.externalValue === this.targetValue) {
        resolve({ status: VALIDATION_STATE.SUCCESS });
      } else {
        resolve({
          status: VALIDATION_STATE.FAILED,
          message: this.error,
        });
      }
    });
  }
}
